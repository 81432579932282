import { useCallback } from 'react';

import ROUTE from 'constants/route';

const useCurrentPage = () => {
  const currentPage = useCallback(paths => {
    if (!paths) return false;
    const urls = typeof paths === 'string' ? [paths] : paths;

    return !!urls?.find(url => window.location.href.includes(url));
  }, []);

  const currentPageIncludesAll = useCallback(paths => {
    if (!paths) return false;

    return paths.every(path => window.location.href.includes(path));
  }, []);

  const exactPage = useCallback(paths => {
    if (!paths) return false;
    const urls = typeof paths === 'string' ? [paths] : paths;
    const pathnameNoSlash = window.location.pathname.substring(1);
    const pathname = pathnameNoSlash.includes('/')
      ? pathnameNoSlash.substring(0, pathnameNoSlash.indexOf('/'))
      : pathnameNoSlash;

    return !!urls?.find(url => pathname === url.substring(1));
  }, []);

  const isSocialMonitoring = currentPage(ROUTE.socialMonitoringUrl);
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);
  const isInsightsCapture = exactPage(ROUTE.captureInsights);
  const isElsieAi = currentPage(ROUTE.elsieAi);
  const isInsights = exactPage(ROUTE.insights);

  return {
    currentPage,
    currentPageIncludesAll,
    isSocialMonitoring,
    isGkp,
    exactPage,
    isInsightsCapture,
    isElsieAi,
    isInsights,
  };
};

export default useCurrentPage;
