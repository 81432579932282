import { useModal } from 'context/modalContext';

import { EditCollectionModal } from 'Pages/Bookmarks/Modals';

export const EditCollectionMenuItem = ({ collection, label }) => {
  const { openModal } = useModal();

  return {
    label: 'Edit Collection',
    onClick: () => openModal(<EditCollectionModal collection={collection} label={label} />),
  };
};
