import { useState } from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { capitaliseFirstLetter } from 'utils/formatStrings';

import Modal from 'common/Modals/Modal';
import Scroll from 'common/Scroll/Scroll';

import { CollectionItem } from 'Pages/Bookmarks/Components';

import styles from './CollectionManagementModal.module.scss';

export const CollectionManagementModal = ({
  title,
  allCollections,
  selectedCollections,
  type,
  onSave,
  createCollection,
}) => {
  const { closeModal } = useModal();
  const [selectedItems, setSelectedItems] = useState([...selectedCollections]);

  const onSelectItem = collectionID => {
    let newItems;
    if (selectedItems?.includes(collectionID)) {
      newItems = selectedItems.filter(itemId => itemId !== collectionID);
    } else {
      newItems = [...selectedItems, collectionID];
    }

    setSelectedItems(newItems);
  };

  return (
    <Modal
      title={title}
      width="medium"
      height="60vh"
      onSave={() => {
        onSave && onSave(selectedItems);
      }}
      onCancel={() => {
        closeModal();
      }}
    >
      <section className={styles['modal-content']}>
        <h6>
          {type === 'list'
            ? 'Add/Remove Profile to existing Lists below'
            : `Add/Remove ${capitaliseFirstLetter(type)} to existing Collections below`}
        </h6>
        <div className={styles['collection-container']}>
          <Scroll maxHeight="40vh" hideArrow>
            {allCollections?.map(collection => (
              <CollectionItem
                collection={collection}
                key={collection.collectionID || collection.collectionID}
                collectionType={type === 'contribution' ? 'bookmark' : type}
                selected={selectedItems?.includes(
                  collection.collectionID || collection.collectionID,
                )}
                onClick={() => onSelectItem(collection.collectionID || collection.collectionID)}
                className={styles['collection-item']}
                showNameOnly
              />
            ))}
          </Scroll>
        </div>
      </section>

      {createCollection?.map((createElement, index) => (
        <section key={`create-collection-${index}`} className={styles['create-section']}>
          {createElement}
        </section>
      ))}
    </Modal>
  );
};

CollectionManagementModal.propTypes = {
  title: PropTypes.string,
  allCollections: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  onSave: PropTypes.func,
  selectedCollections: PropTypes.array,
  createCollection: PropTypes.array,
};

CollectionManagementModal.defaultProps = {
  title: 'Manage Collections',
  allCollections: [],
  type: 'Event',
  onSave: undefined,
  selectedCollections: [],
  createCollection: [],
};
