import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';
import { useLists } from 'context/listsContext';
import { useModal } from 'context/modalContext';

import Button from 'common/button/Button';

import SearchProfilesMenuContent from 'modules/socialMonitoring/common/cardMenus/SearchProfilesMenuContent';

import styles from './AddKolModal.module.scss';

export const AddKolModal = ({ list, disabled, kolCount, showTitle }) => {
  const { addProfilesToList } = useLists();
  const { isDesktop } = useLayout();
  const { openModal, closeModal } = useModal();

  const onSelect = (listId, profileId) => {
    addProfilesToList(listId, profileId);
    closeModal();
  };

  return (
    <div className={styles.kolButtonCount}>
      <Button
        buttonType="transparent"
        icon="kol"
        disabled={disabled}
        onClick={() =>
          openModal(
            <SearchProfilesMenuContent
              onSelect={onSelect}
              listId={list?.listId}
              onCancel={closeModal}
            />,
          )
        }
      >
        {showTitle && isDesktop ? 'Add Profile' : ''}
      </Button>
      {kolCount}
    </div>
  );
};

AddKolModal.propTypes = {
  list: PropTypes.shape({
    listId: PropTypes.string.isRequired,
    listName: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.string,
    notifFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    koLs: PropTypes.array,
  }).isRequired,
  disabled: PropTypes.bool,
  kolCount: PropTypes.number,
  showTitle: PropTypes.bool,
};

AddKolModal.defaultProps = {
  disabled: false,
  kolCount: 0,
  showTitle: true,
};
