import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Footer from 'common/login/Footer';

import styles from './RouteContainer.module.scss';

const RouteContainer = ({ fullScreen, popupShown, children }) => {
  const { hasSideBar } = useLayout();

  const { currentPage } = useCurrentPage();
  const containerRef = useRef();

  // not sure why this is a special case, maybe because it is a details page within another details page
  const showSideBar = !currentPage([ROUTE.attendeeDetails]) && hasSideBar;
  const showFooter = currentPage([ROUTE.userSettings]);

  return (
    <>
      <section
        ref={containerRef}
        className={`${styles.container} ${showSideBar ? styles['side-bar'] : ''} ${
          fullScreen ? styles['full-screen'] : ''
        } `}
        style={{
          overflow: popupShown ? 'hidden' : undefined,
          height: popupShown ? '100vh' : undefined,
        }}
      >
        {children}
      </section>
      {showFooter && <Footer fixed />}
    </>
  );
};

RouteContainer.propTypes = {
  popupShown: PropTypes.bool,
  fullScreen: PropTypes.bool,
  children: PropTypes.node,
};

RouteContainer.defaultProps = {
  popupShown: false,
  fullScreen: false,
  children: null,
};

export default RouteContainer;
