import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';
import IconText from 'common/login/IconText';

import Footer from '../login/Footer';
import Header from '../login/Header';

import styles from './CompliancePage.module.scss';

const CompliancePage = ({ title, downloadLink, children }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = downloadLink;
    link.setAttribute('download', '');
    link.click();
  };

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles['title-container']}>
          <h4>{title}</h4>
          <IconText text="Export PDF" onClick={downloadPdf}>
            <Icon name="pdf" />
          </IconText>
        </div>
        <div className={styles.content}>{children}</div>
      </div>
      <Footer fixed />
    </>
  );
};

CompliancePage.propTypes = {
  title: PropTypes.string,
  downloadLink: PropTypes.string,
  children: PropTypes.array,
};

CompliancePage.defaultProps = {
  title: '',
  downloadLink: '',
  children: [],
};

export default CompliancePage;
