import PropTypes from 'prop-types';

import { useConfirm } from 'context/confirmationContext';
import { useFilterMenu } from 'context/filterMenuContext';
import { useFilters } from 'context/filtersContext';
import { useLayout } from 'context/layoutContext';
import { useSlideDrawer } from 'context/slideDrawerContext';

import Button from 'common/button/Button';
import { FiltersMenu } from 'common/filtersBar/FiltersMenu';

export const AddFiltersAction = ({ search, disabled }) => {
  const { isDesktop } = useLayout();

  const { closeSlideDrawer, openSlideDrawer } = useSlideDrawer();
  const { confirm } = useConfirm();
  const { saveSearch, resetFilter } = useFilters();
  const { clearChanges } = useFilterMenu();

  const updateSavedSearch = filters => {
    saveSearch({
      ...search,
      filter: { ...search.filter, ...filters },
      notifFrequency: undefined,
    });
    resetFilter();
    clearChanges();
    closeSlideDrawer();
  };

  const onApply = filters => {
    confirm({
      title: 'Add Filters',
      text: 'Are you sure you want to add filters?',
      onConfirm: () => updateSavedSearch(filters),
    });
  };

  return (
    <Button
      buttonType="transparent"
      icon="magnifyingGlass"
      onClick={() => {
        resetFilter();
        openSlideDrawer({
          drawerTitle: 'Add filters',
          drawerContent: <FiltersMenu onApply={onApply} />,
        });
      }}
      disabled={disabled}
    >
      {isDesktop ? 'Add Filters' : ''}
    </Button>
  );
};

AddFiltersAction.propTypes = {
  search: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
};

AddFiltersAction.defaultProps = {
  disabled: false,
};
