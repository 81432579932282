import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useLayout } from 'context/layoutContext';

import styles from './StickyHeight.module.scss';

const Container = styled.section`
  height: ${props => `calc(100vh - (${props.height}px + 1.5rem))`};
  top: ${props => `calc(${props.height}px + .75rem)`};

  @media (min-width: 1200px) {
    height: ${props => `calc(100vh - (${props.height}px + 2rem))`};
    top: ${props => `calc(${props.height}px + 1rem)`};
  }
`;

const StickyHeight = ({ children, className, disableSticky }) => {
  const { tarquinValue } = useLayout();

  return (
    <Container
      className={`
        ${styles.container} 
        ${className} 
        ${disableSticky ? styles['disable-sticky'] : ''}
      `}
      height={tarquinValue}
    >
      {children}
    </Container>
  );
};

StickyHeight.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disableSticky: PropTypes.bool,
};

StickyHeight.defaultProps = { disableSticky: false, className: '' };

export default StickyHeight;
