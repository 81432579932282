import PropTypes from 'prop-types';

import styles from './Grid.module.scss';

const Grid = ({ children, columns, gap, rows, noGap, className, customStyles }) => (
  <section
    className={`
      ${styles[`grid-${columns}-${rows}`]} 
      ${noGap ? styles['no-gap'] : ''} 
      ${className || ''}
    `}
    style={{ gap, ...customStyles }}
  >
    {children}
  </section>
);

Grid.propTypes = {
  columns: PropTypes.number,
  gap: PropTypes.number,
  rows: PropTypes.number,
  noGap: PropTypes.bool,
  className: PropTypes.string,
  customStyles: PropTypes.shape({}),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
};

Grid.defaultProps = {
  columns: 12,
  rows: 1,
  gap: undefined,
  noGap: false,
  className: '',
  customStyles: undefined,
  children: undefined,
};

export default Grid;
