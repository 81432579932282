import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import Icon from 'common/Icon/Icon';
import { FixedTooltip } from 'common/tooltip';

import styles from './OnOffTopicSelector.module.scss';

export const OnOffTopicSelector = ({ topicName, setTopicName }) => {
  const { setFilter } = useFilters();
  const topicValues = {
    'On-Topic scientific content': ['1'],
    'Off-Topic scientific content': ['0'],
    'All scientific content': ['0', '1'],
  };

  const selectTopic = name => {
    setTopicName(name);
    setFilter({ onTopic: topicValues[name] });
  };

  return (
    <DropDownMenu
      menuItems={[
        {
          label: (
            <>
              <span>On-Topic scientific content</span>
              <FixedTooltip
                content={
                  <span>
                    <b>On-Topic:</b> includes only scientific content inside your disease or
                    therapeutic area
                  </span>
                }
                position="right"
                containerClass={styles.icon}
              >
                <Icon name="info" size={12} />
              </FixedTooltip>
            </>
          ),
          onClick: () => selectTopic('On-Topic scientific content'),
        },
        {
          label: (
            <>
              <span>Off-Topic scientific content</span>
              <FixedTooltip
                content={
                  <span>
                    <b>Off-Topic:</b> includes only scientific content outside of your disease or
                    therapeutic area
                  </span>
                }
                position="right"
                containerClass={styles.icon}
              >
                <Icon name="info" size={12} />
              </FixedTooltip>
            </>
          ),
          onClick: () => selectTopic('Off-Topic scientific content'),
        },
        {
          label: (
            <>
              <span>All scientific content</span>
              <FixedTooltip
                content={
                  <span>
                    <b>All:</b> includes all scientific content both inside and outside of your
                    disease or therapeutic area
                  </span>
                }
                position="right"
                containerClass={styles.icon}
              >
                <Icon name="info" size={12} />
              </FixedTooltip>
            </>
          ),
          onClick: () => selectTopic('All scientific content'),
        },
      ]}
      clickElement={
        <Button buttonType="filter" size="small">
          {topicName}
        </Button>
      }
    />
  );
};

OnOffTopicSelector.propTypes = {
  topicName: PropTypes.string.isRequired,
  setTopicName: PropTypes.func.isRequired,
};
