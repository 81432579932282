import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useFilterMenu } from 'context/filterMenuContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import DropdownSubmenuEmpty from 'common/formControls/DropdownSubmenuEmpty';
import Icon from 'common/Icon/Icon';

import styles from './CollectionFilter.module.scss';

export const CollectionFilter = ({ eventKey }) => {
  const { collections } = useCollections();
  const { internalFilters, setFilter, removeFilter } = useFilterMenu();

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  return (
    <div ref={filterRef}>
      {collections?.map(collection => (
        <div
          key={collection.collectionID}
          className={styles['filter-collection-item']}
          onClick={() => {
            if (
              !internalFilters?.collection?.find(
                collfilter => collfilter.id === collection.collectionID,
              )
            ) {
              setFilter({
                filterKey: 'collection',
                filterValue: {
                  id: collection.collectionID,
                  reference: collection.collectionName,
                },
              });
            } else {
              removeFilter({
                filterKey: 'collection',
                filterValue: { id: collection.collectionID },
              });
            }
          }}
        >
          {internalFilters?.collection?.find(
            collfilter => collfilter.id === collection.collectionID,
          ) ? (
            <span className={`${styles['selected-collection-name']} ${styles['collection-name']}`}>
              {collection.collectionName}
              <Icon name="cross" size={16} />
            </span>
          ) : (
            <span className={styles['collection-name']}>{collection.collectionName}</span>
          )}
        </div>
      ))}
      {!collections?.length && <DropdownSubmenuEmpty text="No collections for this report" />}
    </div>
  );
};

CollectionFilter.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
