import PropTypes from 'prop-types';

import styles from './MobileCard.module.scss';

export const MobileCard = ({ titleWithUnderline, title, className, subText, children }) => {
  return (
    <div className={`${styles['mobile-card']} ${className}`}>
      {titleWithUnderline && <div className={styles['title-underline']}>{titleWithUnderline}</div>}
      {title && <div className={styles.title}>{title}</div>}
      {subText && <div className={styles['sub-text']}>{subText}</div>}

      {children}
    </div>
  );
};

MobileCard.propTypes = {
  titleWithUnderline: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  title: PropTypes.string,
  subText: PropTypes.string,
};
MobileCard.defaultProps = {
  titleWithUnderline: undefined,
  children: undefined,
  className: undefined,
  title: undefined,
  subText: undefined,
};
