import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useFilters } from 'context/filtersContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import Scroll from 'common/Scroll/Scroll';
import { useSearchBar } from 'common/searchBar/useSearchBar';

export const ListFilter = ({ filterKey, searchTitle, eventKey }) => {
  const { currentProject } = useSocialMonitoring();
  const { selectedValues, setFilter, removeFilter, optionsHeight, resetFilter } = useFilterMenu();
  const { loadingReferences, filterReferenceElements } = useFilters();
  const selectedItems = selectedValues(filterKey);
  const filterOptions = filterReferenceElements?.[filterKey] || [];
  const optionLimit = 100;
  const enableSearch = filterOptions?.length > 10;
  const filterVariable = JSON.stringify({ currentProject, filterReferenceElements });
  const {
    searchValue: searchText,
    searchBar: SearchBarComponent,
    setSearchText,
  } = useSearchBar({
    placeholder: 'Type to search',
    hideSearchIcon: true,
    whiteBackground: true,
    clearSearch: true,
  });

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  useEffect(() => {
    setSearchText('');
  }, [filterVariable, setSearchText]);

  const addItem = item => {
    setFilter({ filterKey, filterValue: { id: item.id, reference: item.reference } });
  };

  const removeItem = item => {
    removeFilter({ filterKey, filterValue: item });
  };

  const addItems = items => {
    items.forEach(item => addItem(item));
  };

  const items = filterOptions?.filter(
    option =>
      !selectedItems?.find(
        item => item.reference === option.reference || item === option.reference,
      ) && option?.reference?.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <div ref={filterRef}>
      <div className="pb-3 px-3">
        {selectedItems?.length > 0 && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="font-weight-bold ">Selected items</div>
              <Button
                icon="eraser"
                size="small"
                buttonType="secondary"
                onClick={() => resetFilter(filterKey)}
              />
            </div>
            <div className="d-flex flex-wrap gap-8">
              {selectedItems.map((item, index) => (
                <div key={`${eventKey}-${item.reference}-${index}`} className="flex-fill">
                  <Button
                    buttonType="secondary"
                    size="small"
                    block
                    secondaryAction={() => removeItem(item)}
                  >
                    {item.reference || item}
                  </Button>
                </div>
              ))}
            </div>
            <hr />
          </>
        )}

        {enableSearch && SearchBarComponent}

        <div className={enableSearch ? 'mt-3' : ''}>
          <div className="d-flex justify-content-between mb-2">
            <div className="font-weight-bold mb-2">
              {searchTitle || `Top ${items.length >= optionLimit ? optionLimit : ''} Results`}
            </div>
            <Button size="small" buttonType="link" onClick={() => addItems(items)}>
              Select all
            </Button>
          </div>
          <Scroll className="d-flex flex-wrap gap-8" maxHeight={optionsHeight} hideArrow>
            {loadingReferences && <LoaderSpinner />}
            {!loadingReferences &&
              items.slice(0, optionLimit).map((option, index) => (
                <div key={`${eventKey}-${option.reference}-${index}`} className="flex-fill">
                  <Button buttonType="secondary" size="small" block onClick={() => addItem(option)}>
                    {option.reference}
                  </Button>
                </div>
              ))}
          </Scroll>
        </div>
      </div>
    </div>
  );
};

ListFilter.propTypes = {
  searchTitle: PropTypes.string,
  filterKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ListFilter.defaultProps = {
  searchTitle: '',
};
