import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Scroll from 'common/Scroll/Scroll';

import { CollectionItemsGroup } from 'Pages/Bookmarks/Components/CollectionItemsGroup';

import { Card } from '../Card';

import { CollectionCardMenuOptions } from './CollectionCardMenuOptions';

export const CollectionCard = ({ height, selectedItems }) => {
  const { collectionsLoading, collections: contextCollections } = useCollections();
  const { setFilter, getFilter, resetFilter } = useFilters();
  const menuOptions = CollectionCardMenuOptions({ selectedItems });
  const { currentPage } = useCurrentPage();
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);
  const { eventName } = useGlobalKolPlanningContext();
  const collectionType = isGkp ? eventName?.toLowerCase() : 'bookmark';
  const selectedCollection = contextCollections?.findIndex(
    collection => collection.collectionID === getFilter('collection')?.[0]?.id,
  );

  return (
    <Card
      title="Collections"
      fullScreen
      loading={collectionsLoading}
      height={height}
      menuOptions={menuOptions}
    >
      <Scroll hideArrow>
        <CollectionItemsGroup
          collections={contextCollections}
          onClick={({ collection }) => {
            if (
              getFilter('collection') &&
              getFilter('collection')?.find(item => item.id === collection.collectionID)
            ) {
              if (getFilter('collection')?.length === 1) {
                resetFilter('collection');
              } else {
                const removeSelectedFilter = getFilter('collection').filter(
                  item => item.id !== collection.collectionID,
                );
                setFilter({ collection: removeSelectedFilter });
              }
            } else {
              const addNewFilter = getFilter('collection')
                ? [
                    ...getFilter('collection'),
                    { id: collection.collectionID, reference: collection.collectionName },
                  ]
                : [{ id: collection.collectionID, reference: collection.collectionName }];
              setFilter({ collection: addNewFilter });
            }
          }}
          selectedCollection={selectedCollection}
          collectionType={collectionType}
          useAppliedCollectionsFilter
        />
      </Scroll>
    </Card>
  );
};

CollectionCard.propTypes = {
  height: PropTypes.string,
  selectedItems: PropTypes.array,
};

CollectionCard.defaultProps = {
  height: undefined,
  selectedItems: [],
};
