import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { useProfileLists } from 'utils/hooks';

import Button from 'common/button/Button';
import { CollectionManagementModal } from 'common/Modals/CollectionManagementModal';

import { AddListAction } from 'Pages/Lists/Components/Actions';

export const ProfileCollections = ({ profileId, disabled }) => {
  const { openModal } = useModal();
  const { allCollections, selectedLists, onSave } = useProfileLists({ profileId });

  const onClick = () => {
    openModal(
      <CollectionManagementModal
        title="Manage Lists"
        allCollections={allCollections}
        selectedCollections={selectedLists}
        createCollection={[<AddListAction profiles={[profileId]} />]}
        onSave={onSave}
        type="list"
      />,
    );
  };

  return (
    <Button
      buttonType="transparent"
      icon="listAdd"
      disabled={disabled}
      onClick={onClick}
      hideArrow
    />
  );
};

ProfileCollections.propTypes = {
  profileId: PropTypes.string,
  disabled: PropTypes.bool,
};
ProfileCollections.defaultProps = {
  profileId: undefined,
  disabled: false,
};
