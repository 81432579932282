import PropagateLoader from 'react-spinners/PropagateLoader';
import { ReactComponent as LoadingIconExternal } from 'assets/icons/loaderExternal.svg';
import { ReactComponent as LoadingIconInternal } from 'assets/icons/loaderInternal.svg';
import PropTypes from 'prop-types';

import styles from './LoaderSpinner.module.scss';

const renderSpinner = ({ spinnerType, color, size }) => {
  const fillColor = color || '#F87602';
  switch (spinnerType) {
    case 'dots':
      return <PropagateLoader color={color} loading size={size} />;
    default:
      return (
        <div className={styles.animationContainer}>
          <LoadingIconInternal className={styles.animateFadeInAndOut} fill={fillColor} />
          <LoadingIconExternal className={styles.animateSpin} fill={fillColor} />
        </div>
      );
  }
};

const LoaderSpinner = ({
  spinnerType,
  color,
  size,
  background,
  minHeight,
  minWidth,
  className,
}) => (
  <div className={`${styles.container} ${className}`} style={{ background, minHeight, minWidth }}>
    {renderSpinner({ spinnerType, color, size })}
  </div>
);

LoaderSpinner.propTypes = {
  spinnerType: PropTypes.oneOf(['logo', 'dots']),
  color: PropTypes.string,
  size: PropTypes.number,
  background: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
};

LoaderSpinner.defaultProps = {
  spinnerType: 'logo',
  color: '#F87602',
  background: 'transparent',
  size: 20,
  minHeight: 60,
  minWidth: undefined,
  className: undefined,
};

export default LoaderSpinner;
