import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

const ContributionSentimentIcon = ({ sentiment }) => {
  switch (sentiment.toLowerCase()) {
    case 'negative':
      return <Icon name="sadFace" />;
    case 'neutral':
      return <Icon name="neutralFace" />;
    case 'positive':
      return <Icon name="happyFace" />;
    default:
      return <Icon name="neutralFace" />;
  }
};

ContributionSentimentIcon.propTypes = {
  sentiment: PropTypes.string,
};
ContributionSentimentIcon.defaultProps = {
  sentiment: undefined,
};

export default ContributionSentimentIcon;
