import PropTypes from 'prop-types';

import Button from 'common/button/Button';

import styles from './MobileFiltersContainer.module.scss';

export const MobileFilterIcon = ({ appliedFilters, onClick }) => {
  return (
    <span onClick={() => onClick && onClick()}>
      <Button
        buttonType="transparent"
        icon="funnel"
        className={appliedFilters > 0 ? styles['applied-filters'] : null}
      />
      {appliedFilters > 0 && (
        <span className={`${styles['filter-count-badge']} ${styles['filter-count-background']}`}>
          {appliedFilters}
        </span>
      )}
    </span>
  );
};

MobileFilterIcon.propTypes = {
  appliedFilters: PropTypes.number,
  onClick: PropTypes.func,
};

MobileFilterIcon.defaultProps = {
  appliedFilters: undefined,
  onClick: undefined,
};
