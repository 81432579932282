import { isMobileOnly } from 'react-device-detect';
import PropTypes from 'prop-types';

import styles from './ModuleItem.module.scss';

export const ModuleItem = ({ tabIndex, name, selected, onClick }) => {
  const selectedClassName = isMobileOnly ? styles['mobile-selected'] : styles.selected;
  return (
    <section
      tabIndex={tabIndex}
      className={`${isMobileOnly ? styles['mobile-item'] : styles.item} ${
        selected ? selectedClassName : ''
      }`}
      role="button"
      onClick={onClick}
    >
      {name}
    </section>
  );
};

ModuleItem.propTypes = {
  selected: PropTypes.bool,
  tabIndex: PropTypes.number,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

ModuleItem.defaultProps = {
  selected: false,
  tabIndex: 0,
  name: undefined,
  onClick: undefined,
};
