import PropTypes from 'prop-types';

import { formatDate } from 'utils/dateHelper';

import { Card } from '../Card';

import styles from './DetailsSection.module.scss';

export const DetailsSection = ({ title, items, loading }) => {
  const formatValues = (dataType, value) => {
    switch (dataType) {
      case 'Date':
        return formatDate(value);
      case 'Checkbox':
      case 'DropdownMulti':
        return Array.isArray(value) ? value.map(val => val?.label)?.join(', ') : value;
      case 'Radio':
      case 'DropdownSingle':
        return value?.label || '';
      default:
        return value;
    }
  };

  return (
    <Card title={title} loading={loading}>
      {items.map((item, index) => (
        <div key={`${title}-${index}`} className={styles['detail-row']}>
          <span className={styles['detail-label']}>{item.label}:</span>
          <span className={styles['detail-value']}>{formatValues(item.dataType, item.value)}</span>
        </div>
      ))}
    </Card>
  );
};

const Items = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  dataType: PropTypes.any,
});

DetailsSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(Items),
  loading: PropTypes.bool,
};

DetailsSection.defaultProps = {
  title: undefined,
  items: [],
  loading: false,
};
