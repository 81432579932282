import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import inputStyles from './Inputs.module.scss';

export const InputFile = ({
  className,
  name,
  label,
  required,
  error,
  maxSizeMessage,
  disabled,
  accept,
}) => {
  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field name={name}>
          {({ field: { onChange, value, ...fieldProps }, form: { setFieldValue } }) => (
            <>
              <input
                className={error ? inputStyles.error : ''}
                type="file"
                accept={accept}
                name={name}
                onChange={event => setFieldValue(name, event.target.files)}
                disabled={disabled}
                {...fieldProps}
              />
              <div className={inputStyles['error-message-container']}>
                <ErrorMessage name={name}>
                  {message => <span className={inputStyles.error}>{message}</span>}
                </ErrorMessage>
                {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
              </div>
              {maxSizeMessage && <div>{maxSizeMessage}</div>}
            </>
          )}
        </Field>
      </label>
    </div>
  );
};

InputFile.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  maxSizeMessage: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.array,
};

InputFile.defaultProps = {
  className: '',
  label: undefined,
  required: false,
  error: undefined,
  maxSizeMessage: undefined,
  disabled: false,
  accept: undefined,
};
