import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import './Kwellobutton.css';

/**
 * Primary UI component for user interaction
 */
export const KwelloButton = ({
  buttonType,
  size,
  label,
  disabled,
  icon,
  iconPlacement,
  type,
  className,
  ...props
}) => {
  const iconSize = () => {
    switch (size) {
      case 'large':
        return 16;
      case 'medium':
        return 16;
      case 'small':
        return 12;
      default:
        return 24;
    }
  };

  return (
    <button
      type={type}
      className={[
        'kwello-button',
        `kwello-button--${size}`,
        `kwello-button--${buttonType}`,
        `${className}`,
      ].join(' ')}
      disabled={disabled}
      {...props}
    >
      {icon && (
        <span className={`kwello-button-icon--${iconPlacement}`}>
          <span className="kwello-label">{label}</span>
          <span>
            <Icon name={icon} size={iconSize()} />
          </span>
        </span>
      )}
      {!icon && <span className="kwello-label">{label}</span>}
    </button>
  );
};

KwelloButton.propTypes = {
  /**
   * Is this the principal call to action on the page?
   */
  buttonType: PropTypes.oneOf([
    'loud',
    'loudOutline',
    'contrast',
    'contrastOutline',
    'soft',
    'softOutline',
  ]),

  /**
   * How large should the button be?
   */
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  /**
   * Button contents
   */
  label: PropTypes.string.isRequired,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  iconPlacement: PropTypes.oneOf(['right', 'left']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  className: PropTypes.string,
};

KwelloButton.defaultProps = {
  buttonType: 'loud',
  size: 'large',
  onClick: undefined,
  disabled: false,
  icon: undefined,
  iconPlacement: 'right',
  type: 'button',
  className: '',
};
