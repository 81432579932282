import { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {
  ChildrenContainer,
  Container,
  ShadowHorizontal,
  ShadowVertical,
  StyledScrollable,
} from './styles';

const defaultState = {
  width: 0,
  height: 0,
};

const Scrollable = ({
  height,
  maxHeight,
  maxWidth,
  children,
  isSearchMobile,
  parentClassName,
  className,
  noShadow,
  initialPosition = false,
  largeThumb = false,
  transparent = false,
}) => {
  const [parent, setParent] = useState(defaultState);
  const [child, setChild] = useState(defaultState);
  const parentResizeObserver = useRef(
    new ResizeObserver(entries => {
      const { width, height: newHeight } = entries[0].contentRect;
      setParent({
        width,
        newHeight,
      });
    }),
  );
  const childResizeObserver = useRef(
    new ResizeObserver(entries => {
      const { width, height: newHeight } = entries[0].contentRect;
      setChild({
        width,
        newHeight,
      });
    }),
  );

  const getParentWidthAndHeight = useCallback(container => {
    if (container !== null) {
      parentResizeObserver.current.observe(container);
    } else if (parentResizeObserver.current) parentResizeObserver.current.disconnect();
  }, []);

  const getChildWidthAndHeight = useCallback(container => {
    if (container !== null) {
      childResizeObserver.current.observe(container);
    } else if (childResizeObserver.current) childResizeObserver.current.disconnect();
  }, []);
  return (
    <Container
      className="containers"
      height={height}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      initialPosition={initialPosition}
    >
      {child.height > parent.height && !noShadow && (
        <ShadowHorizontal both={child.width > parent.width} />
      )}
      {child.width > parent.width && !noShadow && (
        <ShadowVertical both={child.height > parent.height} />
      )}
      <StyledScrollable
        className={`parent ${isSearchMobile ? parentClassName : ''}`}
        ref={getParentWidthAndHeight}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        largeThumb={largeThumb}
        transparent={transparent}
      >
        <ChildrenContainer ref={getChildWidthAndHeight} className={className}>
          {children}
        </ChildrenContainer>
      </StyledScrollable>
    </Container>
  );
};

Scrollable.propTypes = {
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  parentClassName: PropTypes.string,
  isSearchMobile: PropTypes.bool,
  noShadow: PropTypes.bool,
  initialPosition: PropTypes.bool,
  largeThumb: PropTypes.bool,
  transparent: PropTypes.bool,
};

Scrollable.defaultProps = {
  height: undefined,
  maxHeight: undefined,
  maxWidth: undefined,
  children: undefined,
  className: undefined,
  parentClassName: undefined,
  isSearchMobile: false,
  noShadow: false,
  initialPosition: false,
  largeThumb: false,
  transparent: false,
};

export default Scrollable;
