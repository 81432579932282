import styled from 'styled-components';

// TODO: Temporary fix set width and height
export const Container = styled.div`
  position: ${({ initialPosition }) => (initialPosition ? 'initial' : 'relative')};
  height: ${({ height }) => height || '100%'};
  width: 100%;
  ${({ maxHeight }) => `max-height: ${maxHeight}px`};
  ${({ maxWidth }) => `max-width: ${maxWidth}px`};
`;

// TODO: Temporary fix set width and height
export const StyledScrollable = styled.div`
  height: 100%;
  width: 100%;
  ${({ maxHeight }) => `max-height: ${maxHeight}px`};
  ${({ maxWidth }) => `max-width: ${maxWidth}px`};
  overflow: auto;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : '#fefefe')};

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ transparent }) => (transparent ? 'transparent' : 'var(--scrollbar-track)')};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ transparent }) =>
      transparent ? 'rgba(255, 255, 255, 0.2)' : 'var(--scrollbar-thumb)'};
    border-radius: 5px;
    ${({ largeThumb }) => (largeThumb ? 'height: 50px' : '')};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ transparent }) =>
      transparent ? 'rgba(255, 255, 255, 0.3)' : 'var(--scrollbar-thumb-hover)'};
  }
`;

export const ShadowHorizontal = styled.div`
  height: 10px;
  width: calc(100% - 12px);
  position: absolute;
  left: 0;
  bottom: ${({ both }) => (both ? '12px' : '0')};
  z-index: 2;
  box-shadow: inset 0 -7px 9px -7px rgba(0, 0, 0, 0.2);
`;

export const ShadowVertical = styled.div`
  width: 10px;
  height: calc(100% - 12px);
  position: absolute;
  right: ${({ both }) => (both ? '12px' : '0')};
  top: 0;
  z-index: 2;
  box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.2);
`;

export const ChildrenContainer = styled.div`
  display: block;
`;
