import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

export const SwitchContainer = styled.div``;

export const StyledTitle = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    & > svg {
      & > path {
        stroke: var(--primary-color);
      }
    }
  }
`;
export const SearchContainer = styled.div`
  margin: 0 0 0.5rem 0;
`;

export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: var(--border-radius-xs);
  background: #fafafa;
`;

export const StyledFormControl = styled(FormControl)`
  border: none;
  background: #fafafa;
  &:focus {
    background: #fafafa;
  }
`;

export const IconWrapper = styled.div`
  padding: 0 0 0 0.75rem;

  & > svg {
    height: 1rem;
    width: 1rem;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  margin: 0 0 0.5rem 0;

  &:hover {
    cursor: pointer;
  }
`;

export const ProfileImage = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background-color: #99999999;
  margin: 0 0.5rem 0 0;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  background-image: ${({ src }) => `url("${src}")`};
  background-position: left top;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const RadioInput = styled(props => <input type="radio" {...props} />)`
  margin: 0 0.5rem 0 0;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 2px;
  font-family: primary-font;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  padding: 0.25rem;
  margin: 0.5rem 0;
  white-space: nowrap;
`;

export const StyledEmptySubmenu = styled.div``;
