import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';

import { formatToCommaSeparated } from 'utils/formatNumbers';
import { useApiService } from 'utils/hooks/useApiService';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './AdvancedSearchPopup.module.scss';

import { PopupContainer, SearchLengthLabel, SearchTextArea } from './styles';
import { SearchStatus } from './useSearch';

const ShowSearchStatus = ({ status, error, count }) => {
  if (status === SearchStatus.INITIAL) return null;
  if (status === SearchStatus.SEARCHING) {
    return <LoaderSpinner minHeight={34} />;
  }

  if (status === SearchStatus.ERROR) {
    return (
      <Alert className="mb-0 pt-1 pb-1 d-flex align-items-center" variant="danger">
        <span className="mr-2">Query Invalid</span>
        <KwelloTooltip content={error}>
          <Icon name="info" />
        </KwelloTooltip>
      </Alert>
    );
  }

  return (
    <Alert className="mb-0 pt-1 pb-1 d-flex" variant="success">
      {`Query Valid - ${formatToCommaSeparated(count || 0)} possible results`}
    </Alert>
  );
};

const AdvancedSearchPopup = ({ onClose }) => {
  const maxLength = 4000;
  const { filters, setFilter, getFilter } = useFilters();
  const [searchText, setSearchText] = useState(getFilter('advancedSearch') || '');
  const [searchStatus, setSearchStatus] = useState({
    status: SearchStatus.INITIAL,
    count: 0,
    error: undefined,
  });
  const validatedText = useRef(searchText);
  const { projectId } = useParams();
  const api = useApiService();

  useEffect(() => {
    if (searchStatus.status === SearchStatus.SUCCESS) {
      setSearchStatus(prevState => ({ ...prevState, status: SearchStatus.INITIAL }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const searchTextChange = useCallback(event => {
    setSearchText(event.target.value);
  }, []);

  const reset = () => {
    setSearchText('');
  };

  const validate = async () => {
    setSearchStatus(prevState => ({
      ...prevState,
      status: SearchStatus.SEARCHING,
    }));
    try {
      const response = await api({
        method: 'POST',
        url: '/shared/ValidateSearch',
        data: {
          ...filters,
          advancedSearch: searchText,
          projectId,
        },
      });

      validatedText.current = searchText;
      setSearchStatus(prevState => ({
        ...prevState,
        count: response.data.count,
        status: SearchStatus.SUCCESS,
      }));
    } catch (error) {
      setSearchStatus(prevState => ({
        ...prevState,
        status: SearchStatus.ERROR,
        error: error.message,
      }));
    }
  };

  const submit = () => {
    setFilter({ advancedSearch: searchText });
    onClose();
  };

  useEffect(() => {
    const { overflow } = document.body.style;
    document.body.style.overflow = 'hidden';

    if (searchText.length > 0) {
      validate();
    }

    return () => {
      document.body.style.overflow = overflow;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PopupContainer>
      <Card className="p-4">
        <span className={styles.close} onClick={onClose}>
          <Icon name="cross" />
        </span>

        <Card.Body>
          <p>
            To search across Contribution text - enter your Advanced Search boolean query in the box
            below. Phrases should be in double quotation marks (i.e: &quot;Chronic Asthma&quot;).
            Valid operators are AND, AND NOT, OR, NEAR.
          </p>

          <p>
            Wildcards e.g. &quot;Asthm*&quot; are also supported. Note that searching across
            Analytics objects such as Topics, Treatments or Disease States is not covered in the
            search, only the Contribution text.
          </p>

          <SearchTextArea
            maxLength={maxLength}
            value={searchText}
            className="text-sm bordered border-radius-sm p-3"
            onChange={searchTextChange}
          />
          <SearchLengthLabel className="text-xs w-100 pr-2 pt-2 line-height-sm">
            {`${searchText?.length || 0}/${maxLength}`}
          </SearchLengthLabel>

          <hr className="mt-4 mb-3" />

          <div className="d-flex justify-content-between align-items-center">
            <Button buttonType="secondary" onClick={reset}>
              Reset
            </Button>

            <ShowSearchStatus
              error={searchStatus.error}
              count={searchStatus.count}
              status={searchStatus.status}
            />

            <div className={styles['action-buttons']}>
              <Button
                buttonType="secondary"
                onClick={validate}
                disabled={
                  searchText.length === 0 ||
                  searchStatus.status === SearchStatus.SEARCHING ||
                  validatedText.current === searchText
                }
              >
                Validate Query
              </Button>
              <Button
                onClick={submit}
                disabled={
                  searchText.length > 0 &&
                  [SearchStatus.INITIAL, SearchStatus.SEARCHING, SearchStatus.ERROR].includes(
                    searchStatus.status,
                  )
                }
              >
                Apply Search
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </PopupContainer>
  );
};

ShowSearchStatus.propTypes = {
  status: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  count: PropTypes.number,
};

ShowSearchStatus.defaultProps = {
  status: undefined,
  error: undefined,
  count: undefined,
};
AdvancedSearchPopup.propTypes = {
  onClose: PropTypes.func,
};

AdvancedSearchPopup.defaultProps = {
  onClose: undefined,
};

export default AdvancedSearchPopup;
