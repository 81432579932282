import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';

import { useMessageSent } from 'context/loginFormContext';

import pageTitle from '../../utils/pageTitle';

import styles from './Login.module.scss';

import Banner from './Banner';
import Footer from './Footer';
import Login from './LoginPage';

const LoginMainPage = () => {
  const { messageSent } = useMessageSent();

  return (
    <>
      <Helmet>
        <title>{pageTitle('Login')}</title>
        <meta name="description" content="Please login to access Kwello" />
      </Helmet>
      <Container fluid>
        <Row className={styles['row-styles']}>
          <Col xs={12} sm={12} md={12} lg={6} className={styles['col-styles']}>
            {messageSent && <div className="alert alert-success">Form successfully submitted</div>}
            <Login />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className={styles['col-styles']}>
            <Banner />
          </Col>
        </Row>
        <Row>
          <Col className={styles['col-styles']}>
            <Footer fixed />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginMainPage;
