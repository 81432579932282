import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useLayout } from 'context/layoutContext';

import { capitaliseFirstLetter } from 'utils/formatStrings';

import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import Icon from 'common/Icon/Icon';
import { AlertButton } from 'common/MenuItems';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';

import { EmptyListIcon } from 'Pages/Lists/Components/EmptyListIcon';

import { DeleteCollectionMenuItem, EditCollectionMenuItem } from '../MenuItems';

import styles from './CollectionItem.module.scss';

export const CollectionItem = ({
  collection,
  onClick,
  selected,
  collectionType,
  showNameOnly,
  className,
  useAppliedCollectionsFilter,
}) => {
  const { isDesktop } = useLayout();
  const collectionItemName = collectionType === 'bookmark' ? 'contributions' : 'events';
  const label = capitaliseFirstLetter(collectionItemName);
  const { internalFilters } = useFilterMenu();
  const selectedFilters = useMemo(
    () =>
      internalFilters?.collection?.find(
        collectionfilter => collectionfilter.id === collection.collectionID,
      ),
    [internalFilters, collection],
  );
  const isSelected = useAppliedCollectionsFilter ? selectedFilters : selected;

  return (
    <div className={`${styles.container} ${isSelected ? styles.selected : ''} ${className}`}>
      {isDesktop && (
        <div>
          {collection.picture && <ProfilePicture url={collection.picture} size="60px" />}
          {!collection.picture && <EmptyListIcon isBookmark={collectionType !== 'list'} />}
        </div>
      )}
      <div className={styles.details} onClick={onClick}>
        <span className={styles['collection-name']}>{collection.collectionName}</span>
        <span className={styles.description}>{collection.description}</span>
      </div>

      {collectionType === 'event' && !showNameOnly && (
        <AlertButton
          notifFrequency={collection?.notifFrequency}
          collectionID={collection.collectionID}
        />
      )}
      {!showNameOnly && (
        <span className={styles.badge}>
          <Icon name={collection[collectionItemName]?.length > 0 ? 'bookmarkOn' : 'bookmarkOff'} />
          {collection[collectionItemName]?.length || 0}
        </span>
      )}
      {!showNameOnly && (
        <DropDownMenu
          alignRight
          menuItems={[
            EditCollectionMenuItem({ collection, label }),
            DeleteCollectionMenuItem({ collection }),
          ]}
        />
      )}
    </div>
  );
};

CollectionItem.propTypes = {
  collection: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  collectionType: PropTypes.string,
  showNameOnly: PropTypes.bool,
  className: PropTypes.string,
  useAppliedCollectionsFilter: PropTypes.bool,
};

CollectionItem.defaultProps = {
  onClick: undefined,
  selected: false,
  collectionType: 'list',
  showNameOnly: false,
  className: null,
  useAppliedCollectionsFilter: false,
};
