import { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useApiService } from 'utils/hooks/useApiService';

import { useUser } from './userContext';

const SocialMonitoring = createContext({});

const SocialMonitoringProvider = props => {
  const { socialProjects } = useUser();
  const { projectId } = useParams();
  const api = useApiService();

  const currentProject = useMemo(() => {
    return socialProjects.find(project => project.projectID === projectId);
  }, [socialProjects, projectId]);
  const queryClient = useQueryClient();

  const showScientificRank = useMemo(() => currentProject?.showScientificRank, [currentProject]);
  const showScientificUrl = useMemo(() => currentProject?.showScientificUrl, [currentProject]);
  const showScientificProfileUrl = useMemo(
    () => currentProject?.showScientificProfileUrl,
    [currentProject],
  );
  const showScientificContent = useMemo(
    () => currentProject?.showScientificContent,
    [currentProject],
  );

  const callApi = async url =>
    api({
      url,
      method: 'POST',
      data: {
        projectId,
      },
      cancelToken: url.substring(url.lastIndexOf('/') + 1),
    });

  const fetchCurrentUserMutedAuthors = async () => callApi('/UserProfile/MutedAuthors');

  const { data: mutedAuthors, isLoading: isMutedAuthorsLoading } = useQuery({
    queryKey: ['mutedAuthors', projectId],
    queryFn: () => fetchCurrentUserMutedAuthors(),
    staleTime: Infinity,
    select: response => response.data,
  });

  const fetchCurrentUserMutedProfiles = async () => callApi('/UserProfile/MutedProfiles');

  const { data: mutedProfiles, isLoading: isMutedProfilesLoading } = useQuery({
    queryKey: ['mutedProfiles', projectId],
    queryFn: () => fetchCurrentUserMutedProfiles(),
    staleTime: Infinity,
    select: response => response.data,
  });

  const fetchCurrentUserNotifications = async () => callApi('/shared/Notifications');

  const { data: notifications, isLoading: isNotificationsLoading } = useQuery({
    queryKey: ['notifications', projectId],
    queryFn: () => fetchCurrentUserNotifications(),
    staleTime: Infinity,
    select: response => response.data.notifications,
  });

  const refreshData = key => {
    queryClient.invalidateQueries([key, projectId]);
  };

  const value = useMemo(
    () => ({
      projects: socialProjects,
      currentProject,
      showScientificRank,
      showScientificUrl,
      showScientificProfileUrl,
      showScientificContent,
      mutedAuthors,
      isMutedAuthorsLoading,
      mutedProfiles,
      isMutedProfilesLoading,
      notifications,
      isNotificationsLoading,
      refreshData,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      socialProjects,
      currentProject,
      showScientificRank,
      showScientificUrl,
      showScientificProfileUrl,
      showScientificContent,
      mutedAuthors,
      isMutedAuthorsLoading,
      mutedProfiles,
      isMutedProfilesLoading,
      notifications,
      isNotificationsLoading,
    ],
  );

  return <SocialMonitoring.Provider value={value} {...props} />;
};

const useSocialMonitoring = () => useContext(SocialMonitoring);

export { SocialMonitoringProvider, useSocialMonitoring };
