import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonWrapper = styled.div`
  button {
    border-color: ${({ color }) => color};
    border-width: 2px;
  }
`;

export const TreatmentsButtonWrapper = ({ children, color }) => (
  <ButtonWrapper color={color}>{children}</ButtonWrapper>
);

TreatmentsButtonWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

TreatmentsButtonWrapper.defaultProps = {
  color: undefined,
};
