export * from './gkpSortOrders';
export * from './modules';
// CHARTS
export const DEFAULT_CHART_HEIGHT = '250px';
export const DEFAULT_KWELLO_COLOR = '#f36633';
export const DEFAULT_KWELLO_SECONDARY_COLOR = '#73b761';
export const SOURCE_TWITTER_COLOR = '#1da1f2';
export const SOURCE_NEWS_COLOR = '#FFCE22';
export const SOURCE_YOUTUBE_COLOR = '#FF3500';
export const SOURCE_BLOGS_COLOR = '#74B661';
export const SOURCE_REDDIT_COLOR = '#FF4A08';
export const SOURCE_TUMBLR_COLOR = '#1E3765';
export const SOURCE_FACEBOOK_COLOR = '#0668E1';
export const SOURCE_INSTAGRAM_COLOR = '#C13584';
export const SOURCE_FORUMS_COLOR = '#27C2AA';
export const SOURCE_QQ_COLOR = '#1594D3';
export const DEFAULT_LABEL_FORMAT = '0a';
export const DEFAULT_LABEL_COLOR = '#FFFFFF';
export const CHART_BAR_LENGTH_PX = 38;
export const EMPTY_STATE_MESSAGE = 'No data for current filters';
export const TWITTER_VIDEO_URL = 'https://video.twimg';
export const CHART_COLORS_PRIMARY = [
  '#ff7900',
  '#1f17ba',
  '#ab00b2',
  '#fec400',
  '#db0078',
  '#5c00ba',
  '#faed00',
  '#00d300',
  '#0096a5',
  '#94f500',
  '#ff0000',
  '#0059b1',
];
export const CHART_COLORS_SECONDARY = [
  '#af0000',
  '#6a7800',
  '#c26600',
  '#770040',
  '#883300',
  '#005700',
  '#0e004d',
  '#00314d',
  '#510055',
];
export const CHART_COLORS = [...CHART_COLORS_PRIMARY, ...CHART_COLORS_SECONDARY];
export const ALERT_FREQUENCIES = ['None', 'Daily', 'Weekly', 'Monthly'];
export const WHITELIST_SOURCES = ['Twitter', 'Facebook', 'YouTube', 'Instagram'];
export { conversationTabFilterLabels, filtersSettings } from './filtersSettings';
export { savedSearchLabels } from './savedSearchLabels';
