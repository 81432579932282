import { useCollections } from 'context/collectionsContext';

import { CollectionsTemplatePage } from 'modules/globalKolPlanning/common/PageTemplates/Collections/CollectionsTemplatePage';

import { AddCollectionAction } from 'Pages/Bookmarks/Components/Actions';

export const BookMarksPage = () => {
  const { collections, collectionsLoading } = useCollections();

  return (
    <CollectionsTemplatePage
      collections={collections}
      collectionsLoading={collectionsLoading}
      collectionsTitle="Collections"
      collectionDetailsTitle="Collection details"
      collectionType="bookmark"
      collectionActions={[<AddCollectionAction />]}
      hideFeedPreview
    />
  );
};
