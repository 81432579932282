import { useRef, useState } from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import { useLayout } from 'context/layoutContext';
import { useUser } from 'context/userContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';
import useOutsideAlerter from 'utils/hooks/useOutsideAlerter';

import { ExpandMenuIcon } from 'common/ExpandMenu';
import { ExpandProfile } from 'common/ExpandProfile';

import styles from './ModuleSelectionMenu.module.scss';

import { GkpModulePicker } from './GkpModulePicker';
import { InsightsCapturePicker } from './InsightsCapturePicker';
import { Module } from './Module';
import ReportsModulePicker from './ReportsModulePicker';
import { SocialMonitoringModulePicker } from './SocialMonitoringModulePicker';

const ModuleSelectionMenu = () => {
  const { currentPage, exactPage } = useCurrentPage();
  const [isExpanded, setIsExpanded] = useState(-1);
  const [titleExpanded, setTitleExpanded] = useState(true);
  const moduleSelectionMenuContainerRef = useRef();

  const { modules } = useUser();
  const { hasSideBar, isDesktop } = useLayout();

  useOutsideAlerter(moduleSelectionMenuContainerRef, () => setIsExpanded(-1));

  const renderModuleButtons = module => {
    if (module.key === 'socialMonitoring') {
      return <SocialMonitoringModulePicker setIsExpanded={setIsExpanded} />;
    }
    if (['insights', 'sosvExperts', 'sosvScience', 'customReports'].includes(module.key)) {
      return <ReportsModulePicker setIsExpanded={setIsExpanded} module={module} />;
    }
    if (module.key === 'globalKolPlanning') {
      return <GkpModulePicker setIsExpanded={setIsExpanded} />;
    }
    if (module.key === 'insightsCapture') {
      return <InsightsCapturePicker setIsExpanded={setIsExpanded} />;
    }
    return (
      <NavLink
        to={module.navLink ? `${module.navLink}` : 'null'}
        className="d-flex align-items-center mb-4"
      >
        &nbsp;
      </NavLink>
    );
  };

  const showMenu = () => {
    const noMenuPages = [
      ...ROUTE.allowedUrls,
      ROUTE.login,
      `/${ROUTE.kolProfile}`,
      ROUTE.contribution,
      ROUTE.eventDetails,
      ROUTE.maintenance,
      ROUTE.attendeeDetails,
      ROUTE.interactionDetail,
    ];

    if (currentPage(noMenuPages)) return false;

    return hasSideBar;
  };

  const handleClick = () => {
    setIsExpanded(prevValue =>
      prevValue > -1
        ? -1
        : modules.findIndex(module =>
            currentPage(ROUTE.userSettings)
              ? module.navLink === ROUTE.socialMonitoringUrl
              : currentPage(module.navLink),
          ),
    );
  };

  const getExpandedStyle = () => {
    if (isDesktop) {
      if (titleExpanded) {
        return styles['modules-container-title-expanded'];
      }
      return styles['modules-container-expanded'];
    }
    if (titleExpanded) {
      return styles['modules-container-title-expanded-smaller'];
    }
    return styles['modules-container-expanded-smaller'];
  };

  return showMenu() ? (
    <div
      className={`${styles.container} ${isExpanded > -1 ? styles.open : ''}`}
      ref={moduleSelectionMenuContainerRef}
    >
      <ExpandMenuIcon
        className={isExpanded > -1 ? styles['menu-icon-expanded'] : styles['menu-icon']}
        isOpen={isExpanded > -1}
        onClick={handleClick}
      />
      <section className={isExpanded > -1 ? getExpandedStyle() : styles['modules-container']}>
        {modules.map((module, index) => (
          <Module
            index={index}
            expandMenu={setIsExpanded}
            key={module.key}
            module={module}
            active={exactPage(module.navLink)}
            expanded={isExpanded}
            content={renderModuleButtons(module)}
            titleExpanded={titleExpanded}
          />
        ))}
      </section>
      <ExpandProfile
        className={`${styles['profile-icon']} ${
          isExpanded > -1 ? styles['profile-icon-expanded'] : ''
        }`}
        isOpen={isExpanded > -1}
        onClick={handleClick}
        setTitleExpanded={setTitleExpanded}
      />
    </div>
  ) : null;
};

export default withRouter(ModuleSelectionMenu);
