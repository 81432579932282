import { useRef } from 'react';
import PropTypes from 'prop-types';
import { createSliderWithTooltip, Range } from 'rc-slider';

import { useFilterMenu } from 'context/filterMenuContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';

import styles from './RangeFilter.module.scss';

import { StyledMaxMinInput } from './styles';

const TooltipRange = createSliderWithTooltip(Range);

export const RangeFilter = ({ filterKey, eventKey }) => {
  const { currentProject } = useSocialMonitoring();
  const { selectedValues, setFilter, resetFilter } = useFilterMenu();
  const selectedItems = selectedValues(filterKey);

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  const { minScientific, maxScientific } = currentProject;

  const updateValue = value => {
    setFilter({
      filterKey,
      filterValue: {
        greaterThanOrEqualTo: value.min,
        lessThanOrEqualTo: value.max,
      },
    });
  };

  return (
    <div ref={filterRef}>
      <div className="mx-3">
        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="font-weight-bold ">Selected range</div>
          <Button
            icon="eraser"
            size="small"
            buttonType="secondary"
            onClick={() => resetFilter(filterKey)}
          />
        </div>
        <div className={styles.slider}>
          <TooltipRange
            min={minScientific || 0}
            max={maxScientific || 100}
            value={[
              selectedItems?.greaterThanOrEqualTo || minScientific,
              selectedItems?.lessThanOrEqualTo || maxScientific,
            ]}
            onChange={event => updateValue({ min: event[0], max: event[1] })}
          />
        </div>
        <div className="d-flex justify-content-between">
          <StyledMaxMinInput
            type="number"
            name="range-min"
            width="80px"
            size="sm"
            value={selectedItems?.greaterThanOrEqualTo || minScientific}
            onChange={e => {
              updateValue({
                min: Number(e.target.value),
                max: selectedItems?.lessThanOrEqualTo || maxScientific,
              });
            }}
          />
          <StyledMaxMinInput
            type="number"
            name="range-max"
            width="80px"
            size="sm"
            value={selectedItems?.lessThanOrEqualTo || maxScientific}
            onChange={e => {
              updateValue({
                min: selectedItems?.greaterThanOrEqualTo || minScientific,
                max: Number(e.target.value),
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

RangeFilter.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
