import { isTablet } from 'react-device-detect';

import { useLists } from 'context/listsContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import { CollectionsTemplatePage } from 'modules/globalKolPlanning/common/PageTemplates/Collections/CollectionsTemplatePage';

import { AddListAction } from './Components';

export const ListsPage = () => {
  const { lists, listsLoading } = useLists();
  const { isSocialMonitoring } = useCurrentPage();

  return (
    <CollectionsTemplatePage
      collections={lists}
      collectionsLoading={listsLoading}
      collectionsTitle="Your lists"
      collectionActions={[<AddListAction />]}
      collectionDetailsTitle="List details"
      collectionType="list"
      collectionName="listName"
      hideFeedPreview={!isSocialMonitoring || isTablet}
    />
  );
};
