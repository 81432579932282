import PropTypes from 'prop-types';

import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import { EditCollection } from 'Pages/Bookmarks/Components/EditCollection';
import { EditList } from 'Pages/Lists/Components';
import { EditSavedSearchCollection } from 'Pages/SavedSearches';

export const EditCollectionObject = ({
  collection,
  setEditCollection,
  selectedCollectionItems,
  setSelectedCollectionItems,
  collectionType,
}) => {
  const { eventName } = useGlobalKolPlanningContext();
  const editCollectionContent = () => {
    switch (collectionType) {
      case 'bookmark':
      case 'event':
        return (
          <EditCollection
            collection={collection}
            setEditCollection={setEditCollection}
            selectedCollections={selectedCollectionItems}
            setSelectedCollections={setSelectedCollectionItems}
            itemName={collectionType === 'bookmark' ? 'contribution' : eventName?.toLowerCase()}
          />
        );
      case 'list':
        return (
          <EditList
            list={collection}
            setEditList={setEditCollection}
            selectedProfiles={selectedCollectionItems}
            setSelectedProfiles={setSelectedCollectionItems}
          />
        );
      case 'savedSearch':
        return (
          <EditSavedSearchCollection
            savedSearch={collection}
            setEditCollection={setEditCollection}
            selectedFilters={selectedCollectionItems}
            setSelectedFilters={setSelectedCollectionItems}
          />
        );
      default:
        return null;
    }
  };

  return editCollectionContent();
};

EditCollectionObject.propTypes = {
  collection: PropTypes.object,
  setEditCollection: PropTypes.func.isRequired,
  setSelectedCollectionItems: PropTypes.func.isRequired,
  selectedCollectionItems: PropTypes.array,
  collectionType: PropTypes.string,
};

EditCollectionObject.defaultProps = {
  collection: {},
  selectedCollectionItems: [],
  collectionType: 'bookmark',
};
