import { createContext, useCallback, useContext, useMemo } from 'react';
import { KwelloButton } from 'stories/KwelloButton';

import { useModal } from 'context/modalContext';

import Modal from 'common/Modals/Modal';

const ConfirmationContext = createContext({});

const ConfirmationProvider = props => {
  const { openModal, closeModal } = useModal();

  const confirmModal = useCallback(
    config => {
      const content = ({ title, text, onCancel, onConfirm, saveText, cancelText, width }) => (
        <Modal title={title} hideUnderline width={width}>
          <div
            className="body-soft-desktop"
            style={{ paddingBottom: '0.5rem', paddingTop: '1rem' }}
          >
            {text}
          </div>
          <div style={{ marginTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
            <KwelloButton
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
                closeModal();
              }}
              buttonType="loudOutline"
              size="large"
              label={cancelText || 'No'}
            />
            {onConfirm && (
              <KwelloButton
                onClick={() => {
                  if (onConfirm) {
                    onConfirm();
                  }
                  closeModal();
                }}
                buttonType="loud"
                size="large"
                label={saveText || 'Yes'}
              />
            )}
          </div>
        </Modal>
      );
      openModal(content(config));
    },
    [openModal, closeModal],
  );

  const confirm = useCallback(
    config => {
      const content = ({ title, text, onCancel, onConfirm }) => (
        <Modal
          hideClose
          title={title}
          onSave={() => {
            closeModal();
            onConfirm && onConfirm();
          }}
          saveText="Yes"
          onCancel={() => {
            closeModal();
            onCancel && onCancel();
          }}
          cancelText="No"
        >
          <div style={{ paddingBottom: '0.5rem', paddingTop: '1rem' }}>{text}</div>
        </Modal>
      );
      openModal(content(config));
    },
    [openModal, closeModal],
  );
  const value = useMemo(() => ({ confirm, confirmModal }), [confirm, confirmModal]);
  return <ConfirmationContext.Provider value={value} {...props} />;
};

const useConfirm = () => useContext(ConfirmationContext);

export { ConfirmationProvider, useConfirm };
