import {
  add,
  calculateNextQuarters,
  calculateQuarter,
  calculateYear,
  subtract,
} from 'utils/dateHelper';

const today = new Date();
const nextYear = calculateYear(today.getFullYear() + 1);
const lastYear = calculateYear(today.getFullYear() - 1);
const thisYear = calculateYear();
const thisQ1 = calculateQuarter(1);
const thisQ2 = calculateQuarter(2);
const thisQ3 = calculateQuarter(3);
const thisQ4 = calculateQuarter(4);

calculateNextQuarters();

export const thisYearQuarters = [
  { type: 'range', label: 'Quarter 1', start: thisQ1.start, end: thisQ1.end },
  { type: 'range', label: 'Quarter 2', start: thisQ2.start, end: thisQ2.end },
  { type: 'range', label: 'Quarter 3', start: thisQ3.start, end: thisQ3.end },
  { type: 'range', label: 'Quarter 4', start: thisQ4.start, end: thisQ4.end },
];

export const nextFourQuarters =
  calculateNextQuarters()?.map(quarter => ({
    type: 'range',
    label: quarter.label,
    start: quarter.start,
    end: quarter.end,
  })) || [];

export const previousPeriods = [
  { type: 'period-backward', label: 'Last 7 days', value: { metric: 'day', value: 7 } },
  { type: 'period-backward', label: 'Last 14 days', value: { metric: 'day', value: 14 } },
  { type: 'period-backward', label: 'Last 1 month', value: { metric: 'month', value: 1 } },
  { type: 'period-backward', label: 'Last 3 months', value: { metric: 'month', value: 3 } },
  { type: 'period-backward', label: 'Last 6 months', value: { metric: 'month', value: 6 } },
  { type: 'period-backward', label: 'Last 12 months', value: { metric: 'month', value: 12 } },
  { type: 'period-backward', label: 'Last 24 months', value: { metric: 'month', value: 24 } },
];

export const nextPeriods = [
  { type: 'period-forward', label: 'Next 7 days', value: { metric: 'day', value: 7 } },
  { type: 'period-forward', label: 'Next 14 days', value: { metric: 'day', value: 14 } },
  { type: 'period-forward', label: 'Next 1 month', value: { metric: 'month', value: 1 } },
  { type: 'period-forward', label: 'Next 3 months', value: { metric: 'month', value: 3 } },
  { type: 'period-forward', label: 'Next 6 months', value: { metric: 'month', value: 6 } },
  { type: 'period-forward', label: 'Next 12 months', value: { metric: 'month', value: 12 } },
];

export const DefaultDateFilter = [previousPeriods];

export const GkpDateFilter = [
  [
    { type: 'range', label: 'This year', start: thisYear.start, end: thisYear.end },
    { type: 'range', label: 'Future events', start: today, end: add(today, { years: 2 }) },
    { type: 'range', label: 'Past events', start: subtract(today, { years: 2 }), end: today },
    { type: 'range', label: 'Next year', start: nextYear.start, end: nextYear.end },
    { type: 'range', label: 'Last year', start: lastYear.start, end: lastYear.end },
  ],
  nextFourQuarters,
];
