/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './Scroll.module.scss';

const Scroll = ({ children, className, height, maxHeight, hideArrow, disableScroll, style }) => {
  const scrollRef = useRef();
  const [show, setShow] = useState(false);

  const showScrollToTop = useRef();

  const handleScroll = useCallback(() => {
    if (scrollRef?.current) {
      if (scrollRef.current.scrollTop > 100 && !showScrollToTop.current) {
        showScrollToTop.current = true;
        setShow(true);
      }

      if (scrollRef.current.scrollTop <= 100 && showScrollToTop.current) {
        showScrollToTop.current = false;
        setShow(false);
      }
    }
  }, [show, scrollRef]);

  useEffect(() => {
    scrollRef?.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef?.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = useCallback(() => {
    scrollRef?.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <section className={styles.container} style={{ height, maxHeight }}>
      <div
        ref={disableScroll ? undefined : scrollRef}
        className={`${disableScroll ? styles['no-scroll'] : styles['scroll-content']} ${className}`}
        style={{ maxHeight: height || maxHeight, ...style }}
      >
        {children}
      </div>
      {!disableScroll && !hideArrow && (
        <button
          className={`${styles['scroll-to-top']} ${show ? styles.show : ''}`}
          onClick={scrollToTop}
          aria-label="Scroll to top"
        >
          <Icon name="arrowUp" />
        </button>
      )}
    </section>
  );
};

Scroll.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  className: PropTypes.string,
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  hideArrow: PropTypes.bool,
  disableScroll: PropTypes.bool,
  style: PropTypes.object,
};

Scroll.defaultProps = {
  children: null,
  className: '',
  height: undefined,
  maxHeight: undefined,
  hideArrow: false,
  disableScroll: false,
  style: undefined,
};

export default Scroll;
