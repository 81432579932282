import React from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import { toastNotify } from 'common/ToastNotification/ToastNotification';

const LoginButton = ({ disabled }) => {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginRedirect({
        ...loginRequest,
        redirectUri: `${process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI}/login`,
      })
      .catch(() => {
        toastNotify('error', 'Login failed. Please refresh the browser and try again.');
      });
  };

  const onClick = () => {
    sessionStorage.removeItem('loginError');
    if (localStorage.getItem('previousKwelloPath')?.includes('auth0')) {
      localStorage.removeItem('previousKwelloPath');
    }
    handleLogin(instance);
  };

  return (
    <Button size="large" onClick={onClick} block disabled={disabled}>
      Sign in to Kwello
    </Button>
  );
};

LoginButton.propTypes = { disabled: PropTypes.bool };

LoginButton.defaultProps = { disabled: false };

export default LoginButton;
