import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import ReportsDropdown from 'common/filtersBar/ReportsDropdown';
import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import Scroll from 'common/Scroll/Scroll';
import SearchBar from 'common/searchBar/SearchBar';
import StickyHeight from 'common/StickyHeight/StickyHeight';

import {
  CollectionsFeedActions,
  CollectionsFeedComponent,
  Container,
  Header,
} from 'Pages/Lists/Components';

import styles from './CollectionsTemplate.module.scss';

import {
  CollectionObject,
  DetailsCollectionObject,
  DetailsCollectionObjectActions,
  EditCollectionObject,
} from './Components';

export const CollectionsTemplatePage = ({
  collections,
  collectionsTitle,
  collectionActions,
  collectionsLoading,
  collectionDetailsTitle,
  collectionType,
  collectionName,
  hideDetailsSearchBar,
  hideFeedPreview,
}) => {
  const { isDesktop, screenWidth } = useLayout();
  const [selectedCollection, setSelectedCollection] = useState(0);
  const [selectedCollectionItems, setSelectedCollectionItems] = useState([]);
  const [collectionSearchValue, setCollectionSearchValue] = useState('');
  const [contributionSearchValue, setContributionSearchValue] = useState('');
  const [editCollection, setEditCollection] = useState(false);
  const [colSpan, setColSpan] = useState({});
  const collectionItems = collectionType === 'event' ? 'events' : 'contributions';
  const itemName = collectionType === 'event' ? 'eventTitle' : 'title';

  useEffect(() => {
    if (!editCollection) {
      setSelectedCollectionItems([]);
    }
  }, [editCollection]);

  useEffect(() => {
    if (hideFeedPreview) {
      setColSpan({
        1: isDesktop ? 4 : 6,
        2: isDesktop ? 8 : 6,
      });
    } else if (screenWidth === 'largeTablet' || screenWidth === 'tablet') {
      setColSpan({ 1: 5, 2: 4, 3: 3 });
    } else {
      setColSpan({ 1: 4, 2: 4, 3: 4 });
    }
  }, [hideFeedPreview, isDesktop, screenWidth]);

  const filteredCollections = collections.filter(collection =>
    collection?.[collectionName]?.toLowerCase().includes(collectionSearchValue?.toLowerCase()),
  );

  const currentCollection = filteredCollections?.[selectedCollection] || {};

  const filteredContributions =
    collectionType !== 'list'
      ? currentCollection?.[collectionItems]?.filter(contribution =>
          contribution?.[itemName]?.toLowerCase().includes(contributionSearchValue?.toLowerCase()),
        ) || []
      : currentCollection?.koLs?.filter(kol =>
          kol.name.toLowerCase().includes(contributionSearchValue.toLowerCase()),
        ) || [];

  const toggleSelectedCollectionItem = (contributionId, profileId) => {
    if (profileId) {
      if (
        selectedCollectionItems.find(
          cont => cont.contributionId === contributionId && cont.profileId === profileId,
        )
      ) {
        setSelectedCollectionItems(
          selectedCollectionItems.filter(
            contribution =>
              contribution.contributionId !== contributionId &&
              contribution.profileId !== profileId,
          ),
        );
      } else {
        setSelectedCollectionItems([...selectedCollectionItems, { contributionId, profileId }]);
      }
    } else if (selectedCollectionItems.includes(contributionId)) {
      setSelectedCollectionItems(
        selectedCollectionItems.filter(contribution => contribution !== contributionId),
      );
    } else {
      setSelectedCollectionItems([...selectedCollectionItems, contributionId]);
    }
  };

  return (
    <>
      <ReportsDropdown />
      <Grid className={styles['main-content-wrapper']}>
        <GridItem colSpan={colSpan[1]}>
          <StickyHeight>
            <Container>
              <Header title={collectionsTitle} actions={collectionActions} />

              <div className={styles.content}>
                <SearchBar
                  className={styles.search}
                  value={collectionSearchValue}
                  onChange={value => {
                    setCollectionSearchValue(value);
                    setSelectedCollection(0);
                  }}
                />

                {collectionsLoading && <LoaderSpinner />}

                {!collectionsLoading && (
                  <div className={styles['scroll-wrapper']}>
                    <Scroll className={styles.scroll} hideArrow>
                      <CollectionObject
                        collections={filteredCollections}
                        onClick={({ index }) => setSelectedCollection(index)}
                        selectedCollection={selectedCollection}
                        collectionType={collectionType}
                        hideFeedPreview={hideFeedPreview}
                      />
                    </Scroll>
                  </div>
                )}
              </div>
            </Container>
          </StickyHeight>
        </GridItem>

        <GridItem colSpan={colSpan[2]}>
          <StickyHeight>
            <Container>
              <Header
                title={collectionDetailsTitle}
                actions={DetailsCollectionObjectActions({
                  collectionType,
                  setEditCollection,
                  editCollection,
                  filteredCollections,
                  currentCollection,
                })}
              />

              <div className={styles.content}>
                {!hideDetailsSearchBar && (
                  <SearchBar
                    className={styles.search}
                    value={contributionSearchValue}
                    onChange={value => {
                      setContributionSearchValue(value);
                      setSelectedCollectionItems([]);
                    }}
                  />
                )}

                {collectionsLoading && <LoaderSpinner />}

                {!collectionsLoading && (
                  <>
                    {editCollection && (
                      <EditCollectionObject
                        collection={currentCollection}
                        setEditCollection={setEditCollection}
                        selectedCollectionItems={selectedCollectionItems}
                        setSelectedCollectionItems={setSelectedCollectionItems}
                        collectionType={collectionType}
                      />
                    )}

                    <div className={styles['scroll-wrapper']}>
                      <Scroll className={styles.scroll} hideArrow>
                        <DetailsCollectionObject
                          collection={currentCollection}
                          contributions={filteredContributions}
                          selectedCollectionItems={selectedCollectionItems}
                          toggleSelectedCollectionItem={toggleSelectedCollectionItem}
                          editCollection={editCollection}
                          collectionType={collectionType}
                          hideFeedPreview={hideFeedPreview}
                        />
                      </Scroll>
                    </div>
                  </>
                )}
              </div>
            </Container>
          </StickyHeight>
        </GridItem>
        {!hideFeedPreview && (
          <GridItem colSpan={colSpan[3]}>
            <StickyHeight>
              <Header
                title="Feed"
                actions={[<CollectionsFeedActions collection={currentCollection} />]}
              />
              <CollectionsFeedComponent collection={currentCollection} />
            </StickyHeight>
          </GridItem>
        )}
      </Grid>
    </>
  );
};

CollectionsTemplatePage.propTypes = {
  collections: PropTypes.array,
  collectionsTitle: PropTypes.string,
  collectionActions: PropTypes.array,
  collectionsLoading: PropTypes.bool,
  collectionDetailsTitle: PropTypes.string,
  collectionType: PropTypes.oneOf(['event', 'bookmark', 'list', 'savedSearch']),
  collectionName: PropTypes.string,
  hideDetailsSearchBar: PropTypes.bool,
  hideFeedPreview: PropTypes.bool,
};

CollectionsTemplatePage.defaultProps = {
  collections: [],
  collectionsTitle: 'Collections',
  collectionActions: [],
  collectionsLoading: false,
  collectionDetailsTitle: 'Collection Details',
  collectionType: 'bookmark',
  collectionName: 'collectionName',
  hideDetailsSearchBar: false,
  hideFeedPreview: false,
};
