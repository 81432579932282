import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';
import { useModal } from 'context/modalContext';

import Button from 'common/button/Button';

import { AddCollectionModal } from 'Pages/Bookmarks/Modals';

export const AddCollectionAction = ({ events, mentions, hideLabel, disabled }) => {
  const { openModal } = useModal();
  const { isDesktop } = useLayout();
  let addMessage = events
    ? `Create a new Collection and add ${events?.length} event${events?.length > 1 ? 's' : ''}`
    : 'Create a New Collection';
  addMessage = mentions
    ? `Create a new Collection and add ${mentions.length} Contribution(s)`
    : addMessage;

  const addCollection = () => {
    openModal(<AddCollectionModal events={events} mentions={mentions || undefined} />);
  };

  return (
    <Button
      buttonType="transparent"
      icon="createCollection"
      disabled={disabled}
      onClick={addCollection}
    >
      {isDesktop && !hideLabel && addMessage}
    </Button>
  );
};

AddCollectionAction.propTypes = {
  events: PropTypes.array,
  mentions: PropTypes.array,
  hideLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};
AddCollectionAction.defaultProps = {
  events: undefined,
  mentions: undefined,
  hideLabel: false,
  disabled: false,
};
