import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet-async';
import kwelloLogo from 'assets/icons/kwelloLogo.svg';

import { useStatus } from 'context/statusContext';

import pageTitle from 'utils/pageTitle';

import Banner from 'common/login/Banner';
import Footer from 'common/login/Footer';

import styles from 'common/login/Login.module.scss';

export const MaintenancePage = () => {
  const { status } = useStatus();

  const { maintenanceMessage } = status;

  const defaultMessage = 'We are currently making some updates, Kwello will be available soon.';
  return (
    <>
      <Helmet>
        <title>{pageTitle('Login')}</title>
        <meta name="description" content="Please login to access Kwello" />
      </Helmet>
      <Container fluid>
        <Row className={styles['row-styles']}>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={6}
            className={styles['col-styles']}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={kwelloLogo} style={{ width: '400px' }} />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} className={styles['col-styles']}>
            <Banner text="Well Kwello there!" subText={maintenanceMessage || defaultMessage} />
          </Col>
        </Row>
        <Row>
          <Col className={styles['col-styles']}>
            <Footer fixed />
          </Col>
        </Row>
      </Container>
    </>
  );
};
