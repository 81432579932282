import PropTypes from 'prop-types';

import ROUTE from 'constants/route';
import { useNavigate } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { AlertButton } from 'common/MenuItems';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';

import styles from './ProfileItem.module.scss';

import { ProfileMenuItems } from './ProfileMenuItems';

export const ProfileItem = ({ listId, profile, selected, editList, onClick, hideProfileImage }) => {
  const {
    profileId,
    name,
    profileImageURL,
    country,
    twitterBio,
    notifFrequency,
    isMuted,
    alertFrequency,
  } = profile;

  const { navigateToProfileDetail } = useNavigate();
  const { currentPage } = useCurrentPage();
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);

  return (
    <section
      className={`
        ${styles.container} 
        ${editList ? styles.pointer : ''} 
        ${selected ? styles.selected : ''}
      `}
      onClick={editList ? () => onClick(profileId) : undefined}
    >
      {!hideProfileImage && (
        <div className={styles['profile-picture-container']}>
          <Button buttonType="transparent" onClick={() => navigateToProfileDetail(profileId)}>
            <ProfilePicture
              url={profileImageURL}
              alt={`${name} profile picture`}
              size={isGkp ? '40px' : undefined}
            />
          </Button>
        </div>
      )}
      <div className={styles['details-container']}>
        <div className={styles['heading-container']}>
          <div className={styles.name}>
            <Button
              buttonType="transparent"
              onClick={() => navigateToProfileDetail(profileId)}
              wrap
            >
              {name}
            </Button>
          </div>

          <div className={styles.menu}>
            {!isMuted && (
              <AlertButton
                notifFrequency={notifFrequency || alertFrequency}
                profileId={profileId}
              />
            )}
            <DropDownMenu
              menuItems={ProfileMenuItems({ profileId, listId })}
              alignRight
              clickElement={<Button buttonType="transparent" icon="menu" hideArrow />}
            />
          </div>
        </div>

        <div className={styles['body-container']}>
          {country && <div className={styles['body-block']}>{country}</div>}
          {twitterBio && <div className={styles['body-block']}>{twitterBio}</div>}
        </div>
      </div>
    </section>
  );
};

ProfileItem.propTypes = {
  listId: PropTypes.string,
  profile: PropTypes.object,
  selected: PropTypes.bool,
  editList: PropTypes.bool,
  onClick: PropTypes.func,
  hideProfileImage: PropTypes.bool,
};

ProfileItem.defaultProps = {
  listId: undefined,
  profile: undefined,
  selected: false,
  editList: false,
  onClick: undefined,
  hideProfileImage: false,
};
