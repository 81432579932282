/**
 * @function convertArrayToFormData
 * @param {Object[]} data - The data to be converted
 * @returns {Object} - The converted data
 */
export const convertArrayToFormData = data => {
  return (
    data?.reduce((object, current) => {
      return {
        ...object,
        [current.key]: current?.id || current.value,
      };
    }, {}) || {}
  );
};

/**
 * @function convertArrayToTableData
 * @param {Object[]} data - The data to be converted
 * @returns {Object} - The converted data
 */
export const convertArrayToTableData = data => {
  const tableObject =
    data?.reduce((object, current) => {
      if (current.tooltip) {
        return {
          ...object,
          [current.key]: current.value,
          [`${current.key}_tooltip`]: current.tooltip,
        };
      }
      return {
        ...object,
        [current.key]: current.value,
      };
    }, {}) || {};

  tableObject.formData = convertArrayToFormData(data);

  return tableObject;
};

/**
 * @function convertArrayToTableData
 * @param {Array[]} data - The data to be converted
 * @returns {Array} - The converted data
 */

export const formatTableData = data => {
  return (
    data.reduce((dataArray, current) => {
      return [...dataArray, convertArrayToTableData(current)];
    }, []) || []
  );
};

/**
 * @function convertRowToValueWithTooltip
 * @description - converts a react-table row object to a value and tooltip
 * @param {Object<TableRowProps>} row - react-table row object
 * @returns {Object} - { value, tooltip }
 */
export const convertRowToValueWithTooltip = row => {
  const key = row?.column?.key;
  const value = row?.value;
  const tooltip = row?.row?.original[`${key}_tooltip`];

  return { value, tooltip };
};
