import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';

export const AccordionToggleWrapper = ({ children, eventKey, onSubmit }) => {
  const buttonClick = useAccordionButton(eventKey, () => onSubmit());

  return (
    <Button icon="check" onClick={buttonClick}>
      {children}
    </Button>
  );
};

AccordionToggleWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  eventKey: PropTypes.PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
};

AccordionToggleWrapper.defaultProps = {
  children: undefined,
  eventKey: 0,
  onSubmit: undefined,
};
