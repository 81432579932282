import PropTypes from 'prop-types';

import styles from './Container.module.scss';

export const Container = ({ children }) => {
  return <section className={styles.container}>{children}</section>;
};

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

Container.defaultProps = {
  children: undefined,
};
