import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import { MODULE_KEYS } from 'constants';
import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ModuleItem } from './ModuleItem';

export const InsightsCapturePicker = ({ setIsExpanded, onSelect }) => {
  const history = useHistory();
  const { currentPage } = useCurrentPage();
  const { insightsCaptureProjects, fetchProjects, getProjectDisplayName } = useUser();
  const { pathname } = useLocation();

  const projectId = pathname.split('/')[2];

  useEffect(() => {
    fetchProjects(MODULE_KEYS.insightsCapture);
  }, [fetchProjects]);

  const navigate = project => {
    const { projectID } = project;

    setIsExpanded(-1);
    history.push(`${ROUTE.captureInsights}/${projectID}/${ROUTE.landing}`);
    if (onSelect) {
      onSelect();
    }
  };

  if (insightsCaptureProjects?.length > 0) {
    return insightsCaptureProjects.map((project, index) => (
      <ModuleItem
        tabIndex={index}
        key={project.projectID}
        name={getProjectDisplayName(project)}
        selected={currentPage(ROUTE.captureInsights) && project.projectID.toString() === projectId}
        onClick={() => navigate(project)}
      />
    ));
  }

  return <LoaderSpinner color="#FFF" />;
};
InsightsCapturePicker.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};
InsightsCapturePicker.defaultProps = {
  onSelect: undefined,
};
