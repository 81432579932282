import { useLocation } from 'react-router-dom';
import pharmaSpectraLogo from 'assets/images/pharmaSpectraLogo.svg';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import isSosvModule from 'utils/isSosvModule';

import NavBarItems from 'common/NavBar/NavBarItems';

import styles from './NavBar.module.scss';

export const NavBar = ({ navItems, help, kolSearch, additionalSecondaryLinks }) => {
  const { pathname } = useLocation();
  const { isDesktop, screenWidth } = useLayout();

  const subNavItems = navItems?.length
    ? navItems.find(item => pathname?.includes(item.url))?.subNavItems || []
    : [];

  return (
    <>
      <nav className={styles.container}>
        <section className={styles['nav-left']}>
          <div className={styles['logo-container']}>
            {isSosvModule(pathname) && (
              <img
                className={styles.logo}
                src={pharmaSpectraLogo}
                alt="logo"
                style={{ height: 14, marginBottom: '3px' }}
              />
            )}
          </div>

          <NavBarItems
            navItems={navItems}
            type="nav-bar"
            filter={isDesktop || screenWidth === 'largeTablet' ? 'primary' : 'all'}
            element={isDesktop || screenWidth === 'largeTablet' ? undefined : 'icon'}
          />

          {!isDesktop && screenWidth !== 'largeTablet' && (
            <section className={styles['page-label']}>
              <NavBarItems navItems={navItems} element="label" active filter="all" />
            </section>
          )}
        </section>

        <section className={styles['nav-right']}>
          {additionalSecondaryLinks}
          <NavBarItems
            navItems={navItems}
            filter={isDesktop || screenWidth === 'largeTablet' ? 'secondary' : 'none'}
            element="icon"
            help={help}
            kolSearch={kolSearch}
          />
        </section>
      </nav>
      <section
        className={`
          ${styles.container} 
          ${styles['sub-nav']} 
          ${isSosvModule(pathname) ? styles.sosv : ''} 
          ${subNavItems.length ? styles.open : ''}`}
      >
        <NavBarItems
          type="nav-bar"
          filter="all"
          navItems={subNavItems}
          element={isDesktop || screenWidth === 'largeTablet' ? undefined : 'icon'}
        />

        {!isDesktop && screenWidth !== 'largeTablet' && (
          <section className={styles['page-label']}>
            <NavBarItems navItems={subNavItems} element="label" active filter="all" />
          </section>
        )}
      </section>
    </>
  );
};

NavBar.propTypes = {
  navItems: PropTypes.arrayOf(PropTypes.object),
  help: PropTypes.bool,
  kolSearch: PropTypes.bool,
  additionalSecondaryLinks: PropTypes.element,
};

NavBar.defaultProps = {
  navItems: undefined,
  help: false,
  kolSearch: false,
  additionalSecondaryLinks: null,
};
