import { useState } from 'react';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import { EditCollectionAction } from 'common/Collections';
import { AlertButton } from 'common/MenuItems';
import Modal from 'common/Modals/Modal';
import Scroll from 'common/Scroll/Scroll';
import SearchBar from 'common/searchBar/SearchBar';

import { BookMarksDetailsCollection, EditCollection } from 'Pages/Bookmarks/Components';
import { EventDetailsCollection } from 'Pages/EventCollections/Components/DetailsCollection/EventDetailsCollection';

import styles from './EditCollectionModal.module.scss';

export const EditCollectionModal = ({ collection, label }) => {
  const [editCollection, setEditCollection] = useState();
  const [selectedContributions, setSelectedContributions] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [itemSearchValue, setItemSearchValue] = useState('');
  const { eventName } = useGlobalKolPlanningContext();
  const collectionItems = label === 'Events' ? 'events' : 'contributions';
  const itemName = label === 'Events' ? 'eventTitle' : 'profileName';
  const { collections } = useCollections();

  const toggleSelectedContribution = contributionId => {
    if (selectedContributions.includes(contributionId)) {
      setSelectedContributions(
        selectedContributions.filter(contribution => contribution !== contributionId),
      );
    } else {
      setSelectedContributions([...selectedContributions, contributionId]);
    }
  };
  const toggleSelectedEvent = eventId => {
    if (selectedEvents.includes(eventId)) {
      setSelectedEvents(selectedEvents.filter(event => event !== eventId));
    } else {
      setSelectedEvents([...selectedEvents, eventId]);
    }
  };

  const currentCollection =
    collections?.find(item => item.collectionID === collection.collectionID) || {};

  const filteredItems =
    currentCollection?.[collectionItems]?.filter(item =>
      item?.[itemName]?.toLowerCase().includes(itemSearchValue.toLowerCase()),
    ) || [];

  return (
    <Modal width="large" height="65vh" title="Edit Collection" ignoreOutsideClick>
      <section className={styles.header}>
        <h5>{currentCollection?.collectionName}</h5>
        {label === 'Events' && (
          <AlertButton
            notifFrequency={currentCollection?.notifFrequency}
            collectionID={currentCollection?.collectionID}
            collectionName={currentCollection?.collectionName}
          />
        )}
        <EditCollectionAction
          editCollection={editCollection}
          setEditCollection={setEditCollection}
          disabled={filteredItems?.length === 0}
          collectionType={label === 'Contributions' ? 'bookmark' : eventName?.toLowerCase()}
        />
      </section>
      {editCollection && (
        <section>
          <EditCollection
            collection={currentCollection}
            setEditCollection={setEditCollection}
            selectedCollections={label === 'Contributions' ? selectedContributions : selectedEvents}
            setSelectedCollections={
              label === 'Contributions' ? setSelectedContributions : setSelectedEvents
            }
            itemName={label === 'Contributions' ? 'contribution' : eventName?.toLowerCase()}
          />
        </section>
      )}

      <section className={styles.search}>
        <SearchBar
          value={itemSearchValue}
          onChange={value => {
            setItemSearchValue(value);
            label === 'Events' ? setSelectedEvents([]) : setSelectedContributions([]);
          }}
        />
      </section>

      <Scroll
        height={currentCollection?.[collectionItems]?.length > 0 ? '45vh' : undefined}
        hideArrow
      >
        {label === 'Contributions' ? (
          <BookMarksDetailsCollection
            collection={collection}
            contributions={filteredItems}
            selectedContributions={selectedContributions}
            toggleSelectedContribution={toggleSelectedContribution}
            editCollection={editCollection}
          />
        ) : (
          <EventDetailsCollection
            collection={collection}
            contributions={filteredItems}
            selectedContributions={selectedEvents}
            toggleSelectedContribution={toggleSelectedEvent}
            editCollection={editCollection}
          />
        )}
      </Scroll>
    </Modal>
  );
};

EditCollectionModal.propTypes = {
  collection: PropTypes.object,
  label: PropTypes.string,
};
EditCollectionModal.defaultProps = {
  collection: undefined,
  label: '',
};
