import styled from 'styled-components';

export const SavedFilterTooltipBadge = styled.div`
  display: inline-block;
  background: var(--card-background-color);
  margin: 0.25rem 0.5rem 0.25rem 0;
  padding: 0.25rem;
  border-radius: var(--border-radius-xs);
  font-size: 0.8em;
  &:last-child {
    margin-right: 0;
  }
`;

export const SavedFilterTooltipContentContainer = styled.div`
  max-width: 220px;
`;
