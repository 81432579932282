import { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import styles from './InfiniteScroll.module.scss';

const InfiniteScroll = ({ children, hasMore, next, loading }) => {
  const containerRef = useRef();

  const scroll = useCallback(() => {
    const nearBottom =
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight - 250;

    if (nearBottom && hasMore && !loading) {
      next();
    }
  }, [hasMore, next, containerRef, loading]);

  useEffect(() => {
    const container = containerRef.current;

    containerRef.current.addEventListener('scroll', scroll);

    return () => {
      container.removeEventListener('scroll', scroll);
    };
  }, [scroll, containerRef]);

  return (
    <section ref={containerRef} className={styles.container}>
      {children}

      {loading && <LoaderSpinner spinnerType="dots" />}
    </section>
  );
};

InfiniteScroll.propTypes = {
  children: PropTypes.node.isRequired,
  hasMore: PropTypes.bool.isRequired,
  next: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default InfiniteScroll;
