import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Yup from 'utils/formValidation';

import { Form, InputImage, InputText } from 'common/Form';

export const CollectionForm = forwardRef(({ collection, onSubmit }, ref) => {
  const initialValues = {
    collectionID: collection?.collectionID,
    collectionName: collection?.collectionName || '',
    description: collection?.description || '',
    picture: collection?.picture,
  };

  const validationSchema = Yup.object({
    collectionName: Yup.string().validateName().required('Collection name is required'),
  });

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      ref={ref}
      initialValues={initialValues}
    >
      <InputText name="collectionName" label="Collection Name" required />
      <InputText name="description" label="Description" />
      <InputImage name="picture" label="Picture" />
    </Form>
  );
});

CollectionForm.propTypes = {
  collection: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
CollectionForm.defaultProps = {
  collection: {},
};
