import numeral from 'numeral';

/**
 * @function formatBytes
 * @param {number} bytes - The number of bytes
 * @param {number} decimals - The number of decimals to show
 * @returns {string} - The formatted bytes
 */
const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

/**
 * @function formatToK
 * @param {number} input - The number to format
 * @returns {string} - The formatted number
 */
const formatToK = input => {
  if (!input) return '';

  const num = Number(input);
  if (num > 999999) {
    return `${input?.toLocaleString()?.slice(0, -4) || ''}K`;
  }
  if (num > 1000) {
    return numeral(input).format('0.0a');
  }
  return numeral(input).format('0a');
};

/**
 * @function formatToCommaSeparated
 * @param {number} input - The number to format
 * @returns {string} - The formatted number
 */
const formatToCommaSeparated = input => {
  const remainder = input % 1;
  return remainder > 0 ? numeral(input).format('0,0.00') : numeral(input).format('0,0');
};

/**
 * @function formatNumberToKandM
 * @param {number} num - number to format
 * @param {number} [decimals=0] - number of decimals to show
 * @returns {string} - formatted number
 */
const formatNumberToKandM = (num, decimals = 0) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

  const item = lookup
    .slice()
    .reverse()
    .find(lookupItem => num >= lookupItem.value);

  return item ? (num / item.value).toFixed(decimals).replace(rx, '$1') + item.symbol : 0;
};

/**
 * @function percentChange
 * @param {number} curr - current value
 * @param {number} prev - previous value
 * @returns {number} - percentage change
 */
const percentChange = (curr, prev) => {
  if (prev === 0) return 0;
  return Math.round(((curr - prev) * 100) / prev);
};

export { formatBytes, formatNumberToKandM, formatToCommaSeparated, formatToK, percentChange };
