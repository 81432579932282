import PropTypes from 'prop-types';

import { useNavigate } from 'utils/hooks';

const ShowContributionDetailsMenuItem = ({ contributionId }) => {
  const { navigateToContributionDetail } = useNavigate();

  const navigateToContribution = () => {
    navigateToContributionDetail(contributionId);
  };

  return {
    label: 'Show contribution details',
    onClick: () => navigateToContribution(),
  };
};

ShowContributionDetailsMenuItem.propTypes = { contributionId: PropTypes.string.isRequired };

export default ShowContributionDetailsMenuItem;
