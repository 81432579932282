import { useHistory, useLocation, useParams } from 'react-router-dom';

import ROUTE from 'constants/route';

export const CollectionCardMenuOptions = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const menuItems = [
    {
      label: 'Go to Collections Page',
      onClick: () => {
        history.push(
          `/${pathname.split('/')[1]}${projectId ? `/${projectId}` : ''}/${ROUTE.bookmarks}`,
        );
      },
    },
  ];
  return menuItems;
};
