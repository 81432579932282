import axios from 'axios';
import axiosRetry from 'axios-retry';
import PropTypes from 'prop-types';

import ROUTE from 'constants/route';

const calls = {};

const api = async ({
  url,
  method,
  headers,
  data,
  authCall,
  retries,
  returnError,
  baseURL,
  version,
  responseType = 'json',
  accessToken,
}) => {
  const generateKey = value => {
    if (['/shared/lists', '/GlobalKOLPlanningLists/GetGKPLists'].includes(value)) {
      return ROUTE.lists;
    }

    return value;
  };
  const urlKey = `${generateKey(url)}${data?.dataStep || ''}${data?.moduleId || ''}${
    data?.type || ''
  }${data?.id || ''}${data?.search ? JSON.stringify(data) : JSON.stringify(data?.filters) || ''}`;

  if (calls[urlKey]) {
    calls[urlKey].cancel(`Call cancelled by duplicate call: ${url}`);
  }

  calls[urlKey] = axios.CancelToken.source();

  const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';
  const defaultBaseURL = authCall
    ? process.env.REACT_APP_DEFAULT_AUTH_API_URL
    : `${process.env.REACT_APP_DEFAULT_BASE_API_URL}/v${
        version || process.env.REACT_APP_DEFAULT_BASE_API_VERSION || '1'
      }`;

  const apiCall = axios.create({
    baseURL: baseURL || defaultBaseURL,
    withCredentials: true,
    responseType,
  });

  axiosRetry(apiCall, {
    retries,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: error => {
      const retryCodes = [401];

      if (retryCodes.includes(error?.response?.status)) {
        return true;
      }
      return false;
    },
  });

  const response = await apiCall
    .request({
      cancelToken: calls[urlKey].token,
      url,
      method,
      headers: { Authorization: `Bearer ${accessToken}`, ...headers },
      [dataOrParams]: data,
    })
    .catch(error => {
      if (returnError || axios.isCancel(error)) {
        throw error;
      }

      const statusCode = error?.response?.status;
      const logoutCodes = [401, 403];

      if (logoutCodes.includes(statusCode)) {
        window.location.href = '/logout';
        return '';
      }

      // For 400 errors, throw the error back to component to handle
      if ([400].includes(statusCode)) {
        throw error?.response?.data;
      }

      throw error;
    });

  delete calls[urlKey];
  if (response?.data) {
    const filename = response.headers['content-disposition']?.split('filename="')[1]?.split('"')[0];
    if (filename) {
      return { fileName: filename, data: response.data };
    }
    return response.data;
  }
  return response;
};

api.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.oneOf(['GET', 'POST', 'PUT', 'DELETE']),
  headers: PropTypes.object,
  data: PropTypes.object,
  authCall: PropTypes.bool,
  retries: PropTypes.number,
  baseURL: PropTypes.string,
};

api.defaultProps = {
  method: 'GET',
  headers: {},
  data: {},
  authCall: false,
  retries: 5,
  baseURL: undefined,
};

export default api;
