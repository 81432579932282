export const msalConfig = {
  auth: {
    clientId: process.env.aadClientId || process.env.REACT_APP_DEFAULT_CLIENT_ID,
    authority: process.env.REACT_APP_DEFAULT_AZURE_LOGIN_ENDPOINT, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [process.env.REACT_APP_DEFAULT_AZURE_AUTH_SCOPE],
  prompt: 'select_account',
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = { graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me' };
