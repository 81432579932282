import { useState } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';
import UploadPictureModal from 'common/uploadPictureModal/UploadPictureModal';

import { EmptyListIcon } from 'Pages/Lists/Components';

import styles from './InputImage.module.scss';
import inputStyles from './Inputs.module.scss';

export const InputImage = ({ className, type, name, label, placeholder, required, error }) => {
  const [imageModalOpen, setImageModalOpen] = useState(false);

  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field id={name} name={name} type={type} placeholder={placeholder} required={required}>
          {({ field: { value = '' }, form: { setFieldValue } }) => (
            <div className={styles.container}>
              {value && (
                <ProfilePicture
                  url={value}
                  size="64px"
                  alt={label}
                  onClick={() => setImageModalOpen(true)}
                />
              )}
              {!value && <EmptyListIcon onClick={() => setImageModalOpen(true)} />}
              <Button buttonType="transparent" onClick={() => setImageModalOpen(true)}>
                Browse
              </Button>
              <UploadPictureModal
                show={imageModalOpen}
                onSave={imageBase64 => {
                  setFieldValue(name, imageBase64);
                }}
                close={() => setImageModalOpen(false)}
                maxSizeMb={2}
              />
            </div>
          )}
        </Field>
      </label>
      {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
    </div>
  );
};

InputImage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  placeholder: PropTypes.string,
  type: PropTypes.string,
};

InputImage.defaultProps = {
  className: '',
  name: '',
  label: undefined,
  required: false,
  error: undefined,
  placeholder: undefined,
  type: undefined,
};
