import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useModal } from 'context/modalContext';

import { useItemInCollections } from 'utils/hooks';

import { BookmarkIcon } from 'common/BookmarkIcon';
import { MultiItemCollectionManagementModal } from 'common/Modals/MultiItemCollectionManagementModal';

import { AddCollectionAction } from 'Pages/Bookmarks/Components/Actions';

export const MultiContributionCollections = ({ mentions, events, disabled }) => {
  const { openModal } = useModal();
  const { onAdd, onRemove } = useItemInCollections({
    mentions,
    EventID: events,
  });

  const { collections } = useCollections();
  const { eventName } = useGlobalKolPlanningContext();

  const onClick = () => {
    openModal(
      <MultiItemCollectionManagementModal
        title="Manage Collections"
        type={mentions ? 'contribution' : eventName?.toLowerCase()}
        allCollections={collections}
        multiSelectedItems={mentions || events}
        createCollection={[<AddCollectionAction mentions={mentions} events={events} />]}
        onAdd={onAdd}
        onRemove={onRemove}
      />,
    );
  };

  return (
    <BookmarkIcon
      mentions={mentions}
      onClick={onClick}
      disabled={disabled}
      bookmarkOff
      tooltip="Manage Collections"
    />
  );
};

MultiContributionCollections.propTypes = {
  mentions: PropTypes.array,
  events: PropTypes.array,
  disabled: PropTypes.bool,
};
MultiContributionCollections.defaultProps = {
  mentions: undefined,
  events: undefined,
  disabled: false,
};
