import { useCallback } from 'react';

import { useUser } from 'context/userContext';

import { isLocalhost } from 'utils/urlUtils';

export const useFullStory = () => {
  const { userDetails, modules } = useUser();

  const initiateFullStory = useCallback(() => {
    const { FS } = window;
    if (userDetails?.email && !isLocalhost()) {
      const currentModule = modules?.find(module => module.isCurrent);
      if (FS) {
        FS('setIdentity', {
          uid: userDetails.userId,
          properties: {
            userName: userDetails.name,
            email: userDetails.email,
            userClientName: userDetails.clientName,
            userClientId: userDetails.clientId,
            moduleName: currentModule?.name,
            currentProjectId: currentModule?.currentProject,
          },
        });
      }
    }
  }, [userDetails, modules]);

  return { initiateFullStory };
};
