import PropTypes from 'prop-types';

import { EditCollectionAction } from 'common/Collections';
import { AlertButton } from 'common/MenuItems';

import { AddKolAction } from 'Pages/Lists/Components';
import { AddFiltersAction } from 'Pages/SavedSearches/Components';

export const DetailsCollectionObjectActions = ({
  setEditCollection,
  filteredCollections,
  editCollection,
  collectionType,
  currentCollection,
}) => {
  const detailsActionsContent = () => {
    switch (collectionType) {
      case 'bookmark':
        return [
          <EditCollectionAction
            setEditCollection={setEditCollection}
            editCollection={editCollection}
            disabled={filteredCollections?.length === 0}
            label="Edit Collection"
          />,
        ];
      case 'savedSearch':
        return [
          <AddFiltersAction
            search={currentCollection}
            disabled={filteredCollections.length === 0}
          />,
          <EditCollectionAction
            setEditCollection={setEditCollection}
            editCollection={editCollection}
            disabled={filteredCollections?.length === 0}
            label="Edit Saved Search"
          />,
        ];
      case 'list':
        return [
          <AddKolAction list={currentCollection} disabled={filteredCollections.length === 0} />,
          <AlertButton
            notifFrequency={currentCollection?.notifFrequency}
            listId={currentCollection.listId}
            listName={currentCollection.listName}
            disabled={filteredCollections.length === 0}
            tooltip="Set alert for selected list"
            subMenuLabel="Set alert for selected list"
          />,
          <EditCollectionAction
            setEditCollection={setEditCollection}
            editCollection={editCollection}
            disabled={filteredCollections?.length === 0}
            label="Edit List"
          />,
        ];
      case 'event':
        return [
          <AlertButton
            notifFrequency={currentCollection?.notifFrequency}
            collectionID={currentCollection.collectionID}
            disabled={filteredCollections.length === 0}
            subMenuLabel="Set alert for selected collection"
            tooltip="Set alert for selected collection"
          />,
          <EditCollectionAction
            setEditCollection={setEditCollection}
            editCollection={editCollection}
            disabled={filteredCollections?.length === 0}
            label="Edit Collection"
          />,
        ];
      default:
        return null;
    }
  };

  return detailsActionsContent();
};

DetailsCollectionObjectActions.propTypes = {
  setEditCollection: PropTypes.func.isRequired,
  filteredCollections: PropTypes.array,
  collectionType: PropTypes.string,
  editCollection: PropTypes.bool,
  currentCollection: PropTypes.object,
};

DetailsCollectionObjectActions.defaultProps = {
  filteredCollections: [],
  collectionType: 'bookmark',
  editCollection: false,
  currentCollection: {},
};
