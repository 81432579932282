import { useFeatures } from 'context/featureContext';
import { useFilters } from 'context/filtersContext';

import { AlertMenuItem } from 'common/MenuItems/AlertMenuItem';

export const UpdateFilters = ({ close }) => {
  const { features, featureEnabled } = useFeatures();
  const {
    currentSavedSearch,
    saveSearch,
    deleteSavedSearch,
    removeSavedSearch,
    setSavedSearchAlert,
  } = useFilters();

  const submit = () => {
    saveSearch({
      savedSearchName: currentSavedSearch.savedSearchName,
      savedSearchId: currentSavedSearch.savedSearchId,
    });
    close();
  };

  const deleteSearch = () => {
    deleteSavedSearch([currentSavedSearch.savedSearchId]);
    close();
  };

  const setAlert = key => {
    setSavedSearchAlert({
      savedSearchIds: [currentSavedSearch.savedSearchId],
      notifFrequency: key,
    });
  };

  const resetSavedSearch = () => {
    removeSavedSearch();
  };

  const filterMenu = [
    { label: 'Save Changes', onClick: submit },
    featureEnabled(features.alerts) && {
      label: 'Set Alerts',
      subMenuContent: [
        {
          type: 'component',
          component: (
            <AlertMenuItem
              setAlert={setAlert}
              currentValue={currentSavedSearch?.notifFrequency}
              noHeader
              collectionType="savedSearch"
            />
          ),
        },
      ],
    },
    { label: 'Delete', onClick: deleteSearch, className: 'text-danger' },
    { label: 'Clear search', onClick: resetSavedSearch },
  ];

  return { filterMenu };
};
