import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './IconInCircle.module.scss';

export const IconInCircle = ({
  name,
  onClick,
  backgroundColor,
  width,
  height,
  iconSize,
  iconClass,
  border,
}) => (
  <div
    className={styles.container}
    onClick={onClick}
    style={{ backgroundColor, width, height, border }}
  >
    <Icon name={name} size={iconSize} className={iconClass} />
  </div>
);

IconInCircle.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  iconSize: PropTypes.number,
  iconClass: PropTypes.string,
  border: PropTypes.string,
};

IconInCircle.defaultProps = {
  onClick: undefined,
  backgroundColor: '#f7f7f7',
  width: '60px',
  height: '60px',
  iconSize: undefined,
  iconClass: undefined,
  border: undefined,
};
