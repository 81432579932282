import React from 'react';
import PropTypes from 'prop-types';

const IconText = ({ children, text, onClick }) => (
  <div onClick={onClick} className="icon-text d-flex align-items-center justify-content-between">
    {children}
    <span>{text}</span>
  </div>
);

IconText.propTypes = {
  children: PropTypes.object,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IconText.defaultProps = {
  children: [],
  text: '',
};

export default IconText;
