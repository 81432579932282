import PropTypes from 'prop-types';

import {
  DeleteSavedSearchMenuItem,
  DownloadSavedSearchMenuItem,
  SavedSearchItem,
} from 'Pages/SavedSearches';

export const SavedSearchCollection = ({ savedSearches, onClick, selectedSavedSearch }) => {
  return (
    <>
      {savedSearches?.length === 0 && (
        <section>
          <div>
            <span>No saved searches found</span>
          </div>
        </section>
      )}
      {savedSearches?.map((search, index) => (
        <SavedSearchItem
          search={search}
          key={search.savedSearchId}
          onClick={onClick ? () => onClick({ index, search }) : null}
          selected={selectedSavedSearch === index}
          menuItems={[
            DeleteSavedSearchMenuItem({ search }),
            DownloadSavedSearchMenuItem({ search }),
          ]}
        />
      ))}
    </>
  );
};

SavedSearchCollection.propTypes = {
  savedSearches: PropTypes.array,
  onClick: PropTypes.func,
  selectedSavedSearch: PropTypes.number,
};

SavedSearchCollection.defaultProps = {
  savedSearches: [],
  onClick: undefined,
  selectedSavedSearch: 0,
};
