import styled from 'styled-components';

export const PopupContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
`;

export const SearchTextArea = styled.textarea`
  min-height: 170px;
  max-height: 500px;
  width: 100%;
  resize: none;
  line-height: 1.4rem;
  background: var(--filter-background-color);
  box-sizing: border-box;
`;

export const SearchLengthLabel = styled.label`
  text-align: right;
`;
