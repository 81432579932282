import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useFilters } from 'context/filtersContext';

import { useSlideDrawer } from './slideDrawerContext';

const FilterMenuContext = createContext({});

const FilterMenuProvider = props => {
  const { closeSlideDrawer } = useSlideDrawer();
  const { updatedFilters, setFilter: setAllFilters, defaultFilters } = useFilters();
  const [internalFilters, setInternalFilters] = useState(updatedFilters);
  const optionsHeight = '150px';

  useEffect(() => {
    setInternalFilters(updatedFilters);
  }, [updatedFilters]);

  const activeFilters = useMemo(
    () =>
      Object.keys(internalFilters).filter(
        filter => Object.keys(internalFilters?.[filter] || {})?.length > 0,
      ),
    [internalFilters],
  );

  const selectedValues = useCallback(filterName => internalFilters[filterName], [internalFilters]);

  const minMaxDate = useCallback(
    filterName => {
      if (filterName === 'addedToKwello') {
        const { dateRange, projectPeriod } = internalFilters;
        if (dateRange) {
          return { min: dateRange.start, max: new Date() };
        }
        const today = new Date();
        if (projectPeriod) {
          if (projectPeriod.metric === 'month') {
            today.setMonth(today.getMonth() - projectPeriod.value);
            return { min: today, max: new Date() };
          }
          if (projectPeriod.metric === 'day') {
            today.setDate(today.getDate() - projectPeriod.value);
            return { min: today, max: new Date() };
          }
        } else {
          today.setMonth(today.getMonth() - 12);
          return { min: today, max: new Date() };
        }
      }
      return undefined;
    },
    [internalFilters],
  );

  const setFilter = useCallback(({ filterKey, filterValue, singleValue }) => {
    if (filterKey === 'scientificRank' || singleValue) {
      return setInternalFilters(prevState => ({
        ...prevState,
        [filterKey]: filterValue,
      }));
    }

    if (Array.isArray(filterValue)) {
      return setInternalFilters(prevState => ({
        ...prevState,
        [filterKey]: [...(prevState?.[filterKey] || []), ...filterValue],
      }));
    }

    return setInternalFilters(prevState => ({
      ...prevState,
      [filterKey]: [...(prevState?.[filterKey] || []), filterValue],
    }));
  }, []);

  const removeFilter = useCallback(({ filterKey, filterValue }) => {
    setInternalFilters(prevState => ({
      ...prevState,
      [filterKey]:
        prevState[filterKey]?.length === 1
          ? undefined
          : prevState[filterKey].filter(filter => filter.id !== filterValue.id),
    }));
  }, []);

  const resetFilter = useCallback(filterKey => {
    if (filterKey) {
      return setInternalFilters(prevState => ({
        ...prevState,
        [filterKey]: undefined,
      }));
    }

    return setInternalFilters({});
  }, []);

  const getTempFilters = useCallback(() => {
    const tempFilters = internalFilters;
    Object.keys(tempFilters).forEach(filter => {
      if (!tempFilters[filter]) {
        tempFilters[filter] = defaultFilters?.[filter];
      }
    });
    return tempFilters;
  }, [defaultFilters, internalFilters]);

  const applyFilters = useCallback(() => {
    // eslint-disable-next-line no-underscore-dangle
    const cio = window._cio;
    cio.track('applyFilters', { filters: { ...defaultFilters, ...getTempFilters() } });
    setAllFilters({ ...defaultFilters, ...getTempFilters() });
    closeSlideDrawer();
  }, [getTempFilters, closeSlideDrawer, defaultFilters, setAllFilters]);

  const clearChanges = useCallback(() => {
    setInternalFilters(updatedFilters);
  }, [updatedFilters]);

  const value = useMemo(
    () => ({
      internalFilters,
      activeFilters,
      updatedFilters: internalFilters,
      optionsHeight,
      appliedFilters: updatedFilters,
      selectedValues,
      setFilter,
      removeFilter,
      resetFilter,
      applyFilters,
      clearChanges,
      minMaxDate,
    }),
    [
      internalFilters,
      activeFilters,
      updatedFilters,
      optionsHeight,
      applyFilters,
      clearChanges,
      removeFilter,
      resetFilter,
      selectedValues,
      setFilter,
      minMaxDate,
    ],
  );

  return <FilterMenuContext.Provider value={value} {...props} />;
};

const useFilterMenu = () => useContext(FilterMenuContext);

export { FilterMenuProvider, useFilterMenu };
