import { filtersSettings } from 'constants';

/**
 * @function resolveFilterId
 * @param {string} key - The key to be resolved
 * @returns {string} - The resolved filter id
 */
const resolveFilterId = key => {
  let id;

  filtersSettings?.forEach(category => {
    const menuItem = category?.items?.find(item => item.value === key);
    if (menuItem !== undefined) {
      id = menuItem?.key;
    }
  });

  return id;
};

export default resolveFilterId;
