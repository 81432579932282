import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth0 } from '@auth0/auth0-react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';

import { oneHour } from 'constants/reactQueryConfig';
import ROUTE from 'constants/route';
import { difference } from 'utils/dateHelper';

import { InactiveModal } from 'common/InactiveModal';
import { toastNotify } from 'common/ToastNotification/ToastNotification';

import { useModal } from './modalContext';

const AuthContext = createContext({});

const AuthProvider = props => {
  const { openModal } = useModal();
  const msalIsAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(undefined);
  const [tokenExpiry, setTokenExpiry] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [msalCookies, setMsalCookies] = useState(!!document?.cookie.includes('msal'));
  const [auth0Cookies, setAuth0Cookies] = useState(!!document?.cookie.includes('auth0'));
  const refreshTimer = useRef();

  const {
    isAuthenticated: auth0Authenticated,
    getAccessTokenSilently,
    getIdTokenClaims,
    logout: authLogout,
  } = useAuth0();

  const showInactiveModal = () => {
    openModal(<InactiveModal />);
  };

  const idleTimeout = useIdleTimer({
    startOnMount: false,
    timeout: oneHour,
    onIdle: showInactiveModal,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
  });

  const isMsalAuthenticated = useMemo(
    () => msalIsAuthenticated && !msalCookies,
    [msalIsAuthenticated, msalCookies],
  );

  useEffect(() => {
    setMsalCookies(document?.cookie.includes('msal'));
    setAuth0Cookies(document?.cookie.includes('auth0'));

    // Disabled the following rule because we want to update state when cookies change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document?.cookie]);

  const requestAccessToken = useCallback(() => {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then(response => {
        setTokenExpiry(new Date(response.expiresOn));
        setAccessToken(response.accessToken);
        setLoading(false);
      })
      .catch(() => {
        instance.acquireTokenRedirect(request).then(response => {
          setTokenExpiry(new Date(response.expiresOn));
          setAccessToken(response.accessToken);
          setLoading(false);
        });
      });
  }, [instance, accounts]);

  const requestAuth0AccessToken = useCallback(async () => {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://kwello.uk.auth0.com/userinfo',
        scope: 'read:current_user',
      },
    });
    if (token) {
      const idToken = await getIdTokenClaims();
      if (idToken) {
        setTokenExpiry(new Date(idToken.exp * 1000));
        // eslint-disable-next-line no-underscore-dangle
        setAccessToken(idToken?.__raw);
        setLoading(false);
      }
    }
  }, [getIdTokenClaims, getAccessTokenSilently]);

  useEffect(() => {
    if (tokenExpiry) {
      const timeRemaining = difference(tokenExpiry, new Date());

      if (timeRemaining > 0) {
        refreshTimer.current = setTimeout(() => {
          if (auth0Authenticated) {
            requestAuth0AccessToken();
          } else {
            requestAccessToken();
          }
        }, timeRemaining * 60 * 1000);
      } else if (auth0Authenticated) {
        requestAuth0AccessToken();
      } else {
        requestAccessToken();
      }
    } else {
      clearTimeout(refreshTimer.current);
    }
  }, [tokenExpiry, requestAccessToken, auth0Authenticated, requestAuth0AccessToken]);

  useEffect(() => {
    if (loggingOut && !isMsalAuthenticated && !accessToken && !auth0Authenticated) {
      setLoggingOut(false);
    }

    if (!loggingOut) {
      if (isMsalAuthenticated && !accessToken && !auth0Authenticated) {
        setLoading(true);
        if (isMsalAuthenticated) {
          requestAccessToken();
        }
      }
      if (!isMsalAuthenticated) {
        if (auth0Authenticated && !accessToken) {
          setLoading(true);
          requestAuth0AccessToken();
        }

        if (accessToken && !auth0Authenticated) {
          setAccessToken(undefined);
          setLoading(false);
        }
      }
    }
  }, [
    isMsalAuthenticated,
    accessToken,
    loggingOut,
    requestAccessToken,
    auth0Authenticated,
    requestAuth0AccessToken,
    auth0Cookies,
  ]);

  const clearAccessToken = useCallback(() => {
    setAccessToken(undefined);
  }, []);

  useEffect(() => {
    if (isMsalAuthenticated || auth0Authenticated) {
      idleTimeout.start();
    } else {
      idleTimeout.pause();
    }
  }, [isMsalAuthenticated, idleTimeout, auth0Authenticated]);

  const logout = useCallback(() => {
    setLoggingOut(true);
    localStorage.clear();
    clearAccessToken();
    if (auth0Authenticated) {
      authLogout({
        logoutParams: {
          returnTo: `${process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI}${ROUTE.auth0Login}`,
        },
      });
    } else {
      instance.logout().catch(() => {
        toastNotify('error', 'Logout failed');
      });
    }
  }, [clearAccessToken, auth0Authenticated, authLogout, instance]);

  const value = useMemo(
    () => ({
      msalCookies,
      isAuthenticated: isMsalAuthenticated || auth0Authenticated,
      accessToken,
      loading,
      clearAccessToken,
      logout,
    }),
    [
      msalCookies,
      accessToken,
      loading,
      clearAccessToken,
      logout,
      auth0Authenticated,
      isMsalAuthenticated,
    ],
  );

  return <AuthContext.Provider value={value} {...props} />;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
