import { useCollections } from 'context/collectionsContext';
import { useConfirm } from 'context/confirmationContext';

export const DeleteCollectionMenuItem = ({ collection }) => {
  const { confirm } = useConfirm();
  const { deleteCollection } = useCollections();

  const removeCollection = () => {
    confirm({
      title: 'Delete Collection',
      text: `Do you want to delete the collection "${collection?.collectionName}"?`,
      onConfirm: () => {
        deleteCollection(collection?.collectionID);
      },
    });
  };

  return {
    label: 'Delete Collection',
    className: 'text-danger',
    onClick: removeCollection,
  };
};
