import { useCollections } from 'context/collectionsContext';
import { useModal } from 'context/modalContext';

export function useItemInCollections({ EventID, mentions }) {
  const {
    collections,
    getEventCollections,
    removeItemFromCollection,
    addItemToCollection,
    getContributionCollections,
    addItemToCollectionLoading,
    removeItemFromCollectionLoading,
  } = useCollections();

  const { closeModal } = useModal();

  const existsInCollections = mentions?.length
    ? getContributionCollections(mentions)
    : (EventID && getEventCollections(EventID)) || [];

  const selectedCollections = existsInCollections?.map(collection => collection.collectionID);

  const doAdd = added => {
    addItemToCollection(
      mentions
        ? added?.map(addedCollectionId => ({
            collectionID: addedCollectionId,
            mentions,
          }))
        : {
            collectionID: added,
            events: Array.isArray(EventID) ? EventID : [EventID],
          },
    );
  };

  const onAdd = selectedItems => {
    doAdd(selectedItems);
    closeModal();
  };

  const doRemove = removed => {
    removeItemFromCollection(
      mentions
        ? removed?.map(addedCollectionId => ({
            collectionID: addedCollectionId,
            mentions,
          }))
        : {
            collectionID: removed,
            events: Array.isArray(EventID) ? EventID : [EventID],
          },
    );
  };

  const onRemove = selectedItems => {
    doRemove(selectedItems);
    closeModal();
  };

  const onSave = selectedItems => {
    const removed = existsInCollections
      ?.filter(collection => !selectedItems.includes(collection.collectionID))
      .map(item => item.collectionID);

    const added = selectedItems?.filter(
      collectionID => !selectedCollections.includes(collectionID),
    );

    if (removed?.length) {
      doRemove(removed);
    }

    if (added?.length) {
      doAdd(added);
    }

    closeModal();
  };

  return {
    collections,
    selectedCollections,
    onSave,
    onAdd,
    onRemove,
    addItemToCollectionLoading,
    removeItemFromCollectionLoading,
  };
}
