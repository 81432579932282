import api from 'services/apiService';

const { createContext, useEffect, useMemo, useState, useContext } = require('react');

const StatusContext = createContext({});

const StatusProvider = props => {
  const [status, setStatus] = useState(undefined);

  const fetchStatus = async () => {
    try {
      const response = await api({ url: '/status/GetSiteStatus' });

      if (response?.data) {
        setStatus(response.data);
      } else {
        setStatus({
          maintenanceMode: true,
        });
      }
    } catch {
      setStatus({
        maintenanceMode: true,
      });
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  const value = useMemo(() => ({ status }), [status]);

  return <StatusContext.Provider value={value} {...props} />;
};

const useStatus = () => useContext(StatusContext);

export { StatusProvider, useStatus };
