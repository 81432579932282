import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { useUser } from 'context/userContext';

export const usePendo = () => {
  const { userDetails, currentModuleProject, modules } = useUser();

  const initiatePendo = useCallback(() => {
    const { pendo } = window;
    if (userDetails?.email) {
      if (pendo) {
        pendo.initialize({
          visitor: {
            id: userDetails?.userId, // Required if user is logged in, default creates anonymous ID
            email: userDetails?.email, // Recommended if using Pendo Feedback, or NPS Email
            full_name: userDetails?.name, // Recommended if using Pendo Feedback
            // role:         // Optional
          },

          account: {
            id: userDetails?.clientId, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
            name: userDetails?.clientName, // Optional
          },
        });
      }
    }
    pendo.track('pageView', {
      page: 'Dashboard',
      isMobile: isMobileOnly,
      moduleId: currentModuleProject?.moduleId,
      projectId: currentModuleProject?.projectId,
      currentModule: modules?.find(module => module.isCurrent)?.name,
      width: JSON.stringify(window.innerWidth),
      height: JSON.stringify(window.innerHeight),
    });
  }, [userDetails, currentModuleProject, modules]);

  return { initiatePendo };
};
