import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import { CollectionItemsGroup } from 'Pages/Bookmarks/Components/CollectionItemsGroup';
import { ListCollection } from 'Pages/Lists/Components/ListCollection';
import { SavedSearchCollection } from 'Pages/SavedSearches';

export const CollectionObject = ({
  collections,
  onClick,
  selectedCollection,
  collectionType,
  hideFeedPreview,
}) => {
  const { isDesktop } = useLayout();
  const collectionContent = () => {
    switch (collectionType) {
      case 'bookmark':
      case 'event':
        return (
          <CollectionItemsGroup
            collections={collectionType === 'event' ? collections : collections}
            onClick={onClick}
            selectedCollection={selectedCollection}
            collectionType={collectionType}
          />
        );
      case 'list':
        return (
          <ListCollection
            lists={collections}
            onClick={onClick}
            selectedList={selectedCollection}
            hideCount={!hideFeedPreview && !isDesktop}
          />
        );
      case 'savedSearch':
        return (
          <SavedSearchCollection
            savedSearches={collections}
            onClick={onClick}
            selectedSavedSearch={selectedCollection}
          />
        );
      default:
        return <>Define a collection object for the collection</>;
    }
  };

  return <>{collectionContent()}</>;
};

CollectionObject.propTypes = {
  collections: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  selectedCollection: PropTypes.number,
  collectionType: PropTypes.string,
  hideFeedPreview: PropTypes.bool,
};

CollectionObject.defaultProps = {
  collections: [],
  selectedCollection: 0,
  collectionType: 'bookmark',
  hideFeedPreview: false,
};
