import { useParams } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { useCollections } from 'context/collectionsContext';
import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useLists } from 'context/listsContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import { thirtyMinutes } from 'constants/reactQueryConfig';
import useCurrentPage from 'utils/hooks/useCurrentPage';

export const useKwelloQueryKey = args => {
  const { filters } = useFilters();
  const { lists } = useLists();
  const { collections } = useCollections();
  const { projectId } = useParams();
  const { currentProject: currentSocialMonitoringProject } = useSocialMonitoring();
  const { projectSecret } = useGlobalKolPlanningContext();
  const { isSocialMonitoring } = useCurrentPage();

  const collectionKey = isSocialMonitoring ? 'contributions' : 'events';
  const collectionItemKey = isSocialMonitoring ? 'contributionId' : 'eventId';

  // Modify the filters to include the list of KOLs for each list and a list of contributions or events for each collection
  const queryFilters = {
    ...filters,
    list: filters?.list?.length
      ? filters?.list.map(filterList =>
          lists?.find(list => list.listId === filterList.id)?.koLs.map(kol => kol.profileId),
        )
      : [],
    collection: filters?.collection?.length
      ? filters.collection.map(filterCollection =>
          collections
            ?.find(collection => collection.collectionID === filterCollection.id)
            ?.[collectionKey].map(item => item[collectionItemKey]),
        )
      : [],
  };

  return [...args, queryFilters, projectId, currentSocialMonitoringProject, projectSecret];
};

/**
 * @function useKwelloQuery
 * @description React Query hook that adds modified filters to the query key
 * @param {ReactQueryOptions} args - React Query options
 * @returns {ReactQueryResult} - React Query result
 */
export const useKwelloQuery = args => {
  // Add the modified filters to the query key
  const kwelloQueryKey = useKwelloQueryKey(args.queryKey || []);

  // Return the query result and the modified query key
  return {
    ...useQuery({
      ...args,
      queryKey: kwelloQueryKey,
      staleTime: args.staleTime || thirtyMinutes,
    }),
    kwelloQueryKey,
  };
};

/**
 * @function useKwelloInfiniteQuery
 * @description React Query hook that adds modified filters to the query key
 * @param {ReactQueryOptions} args - React Query options
 * @returns {ReactQueryResult} - React Query result
 */
export const useKwelloInfiniteQuery = args => {
  // Add the modified filters to the query key
  const kwelloQueryKey = useKwelloQueryKey(args.queryKey || []);

  // Return the query result and the modified query key
  return {
    ...useInfiniteQuery({
      ...args,
      queryKey: kwelloQueryKey,
      staleTime: args.staleTime || thirtyMinutes,
    }),
    kwelloQueryKey,
  };
};
