import { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './ExpandTitle.module.scss';

export const ExpandTitle = ({
  title,
  children,
  icon,
  className,
  isExpanded,
  onClick,
  expandedClass,
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  return (
    <>
      <div
        className={`${expanded ? expandedClass : className} ${styles['title-container']}`}
        onClick={() => {
          if (onClick) {
            onClick(!expanded);
          }
          setExpanded(!expanded);
        }}
      >
        {icon && <div>{icon}</div>}
        <div>{title}</div>
        {expanded ? <Icon name="upChevron" size={12} /> : <Icon name="downChevron" size={12} />}
      </div>
      <div>{expanded && children}</div>
    </>
  );
};

ExpandTitle.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  onClick: PropTypes.func,
  expandedClass: PropTypes.string,
};
ExpandTitle.defaultProps = {
  icon: undefined,
  children: undefined,
  className: null,
  isExpanded: false,
  onClick: undefined,
  expandedClass: null,
};
