import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import Icon from 'common/Icon/Icon';

import styles from './CheckboxDropdown.module.scss';

export const CheckboxDropdown = ({
  checkboxes,
  onSubmit,
  filterKey,
  className,
  showApply,
  loading,
  noDataMessage,
  dropdownHeader,
  selected,
}) => {
  const [disabled, setDisabled] = useState(true);

  const onClick = checkbox => {
    let newSelectValues;
    if (Array.isArray(checkbox.id)) {
      newSelectValues = selected.find(val => checkbox.id.includes(val))
        ? selected.filter(val => !checkbox.id.includes(val))
        : [...selected, ...checkbox.id];
    } else {
      newSelectValues = selected.includes(checkbox.id)
        ? selected.filter(v => v !== checkbox.id)
        : [...selected, checkbox.id];
    }

    const originalState = checkboxes
      ?.filter(val => val.selected)
      .map(v => (Array.isArray(v.id) ? [...v.id] : v.id));

    if (JSON.stringify(originalState) !== JSON.stringify(newSelectValues)) {
      if (!showApply) {
        onSubmit(newSelectValues, filterKey);
      }
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const checkSelected = checkbox => {
    if (Array.isArray(checkbox.id)) {
      return selected.find(val => checkbox.id.includes(val));
    }
    return selected.includes(checkbox.id);
  };

  return (
    <DropDownMenu
      clickElement={
        <Button
          buttonType="filter"
          size="small"
          className={`${className} ${styles['title-button']}`}
          disabled={!checkboxes?.length || loading}
        >
          <span className={styles['dropdown-title']}>{dropdownHeader}</span>
        </Button>
      }
      menuItems={[
        {
          type: 'component',
          component: (
            <div>
              {checkboxes?.map(checkbox => {
                return (
                  <div
                    className={styles['input-item']}
                    onClick={() => onClick(checkbox)}
                    key={checkbox.id}
                  >
                    <Icon
                      name={checkSelected(checkbox) ? 'tickedCheckBox' : 'emptyCheckBox'}
                      className={checkSelected(checkbox) ? styles['color-check'] : ''}
                      size={18}
                    />

                    <div className={styles.label}>{checkbox.label}</div>
                  </div>
                );
              })}
              {!checkboxes?.length && <div className={styles['no-data']}>{noDataMessage}</div>}
              {showApply && (
                <div className={styles['apply-button']}>
                  <Button
                    hideArrow
                    onClick={() => (onSubmit ? onSubmit(selected, filterKey) : {})}
                    disabled={disabled}
                  >
                    Apply
                  </Button>
                </div>
              )}
            </div>
          ),
        },
      ]}
    />
  );
};

CheckboxDropdown.propTypes = {
  checkboxes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      label: PropTypes.string,
    }),
  ),
  onSubmit: PropTypes.func,
  filterKey: PropTypes.string,
  className: PropTypes.string,
  showApply: PropTypes.bool,
  loading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  selected: PropTypes.array,
  dropdownHeader: PropTypes.string,
};
CheckboxDropdown.defaultProps = {
  checkboxes: undefined,
  onSubmit: undefined,
  filterKey: undefined,
  className: undefined,
  showApply: false,
  loading: false,
  noDataMessage: undefined,
  selected: [],
  dropdownHeader: undefined,
};
