import BeatLoader from 'react-spinners/BeatLoader';
import PropTypes from 'prop-types';

import Icon, { availableIcons } from 'common/Icon/Icon';

import styles from './Button.module.scss';

const handleClick = (event, onClick) => {
  event.stopPropagation();
  if (onClick) {
    onClick();
  }
};

const Button = ({
  block,
  buttonType,
  children,
  containerClassName,
  scrollToTop,
  className,
  disabled,
  icon,
  loading,
  onClick,
  radius,
  secondaryAction,
  secondaryIcon,
  selected,
  size,
  type,
  wrap,
  dropdown,
  hideArrow,
  upArrow,
  buttonTextClass,
  centerButton,
}) => (
  <div
    className={`${styles['button-container']} ${block ? styles.block : ''} ${containerClassName}  ${
      centerButton ? styles['center-button'] : ''
    }`}
  >
    <button
      type={type}
      className={`
            ${styles.button}
            ${styles[`size-${size}`]}
            ${styles[`radius-${radius}`]}
            ${block ? styles.block : ''}
            ${styles[buttonType]}
            ${loading ? styles.loading : ''}
            ${scrollToTop ? styles.top : ''}
            ${selected ? styles.selected : ''}
            ${!onClick && type !== 'submit' ? styles['no-click'] : ''}
            ${wrap ? styles.wrap : ''}
            ${className}
            ${buttonTextClass}
          `}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && (
        <div className={styles['loading-spinner']}>
          <BeatLoader
            size={10}
            radius={200}
            color={buttonType === 'primary' ? '#fff' : undefined}
            cssOverride={{ paddingTop: '3px' }}
          />
        </div>
      )}
      {icon && (
        <Icon
          name={icon}
          className={`${styles['button-icon']} ${
            !children ? styles['icon-only'] : ''
          } ${className}`}
        />
      )}
      {children}
      {dropdown && !hideArrow && (
        <Icon
          name="arrowDown"
          className={`${styles['dropdown-icon']} ${upArrow ? styles.rotate : ''}`}
        />
      )}
    </button>
    {secondaryAction && (
      <button
        className={`${styles.button} ${styles[`size-${size}`]} ${styles[`radius-${radius}`]} ${
          styles[buttonType]
        } ${styles['secondary-action']} ${className}`}
        onClick={event => handleClick(event, secondaryAction)}
        aria-label="Button click"
      >
        <Icon name={secondaryIcon} className={styles['secondary-icon']} />
      </button>
    )}
  </div>
);
const IconType = PropTypes.oneOf(Object.keys(availableIcons));

Button.propTypes = {
  block: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  buttonType: PropTypes.oneOf([
    'primary',
    'primary-outline',
    'contrast',
    'secondary',
    'secondary-white',
    'transparent',
    'link',
    'filter',
    'filter-primary',
    'insights-checked',
    'filter-with-hover',
    'transparent-primary',
    'transparent-warning',
    'warning',
  ]),
  children: PropTypes.node,
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: IconType,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  radius: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'round']),
  secondaryAction: PropTypes.func,
  secondaryIcon: IconType,
  selected: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  wrap: PropTypes.bool,
  hideArrow: PropTypes.bool,
  upArrow: PropTypes.bool,
  dropdown: PropTypes.string,
  centerButton: PropTypes.bool,
  buttonTextClass: PropTypes.string,
};

Button.defaultProps = {
  block: false,
  scrollToTop: false,
  buttonType: 'primary',
  children: null,
  containerClassName: null,
  className: '',
  disabled: false,
  icon: undefined,
  loading: false,
  onClick: undefined,
  radius: 'small',
  secondaryAction: undefined,
  secondaryIcon: 'cross',
  selected: false,
  size: 'medium',
  type: 'button',
  wrap: false,
  hideArrow: false,
  upArrow: false,
  dropdown: undefined,
  centerButton: false,
  buttonTextClass: null,
};

export default Button;
