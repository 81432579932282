import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useModal } from 'context/modalContext';

import Modal from 'common/Modals/Modal';

import { SavedSearchForm } from 'Pages/SavedSearches';

export const CreateSavedSearchModal = ({ useDefaultFilters }) => {
  const { closeModal } = useModal();

  const formRef = useRef();
  const { saveSearch, loadingSavedSearches, SocialMonitoringDefaultFilters } = useFilters();

  const saveNewSearch = values => {
    const searchValues = useDefaultFilters
      ? { ...values, filter: SocialMonitoringDefaultFilters }
      : { ...values };

    saveSearch(searchValues);

    closeModal();
  };

  const onSave = () => {
    formRef.current?.submitForm();
  };

  return (
    <Modal
      width="medium"
      title="Create Saved Search"
      onCancel={closeModal}
      onSave={onSave}
      saveText="Save Search"
      saveLoading={loadingSavedSearches}
      hideClose
    >
      <SavedSearchForm ref={formRef} onSubmit={saveNewSearch} />
    </Modal>
  );
};

CreateSavedSearchModal.propTypes = {
  useDefaultFilters: PropTypes.bool,
};
CreateSavedSearchModal.defaultProps = {
  useDefaultFilters: false,
};
