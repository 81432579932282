/**
 * @function formatName
 * @param {key} key - The key to format
 * @returns {string} - The formatted key
 */
export const formatName = key => {
  const reg = /([a-z0-9])([A-Z])/g;
  const text = key.replace(reg, '$1 $2');
  return `${text[0].toUpperCase()}${text.slice(1)}`;
};

/**
 * @function capitaliseFirstLetter
 * @param {string} val - string to capitalise first letter
 * @returns {string} - string with first letter capitalised
 */
export const capitaliseFirstLetter = val => {
  if (val === undefined) return '';

  return val.charAt(0).toUpperCase() + val.slice(1);
};

/**
 * @function camelize
 * @param {string} str - string to camelise
 * @returns {string} - camelised string
 */
export const camelize = str => {
  // returns camel case for string "This is my string" -> "thisIsMyString"
  if (!str || typeof str !== 'string') {
    return 'stringCantBeCamelised';
  }
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

/**
 * @function camelize
 * @param {string} str - camelised string
 * @returns {string} - normal string all with capital letters
 */
export const camelToFlat = str => {
  const normalCase = str.replace(/([a-z])([A-Z])/g, '$1 $2');

  return capitaliseFirstLetter(normalCase);
};

/**
 *
 * @param {string} string - string to shorten
 * @param {number} [length=20] - length of string to return
 * @param {string} [suffix='...'] - suffix to add to shortened string
 * @returns {string} - shortened string
 */
export const shortenString = (string, length = 20, suffix = '...') => {
  if (!string) {
    return null;
  }
  if (string.length <= length) return string;
  return `${string.substring(0, length)} ${suffix}`;
};

export default { formatName };
