import { useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import imageCompression from 'browser-image-compression';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import { toastNotify } from 'common/ToastNotification/ToastNotification';

import styles from './UploadPictureModal.module.scss';

const UploadPictureModal = ({ show, close, onCancel, onSave, maxSizeMb, allowedFileTypes }) => {
  const [saving, setSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [editedSrc, setEditedSrc] = useState(null);
  const [imageRef, setImageRef] = useState(null);
  const [croppedCanvas, setCroppedCanvas] = useState(null);
  const [crop, setCrop] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
  const [imageBase64, setImageBase64] = useState(null);
  const allowedFileSize = maxSizeMb * 1024 * 1024;

  async function handleImageCompression(imageFile) {
    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      toastNotify('error', 'An error occured uploading the image. Please try again');
    }
    return null;
  }

  const onSelectFile = e => {
    setErrorMessage(null);
    setImageRef(null);
    setEditedSrc(null);
    setImageBase64(null);

    const file = e?.target?.files?.[0];

    if (!file) return;

    if (!allowedFileTypes.includes(file.type)) {
      setErrorMessage(`Incorrect file type. Please use one of ${allowedFileTypes.join(', ')}`);
      return;
    }

    if (file.size > allowedFileSize) {
      setErrorMessage(`Please upload a file that is not larger than ${maxSizeMb}Mb`);
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', () => setEditedSrc(reader.result));
    reader.readAsDataURL(file);
  };

  const onImageLoaded = image => setImageRef(image);

  const onCropChange = newCrop => setCrop(newCrop);

  const completeFileSave = base64data => {
    if (onSave) onSave(base64data);
    setErrorMessage(null);
    setImageRef(null);
    setEditedSrc(null);
    if (close) close();
  };

  const imageToBlobCallback = async blob => {
    setSaving(true);
    const compressedBlob = await handleImageCompression(blob);
    const reader = new FileReader();
    reader.readAsDataURL(compressedBlob);
    reader.onloadend = () => {
      const base64data = reader.result;
      setSaving(false);
      completeFileSave(base64data);
    };
  };

  function getCroppedImg(image, newCrop) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = newCrop.width * pixelRatio * scaleX;
    canvas.height = newCrop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      newCrop.x * scaleX,
      newCrop.y * scaleY,
      newCrop.width * scaleX,
      newCrop.height * scaleY,
      0,
      0,
      newCrop.width * scaleX,
      newCrop.height * scaleY,
    );

    setCroppedCanvas(canvas);
    return canvas.toDataURL();
  }

  const makeClientCrop = newCrop => {
    if (imageRef && newCrop.width && newCrop.height) {
      const croppedImageUrl = getCroppedImg(imageRef, crop, 'newFile.jpeg');
      setImageBase64(croppedImageUrl);
    }
  };

  return (
    <Modal show={show} className={styles.container}>
      <Modal.Body>
        <div className="flex justify-content-between">
          <input type="file" accept={allowedFileTypes.join(', ')} onChange={onSelectFile} />
          <span>
            Max file size:
            {maxSizeMb} Mb
          </span>
        </div>

        {errorMessage && (
          <Alert variant="danger" className="p-2 mb-0 mt-3">
            {errorMessage}
          </Alert>
        )}

        {editedSrc && (
          <ReactCrop
            src={editedSrc}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={makeClientCrop}
            onChange={onCropChange}
          />
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between align-items-center">
        <Button
          buttonType="secondary"
          onClick={() => {
            if (onCancel) onCancel();
            setErrorMessage(null);
            setImageRef(null);
            setEditedSrc(null);
            if (close) close();
          }}
        >
          Cancel
        </Button>

        <Button
          onClick={() => croppedCanvas.toBlob(imageToBlobCallback)}
          disabled={!imageBase64}
          loading={saving}
        >
          Save Image
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

UploadPictureModal.propTypes = {
  show: PropTypes.bool.isRequired,
  close: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  maxSizeMb: PropTypes.number,
  allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
};

UploadPictureModal.defaultProps = {
  close: undefined,
  onCancel: undefined,
  onSave: undefined,
  maxSizeMb: 2,
  allowedFileTypes: ['image/png', 'image/jpg', 'image/jpeg'],
};

export default UploadPictureModal;
