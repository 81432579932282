import PropTypes from 'prop-types';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import RouteContainer from 'common/RouteContainer/RouteContainer';

import styles from './Loading.module.scss';

export const LoadingPage = ({ message }) => {
  return (
    <RouteContainer>
      <section className={styles.container}>
        <div className={styles.spinner}>
          <LoaderSpinner />
        </div>
        <span>{message}</span>
      </section>
    </RouteContainer>
  );
};

LoadingPage.propTypes = {
  message: PropTypes.string,
};

LoadingPage.defaultProps = {
  message: 'Loading',
};
