import { useModal } from 'context/modalContext';

import { EditListModal } from 'Pages/Lists/Modals';

export const EditListMenuItem = ({ list }) => {
  const { openModal } = useModal();

  return {
    label: 'Edit List',
    onClick: () => openModal(<EditListModal list={list} />),
  };
};
