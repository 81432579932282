import { useFilters } from 'context/filtersContext';

import { useKwelloQuery } from 'utils/hooks/kwelloQuery';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { useApi } from './useApi';

export const useSocialSummary = () => {
  const { call } = useApi();
  const { filters } = useFilters();
  const { isSocialMonitoring } = useCurrentPage();

  const fetchSummaryData = async () => {
    let response = {};
    try {
      response = await call({
        url: '/shared/summary',
        method: 'POST',
        data: filters,
      });

      return {
        monitoredProfiles: { count: response?.monitoredProfiles },
        withContributions: {
          count: response?.withContributions,
          prev: response?.prevWithContributions,
        },
        totalContributions: {
          count: response?.totalContributions,
          prev: response?.prevTotalContributions,
        },
        totalMentions: {
          count: response?.totalMentions,
          prev: response?.prevTotalMentions,
        },
        reachEstimate: {
          count: response?.reachEstimate,
          prev: response?.prevReachEstimate,
        },
        earned: {
          count: response?.earned,
          prev: response?.prevEarned,
        },
        owned: {
          count: response?.owned,
          prev: response?.prevOwned,
        },
        scientific: {
          count: response?.scientific,
          prev: response?.prevScientific,
        },
      };
    } catch {}
    return response;
  };

  const { data: summaryData, isLoading: summaryLoading } = useKwelloQuery({
    queryKey: ['social-summary-bar'],
    queryFn: () => fetchSummaryData(),
    enabled: isSocialMonitoring,
  });

  return { summaryData, summaryLoading };
};
