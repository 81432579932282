import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import Yup from 'utils/formValidation';

import { Form, InputImage, InputText } from 'common/Form';

export const ListForm = forwardRef(({ list, onSubmit }, ref) => {
  const { defaultNotifFrequency } = useGlobalKolPlanningContext();
  const initialValues = {
    listId: list?.listId,
    listName: list?.listName || '',
    description: list?.description || '',
    picture: list?.picture,
    notifFrequency: list?.notifFrequency || defaultNotifFrequency,
  };

  const validationSchema = Yup.object({
    listName: Yup.string().validateName().required('List name is required'),
  });

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      ref={ref}
      initialValues={initialValues}
    >
      <InputText name="listName" label="List Name" required />
      <InputText name="description" label="Description" />
      <InputImage name="picture" label="Picture" />
    </Form>
  );
});

ListForm.propTypes = {
  list: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
ListForm.defaultProps = {
  list: {},
};
