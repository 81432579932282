import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useSlideDrawer } from 'context/slideDrawerContext';

import { filtersSettings as filterMenu } from 'constants';

import Button from 'common/button/Button';
import { FiltersMenu } from 'common/filtersBar/FiltersMenu';
import { resolveSavedSearchFilterValue } from 'common/SavedSearch';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './SavedSearchDetails.module.scss';

export const SavedSearchFilter = ({
  filter,
  onRemoveFilter,
  onApply,
  editSearch,
  onClick,
  selected,
}) => {
  const { openSlideDrawer } = useSlideDrawer();
  const { setFilter, resetFilter } = useFilterMenu();

  const filterKey = filterMenu
    ?.map(sec => sec.items.map(item => ({ key: item.key, value: item.value })))
    .filter(item => item.find(i => i.value === filter.originalKey))[0]
    ?.find(item => item.value === filter.originalKey)?.key;

  const filterValue = resolveSavedSearchFilterValue(filter);

  const openFilters = () => {
    if (!editSearch) {
      resetFilter();
      openSlideDrawer({
        drawerTitle: 'Add filters',
        drawerContent: <FiltersMenu openFilter={filterKey} onApply={onApply} />,
      });
    } else if (filter.label !== 'Project Period' && onClick) {
      onClick(filter.originalKey);
    }
  };

  return (
    <section>
      {filter.label === 'Project Period' ? (
        <Button
          key={filter.label}
          buttonType="filter"
          size="small"
          onClick={() => {
            openFilters();
          }}
        >
          <span className={styles['button-key']}>
            <b>{filter.label}:</b>
          </span>

          {filterValue}
        </Button>
      ) : (
        <KwelloTooltip content={filterValue} position="bottom">
          <Button
            key={filter.label}
            className={`${selected ? styles['button-selected'] : null} ${
              filterValue.length > 30 ? styles['button-label'] : null
            }`}
            buttonType="filter"
            size="small"
            secondaryAction={() =>
              filter.label === 'Project Period' ? undefined : onRemoveFilter(filter)
            }
            onClick={() => {
              openFilters();
              setFilter({ filterKey: filter.originalKey, filterValue: filter.value });
            }}
          >
            <span className={styles['button-key']}>
              <b>{filter.label}:</b>
            </span>

            {filterValue}
          </Button>
        </KwelloTooltip>
      )}
    </section>
  );
};

SavedSearchFilter.propTypes = {
  filter: PropTypes.object,
  onRemoveFilter: PropTypes.func,
  onApply: PropTypes.func,
  editSearch: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

SavedSearchFilter.defaultProps = {
  filter: {},
  onRemoveFilter: undefined,
  onApply: undefined,
  editSearch: false,
  onClick: undefined,
  selected: false,
};
