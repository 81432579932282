import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useFilters } from 'context/filtersContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';
import { useUser } from 'context/userContext';

import { useApi, useSocialSummary } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

const InsightsAiContext = createContext({});

const InsightsAiProvider = props => {
  const SIX_MONTHS_AGO = new Date();
  SIX_MONTHS_AGO.setMonth(SIX_MONTHS_AGO.getMonth() - 6);
  const TODAY = new Date();
  TODAY.setDate(TODAY.getDate());

  const defaultInsights = {
    startDate: SIX_MONTHS_AGO, // 6 months previous
    endDate: TODAY,
    surveys: [],
    questions: [],
    answers: [],
    managers: [],
    users: [],
    specialties: [],
    profiles: [],
  };

  const { filters: socialFilters } = useFilters();
  const { isSocialMonitoring, isElsieAi } = useCurrentPage();
  const [defaultInsightAiFilters, setDefaultInsightAiFilters] = useState(defaultInsights);
  const [insightAiFilters, setInsightAiFilters] = useState({});
  const [tempInsightAiFilters, setTempInsightAiFilters] = useState({});
  const [serverLoading, setServerLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [chatHistory, setChatHistory] = useState({});
  const [chatKey, setChatKey] = useState(undefined);
  const [citationReference, setCitationReference] = useState(undefined);
  const [tempQuestion, setTempQuestion] = useState(undefined);
  const [conversationId, setConversationId] = useState(undefined);
  const [minDate, setMinDate] = useState(SIX_MONTHS_AGO);
  const [maxDate, setMaxDate] = useState(TODAY);
  const [activeFilterKey, setActiveFilterKey] = useState(undefined);
  const { currentProject: socialProject } = useSocialMonitoring();
  const { summaryLoading } = useSocialSummary();

  const { call } = useApi();
  const { currentModuleProject, embeddedProjects } = useUser();
  const currentProject = currentModuleProject?.projectId;
  const currentEmbedProject = Object.values(embeddedProjects)
    ?.filter(eproj => eproj !== undefined)[0]
    ?.find(proj => proj.projectID === currentProject);
  const projectKey = currentEmbedProject?.projectDetails?.[0].key;
  const projectRoleId = currentEmbedProject?.projectDetails?.[0].roleID;
  const { projectId } = useParams();

  useEffect(() => {
    if (currentEmbedProject) {
      setChatHistory({});
      setInsightAiFilters({ ...defaultInsights });
      setTempInsightAiFilters({});
      setConversationId(undefined);
      setServerError(false);
      setChatKey(undefined);
      setTempQuestion(undefined);
      setCitationReference(undefined);
    }
    // defaultInsights is not a dependency because it is a constant value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEmbedProject]);

  useEffect(() => {
    if (projectId || !isElsieAi) {
      setChatHistory({});
      setConversationId(undefined);
      setServerError(false);
      setChatKey(undefined);
      setTempQuestion(undefined);
      setCitationReference(undefined);
    }
  }, [projectId, isElsieAi]);

  const setFilter = (key, value) => {
    setInsightAiFilters(prev => ({ ...prev, [key]: value }));
  };

  const setTemporaryFilter = useCallback(
    (key, value) => {
      setTempInsightAiFilters(prev => ({ ...insightAiFilters, ...prev, [key]: value }));
    },
    [insightAiFilters],
  );

  const updateChatHistory = useCallback(values => {
    setChatHistory(prev => ({
      ...prev,
      [values.messageId]: {
        ...values,
      },
    }));
  }, []);

  const {
    data: filterObjects,
    isLoading: loadingFilters,
    isFetching: fetchingFilters,
    error: getFiltersError,
  } = useQuery({
    queryKey: ['fetch-filter-objects', insightAiFilters, currentEmbedProject],
    queryFn: () =>
      call({
        url: '/Common/GetFilterObjects',
        method: 'POST',
        data: {
          key: projectKey,
          RoleID: [projectRoleId],
          insightFilters: {
            ...defaultInsights,
            ...insightAiFilters,
          },
          activeFilter: activeFilterKey,
        },
      }),
    staleTime: Infinity,
    enabled: isElsieAi && projectRoleId !== undefined,
  });

  const {
    data: tempFilterObjects,
    isLoading: loadingTempFilters,
    isFetching: fetchingTempFilters,
    error: getTempFiltersError,
  } = useQuery({
    queryKey: ['fetch-filter-objects-temp', tempInsightAiFilters, currentEmbedProject],
    queryFn: () =>
      call({
        url: '/Common/GetFilterObjects',
        method: 'POST',
        data: {
          key: projectKey,
          RoleID: [projectRoleId],
          insightFilters: {
            ...defaultInsights,
            ...tempInsightAiFilters,
          },
          activeFilter: activeFilterKey,
        },
      }),
    staleTime: Infinity,
    enabled: isElsieAi && Object.keys(tempInsightAiFilters)?.length > 0,
  });

  const { data: promptIdeas, isLoading: loadingPrompts } = useQuery({
    queryKey: ['fetch-prompt-ideas', insightAiFilters, currentEmbedProject],
    queryFn: () =>
      call({
        url: 'Common/PromptIdeas',
        method: 'POST',
        data: {
          key: projectKey,
          RoleID: [projectRoleId],
          clientName: currentEmbedProject?.projectClientName,
          insightFilters: {
            ...insightAiFilters,
          },
        },
      }),
    staleTime: Infinity,
    select: data => data?.promptIdeas,
    enabled: isElsieAi && projectRoleId !== undefined && !isSocialMonitoring,
  });

  const { data: socialPromptIdeas, isLoading: loadingSocialPrompts } = useQuery({
    queryKey: ['fetch-prompt-ideas', socialFilters, projectId],
    queryFn: () =>
      call({
        url: 'Common/PromptIdeas',
        method: 'POST',
        data: {
          socialFilters: { ...socialFilters },
          projectId,
          key: '',
          clientName: 'social',
          conversationType: 'promptIdeas',
          projectName: socialProject.projectName,
        },
      }),
    staleTime: Infinity,
    select: data => data?.promptIdeas,
    enabled: isElsieAi && isSocialMonitoring && !summaryLoading,
  });

  useEffect(() => {
    const filters = tempFilterObjects || filterObjects;
    if (filters?.dateRange) {
      const today = new Date();
      today.setDate(today.getDate());
      if (maxDate > today) {
        const newEndDate =
          new Date(filters?.dateRange?.endDate) > today
            ? today
            : new Date(filters?.dateRange?.endDate);
        setMaxDate(newEndDate);
      }
      if (minDate.getTime() !== new Date(filters?.dateRange?.startDate).getTime()) {
        setMinDate(new Date(filters?.dateRange?.startDate));
      }
    }
  }, [tempFilterObjects, filterObjects, maxDate, minDate]);

  const value = useMemo(
    () => ({
      defaultInsightAiFilters,
      setDefaultInsightAiFilters,
      setInsightAiFilters,
      insightAiFilters,
      setFilter,
      serverLoading,
      setServerLoading,
      chatHistory,
      updateChatHistory,
      setChatHistory,
      chatKey,
      setChatKey,
      promptIdeas: isSocialMonitoring ? socialPromptIdeas : promptIdeas,
      loadingPrompts: isSocialMonitoring ? loadingSocialPrompts : loadingPrompts,
      loadingFilters: tempFilterObjects ? loadingTempFilters : loadingFilters,
      fetchingFilters: fetchingTempFilters || fetchingFilters,
      filterObjects: tempFilterObjects || filterObjects,
      filtersError: getFiltersError || getTempFiltersError,
      currentEmbedProject,
      serverError,
      setServerError,
      tempQuestion,
      setTempQuestion,
      conversationId,
      setConversationId,
      projectKey,
      projectRoleId,
      setTemporaryFilter,
      tempInsightAiFilters,
      setTempInsightAiFilters,
      minDate,
      maxDate,
      setActiveFilterKey,
      citationReference,
      setCitationReference,
    }),
    [
      defaultInsightAiFilters,
      insightAiFilters,
      serverLoading,
      chatHistory,
      chatKey,
      promptIdeas,
      loadingPrompts,
      loadingFilters,
      filterObjects,
      currentEmbedProject,
      serverError,
      updateChatHistory,
      tempQuestion,
      setTempQuestion,
      conversationId,
      projectKey,
      projectRoleId,
      tempInsightAiFilters,
      tempFilterObjects,
      loadingTempFilters,
      minDate,
      maxDate,
      setTemporaryFilter,
      fetchingFilters,
      fetchingTempFilters,
      setActiveFilterKey,
      getFiltersError,
      getTempFiltersError,
      socialPromptIdeas,
      loadingSocialPrompts,
      isSocialMonitoring,
      citationReference,
    ],
  );

  return <InsightsAiContext.Provider value={value} {...props} />;
};

const useInsightsAi = () => useContext(InsightsAiContext);

export { InsightsAiProvider, useInsightsAi };
