import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './Module.module.scss';

import { ExpandedModule } from './ExpandedModule';

const iconNames = {
  'Social Monitoring': 'socialMonitoringModule',
  Insights: 'insightsModule',
  'SOSV Experts': 'sosvExpertsModule',
  'SOSV Science': 'sosvScienceModule',
  'Custom Reports': 'customReportsModule',
  'Global KOL Planning': 'globalKOLPlanningModule',
  'Insights Capture': 'insightsCaptureModule',
};

const moduleNames = {
  'Social Monitoring': 'Social',
  'Global KOL Planning': 'Planning',
  'Insights Capture': 'Insights',
};

export const Module = ({ index, module, active, expanded, content, expandMenu }) => {
  const navigate = () => {
    if (expanded === -1 || expanded !== index) {
      expandMenu(index);
    } else {
      expandMenu(-1);
    }
  };

  if (expanded > -1) {
    return (
      <ExpandedModule
        index={index}
        module={module}
        active={active}
        expanded={expanded}
        content={content}
        onNavigate={navigate}
      />
    );
  }

  return (
    <section
      className={`
        ${styles.container} 
        ${active || expanded === index ? styles.active : ''} 
      `}
    >
      <div
        className={`${styles['icon-name']} ${styles['icon-not-expanded']}  ${
          active || expanded === index ? styles.active : ''
        } `}
        onClick={navigate}
      >
        <span className={active ? styles['icon-active'] : styles['icon-inactive']}>
          <Icon name={iconNames[module.name]} size={24} />
        </span>

        <div className={active ? styles['text-active'] : styles['text-inactive']}>
          {moduleNames[module.name] || module.name}
        </div>
      </div>
    </section>
  );
};

Module.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  expandMenu: PropTypes.func.isRequired,
  expanded: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    navLink: PropTypes.string,
  }).isRequired,
};
