import { isTablet } from 'react-device-detect';

import { useFilters } from 'context/filtersContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import { CollectionsTemplatePage } from 'modules/globalKolPlanning/common/PageTemplates/Collections/CollectionsTemplatePage';

import { AddSavedSearchAction } from './Components';

export const SavedSearchesPage = () => {
  const { savedSearches } = useFilters();
  const { isSocialMonitoring } = useCurrentPage();

  return (
    <CollectionsTemplatePage
      collections={savedSearches}
      collectionActions={[<AddSavedSearchAction />]}
      collectionsTitle="Your Saved Searches"
      collectionDetailsTitle="Saved Search details"
      collectionType="savedSearch"
      collectionName="savedSearchName"
      collectionsLoading={!savedSearches}
      hideDetailsSearchBar
      hideFeedPreview={!isSocialMonitoring || isTablet}
    />
  );
};
