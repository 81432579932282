import PropTypes from 'prop-types';

import styles from './TypingDots.module.scss';

export const TypingDots = ({ color }) => {
  return (
    <div className={styles.typing}>
      <span style={{ background: color }} />
      <span style={{ background: color }} />
      <span style={{ background: color }} />
    </div>
  );
};

TypingDots.propTypes = {
  color: PropTypes.string,
};

TypingDots.defaultProps = {
  color: '#B0A0A0',
};
