import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

const FeatureContext = createContext({});

const FeatureProvider = props => {
  const [enabledFeatures, setEnabledFeatures] = useState({});

  useEffect(() => {
    const features = {
      flagProfile: 'FLAG_PROFILE',
      muteProfile: 'MUTE_PROFILE',
      flagContribution: 'FLAG_CONTRIBUTION',
      notifications: 'NOTIFICATIONS',
      alerts: 'ALERTS',
    };
    setEnabledFeatures(
      Object.keys(features).reduce(
        (object, feature) => ({
          ...object,
          [features[feature]]: process.env[`REACT_APP_FEATURE_${features[feature]}`] === 'true',
        }),
        {},
      ),
    );
  }, []);

  const featureEnabled = useCallback(feature => enabledFeatures[feature], [enabledFeatures]);

  const setFeatureEnabled = useCallback((feature, value) => {
    setEnabledFeatures(prevState => ({
      ...prevState,
      [feature]: value,
    }));
  }, []);

  const value = useMemo(
    () => ({
      features: {
        flagProfile: 'FLAG_PROFILE',
        muteProfile: 'MUTE_PROFILE',
        flagContribution: 'FLAG_CONTRIBUTION',
        notifications: 'NOTIFICATIONS',
        alerts: 'ALERTS',
      },
      featureEnabled,
      setFeatureEnabled,
    }),
    [featureEnabled, setFeatureEnabled],
  );

  return <FeatureContext.Provider value={value} {...props} />;
};

const useFeatures = () => useContext(FeatureContext);

export { FeatureProvider, useFeatures };
