import PropTypes from 'prop-types';

import { Card } from 'common/Card';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import styles from './index.module.scss';

import SummaryBarItem from './SummaryBarItem';

const SummaryBar = ({ asBody, className, data, loading, height }) => {
  return (
    <Card className={className} asBody={asBody} height={height}>
      {loading ? (
        <LoaderSpinner />
      ) : (
        <section className={`${styles.container} ${height ? styles.float : ''} `}>
          {data.map((item, indexItem) => {
            if (item.hidden) return null;

            if (Array.isArray(item)) {
              return (
                <div key={`sumary-item-${indexItem}`} className={styles['item-group']}>
                  {item.map((groupItem, index) => {
                    if (groupItem.hidden) return null;

                    return (
                      <SummaryBarItem
                        key={`summary-bar-item-${index}`}
                        data={groupItem}
                        groupedItem
                        onClick={groupItem.onClick}
                      />
                    );
                  })}
                </div>
              );
            }

            return (
              <SummaryBarItem
                key={`sumary-item-${indexItem}-secondary`}
                data={item}
                onClick={item.onClick}
              />
            );
          })}
        </section>
      )}
    </Card>
  );
};

SummaryBar.propTypes = {
  asBody: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
  loading: PropTypes.bool,
  height: PropTypes.string,
};

SummaryBar.defaultProps = {
  className: '',
  asBody: false,
  loading: false,
  height: undefined,
};

export default SummaryBar;
