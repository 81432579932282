// Javascript function to generate HMAC using Web Crypto API

import { toastNotify } from 'common/ToastNotification/ToastNotification';

export const generateHMAC = async (userEmail, secretKey) => {
  try {
    const cryptoKey = await crypto.subtle.importKey(
      'raw',
      new TextEncoder().encode(secretKey),
      { name: 'HMAC', hash: 'SHA-256' },
      false,
      ['sign'],
    );

    // Sign the email id with the HMAC key
    const signature = await crypto.subtle.sign(
      'HMAC',
      cryptoKey,
      new TextEncoder().encode(userEmail),
    );

    // Convert the signature to a hex string
    const signatureArray = new Uint8Array(signature);
    const signatureHex = Array.from(signatureArray, byte =>
      byte.toString(16).padStart(2, '0'),
    ).join('');

    return signatureHex;
  } catch (error) {
    toastNotify('error', 'Error while generating HMAC');
    throw error; // Propagate the error
  }
};
