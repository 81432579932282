import PropTypes from 'prop-types';

import Button from 'common/button/Button';

import styles from './Dialog.module.scss';

import { Backdrop, Body, DialogContainer, Footer, Header, Title } from './styles';

const Dialog = ({
  title,
  children,
  onCancel,
  onComplete,
  width,
  disableCompleteBtn,
  show,
  saveButtonLabel,
}) => {
  const handleOnCancel = e => {
    e.preventDefault();
    if (typeof onCancel === 'function') {
      onCancel(e);
    }
  };
  const handleOnComplete = e => {
    e.preventDefault();
    if (!disableCompleteBtn && typeof onComplete === 'function') {
      onComplete(e);
    }
  };
  return show ? (
    <Backdrop>
      <DialogContainer width={width} className={styles.dialog}>
        {title ? (
          <Header>
            <Title>{title}</Title>
          </Header>
        ) : null}
        <Body withTitle={!!title}>{children}</Body>
        <Footer>
          <Button buttonType="secondary" onClick={handleOnCancel}>
            Cancel
          </Button>

          <Button onClick={handleOnComplete} disabled={disableCompleteBtn}>
            {saveButtonLabel}
          </Button>
        </Footer>
      </DialogContainer>
    </Backdrop>
  ) : null;
};

Dialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  onCancel: PropTypes.func,
  onComplete: PropTypes.func,
  width: PropTypes.string,
  disableCompleteBtn: PropTypes.bool,
  show: PropTypes.bool,
  saveButtonLabel: PropTypes.string,
};
Dialog.defaultProps = {
  title: undefined,
  children: undefined,
  onCancel: undefined,
  onComplete: undefined,
  width: undefined,
  disableCompleteBtn: false,
  show: false,
  saveButtonLabel: 'Save List',
};

export default Dialog;
