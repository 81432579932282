import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';
import { useLists } from 'context/listsContext';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';

import SearchProfilesMenuContent from 'modules/socialMonitoring/common/cardMenus/SearchProfilesMenuContent';

import styles from './AddKolAction.module.scss';

export const AddKolAction = ({ list, disabled, showTitle }) => {
  const { addProfilesToList } = useLists();
  const { isDesktop } = useLayout();

  return (
    <DropDownMenu
      alignRight
      clickElement={
        <div className={styles.kolButtonCount}>
          <Button icon="kol" buttonType="transparent" hideArrow disabled={disabled}>
            {showTitle && isDesktop ? 'Add Profile' : ''}
          </Button>
        </div>
      }
      menuItems={[
        {
          type: 'component',
          component: (
            <SearchProfilesMenuContent onSelect={addProfilesToList} listId={list?.listId} />
          ),
        },
      ]}
    />
  );
};

AddKolAction.propTypes = {
  list: PropTypes.shape({
    listId: PropTypes.string,
    listName: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.string,
    notifFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    koLs: PropTypes.array,
  }).isRequired,
  disabled: PropTypes.bool,
  showTitle: PropTypes.bool,
};

AddKolAction.defaultProps = {
  disabled: false,
  showTitle: true,
};
