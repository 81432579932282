import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

const ContributionSourceIcon = ({ source }) => {
  switch (source) {
    case 'Facebook':
      return <Icon name="faceBook" />;
    case 'News':
      return <Icon name="news" />;
    case 'QQ':
      return <Icon name="qq" />;
    case 'Reddit':
      return <Icon name="reddit" />;
    case 'Tumblr':
      return <Icon name="tumblr" />;
    case 'Twitter':
      return <Icon name="twitter" />;
    case 'YouTube':
      return <Icon name="youtube" />;
    case 'RSS':
      return <Icon name="rss" />;
    case 'Science':
      return <Icon name="science" />;
    case 'LinkedIn':
      return <Icon name="linkedIn" />;
    case 'Instagram':
      return <Icon name="instagram" />;
    case 'Forums':
      return <Icon name="forum" />;
    case 'Blogs':
      return <Icon name="blog" />;
    default:
      return <Icon name="web" />;
  }
};

ContributionSourceIcon.propTypes = {
  source: PropTypes.string,
};
ContributionSourceIcon.defaultProps = {
  source: undefined,
};

export default ContributionSourceIcon;
