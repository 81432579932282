import { useState } from 'react';
import { ReactComponent as GraphStatsAscendIcon } from 'assets/icons/graphStatsAscend.svg';
import PropTypes from 'prop-types';

import { WHITELIST_SOURCES } from 'constants/index.js';
import { formatPastDateToString } from 'utils/dateHelper';
import { formatToK } from 'utils/formatNumbers';
import { isVideoUrl } from 'utils/urlUtils';

import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import ContributionSentimentIcon from '../contributionSentimentIcon/ContributionSentimentIcon';
import ContributionSourceIcon from '../contributionSourceIcon/ContributionSourceIcon';

import styles from './PostContent.module.scss';

const PostContent = ({ post, openContributionDetails }) => {
  const [isImageExpanded, setIsImageExpanded] = useState(false);
  const isWhiteList = WHITELIST_SOURCES.includes(post.source);
  return (
    <>
      <div
        className="mb-2 font-weight-400"
        onClick={() => openContributionDetails && openContributionDetails()}
        role={openContributionDetails && 'button'}
      >
        {post.title}
        {post.snippet && <div className="mt-3">{post.snippet}</div>}
      </div>
      {post.images && post.images.length > 0 && isWhiteList && !isVideoUrl(post.images[0]) && (
        <div
          className={`mb-2 content-image ${styles['image-wrapper']} ${
            isImageExpanded ? 'expanded' : styles.collapsed
          } ${post.images.length > 1 ? 'multiple-pictures-layout' : ''}`}
          style={isImageExpanded ? undefined : { backgroundImage: `url(${post.images[0]})` }}
        >
          <button
            className={styles['expand-collapse-button']}
            onClick={() => setIsImageExpanded(prev => !prev)}
          >
            {isImageExpanded ? 'Collapse' : 'Expand'}
          </button>
          {isImageExpanded && (
            <img
              alt="Contribution thumbprint"
              className={styles['content-image']}
              src={post.images[0]}
            />
          )}
          {post.images.length > 1 && (
            <img
              alt="Contribution thumbprint"
              className={styles['content-image']}
              src={post.images[1]}
            />
          )}
        </div>
      )}
      {post.images && post.images.length > 0 && isVideoUrl(post.images[0]) && (
        <video alt="Contribution thumbprint" className="d-block w-100 mb-2" controls>
          <track default kind="captions" />
          <source src={post.images[0]} type="video/mp4" />
        </video>
      )}
      {post.content?.videoUrl && (
        <video alt="Contribution thumbprint" className="d-block w-100 mb-2" controls>
          <track default kind="captions" />
          <source src={post.content?.videoUrl} type="video/mp4" />
        </video>
      )}
      <span className="font-weight-bold d-block mb-2">
        {post.source === 'Twitter' ? `@${post.authorName}` : post.authorName}
        <span className="gray-text mx-1">{formatPastDateToString(post.date)}</span>
      </span>
      <div>
        <span className="badge badge-light font-weight-500 text-sm mr-2 p-1">
          <GraphStatsAscendIcon className="mr-1" />
          {`Reach: ${formatToK(post.reach)}`}
        </span>
        <KwelloTooltip content="Sentiment of the post">
          <span className="badge badge-light font-weight-500 text-sm mr-2 p-1">
            <ContributionSentimentIcon sentiment={post.sentiment} />
          </span>
        </KwelloTooltip>
        <span
          className={`${styles['badge-source']} badge badge-light text-capitalize font-weight-500 text-sm p-1`}
        >
          <ContributionSourceIcon source={post.source} />
          {post.source}
        </span>
      </div>
    </>
  );
};

PostContent.propTypes = {
  post: PropTypes.object,
  openContributionDetails: PropTypes.func,
};

PostContent.defaultProps = {
  post: undefined,
  openContributionDetails: undefined,
};

export default PostContent;
