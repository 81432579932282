import { NotFoundTemplate } from './NotFoundTemplate';

export const ModuleNotFound = () => {
  return (
    <NotFoundTemplate
      title="You do not have access to this module."
      message="If you feel this is in error, please contact your administrator."
      homeButton
    />
  );
};
