import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useModal } from 'context/modalContext';

import ROUTE from 'constants/route';

import { AddListModal } from 'Pages/Lists/Modals';

export const ListCardMenuOptions = ({ selectedProfiles, newListOnly = false }) => {
  const { openModal } = useModal();
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const menuItems = newListOnly
    ? {
        label: selectedProfiles?.length
          ? `Create New List, add ${selectedProfiles.length} Profile${
              selectedProfiles.length > 1 ? 's' : ''
            }`
          : 'Create New List, add 1 profile',
        onClick: () => {
          openModal(<AddListModal profiles={selectedProfiles} />);
        },
      }
    : [
        {
          label: selectedProfiles?.length
            ? `Create New List, add ${selectedProfiles.length} Profile${
                selectedProfiles.length > 1 ? 's' : ''
              }`
            : 'Create New List',
          onClick: () => {
            openModal(<AddListModal profiles={selectedProfiles} />);
          },
        },
        {
          label: 'Go to Lists Page',
          onClick: () => {
            history.push(
              `/${pathname.split('/')[1]}${projectId ? `/${projectId}` : ''}/${ROUTE.lists}`,
            );
          },
        },
      ];
  return menuItems;
};
