import { useState } from 'react';

import Icon from 'common/Icon/Icon';
import { KwelloWhiteLogo } from 'common/Logo/KwelloWhiteLogo';
import { MobileModuleSelectionMenu, ProfileLogout, SlidingMenu } from 'common/Mobile';

import styles from './MobileNavBar.module.scss';

export const MobileHeaderMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const onChange = () => {
    setShowMenu(!showMenu);
  };

  return (
    <SlidingMenu onChange={onChange} showMenu={showMenu}>
      <div className={styles['menu-flyout-container']}>
        <div className={styles['flyout-header']} onClick={onChange}>
          <KwelloWhiteLogo className={styles['kwello-header-logo']} />
          <Icon name="cross" className={styles.cross} />
        </div>

        <MobileModuleSelectionMenu onSelect={onChange} />
        <div className={styles.logout}>
          <ProfileLogout />
        </div>
      </div>
    </SlidingMenu>
  );
};
