import { useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';

import inputStyles from './Inputs.module.scss';

export const InputFileButton = ({
  className,
  name,
  label,
  required,
  error,
  maxSizeMessage,
  disabled,
  accept,
  buttonLabel,
  icon,
  buttonType,
  displayAsImage,
  imageURL,
}) => {
  const [imageSource, setImageSource] = useState(imageURL);

  const uploadFile = () => {
    document.getElementById(name).click();
  };

  const displayImage = file => {
    if (file) {
      const imgURL = URL.createObjectURL(file);
      setImageSource(imgURL);
    }
  };

  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field name={name}>
          {({ field: { onChange, value, ...fieldProps }, form: { setFieldValue } }) => (
            <>
              {imageSource && (
                <div className={inputStyles['delete-image']}>
                  <div className={inputStyles.thumbnail}>
                    <img src={imageSource} />
                  </div>
                  <div className={inputStyles.trash}>
                    <Button
                      buttonType="transparent"
                      icon="trash"
                      onClick={event => {
                        event.preventDefault();
                        setImageSource('');
                        setFieldValue(name, '');
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                className={inputStyles['file-selection']}
                onClick={event => {
                  if (!event.target.type) {
                    event.preventDefault();
                  }
                }}
              >
                {!imageSource && (
                  <label htmlFor="files">
                    <Button
                      hideArrow
                      onClick={uploadFile}
                      buttonType={buttonType}
                      icon={icon}
                      disabled={disabled}
                    >
                      {buttonLabel}
                    </Button>

                    <input
                      className={error ? inputStyles.error : ''}
                      id={name}
                      type="file"
                      accept={accept}
                      name={name}
                      onChange={event => {
                        if (displayAsImage) {
                          displayImage(event.target.files?.[0]);
                        }
                        setFieldValue(name, event.target.files?.[0]);
                      }}
                      disabled={disabled}
                      style={{ display: 'none' }}
                      {...fieldProps}
                    />
                  </label>
                )}
              </div>
              <div className={inputStyles['error-message-container']}>
                <ErrorMessage name={name}>
                  {message => <span className={inputStyles.error}>{message}</span>}
                </ErrorMessage>
                {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
              </div>
              {maxSizeMessage && !imageSource && (
                <div
                  className={`${disabled && inputStyles['disabled-text']} ${
                    inputStyles['max-size']
                  }`}
                >
                  {maxSizeMessage}
                </div>
              )}
            </>
          )}
        </Field>
      </label>
    </div>
  );
};

InputFileButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  maxSizeMessage: PropTypes.string,
  disabled: PropTypes.bool,
  accept: PropTypes.array,
  buttonLabel: PropTypes.string,
  icon: PropTypes.string,
  buttonType: PropTypes.string,
  displayAsImage: PropTypes.bool,
  imageURL: PropTypes.string,
};

InputFileButton.defaultProps = {
  className: '',
  label: undefined,
  required: false,
  error: undefined,
  maxSizeMessage: undefined,
  disabled: false,
  accept: undefined,
  buttonLabel: undefined,
  icon: undefined,
  buttonType: undefined,
  displayAsImage: false,
  imageURL: undefined,
};
