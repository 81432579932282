import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  margin: 0 0 0.5rem 0;
`;

export const StyledInputGroup = styled(InputGroup)`
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: var(--border-radius-xs);
  background: #fafafa;
`;

export const StyledInputGroupWhite = styled(InputGroup)`
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  border-radius: var(--border-radius-xs);
  background: #fff;
`;

export const StyledFormControl = styled(FormControl)`
  border: none;
  background: #fafafa;
  &:focus {
    background: #fafafa;
  }
`;

export const StyledFormControlWhite = styled(FormControl)`
  border: none;
  background: #fff;
  &:focus {
    background: #fff;
  }
`;

export const IconWrapper = styled.div`
  padding: 0 0 0 0.75rem;

  & > svg {
    height: 1rem;
    width: 1rem;
    stroke: #333;
  }
`;
