import PropTypes from 'prop-types';

import Button from 'common/button/Button';

export const GoBackButton = ({ onClick, buttonType }) => (
  <Button buttonType={buttonType} size="large" icon="goBack" radius="medium" onClick={onClick} />
);

GoBackButton.propTypes = {
  onClick: PropTypes.func,
  buttonType: PropTypes.string,
};

GoBackButton.defaultProps = {
  onClick: undefined,
  buttonType: 'secondary',
};
