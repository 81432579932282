import { useCallback } from 'react';
import { useLocation } from 'react-router';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';

import { useUser } from 'context/userContext';

const useTrackAction = label => {
  const appInsights = useAppInsightsContext();
  const trackEvent = useTrackEvent(appInsights, label, { initial: true }, true);
  const { userDetails } = useUser();
  const { pathname } = useLocation();

  const trackAction = useCallback(
    data => {
      const [, module, project] = pathname.split('/');

      trackEvent({
        kwelloURL: pathname,
        kwelloModuleName: module,
        kwelloProjectID: project,
        kwelloUserID: userDetails.userId,
        ...data,
      });
    },
    [userDetails, trackEvent, pathname],
  );

  return trackAction;
};

export default useTrackAction;
