export const savedSearchLabels = {
  search: 'Search',
  advancedSearch: 'Advanced Search',
  dateRange: 'Date Range',
  projectPeriod: 'Project Period',
  state: 'State',
  country: 'Country',
  hasTwitterFollowers: 'Has Twitter Followers?',
  isDigitalOpinionLeader: 'Is Digital Opinion Leader?',
  segment: 'Segment',
  profile: 'Profile',
  scientificRank: 'Scientific Rank',
  isScientificallyProfiled: 'Is Scientifically Profiled?',
  twitterBioSearch: 'Twitter Bio Search',
  treatment: 'Treatment',
  category: 'Earned/Owned',
  source: 'Source',
  sentiment: 'Sentiment',
  diseaseState: 'Disease State',
  topic: 'Topic',
  domain: 'Domain',
  keyword: 'Keyword',
  collection: 'Collection',
  list: 'List',
  addedToKwello: 'Added Since',
};
