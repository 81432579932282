import { useModal } from 'context/modalContext';

import Button from 'common/button/Button';
import { CreateSavedSearchModal } from 'common/SavedSearch';

export const AddSavedSearchAction = () => {
  const { openModal } = useModal();

  const addSavedSearch = () => {
    openModal(<CreateSavedSearchModal useDefaultFilters />);
  };

  return (
    <Button buttonType="transparent" icon="search" onClick={addSavedSearch}>
      Create Saved Search
    </Button>
  );
};
