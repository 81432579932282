import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';
import { KwelloWhiteLogo } from 'common/Logo/KwelloWhiteLogo';

export const ExpandMenuIcon = ({ onClick, isOpen, className }) => {
  return (
    <div className={className}>
      {!isOpen && (
        <div onClick={onClick}>
          <Icon name="kwelloShortLogo" size={48} />
        </div>
      )}
      {isOpen && (
        <div onClick={onClick}>
          <KwelloWhiteLogo />
        </div>
      )}
    </div>
  );
};

ExpandMenuIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

ExpandMenuIcon.defaultProps = {
  onClick: undefined,
  className: '',
  isOpen: false,
};
