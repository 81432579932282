import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import { useCollectionsFeed } from 'utils/hooks/apiHooks/useCollectionsFeed';

import { Card } from 'common';
import ChartNotification from 'common/Charts/ChartNotification';
import InfiniteScroll from 'common/InfiniteScroll/InfiniteScroll';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import Post from 'modules/socialMonitoring/common/feed/Post';

import styles from './CollectionsFeed.module.scss';

export const CollectionsFeedComponent = ({ collection }) => {
  const bodyRef = useRef();
  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useCollectionsFeed({
    collection,
  });
  const { screenWidth } = useLayout();

  return (
    <Card height="82vh" showScrollbar isFeedComponent>
      <div className={styles['feed-body-container']}>
        <div
          className={`${styles['feed-body-content']} ${
            screenWidth === 'desktop' || screenWidth === 'largeDesktop' ? styles.desktop : null
          }`}
          ref={bodyRef}
        >
          {isLoading && <LoaderSpinner />}
          {!isLoading && (
            <>
              {data?.length > 0 && (
                <InfiniteScroll
                  hasMore={hasNextPage}
                  next={fetchNextPage}
                  loading={isFetchingNextPage}
                >
                  {data.map(post => (
                    <Post post={post} key={post.contributionId + post.profileId} hideActions />
                  ))}
                </InfiniteScroll>
              )}

              {!data.length && <ChartNotification />}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

CollectionsFeedComponent.propTypes = {
  collection: PropTypes.object,
};

CollectionsFeedComponent.defaultProps = {
  collection: {},
};
