import { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useUser } from 'context/userContext';

import { MODULE_KEYS } from 'constants';
import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ModuleItem } from './ModuleItem';

export const SocialMonitoringModulePicker = ({ setIsExpanded, onSelect }) => {
  const { currentPage } = useCurrentPage();
  const { socialProjects, fetchProjects, getProjectDisplayName } = useUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { currentSavedSearch, removeSavedSearch } = useFilters();

  const projectId = pathname.split('/')[2];

  useEffect(() => {
    fetchProjects(MODULE_KEYS.socialMonitoring);
  }, [fetchProjects]);

  if (socialProjects?.length > 0) {
    return socialProjects.map((project, index) => (
      <ModuleItem
        key={project.projectID}
        tabIndex={index}
        name={getProjectDisplayName(project)}
        onClick={() => {
          setIsExpanded(-1);
          if (currentSavedSearch) {
            removeSavedSearch();
          }

          if (currentPage(ROUTE.socialMonitoringUrl)) {
            history.push(pathname.replace(projectId, project.projectID));
          } else if (isMobileOnly) {
            history.push(`${ROUTE.socialMonitoringUrl}/${project.projectID}/${ROUTE.feed}`);
          } else {
            history.push(`${ROUTE.socialMonitoringUrl}/${project.projectID}/${ROUTE.dashboard}`);
          }
          if (onSelect) {
            onSelect();
          }
        }}
        selected={currentPage(ROUTE.socialMonitoringUrl) && project.projectID === projectId}
      />
    ));
  }

  return <LoaderSpinner color="#FFF" />;
};

SocialMonitoringModulePicker.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
};
SocialMonitoringModulePicker.defaultProps = {
  onSelect: undefined,
};
