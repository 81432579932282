import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './DropdownItem.module.scss';

import SubMenu from './SubMenu';

const DropdownItem = ({ shiftUp, subMenu, setSubMenu, setOpen, item }) => {
  const [clickPosition, setClickPosition] = useState(0);
  const contentRef = useRef(null);
  const clickItem = event => {
    if (item.onClick) {
      item.onClick();
      setOpen(false);
      return;
    }

    if (subMenu === item.label) {
      setSubMenu();
    } else {
      setSubMenu(item.label);
      const parentBounds = contentRef?.current?.parentElement?.getBoundingClientRect();
      const targetClick = event.currentTarget.getBoundingClientRect().height / 2;
      const calc = shiftUp
        ? parentBounds.bottom - event.clientY - targetClick
        : event.clientY - parentBounds.top - targetClick;
      setClickPosition(calc);
    }
  };

  const itemClass = () => {
    if (item.type === 'title') {
      return styles['menu-item-title'];
    }
    if (item.subMenuContent) {
      return styles['menu-item-submenu'];
    }
    if (item.className) {
      return item.className;
    }
    return styles['menu-item'];
  };

  if (item.hidden) {
    return null;
  }

  return (
    <section
      ref={contentRef}
      className={`${styles['dropdown-item']} ${item.disabled ? styles.disabled : ''}`}
      onClick={clickItem}
    >
      {item.type === 'component' && item.component}
      {item.type !== 'component' && (
        <p className={itemClass()}>
          {item.label}
          {item.labelIcon && <Icon name={item.labelIcon} size={16} />}
          {item.subMenuContent && <Icon name="arrowRight" size={16} />}
        </p>
      )}
      {subMenu && (
        <SubMenu
          clickPosition={clickPosition}
          isShowing={subMenu === item.label}
          content={item.subMenuContent}
          shiftUp={shiftUp}
        />
      )}
    </section>
  );
};

DropdownItem.propTypes = {
  label: PropTypes.string,
  shiftUp: PropTypes.bool,
  subMenu: PropTypes.string,
  setSubMenu: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};
DropdownItem.defaultProps = {
  label: '',
  shiftUp: false,
  subMenu: undefined,
};

export default DropdownItem;
