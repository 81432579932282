import { useLists } from 'context/listsContext';

export const RemoveProfilesFromListMenuItem = ({ list, selectedProfiles }) => {
  const { removeProfileFromList } = useLists();
  const listProfiles = list?.koLs?.map(profile => profile.profileId);

  const removeItem = selectedProfiles?.length
    ? {
        label: 'Remove Selected Profiles',
        className: 'text-danger',
        onClick: () => removeProfileFromList([list.listId], selectedProfiles),
      }
    : {
        label: 'Remove All Profiles',
        className: 'text-danger',
        onClick: () => removeProfileFromList([list.listId], listProfiles),
      };

  return removeItem;
};
