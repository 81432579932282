import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useAuth } from 'context/authContext';

import ROUTE from 'constants/route';

const LogOutPage = () => {
  const { logout } = useAuth();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    logout();
  }, [logout]);

  return <Redirect to={isAuthenticated ? ROUTE.auth0Login : ROUTE.login} />;
};

export default LogOutPage;
