import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useConfirm } from 'context/confirmationContext';
import { useFilters } from 'context/filtersContext';

import Button from 'common/button/Button';

import { SavedSearchForm } from 'Pages/SavedSearches';

import styles from './EditSavedSearchCollection.module.scss';

export const EditSavedSearchCollection = ({
  savedSearch,
  setEditCollection,
  selectedFilters,
  setSelectedFilters,
}) => {
  const editFormRef = useRef();
  const { confirm } = useConfirm();
  const { deleteSavedSearch, saveSearch, setSavedSearchAlert, SocialMonitoringDefaultFilters } =
    useFilters();

  const removeAllFilters = () => {
    confirm({
      title: 'Remove all filters',
      text: `Do you want to remove all filters associated with the search "${savedSearch.savedSearchName}"?`,
      onConfirm: () => {
        const data = {
          ...savedSearch,
          filter: {
            ...SocialMonitoringDefaultFilters,
          },
          notifFrequency: undefined,
        };
        saveSearch(data);
        setSelectedFilters([]);
      },
    });
  };

  const removeSelectedFilters = () => {
    confirm({
      title: `Remove ${selectedFilters?.length} filter(s)`,
      text: `Do you want to remove ${selectedFilters?.length} filter(s) associated with the search "${savedSearch.savedSearchName}"?`,
      onConfirm: () => {
        const newFilters = { ...savedSearch.filter };
        selectedFilters.forEach(key => {
          newFilters[key] = SocialMonitoringDefaultFilters[key];
        });

        const data = {
          ...savedSearch,
          filter: {
            ...newFilters,
          },
          notifFrequency: undefined,
        };
        saveSearch(data);
        setSelectedFilters([]);
      },
    });
  };

  const removeSavedSearch = () => {
    confirm({
      title: 'Delete Saved Search',
      text: `Do you want to delete the saved search "${savedSearch.savedSearchName}"?`,
      onConfirm: () => {
        setEditCollection(false);
        deleteSavedSearch([savedSearch.savedSearchId]);
      },
    });
  };

  const editSubmit = values => {
    if (
      savedSearch.savedSearchName === values.savedSearchName &&
      savedSearch.notifFrequency !== values.notifFrequency
    ) {
      setSavedSearchAlert(
        {
          savedSearchIds: [savedSearch.savedSearchId],
          notifFrequency: values.notifFrequency,
        },
        () => {
          setEditCollection(false);
        },
      );
    } else {
      const data = {
        ...savedSearch,
        savedSearchName: values.savedSearchName,
        notifFrequency:
          values.notifFrequency !== savedSearch.notifFrequency ? values.notifFrequency : undefined,
      };

      saveSearch(data, () => {
        setEditCollection(false);
      });
    }
  };

  return (
    <div className={styles.edit}>
      <SavedSearchForm ref={editFormRef} savedSearch={savedSearch} onSubmit={editSubmit} />
      <div className={styles['edit-actions']}>
        <Button buttonType="secondary" onClick={() => setEditCollection(false)}>
          Cancel
        </Button>
        <div className={styles['delete-actions']}>
          <Button buttonType="warning" onClick={removeSavedSearch}>
            Delete Search
          </Button>
          <Button buttonType="warning" onClick={removeAllFilters}>
            Remove All Filters
          </Button>
          <Button
            buttonType="warning"
            disabled={selectedFilters?.length === 0}
            onClick={removeSelectedFilters}
          >
            Remove ({selectedFilters?.length}) Filters
          </Button>
        </div>

        <Button
          onClick={() => {
            editFormRef?.current?.submitForm();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

EditSavedSearchCollection.propTypes = {
  savedSearch: PropTypes.object.isRequired,
  setEditCollection: PropTypes.func,
  selectedFilters: PropTypes.array,
  setSelectedFilters: PropTypes.func,
};

EditSavedSearchCollection.defaultProps = {
  setEditCollection: undefined,
  selectedFilters: undefined,
  setSelectedFilters: undefined,
};
