import { useConfirm } from 'context/confirmationContext';
import { useFilters } from 'context/filtersContext';

export const DeleteSavedSearchMenuItem = ({ search }) => {
  const { deleteSavedSearch } = useFilters();
  const { confirm } = useConfirm();

  const confirmDeleteSavedSearch = () => {
    confirm({
      title: 'Delete Saved Search',
      text: `Are you sure you want to delete ${search.savedSearchName}?`,
      onConfirm: () => deleteSavedSearch([search.savedSearchId]),
    });
  };

  return {
    label: 'Delete Saved Search',
    className: 'text-danger',
    onClick: confirmDeleteSavedSearch,
  };
};
