const ROUTE = {
  allowedUrls: ['/privacy-policy', '/terms', '/cookies', '/mobile'],
  mobileAllowedUrls: [
    '/social-monitoring',
    '/privacy-policy',
    '/terms',
    '/cookies',
    '/login',
    '/logout',
    '/maintenance',
    '/module-not-found',
    '/project-not-found',
    '/mobile',
    '/auth0-login',
    '/insights-capture',
    'insights-capture',
  ],
  mobileAllowedSocialUrls: ['mobile-filters', 'mobile-search', 'feed', 'contribution'],
  mobileModules: ['/social-monitoring', '/insights-capture'],
  maintenance: '/maintenance',
  moduleNotFound: '/module-not-found',
  projectNotFound: '/project-not-found',
  pageNotFound: '/page-not-found',
  login: '/login',
  logout: '/logout',
  auth0Login: '/auth0-login',
  pretendDashboard: '/pretend-dashboard',
  socialMonitoringUrl: '/social-monitoring',
  globalKolPlanningUrl: '/kol-planning',
  dashboard: 'dashboard',
  events: 'events',
  conflicts: 'conflicts',
  insights: '/insights',
  sosvExperts: '/sosv-experts',
  sosvScience: '/sosv-science',
  customReports: '/custom-reports',
  kol: 'key-opinion-leaders',
  kolProfile: 'kol-profile',
  exportKolProfile: 'export-kol-profile',
  analytics: 'analytics',
  sov: 'share-of-voice',
  dataSteps: 'data-steps',
  userSettings: '/user-settings',
  bookmarks: 'bookmarks',
  faq: 'faq',
  feed: 'feed',
  lists: 'lists',
  contribution: 'contribution',
  eventDetails: 'event-details',
  profileDetails: 'profile-details',
  exportProject: 'export-project',
  interactionDetail: 'interaction-detail',
  attendeeDetails: 'attendee-details',
  exportGlobalKolPlanningProject: 'export-kol-planning',
  savedSearch: 'saved-search',
  mobileFilters: 'mobile-filters',
  mobileSearch: 'mobile-search',
  mobile: '/mobile',
  captureInsights: '/insights-capture',
  landing: 'landing',
  insight: 'insight',
  profileSearch: 'profile-search',
  insightsSearch: 'insights-search',
  insightsType: 'insights-type',
  directedInsights: 'directed-insights',
  freeformInsights: 'freeform-insights',
  insightsQuestions: 'directed-questions',
  thankyou: 'thankyou',
  elsieAi: 'elsieai',
};

export default ROUTE;

// This is the mapping between a details page and its parent
const childParentPage = {
  'kol-profile': ROUTE.kol,
  contribution: ROUTE.analytics,
  'event-details': ROUTE.events,
  'interaction-detail': ROUTE.kol,
  'attendee-details': ROUTE.events,
  'alignment/summary': 'interactions',
  'alignment/detail': 'interactions',
  'alignment/response': 'interactions',
  'insights/summary': 'interactions',
  'insights/detail': 'interactions',
  'insights/question%20comparison': 'interactions',
  'conferences/summary': 'sosv',
  'conferences/detail': 'sosv',
  'conferences/geography': 'sosv',
  'journals/summary': 'sosv',
  'journals/detail': 'sosv',
  'journals/geography': 'sosv',
};

const socialMonitoringPages = [
  ROUTE.dashboard,
  ROUTE.feed,
  ROUTE.kol,
  ROUTE.analytics,
  ROUTE.sov,
  ROUTE.lists,
  ROUTE.bookmarks,
  ROUTE.kolProfile,
  ROUTE.exportKolProfile,
  ROUTE.contribution,
  ROUTE.exportProject,
  ROUTE.mobileFilters,
  ROUTE.mobileSearch,
];

const gkpPages = [
  ROUTE.dashboard,
  ROUTE.events,
  ROUTE.conflicts,
  ROUTE.kol,
  ROUTE.kolProfile,
  ROUTE.eventDetails,
  ROUTE.lists,
  ROUTE.bookmarks,
  ROUTE.attendeeDetails,
];

const gkpDetailPages = [
  ROUTE.eventDetails,
  ROUTE.kolProfile,
  ROUTE.interactionDetail,
  ROUTE.attendeeDetails,
];

export { childParentPage, gkpDetailPages, gkpPages, socialMonitoringPages };
