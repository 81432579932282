import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { CollectionItem } from 'Pages/Bookmarks/Components';
import { AddCollectionModal } from 'Pages/Bookmarks/Modals';
import { EmptyListIcon } from 'Pages/Lists/Components/EmptyListIcon';

import styles from './CollectionItemsGroup.module.scss';

export const CollectionItemsGroup = ({
  collections,
  onClick,
  selectedCollection,
  collectionType,
  useAppliedCollectionsFilter,
}) => {
  const { openModal } = useModal();

  return (
    <>
      {collections?.length === 0 && (
        <>
          <div className={styles['no-collections']}>No Collections found</div>
          <section className={styles.empty} onClick={() => openModal(<AddCollectionModal />)}>
            <EmptyListIcon isBookmark />{' '}
            <div className={styles['message-container']}>
              <span className={styles.title}>Create Collection</span>
              <span className={styles.message}>
                {collectionType === 'bookmark'
                  ? 'Bookmark a feed contribution to add it to a collection.'
                  : 'Add events to collections and set alerts to get regular event updates.'}
              </span>
            </div>
          </section>
        </>
      )}
      {collections?.map((collection, index) => (
        <CollectionItem
          collection={collection}
          key={collection.collectionID}
          onClick={onClick ? () => onClick({ index, collection }) : null}
          selected={selectedCollection === index}
          collectionType={collectionType}
          useAppliedCollectionsFilter={useAppliedCollectionsFilter}
        />
      ))}
    </>
  );
};

CollectionItemsGroup.propTypes = {
  collections: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  selectedCollection: PropTypes.number,
  collectionType: PropTypes.string,
  useAppliedCollectionsFilter: PropTypes.bool,
};

CollectionItemsGroup.defaultProps = {
  collections: [],
  selectedCollection: 0,
  collectionType: 'bookmark',
  useAppliedCollectionsFilter: false,
};
