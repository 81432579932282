import PropTypes from 'prop-types';

import Button from 'common/button/Button';

export const FilterBadge = ({ filterValues, label, onRemove }) => {
  const getConcatenatedFilters = () => {
    if (Array.isArray(filterValues)) {
      if (filterValues.length < 4) {
        return filterValues.join(', ');
      }
      return `${filterValues.slice(0, 3).join(', ')}, +${filterValues.length - 3} More`;
    }
    const propNames = Object.getOwnPropertyNames(filterValues);
    return propNames
      .reduce((prev, curr) => [...prev, `${curr}: ${filterValues[curr]}`], [])
      .join(', ');
  };

  return (
    <Button buttonType="filter" size="small" secondaryAction={onRemove} hideArrow>
      {label}
      :&nbsp;
      <span className="bold">{getConcatenatedFilters()}</span>
    </Button>
  );
};

FilterBadge.propTypes = {
  label: PropTypes.string,
  filterValues: PropTypes.array,
  onRemove: PropTypes.func,
};

FilterBadge.defaultProps = {
  label: undefined,
  filterValues: undefined,
  onRemove: undefined,
};
