import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useModal } from 'context/modalContext';

import ROUTE from 'constants/route';
import { useCountdownTimer } from 'utils/hooks/useCountdownTimer';

import Modal from 'common/Modals/Modal';

export const InactiveModal = () => {
  const { closeModal } = useModal();
  const timeLeft = useCountdownTimer();
  const history = useHistory();

  useEffect(() => {
    if (timeLeft === 0) {
      history.push(ROUTE.logout);
    }
  }, [history, timeLeft]);

  const stayLoggedIn = () => {
    closeModal();
  };

  return (
    <Modal width="medium" title="Session timeout" onSave={stayLoggedIn} saveText="Stay Logged In">
      <p>You have been inactive for some time. Please click the button below to stay logged in.</p>

      <p>
        You will automatically be logged out in <strong>{timeLeft}</strong> seconds
      </p>
    </Modal>
  );
};
