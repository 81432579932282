import { useState } from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { capitaliseFirstLetter } from 'utils/formatStrings';

import Button from 'common/button/Button';
import Modal from 'common/Modals/Modal';
import Scroll from 'common/Scroll/Scroll';

import { CollectionItem } from 'Pages/Bookmarks/Components';

import styles from './CollectionManagementModal.module.scss';

export const MultiItemCollectionManagementModal = ({
  title,
  allCollections,
  type,
  createCollection,
  multiSelectedItems,
  onAdd,
  onRemove,
}) => {
  const [selectedCollections, setSelectedCollections] = useState([]);
  const { closeModal } = useModal();
  const subheading = multiSelectedItems
    ? `Add or Remove ${multiSelectedItems.length} ${capitaliseFirstLetter(
        type,
      )}s to existing Collections`
    : `Add/Remove ${capitaliseFirstLetter(type)} to existing Collections below`;

  const remove = () => {
    onRemove(selectedCollections);
  };

  const add = () => {
    onAdd(selectedCollections);
  };

  const onSelectItem = collectionID => {
    let newItems;
    if (selectedCollections?.includes(collectionID)) {
      newItems = selectedCollections.filter(itemId => itemId !== collectionID);
    } else {
      newItems = [...selectedCollections, collectionID];
    }

    setSelectedCollections(newItems);
  };
  return (
    <Modal title={title} width="medium" height="60vh">
      <section className={styles['modal-content']}>
        <h6>{type === 'list' ? 'Add/Remove Profile to existing Lists below' : subheading}</h6>
        <div className={styles['collection-container']}>
          <Scroll maxHeight="40vh" hideArrow>
            {allCollections?.map(collection => (
              <CollectionItem
                collection={collection}
                key={collection.collectionID}
                collectionType={type === 'contribution' ? 'bookmark' : type}
                selected={selectedCollections?.includes(collection.collectionID)}
                onClick={() => onSelectItem(collection.collectionID)}
                className={styles['collection-item']}
                showNameOnly
              />
            ))}
          </Scroll>
        </div>
      </section>
      <section className={styles['create-section']}>
        {createCollection?.map((createElement, index) => (
          <div key={`create-collection-${index}`}>{createElement}</div>
        ))}
      </section>
      <section className={styles['modal-footer']}>
        <div>
          <Button buttonType="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
        <div>
          <Button
            onClick={remove}
            disabled={!selectedCollections?.length || !multiSelectedItems?.length}
          >
            Remove From Collection
          </Button>
        </div>
        <div>
          <Button
            onClick={add}
            disabled={!selectedCollections?.length || !multiSelectedItems?.length}
          >
            Add To Collection
          </Button>
        </div>
      </section>
    </Modal>
  );
};

MultiItemCollectionManagementModal.propTypes = {
  title: PropTypes.string,
  allCollections: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string,
  createCollection: PropTypes.array,
  multiSelectedItems: PropTypes.array,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
};

MultiItemCollectionManagementModal.defaultProps = {
  title: 'Manage Collections',
  allCollections: [],
  type: 'Event',
  createCollection: [],
  multiSelectedItems: undefined,
  onAdd: undefined,
  onRemove: undefined,
};
