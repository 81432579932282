import useCurrentPage from 'utils/hooks/useCurrentPage';

import Icon from 'common/Icon/Icon';

import styles from './HelpCenter.module.scss';

export const HelpCenter = () => {
  const { isGkp, isSocialMonitoring, isInsights } = useCurrentPage();
  if (isGkp || isSocialMonitoring || isInsights) {
    return (
      <div className={styles['help-center']}>
        <Icon name="helpMessage" />
        <span id="intercom_help_center">Help Center</span>
      </div>
    );
  }
  return null;
};
