import { useCallback, useEffect, useRef, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import { DefaultDateFilter, GkpDateFilter } from 'constants/dateFilterConfigs';
import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { Form, InputDate } from 'common/Form';
import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';

import { calculatePeriodDate, dateFormats, formatDate } from '../../utils/dateHelper';

import styles from './DateDropdown.module.scss';

import { DateButton } from './DateButton';

const DateDropdown = ({ header, buttonType, hideButtonArrow, loading, containerStyle }) => {
  const formRef = useRef();
  const { currentProject } = useSocialMonitoring();
  const { currentPage } = useCurrentPage();
  const { projectId } = useParams();
  const [formValues, setFormValues] = useState({});
  const isOnDashboard = currentPage(`${ROUTE.socialMonitoringUrl}/${projectId}/${ROUTE.dashboard}`);
  const isElsieAI = currentPage(`${ROUTE.socialMonitoringUrl}/${projectId}/${ROUTE.elsieAi}`);
  const minDate = new Date(currentProject?.projectStart);
  const maxDate = currentPage(ROUTE.socialMonitoringUrl) ? new Date() : undefined;
  const { setFilter, getFilter, defaultFilters } = useFilters();

  const value = {
    ...getFilter('dateRange'),
    selectedPeriod: getFilter('projectPeriod'),
  };

  useEffect(() => {
    setFormValues({
      selectedPeriod: value.selectedPeriod,
      start: value.start || calculatePeriodDate('start', value.selectedPeriod),
      end: value.end || calculatePeriodDate('end', value.selectedPeriod),
    });
    // Disabled exhaustive deps because selectedPeriod is an object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    value?.selectedPeriod?.direction,
    value?.selectedPeriod?.metric,
    value?.selectedPeriod?.value,
    value?.start,
    value?.end,
  ]);

  const setDateFilter = useCallback(
    dateFilterValue => {
      setFilter({
        dateRange: dateFilterValue.selectedPeriod
          ? undefined
          : {
              start: dateFilterValue.start,
              end: dateFilterValue.end,
            },
        projectPeriod: dateFilterValue.selectedPeriod,
      });
    },
    [setFilter],
  );

  const dateConfig = currentPage(ROUTE.globalKolPlanningUrl) ? GkpDateFilter : DefaultDateFilter;

  const resetDateFilter = useCallback(() => {
    if (currentPage(ROUTE.globalKolPlanningUrl)) {
      setDateFilter({
        start: formatDate(defaultFilters?.dateRange?.start, dateFormats.api),
        end: formatDate(defaultFilters?.dateRange?.end, dateFormats.api),
      });
    }

    if (currentPage(ROUTE.socialMonitoringUrl)) {
      const elsieAIPeriod = { metric: 'day', value: 14, direction: 'backward' };
      setDateFilter({
        start: calculatePeriodDate(
          'start',
          isElsieAI ? elsieAIPeriod : defaultFilters?.projectPeriod,
        ),
        end: calculatePeriodDate('end', isElsieAI ? elsieAIPeriod : defaultFilters?.projectPeriod),
        selectedPeriod: isElsieAI ? elsieAIPeriod : defaultFilters?.projectPeriod,
      });
    }
  }, [currentPage, setDateFilter, defaultFilters, isElsieAI]);

  const submit = useCallback(() => {
    const { start, end, selectedPeriod } = formValues || {};
    setDateFilter({
      start: selectedPeriod ? undefined : start,
      end: selectedPeriod ? undefined : end,
      selectedPeriod,
    });
  }, [formValues, setDateFilter]);

  const checkValues = useCallback(() => {
    const { start, end, selectedPeriod } = formValues || {};
    if (selectedPeriod) {
      return false;
    }
    if (start && end) {
      return false;
    }
    return true;
  }, [formValues]);

  const isSelected = (option, testValue) => {
    const values = testValue || formValues;

    if (option.value && values.selectedPeriod) {
      const { metric: optionMetric, value: optionValue, type: optionType } = option.value;
      const { metric: valueMetric, value: valueValue, type: valueType } = values.selectedPeriod;

      return (
        optionMetric === valueMetric &&
        optionValue === valueValue &&
        (valueType ? optionType.includes(valueType) : true)
      );
    }

    if (option?.type === 'range' && option?.start && values?.start) {
      return (
        formatDate(option?.start, dateFormats.api) === values?.start &&
        formatDate(option?.end, dateFormats.api) === values?.end
      );
    }

    return false;
  };

  const generateLabel = () => {
    if (value.start && value.end) return `${formatDate(value.start)} - ${formatDate(value.end)}`;

    return dateConfig.flat().find(option => isSelected(option, value))?.label;
  };

  const onFormValueChange = useCallback(values => {
    setFormValues(values);
  }, []);

  return (
    <DropDownMenu
      maxHeight="75vh"
      shiftDown
      containerStyle={containerStyle}
      clickElement={
        <Button
          disabled={isOnDashboard || loading}
          buttonType={buttonType}
          size="small"
          icon="calendar"
          hideArrow={hideButtonArrow}
        >
          {generateLabel()}
        </Button>
      }
      menuItems={[
        {
          type: 'component',
          component: (
            <div className={isMobileOnly ? null : styles['dropdown-container']}>
              {!isMobileOnly && (
                <div className="dropdown-header">
                  <div>{header}</div>
                </div>
              )}
              <div className={styles['dropdown-content']}>
                {dateConfig.map((section, index) => (
                  <div key={index} className={styles['dropdown-section']}>
                    {section.map((item, itemIndex) => (
                      <DateButton
                        selected={isSelected(item)}
                        key={itemIndex}
                        type={item.type}
                        label={item.label}
                        start={item.start}
                        end={item.end}
                        value={item.value}
                        setFormValues={setFormValues}
                      />
                    ))}
                  </div>
                ))}

                {!isMobileOnly && (
                  <div className={styles['dropdown-section']}>
                    <Form
                      ref={formRef}
                      initialValues={{
                        start: formValues?.start,
                        end: formValues?.end,
                      }}
                      onValueChange={onFormValueChange}
                    >
                      <Grid columns={2}>
                        <GridItem>
                          <InputDate
                            name="start"
                            label="Start Date"
                            min={minDate}
                            max={formValues?.end}
                          />
                        </GridItem>
                        <GridItem>
                          <InputDate
                            name="end"
                            label="End Date"
                            min={formValues?.start}
                            max={maxDate}
                          />
                        </GridItem>
                      </Grid>
                    </Form>
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <Button buttonType="secondary" onClick={resetDateFilter}>
                    Reset
                  </Button>
                  <Button icon="check" onClick={submit} disabled={checkValues()}>
                    Apply Filter
                  </Button>
                </div>
              </div>
            </div>
          ),
        },
      ]}
    />
  );
};

DateDropdown.propTypes = {
  header: PropTypes.string,
  buttonType: PropTypes.string,
  hideButtonArrow: PropTypes.bool,
  loading: PropTypes.bool,
  containerStyle: PropTypes.object,
};

DateDropdown.defaultProps = {
  header: 'Date',
  buttonType: 'filter',
  hideButtonArrow: false,
  loading: false,
  containerStyle: undefined,
};

export default DateDropdown;
