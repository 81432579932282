import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './EmptyListIcon.module.scss';

export const EmptyListIcon = ({ isBookmark, onClick }) => (
  <div className={styles.container} onClick={onClick}>
    {isBookmark ? <Icon name="bookmarkOff" /> : <Icon name="bulletList" />}
  </div>
);

EmptyListIcon.propTypes = {
  isBookmark: PropTypes.bool,
  onClick: PropTypes.func,
};

EmptyListIcon.defaultProps = {
  isBookmark: false,
  onClick: undefined,
};
