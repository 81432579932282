import { useLists } from 'context/listsContext';

export const ProfileMenuItems = ({ profileId, listId }) => {
  const { removeProfileFromList } = useLists();
  const menuItems = [];

  menuItems.push({
    label: 'Remove from the list',
    className: 'text-danger',
    onClick: () => removeProfileFromList([listId], [profileId]),
  });

  return menuItems;
};
