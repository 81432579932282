import { useHistory } from 'react-router';
import emptyProfilePlaceholder from 'assets/images/emptyProfilePlaceholder.png';

import { useUser } from 'context/userContext';

import { useNavigate } from 'utils/hooks';

import styles from './ProfileLogout.module.scss';

export const ProfileLogout = () => {
  const { userDetails } = useUser();
  const { clearPreviousPath } = useNavigate();
  const history = useHistory();

  const handleLogout = () => {
    clearPreviousPath();
    history.push('/logout');
  };

  return (
    <div className={styles['profile-logout']}>
      <img
        src={userDetails?.profileImage || emptyProfilePlaceholder}
        width="36px"
        alt="Profile"
        height="36px"
        className="circle mr-2"
      />
      <div onClick={handleLogout}>Logout</div>
    </div>
  );
};
