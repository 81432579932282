import CompliancePage from './CompliancePage';

const TermsOfUse = () => (
  <CompliancePage title="Terms of use" downloadLink="/static/terms-of-service.pdf">
    <h6 id="date">January 5, 2021</h6>

    <p>
      Welcome to the website of Acceleration Point, LLC (the &ldquo;Company&rdquo;, &ldquo;we&rdquo;
      or &ldquo;us&rdquo;). The following terms and conditions (the &ldquo;Terms of Use&rdquo;)
      govern your access to and use of www.accelerationpoint.com, including any content,
      functionality and services offered on or through any portals or platforms operated by the
      Company (collectively the &ldquo;Website&rdquo;), whether as a guest or a registered user.
    </p>
    <p>
      Please read the Terms of Use carefully before you start to use the Website. By using the
      Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy
      Policy, found at www.accelerationpoint.com (the &ldquo;Privacy Policy&rdquo;), incorporated
      herein by reference. If you do not want to agree to these Terms of Use or the Privacy Policy,
      you must not access or use the Website.
    </p>
    <h6>ELIGIBILITY</h6>
    <p>
      The Website is intended solely for individuals who are 16 years of age or older. Any use by
      anyone under age 16 is unauthorized, unlicensed and in violation of these Terms of Use. By
      using the Website, you represent and warrant that you are 16 years of age or older and that
      you agree to abide by these Terms of Use.
    </p>
    <h6>CHANGES TO THE TERMS OF USE</h6>
    <p>
      We may revise and update these Terms of Use from time to time in our sole discretion. All
      changes are effective immediately when we post them, and apply to all access to and use of the
      Website thereafter. Your continued use of the Website following the posting of revised Terms
      of Use means that you accept and agree to the changes. You are expected to check this page
      frequently so that you are aware of any changes, as they are binding on you.
    </p>
    <h6>ACCESSING THE WEBSITE AND REGISTRATION</h6>
    <p>
      We reserve the right to withdraw or amend this Website, and any service or material we provide
      on the Website, in our sole discretion without notice. We will not be liable if, for any
      reason, all or any part of the Website is unavailable at any time or for any period. From time
      to time, we may restrict access to some parts of the Website or the entire Website to users,
      including registered users.
    </p>
    <p>
      You are responsible for making all arrangements necessary for you to have access to the
      Website and for ensuring that all persons who access the Website through your internet
      connection are aware of these Terms of Use and comply with them.
    </p>
    <p>
      In order to use certain features of the Website, such as seeking product or services
      information, you may be required to provide certain information about yourself, such as your
      e-mail address, your name, and your telephone number. It is a condition of your use of the
      Website that all the information you provide on the Website is correct, current and complete.
      You agree that all information you provide to register with this Website or otherwise,
      including but not limited to through the use of any interactive features on the Website, is
      governed by our Privacy Policy, and you consent to all actions we take with respect to your
      information consistent with our Privacy Policy.
    </p>
    <p>
      If you choose, or are provided with, a username, password or any other piece of information as
      part of our security procedures, you must treat such information as confidential, and you must
      not disclose it to any other person or entity. You also acknowledge that your account is
      personal to you and agree not to provide any other person with access to this Website or
      portions of it using your username, password or other security information. You agree to
      notify us immediately of any unauthorized access to or use of your username or password or any
      other breach of security. You also agree to ensure that you exit from your account at the end
      of each session. You should use particular caution when accessing your account from a public
      or shared computer so that others are not able to view or record your password or other
      personal information.
    </p>
    <p>
      We have the right to disable any username, password or other identifier, whether chosen by you
      or provided by us, at any time in our sole discretion for any or no reason, including if, in
      our opinion, you have violated any provision of these Terms of Use.
    </p>
    <h6>INTELLECTUAL PROPERTY RIGHTS</h6>
    <p>
      The Website and its entire contents, features and functionality (including but not limited to
      all information, text, graphics, logos, button icons, images, audio clips, video clips,
      digital downloads, data compilations, software and the design, selection and arrangement
      thereof), is the property of the Company or its licensors or other content suppliers and is
      protected by United States and international copyright, trademark, patent, trade secret and
      other intellectual property laws.
    </p>
    <p>
      Except as expressly provided in the Limited License section below, no content on the Website
      may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed,
      posted, transmitted or sold in any form or by any means without the Company&rsquo;s written
      permission, except that the foregoing restriction does not apply to content that you provide
      to us. If you are eligible to use the Website, you are granted a limited license to access and
      use the Website and the Website&rsquo;s content as set forth in the Limited License section
      below.
    </p>
    <h6>TRADEMARKS</h6>
    <p>
      Acceleration Point and all related names, logos, product and service names, designs and
      slogans are trademarks of the Company or its affiliates or licensors. You must not use such
      marks without the prior written permission of the Company. All other names, logos, product and
      service names, designs and slogans on this Website are the trademarks of their respective
      owners, who may or may not be affiliated with, connected to, or sponsored by the Company.
    </p>
    <h6>LIMITED LICENSE</h6>
    <p>
      The Company grants you a limited license to access and make personal use of the Website. This
      license does not include any rights to resell or make commercial use of the Website or its
      contents; to collect or use postings for any purposes other than those expressly permitted by
      the Website; to make any derivative commercial use of the Website or its contents; or to use
      any data mining, robots, or similar data gathering or extraction tools. Neither the Website
      nor any portion thereof may be publicly displayed, reproduced, duplicated, modified,
      republished, downloaded, stored, transmitted, copied, sold, resold, visited, or otherwise
      exploited for any commercial purpose without the express written consent of the Company. You
      may not frame or utilize framing techniques to enclose any trademark, logo, or other
      proprietary information (including images, text, page layout, or form) of the Company without
      the express written consent of the Company. You may not use any meta tags or any other
      &ldquo;hidden text&rdquo; utilizing the Company&rsquo;s name or trademarks without the express
      written consent of the Company. Any unauthorized use terminates the permissions and licenses
      granted by the Company. You may not use any Company logo or other proprietary graphic or
      trademark as part of any reference to the Company without express written permission. Further,
      you agree that you will not circumvent, disable or otherwise interfere with security-related
      features of the Website.
    </p>
    <p>
      If you print, copy, modify, download or otherwise use or provide any other person with access
      to any part of the Website in breach of these Terms of Use, your right to use the Website will
      cease immediately and you must, at our option, return or destroy any copies of the materials
      you have made. No right, title or interest in or to the Website or any content on the Website
      is transferred to you, and all rights not expressly granted are reserved by the Company. Any
      use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of
      Use and may violate copyright, trademark and other laws.
    </p>
    <h6>COPYRIGHT COMPLAINTS</h6>
    <p>
      Complaints that any material posted on the Website infringes a third party copyright generally
      are governed by the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;) which specifies
      certain procedures that must be followed by the complaining party and the Company. These
      procedures are detailed below and must be followed by any party seeking to have allegedly
      infringing content removed (or contesting such removal) from the Website. Please note that all
      statements and representations made in the notice and counter-notice below are made under oath
      and that any false statements or material misrepresentations may subject the party making such
      statements to liability, including prosecution for perjury.
    </p>
    <p>
      Notice and Take-Down Request. If you are a copyright owner and believe that any content on the
      Website infringes upon your copyrights, you may submit a notification pursuant to the DMCA by
      providing our Copyright Agent with the following information in writing (see 17 U.S.C. Section
      512(c)(3) for further detail):
    </p>
    <p>
      A physical or electronic signature of the owner of the copyright or a person authorized to act
      on the owner&rsquo;s behalf;
      <br />
      Identification of the copyrighted work claimed to have been infringed, or, if multiple
      copyrighted works on the Website are covered by a single notification, a representative list
      of such works;
      <br />
      Identification of the material that is claimed to be infringing and information reasonably
      sufficient to permit the Company to locate the material (for instance, by supplying the URL of
      the page within the Website where the material that is the subject of the notice is located);
      <br />
      Information sufficient to permit the Company to contact you, such as a name, address,
      telephone number, and, if available, an e-mail address;
      <br />
      A statement that you have a good faith belief that use of the material in the manner
      complained of is not authorized by the copyright owner, its agent, or the law; and
      <br />
      A statement that the information in the notification is accurate, and under penalty of
      perjury, that you are authorized to act on behalf of the copyright owner whose work or works
      are being infringed.
      <br />
      The Company&rsquo;s designated Copyright Agent to receive notifications of claimed
      infringement may be reached at the following e-mail and physical address:
    </p>
    <p>
      info@accelerationpoint.com
      <br />
      Acceleration Point, LLC
      <br />
      119 Norfolk Ave., Suite 110
      <br />
      Roanoke, VA 24011
    </p>
    <p>
      You acknowledge that if you fail to comply with all of the requirements of this Section A,
      your DMCA notice may not be valid. Please note that if you submit a false claim or materially
      misrepresent that content on the Website is infringing, you may be liable for damages,
      including court costs and attorneys&rsquo; fees.
    </p>
    <p>
      Upon receiving a proper notification of alleged copyright infringement as described in this
      Section A, we will remove or disable access to the allegedly infringing material and promptly
      notify the alleged infringer of your claim. We also will advise the alleged infringer of the
      DMCA statutory counter-notification procedure described below in Section B by which the
      alleged infringer may respond to your claim and request that we restore this material.
    </p>
    <p>
      Counter-Notice. If you believe that your content that was removed (or to which access was
      disabled) is not infringing, or that you have the authorization from the copyright owner, the
      copyright owner&rsquo;s agent, or pursuant to the law, to post and use the content at issue,
      you may send a counter-notice containing the following information to the Company&rsquo;s
      Copyright Agent at the physical and/or e-mail address set forth in Section A above:
    </p>
    <p>
      Your physical or electronic signature;
      <br />
      Identification of the content that has been removed or to which access has been disabled and
      the location within the Website at which the content appeared before it was removed or
      disabled;
      <br />
      A statement that you have a good faith belief that the content was removed or disabled as a
      result of a mistake or a misidentification of the content; and
      <br />
      Your name, address, telephone number, and e-mail address, a statement that you consent to the
      jurisdiction of the federal court in which your address is located, and a statement that you
      will accept service of process from the person who provided notification of the alleged
      infringement.
      <br />
      If a counter-notice is received by the Copyright Agent, the Company may send a copy of the
      counter-notice to the original complaining party informing that person that the Company may
      replace the removed content or cease disabling it in 10 business days. Unless the copyright
      owner files an action seeking a court order against the content provider, member or user, the
      removed content may be replaced, or access to it restored, in 10 to 14 business days or more
      after receipt of the counter-notice, at the Company&rsquo;s sole discretion.
    </p>
    <h6>DISCLAIMERS, DISCLAIMERS OF WARRANTIES AND LIMITATION OF LIABILITY</h6>
    <p>
      The information presented on or through the Website is made available solely for general
      information purposes. We do not warrant the accuracy, completeness or usefulness of this
      information. Any reliance you place on such information is strictly at your own risk. We
      disclaim all liability and responsibility arising from any reliance placed on such materials
      by you or any other visitor to the Website, or by anyone who may be informed of any of its
      contents.
    </p>
    <p>
      The Company is not responsible or liable in any manner for any third party content,
      applications, software or content posted on or communicated within the Website. Although we
      provide rules for user conduct and postings, we do not control and are not responsible for any
      third party applications, software or content.
    </p>
    <p>
      If the Website contains links to other sites and resources provided by third parties, these
      links are provided for your convenience only. We have no control over the contents of those
      sites or resources, and accept no responsibility for them or for any loss or damage that may
      arise from your use of them. If you decide to access any of the third party websites linked to
      this Website, you do so entirely at your own risk and subject to the terms and conditions of
      use for such websites.
    </p>
    <p>
      The Website may be temporarily unavailable from time to time for maintenance or other reasons.
      The Company assumes no responsibility for any error, omission, interruption, deletion, defect,
      delay in operation or transmission, communications line failure, theft or destruction or
      unauthorized access to, or alteration of, visitor or registered user communications. The
      Company is not responsible for any technical malfunction or other problems of any telephone
      network or service, computer systems, servers or providers, computer or mobile phone
      equipment, software, failure of email on account of technical problems or traffic congestion
      on the Internet or at the Website or any combination thereof, including injury or damage to
      the computer of any registered user or visitor or other hardware or software, related to or
      resulting from using or downloading materials in connection with the Website. Under no
      circumstances will the Company be responsible for any loss or damage, including any loss or
      damage to any content posted by visitors, or personal injury or death, resulting from
      anyone&rsquo;s use of the Website or any content posted on or through the Website or
      transmitted to visitors, or any interactions between visitors to the Website, whether online
      or offline.
    </p>
    <p>
      You understand that we cannot and do not guarantee or warrant that files available for
      downloading from the internet or the Website will be free of viruses or other destructive
      code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
      your particular requirements for anti-virus protection and accuracy of data input and output,
      and for maintaining a means external to our site for any reconstruction of any lost data. WE
      WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
      VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
      COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
      SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED
      ON IT, OR ON ANY WEBSITE LINKED TO IT.
    </p>
    <p>
      THIS WEBSITE IS PROVIDED BY THE COMPANY ON AN &ldquo;AS IS&rdquo; AND &ldquo;AS
      AVAILABLE&rdquo; BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
      EXPRESS OR IMPLIED, AS TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
      AVAILABILITY OF THE WEBSITE OR THE INFORMATION, CONTENT, OR MATERIALS INCLUDED ON THE WEBSITE.
      YOU EXPRESSLY AGREE THAT YOUR USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE COMPANY RESERVES
      THE RIGHT TO WITHDRAW OR DELETE ANY INFORMATION FROM THE WEBSITE AT ANY TIME IN ITS
      DISCRETION. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL
      WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR A PARTICULAR PURPOSE. THE COMPANY DOES NOT
      WARRANT THAT THE WEBSITE, ITS SERVERS, OR E-MAIL SENT FROM THE WEBSITE ARE FREE OF VIRUSES OR
      OTHER HARMFUL COMPONENTS.
    </p>
    <p>
      IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
      AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
      ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES
      LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING, BUT NOT LIMITED TO DIRECT,
      INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO,
      PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS,
      LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
      WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
      FORESEEABLE. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY&rsquo;S
      LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL NOT
      EXCEED $500. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE COMPANY FOR THE SERVICE, YOU
      SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT
      BE ENTITLED TO DAMAGES OF ANY KIND FROM THE COMPANY, REGARDLESS OF THE CAUSE OF ACTION.
    </p>
    <p>
      CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
      LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
      DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL
      RIGHTS.
    </p>
    <h6>APPLICABLE LAW</h6>
    <p>
      You agree that the laws of the Commonwealth of Virginia, without regard to principles of
      conflict of laws, will govern your use of the Website and these Terms of Use and any dispute
      or claim of any sort that might arise therefrom or related thereto between you and the Company
      or its affiliates. Any legal suit, action or proceeding arising out of, or related to, these
      Terms of Use or the Website shall be brought exclusively in the federal or state courts
      located in Roanoke, Virginia. You waive any and all objections to the exercise of jurisdiction
      over you by such courts and to venue in such courts.
    </p>
    <h6>INDEMNITY</h6>
    <p>
      You agree to indemnify and hold the Company, its subsidiaries and affiliates, and each of
      their directors, officers, agents, contractors, partners and employees, harmless from and
      against any loss, liability, claim, demand, damages, costs and expenses, including reasonable
      attorney&rsquo;s fees, arising out of or in connection with these Terms of Use, your use of
      the Website, your conduct in connection with the Website or with other visitors to the
      Website, or any violation of any law or the rights of any third party.
    </p>
    <h6>LIMITATION ON TIME TO FILE CLAIMS</h6>
    <p>
      ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR
      THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES,
      OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
    </p>
    <h6>PRIVACY POLICY</h6>
    <p>
      When visitors leave messages on the site we collect the data shown in the contact form, and
      your IP address and browser user agent string to help spam detection and prevention efforts.
      This may include your name, email, company name, and any messages sent in correspondence while
      on our site.
    </p>
    <h6>ON-SITE COOKIES</h6>
    <p>
      Our Website uses &ldquo;Cookies&rdquo; to identify the areas of our Website that you have
      visited. A Cookie is a small piece of data stored on your computer or mobile device by your
      web browser. We use Cookies to personalize the content that you see on our website. Most web
      browsers can be set to disable the use of Cookies. However, if you disable cookies, you may
      not be able to access functionality on our website correctly or at all. We never place
      Personally Identifiable Information in cookies.
    </p>
    <p>
      Some of our business partners may use cookies on our site (e.g., advertising and marketing
      tool providers). However, we have no access to or control over these cookies.
    </p>
    <h6>EMBEDDED CONTENT FROM OTHER WEBSITES</h6>
    <p>
      Articles on this site may include embedded content (e.g. videos, images, articles, etc.).
      Embedded content from other websites behaves in the exact same way as if the visitor has
      visited the other website.
    </p>
    <p>
      These websites may collect data about you, use cookies, embed additional third-party tracking,
      and monitor your interaction with that embedded content, including tracking your interaction
      with the embedded content if you have an account and are logged in to that website.
    </p>
    <p>A common example of this would be an embedded YouTube video.</p>
    <h6>ANALYTICS (Cookies, Web Beacons, and Other Tracking Technologies)</h6>
    <p>
      Like other businesses across the internet, we make use of multiple Cookie and Web Beacon
      enabled services. These services generally provide anonymous information about visitors and
      their computer devices, and those whom interact with our marketing efforts. In all cases of
      the following services, we do not control the data they collect and how it is used. For each
      service, you will need to read their privacy policies to understand how they make use of data
      gathered from your activities. If you require actions to be taken with your data, you will
      also need to contact those parties who you object to using data collected from your
      activities.
    </p>
    <h6>FACEBOOK PIXEL</h6>
    <p>
      We leverage Facebook Pixel to understand what kind of actions visitors are taking with
      Facebook ads and services. This information is anonymous and does not provide us with any
      identifiable information about you. This data is housed and provided by Facebook and you can
      object to this functionality by visiting: https://www.facebook.com/settings?tab=ads
    </p>
    <p>
      For more information about Facebook&rsquo;s privacy practices, please visit:
      https://www.facebook.com/about/privacy/
    </p>
    <h6>FACEBOOK SIGNAL</h6>
    <p>
      We leverage Signals by Facebook to better understand what subject matter our visitors are
      interested in. This data is housed and provided by Facebook and you can object to this
      functionality by visiting: https://www.facebook.com/settings?tab=ads
    </p>
    <p>
      For more information about Facebook&rsquo;s privacy practices, please visit:
      https://www.facebook.com/about/privacy/
    </p>
    <h6>FACEBOOK CONVERSION TRACKING</h6>
    <p>
      We leverage Facebook Conversion Tracking to monitor performance of Facebook ads and services.
      This data is housed and provided by Facebook and you can object to this functionality by
      visiting: https://www.facebook.com/settings?tab=ads
    </p>
    <p>
      For more information about Facebook&rsquo;s privacy practices, please visit:
      https://www.facebook.com/about/privacy/
    </p>
    <h6>LINKEDIN INSIGHTS</h6>
    <p>
      We leverage LinkedIn Insights Tag to better understand how LinkedIn users interact with our
      website and LinkedIn services.This information is anonymous and does not provide us with any
      identifiable information about you.The data is housed and provided by LinkedIn and you can
      object and opt-out of tracking by visiting:
      https://www.linkedin.com/psettings/advertising?trk=microsites-frontend_legal_cookie-policy
    </p>
    <p>
      For more information about LinkedIn&rsquo;s privacy practices, please visit:
      https://www.linkedin.com/legal/cookie-policy
    </p>
    <h6>ACTIVECAMPAIGN</h6>
    <p>
      We leverage ActiveCampaign to ensure follow-up communication and as our CRM to track
      interactions with clients and potential clients.
    </p>
    <h6>GOOGLE ANALYTICS</h6>
    <p>
      We leverage Google Analytics to better understand what our visitors are looking for and how
      they interactive with our website. This information is anonymous and does not provide us with
      any identifiable information about you. You can read more about how your data is safeguarded
      by Google here: https://support.google.com/analytics/answer/6004245
    </p>
    <p>
      For more information about Google privacy practices, please visit:
      https://policies.google.com/privacy
    </p>
    <h6>GOOGLE UNIVERSAL ANALYTICS</h6>
    <p>
      We leverage Google Universal Analytics to get a more in-depth understanding of visitor
      interests and habits. This anonymous information is linked together through a unique user ID
      and is stored and provided by Google. You can read more about how your data is safeguarded by
      Google here: https://support.google.com/analytics/answer/6004245
    </p>
    <p>
      For more information about Google privacy practices, please visit:
      https://policies.google.com/privacy
    </p>
    <h6>VISUAL VISITOR</h6>
    <p>
      We leverage Visual Visitor to identify visitor interest in services we offer. This information
      is anonymous and uses techniques provided by Visual Visitor to help us discover who our
      potential customers might be and how to better serve them.
    </p>
    <p>
      For more information about Visual Visitor&rsquo;s services and contact information, please
      visit: https://www.visualvisitor.com/legal/privacy-policy/
    </p>
    <h6>WHO WE SHARE YOUR DATA WITH</h6>
    <p>
      We do not share any data we collect with other parties. Any data used outside this site is
      gathered from our Tracking partners&rsquo; services listed above in the Analytics section.
    </p>
    <h6>WHAT RIGHTS YOU HAVE OVER YOUR DATA</h6>
    <p>
      If you have left comments or contact information through our site, you can request to receive
      an exported file of the personal data we hold about you, including any data you have provided
      to us. You can also request that we erase any personal data we hold about you. This does not
      include any data we are obliged to keep for administrative, legal, or security purposes.
    </p>
    <h6>WHERE WE SEND YOUR DATA</h6>
    <p>
      Visitor comments may be checked through an automated spam detection service. Visitor activity
      may be collected through any of the Analytics partner services listed above in the Tracking
      section.
    </p>
    <h6>YOUR CONTACT INFORMATION</h6>
    <p>
      If you opt into contacting us or signing up for our newsletter, we retain your contact
      information to get in touch with you. You maintain the right to request to be removed from
      further contact at any time.
    </p>
    <h6>ADDITIONAL INFORMATION</h6>
    <p>How we protect your data</p>
    <p>
      We utilize 3rd party Analytics and Tracking tools and services that maintain a reputable
      status in the industry. While data leaks do happen, we do not rely on personal identifiable
      data collected by any of these services.
    </p>
    <h6>WHAT DATA BREACH PROCEDURES WE HAVE IN PLACE</h6>
    <p>
      We do not store any personal identifying information on our website. Any third party Analytics
      or Tracking tools or services we use have their own procedures and documentation provided for
      public consumption. See Analytics section above.
    </p>
    <h6>WHAT THIRD PARTIES WE RECEIVE DATA FROM</h6>
    <p>See Analytics section above.</p>
    <h6>WHAT AUTOMATED DECISION MAKING AND/OR PROFILING WE DO WITH USER DATA</h6>
    <p>See Analytics section above.</p>
    <h6>WAIVER AND SEVERABILITY</h6>
    <p>
      No waiver by the Company of any term or condition set forth in these Terms of Use shall be
      deemed a further or continuing waiver of such term or condition or a waiver of any other term
      or condition, and any failure of the Company to assert a right or provision under these Terms
      of Use shall not constitute a waiver of such right or provision.
    </p>
    <p>
      If any provision of these Terms of Use is held by a court or other tribunal of competent
      jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
      eliminated or limited to the minimum extent such that the remaining provisions of the Terms of
      Use will continue in full force and effect.
    </p>
    <h6>ENTIRE AGREEMENT</h6>
    <p>
      These Terms of Use and our Privacy Policy constitute the sole and entire agreement between you
      and the Company with respect to the Website and supersede all prior and contemporaneous
      understandings, agreements, representations and warranties, both written and oral, with
      respect to the Website.
    </p>
  </CompliancePage>
);

export default TermsOfUse;
