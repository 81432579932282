import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';
import { useLists } from 'context/listsContext';

import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ProfileItem } from '../ProfileItem';

import styles from './DetailsCollection.module.scss';

export const DetailsCollection = ({
  list,
  profiles,
  selectedProfiles,
  toggleSelectedProfile,
  editList,
  hideFeedPreview,
}) => {
  const { isDesktop, screenWidth } = useLayout();
  const { listsLoading } = useLists();

  if (listsLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {profiles.length === 0 && (
        <section className={styles['no-profiles']}>
          No profiles found. Please add a profile / change your search criteria.
        </section>
      )}
      <Grid columns={hideFeedPreview && isDesktop ? 2 : 1}>
        {profiles?.map(kol => (
          <GridItem key={kol.profileId} colSpan={1}>
            <ProfileItem
              listId={list?.listId}
              profile={kol}
              editList={editList}
              onClick={toggleSelectedProfile}
              selected={selectedProfiles?.includes(kol.profileId)}
              hideProfileImage={screenWidth === 'largeTablet' || screenWidth === 'tablet'}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

DetailsCollection.propTypes = {
  list: PropTypes.object,
  profiles: PropTypes.array,
  selectedProfiles: PropTypes.array,
  toggleSelectedProfile: PropTypes.func,
  editList: PropTypes.bool,
  hideFeedPreview: PropTypes.bool,
};

DetailsCollection.defaultProps = {
  list: undefined,
  profiles: undefined,
  selectedProfiles: undefined,
  toggleSelectedProfile: undefined,
  editList: false,
  hideFeedPreview: false,
};
