import PropTypes from 'prop-types';

import { useExternalLink } from 'context/externalLinkContext';

const ExternalLink = ({ className, children, href, target, title }) => {
  const { openExternalLink } = useExternalLink();

  const onClick = event => {
    event.preventDefault();

    openExternalLink({ href, target });
  };

  return (
    <a className={className} href={href} target={target} onClick={onClick} title={title}>
      {children}
    </a>
  );
};

ExternalLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

ExternalLink.defaultProps = {
  className: '',
  target: '_blank',
  title: '',
};

export default ExternalLink;
