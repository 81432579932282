import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './DropdownItem.module.scss';

export const Switch = ({ on, children, value, onSwitch }) => {
  const handleOnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onSwitch === 'function') {
      onSwitch(value, !on);
    }
  };
  return (
    <div className={styles['subnav-switch-label']} onClick={handleOnClick}>
      <span>{children}</span>
      <div className={styles.switch} onClick={handleOnClick}>
        {on ? <Icon name="switchOn" /> : <Icon name="switchOff" />}
      </div>
    </div>
  );
};

export const SubMenuItem = ({ menuItem }) => {
  if (menuItem?.hidden) {
    return null;
  }

  const itemContent = () => {
    switch (menuItem?.type) {
      case 'switch':
        return (
          <Switch
            key={menuItem?.value}
            value={menuItem?.value}
            onSwitch={menuItem?.onSwitch}
            on={!menuItem?.columnHidden}
          >
            {menuItem?.label}
          </Switch>
        );
      case 'title':
        return <div className={styles['menu-item-title']}>{menuItem?.label}</div>;
      case 'tooltip':
        return (
          <KwelloTooltip content={menuItem?.tooltipContent}>
            <div className={styles['subnav-label']}>
              {menuItem?.label}
              {menuItem?.icon && <Icon name={menuItem?.icon} size={16} />}
            </div>
          </KwelloTooltip>
        );
      case 'component':
        return menuItem?.component;
      default:
        return <div className={styles['subnav-label']}>{menuItem?.label}</div>;
    }
  };

  return (
    <section
      className={`${styles['subnav-item']} ${menuItem?.disabled ? styles.disabled : ''}`}
      onClick={() => {
        if (menuItem?.onClick) {
          menuItem?.onClick();
        }
      }}
    >
      {itemContent()}
    </section>
  );
};

Switch.propTypes = {
  on: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  onSwitch: PropTypes.func,
  value: PropTypes.string,
};

Switch.defaultProps = {
  on: false,
  children: null,
  onSwitch: undefined,
  value: undefined,
};

SubMenuItem.propTypes = {
  menuItem: PropTypes.object,
};

SubMenuItem.defaultProps = {
  menuItem: undefined,
};
