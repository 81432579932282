import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

import { useUser } from './userContext';

const GlobalKolPlanningContext = createContext({});

const GlobalKolPlanningProvider = props => {
  const [eventsList, setEventsList] = useState(undefined);
  const { gkpProjects, gkpFeatures } = useUser();
  const { projectId } = useParams();

  const projectSecret = useMemo(() => {
    const key = gkpProjects.find(project => project.projectID.toString() === projectId)?.key;
    return key;
  }, [projectId, gkpProjects]);

  const projectParams = useMemo(() => {
    const key = gkpProjects.find(project => project.projectID.toString() === projectId)?.parameters;
    return key;
  }, [projectId, gkpProjects]);

  const role = useMemo(
    () => gkpProjects.find(project => project.projectID.toString() === projectId)?.role,
    [gkpProjects, projectId],
  );

  const roleTeamName = useMemo(
    () => gkpProjects.find(project => project.projectID.toString() === projectId)?.roleTeamName,
    [gkpProjects, projectId],
  );

  const projectDisplayName = useMemo(() => {
    const displayName = gkpProjects.find(
      project => project.projectID.toString() === projectId,
    )?.projectDisplayName;
    return displayName;
  }, [projectId, gkpProjects]);

  const projectNotifFrequencies = useMemo(() => {
    const notifFrequencies = gkpProjects.find(
      project => project.projectID.toString() === projectId,
    )?.notifFrequencies;
    return sortBy(notifFrequencies, freq => freq.sortOrder);
  }, [projectId, gkpProjects]);

  const projectFeatures = useMemo(
    () => gkpProjects.find(project => project.projectID.toString() === projectId)?.objects,
    [gkpProjects, projectId],
  );

  const defaultNotifFrequency = useMemo(() => {
    return projectNotifFrequencies[0]?.value;
  }, [projectNotifFrequencies]);

  const documentSettings = useMemo(() => {
    const fileTypes = projectParams?.find(param => param.name === 'MIME Types');
    const fileSize = projectParams?.find(param => param.name === 'Max File Size (MB)');
    return {
      maxDocumentSize: parseInt(fileSize?.value, 10) || 2,
      documentFileTypes: fileTypes?.value?.split(',') || [
        '.csv',
        '.pdf',
        '.doc',
        '.docx',
        '.xls',
        '.xlsx',
        '.ppt',
        '.pptx',
        '.png',
        '.jpg',
        '.jpeg',
        '.gif',
      ],
    };
  }, [projectParams]);

  const eventName = useMemo(
    () =>
      gkpProjects.find(project => project.projectID.toString() === projectId)?.eventName || 'Event',

    [projectId, gkpProjects],
  );

  const value = useMemo(
    () => ({
      eventsList,
      gkpProjects,
      projectSecret,
      projectParams,
      setEventsList,
      gkpFeatures,
      documentSettings,
      projectDisplayName,
      projectNotifFrequencies,
      defaultNotifFrequency,
      role,
      roleTeamName,
      projectFeatures,
      eventName,
    }),
    [
      eventsList,
      gkpProjects,
      projectSecret,
      projectParams,
      setEventsList,
      gkpFeatures,
      documentSettings,
      projectDisplayName,
      projectNotifFrequencies,
      defaultNotifFrequency,
      role,
      roleTeamName,
      projectFeatures,
      eventName,
    ],
  );
  return <GlobalKolPlanningContext.Provider value={value} {...props} />;
};

const useGlobalKolPlanningContext = () => useContext(GlobalKolPlanningContext);
export { GlobalKolPlanningProvider, useGlobalKolPlanningContext };
