import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useFeatures } from 'context/featureContext';
import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useLists } from 'context/listsContext';

import { ALERT_FREQUENCIES } from 'constants/index';
import { useApi, useGkpApi } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { Header } from 'common/formControls/styles';
import { toastNotify } from 'common/ToastNotification/ToastNotification';

export const AlertMenuItem = ({
  currentValue,
  profileId,
  EventID,
  onSuccess,
  listId,
  listName,
  setAlert,
  searchId,
  noHeader,
  collectionID,
  collectionType,
}) => {
  const menuItemId = Math.floor(100000 + Math.random() * 900000);
  const { featureEnabled, features } = useFeatures();
  const { refreshLists, configureAlerts } = useLists();
  const { configureAlert } = useCollections();
  const { isGkp, isSocialMonitoring } = useCurrentPage();
  const { call: gkpCall } = useGkpApi();
  const { projectId } = useParams();
  const { projectNotifFrequencies, defaultNotifFrequency } = useGlobalKolPlanningContext();
  const { call } = useApi();
  const { setSavedSearchAlert, setFilterReferenceElements } = useFilters();

  if (!featureEnabled(features.alerts)) return null;

  const setGlobalKolPlanningAlerts = async ({ frequency }) => {
    try {
      const response = await gkpCall({
        url: profileId
          ? '/GlobalKOLPlanningKOLProfiles/AddAlertToKolProfile'
          : '/GlobalKOLPlanningEvents/AddAlertToEvent',
        method: 'POST',
        data: {
          projectId,
          frequency,
          ...(profileId && { profiles: profileId.constructor === Array ? profileId : [profileId] }),
          ...(EventID && { events: EventID.constructor === Array ? EventID : [EventID] }),
        },
      });

      if (response) {
        toastNotify('success', 'Alert frequency updated');
        if (profileId) {
          refreshLists(profileId.constructor === Array ? profileId[0] : profileId);
        }
        if (onSuccess) onSuccess(frequency, response);
      }
    } catch {}
  };

  const setNotifFrequency = async (frequency, label) => {
    if (searchId) {
      setSavedSearchAlert({ savedSearchIds: [searchId], notifFrequency: frequency });
    } else if (listId) {
      configureAlerts({ listId, listName, notifFrequency: frequency, label });
    } else if (isGkp) {
      if (collectionID) {
        configureAlert({ collectionID, notifFrequency: frequency, label });
      } else {
        setGlobalKolPlanningAlerts({ frequency });
      }
    } else {
      try {
        const response = await call({
          url: '/shared/setProfilesAlert',
          method: 'PUT',
          data: {
            profiles: profileId.constructor === Array ? profileId : [profileId],
            frequency,
            projectId,
          },
        });

        if (response?.profiles) {
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: prevState.lists.map(list => ({
                ...list,
                koLs: list.koLs.map(kol => ({
                  ...kol,
                  notifFrequency: response.profiles.includes(kol.profileId)
                    ? response.frequency
                    : kol.notifFrequency,
                })),
              })),
            }));
          } else {
            refreshLists(response.profiles[0]);
          }
          toastNotify('success', `Alert set to ${frequency}`);
          if (onSuccess) onSuccess(response);
        }
      } catch {
        toastNotify('error', 'Error occured while updating profile alert');
      }
    }
  };

  const setAlertValue = ({ type, label, event }) => {
    if (setAlert) {
      if (searchId) {
        setAlert(searchId, type);
      } else {
        setAlert(type);
      }
    } else {
      setNotifFrequency(type, label);
    }
    if (collectionType === 'savedSearch') {
      toastNotify('success', `Saved search alert updated to ${type}`);
    }
    event.preventDefault();
  };

  const currentAlertValue = ['None', 'none', 0, null, undefined, defaultNotifFrequency].includes(
    currentValue,
  )
    ? defaultNotifFrequency
    : currentValue;

  return (
    <>
      {!noHeader && (
        <Header>
          <div>Configure Alerts</div>
        </Header>
      )}

      <Form.Group>
        {projectNotifFrequencies?.length &&
          projectNotifFrequencies.map(frequency => (
            <div
              key={`${menuItemId}-${frequency.label}`}
              onClick={event =>
                setAlertValue({ type: frequency.value, label: frequency.label, event })
              }
            >
              <Form.Check
                id={`${menuItemId}-${frequency.value}`}
                type="radio"
                name={`alert-frequency-${menuItemId}`}
                label={frequency.label}
                defaultChecked={currentAlertValue === frequency.value}
              />
            </div>
          ))}
        {!projectNotifFrequencies?.length &&
          ALERT_FREQUENCIES.map(type => (
            <div key={`${menuItemId}-${type}`} onClick={event => setAlertValue({ type, event })}>
              <Form.Check
                id={`${menuItemId}-${type}`}
                type="radio"
                name={`alert-frequency-${menuItemId}`}
                label={type}
                defaultChecked={
                  (typeof currentValue === 'string' &&
                    currentValue?.toLowerCase() === type?.toLowerCase()) ||
                  (!currentValue && type?.toLocaleLowerCase() === 'none')
                }
              />
            </div>
          ))}
      </Form.Group>
    </>
  );
};

AlertMenuItem.propTypes = {
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profileId: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  EventID: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  listId: PropTypes.string,
  listName: PropTypes.string,
  onSuccess: PropTypes.func,
  setAlert: PropTypes.func,
  noHeader: PropTypes.bool,
  collectionID: PropTypes.string,
  searchId: PropTypes.string,
  collectionType: PropTypes.string,
};

AlertMenuItem.defaultProps = {
  currentValue: undefined,
  onSuccess: undefined,
  profileId: undefined,
  EventID: undefined,
  listId: undefined,
  listName: undefined,
  setAlert: undefined,
  noHeader: false,
  collectionID: undefined,
  searchId: undefined,
  collectionType: undefined,
};
