import { useParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useFilters } from 'context/filtersContext';
import { useUser } from 'context/userContext';

import { useApi } from '../useApi';

export const useCollectionsFeed = ({ collection }) => {
  const { projectId } = useParams();
  const { SocialMonitoringDefaultFilters } = useFilters();

  const { call } = useApi();
  const { sortByFeedPreference } = useUser();

  const fetchFeed = async ({ pageParam = 1 }) => {
    let data = [];
    try {
      data = await call({
        url: '/shared/feedPaginated',
        method: 'POST',
        data: {
          pageNumber: pageParam,
          pageSize: 50,
          filter: {
            ...SocialMonitoringDefaultFilters,
            ...collection.filter,
            list: collection.listId
              ? [{ id: collection.listId, reference: collection.listName }]
              : collection?.filter?.list,
            projectId,
          },
          sortBy: sortByFeedPreference,
        },
      });
    } catch {}
    return data;
  };

  const { data, fetchNextPage, isFetchingNextPage, isLoading, hasNextPage } = useInfiniteQuery({
    queryKey: ['feed', sortByFeedPreference, collection],
    queryFn: fnData => fetchFeed(fnData),
    getNextPageParam: lastPage => {
      if (lastPage?.feeds?.length < lastPage.totalRecords) {
        return lastPage.pageNumber + 1;
      }

      return undefined;
    },
    enabled: Object.keys(collection)?.length > 0,
    staleTime: Infinity,
  });

  return {
    data:
      data?.pages?.reduce((acc, page) => (page?.feeds ? [...acc, ...page.feeds] : []), []) || [], // Flatten the paginated data
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    collectionFilters: {
      ...SocialMonitoringDefaultFilters,
      ...collection.filter,
      list: collection.listId
        ? [{ id: collection.listId, reference: collection.listName }]
        : collection?.filter?.list,
      projectId,
    },
  };
};
