import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { isMobileOnly } from 'react-device-detect';
import { Redirect, useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useMsal } from '@azure/msal-react';

import { useAuth } from 'context/authContext';

import ROUTE, { childParentPage } from 'constants/route';
import { useNavigate } from 'utils/hooks/useNavigate';

import KwelloLogo from 'common/Logo/kwelloLogo';

import styles from './LoginPage.module.scss';

import { Auth0LoginButton } from './Auth0LoginButton';
import LoginButton from './LoginButton';

const LoginPage = () => {
  const { isAuthenticated, accessToken, loading } = useAuth();
  const { isLoading } = useAuth0();
  const { inProgress } = useMsal();
  const { previousPath, clearPreviousPath } = useNavigate();
  const history = useHistory();
  const mailto = 'mailto:support@accelerationpoint.com?subject=Kwello Support Request';
  const [previousKwelloPath, setPreviousKwelloPath] = useState(undefined);

  useEffect(() => {
    if (previousKwelloPath) {
      const previousSearch = localStorage.getItem('previousSearchValues') || '';
      const childPage = Object.keys(childParentPage).find(page =>
        previousKwelloPath.includes(page),
      );

      if (childPage) {
        const parentPath =
          previousKwelloPath.substring(0, previousKwelloPath.indexOf(childPage)) +
          childParentPage[childPage];

        history.push(`${parentPath}${previousSearch}`);
      }
      if (childPage !== ROUTE.interactionDetail) {
        history.push(`${previousKwelloPath}${previousSearch}`);
      }
    }
  }, [previousKwelloPath, history]);

  if (isAuthenticated && accessToken) {
    if (previousPath) {
      clearPreviousPath();
      const previousSearch = localStorage.getItem('previousSearchValues') || '';
      return <Redirect to={`${previousPath}${previousSearch}`} />;
    }
    if (localStorage.getItem('previousKwelloPath') && !previousKwelloPath) {
      const previousPathname = localStorage.getItem('previousKwelloPath');
      setPreviousKwelloPath(previousPathname);
    }

    if (!sessionStorage.getItem('loginError')) {
      return <Redirect to="/" />;
    }
  }

  return (
    <div
      className={`d-flex align-items-center flex-column pb-5 ${
        isMobileOnly ? styles.mobile : 'login'
      }`}
    >
      <KwelloLogo className={styles['kwello-login-icon']} />
      {isMobileOnly && (
        <div className={styles['mobile-greeting']}>
          <h2>
            <b>Welcome!</b>
          </h2>
          <h4>Sign in to continue</h4>
        </div>
      )}
      <Card>
        <Card.Body className="p-4">
          <h1 className="mb-4">Login</h1>
          <div className="mb-3 text-justify" style={{ fontWeight: '500' }}>
            Enter your organisation&apos;s email address to sign in. You will be redirected to login
            with your single sign on account.
          </div>
          <div className="text-center mb-4">
            <LoginButton disabled={inProgress !== 'none' || loading} />
          </div>

          <hr />
          <div className="mb-4">
            <a
              href="#"
              className={styles['text-align-right']}
              data-test="link-need-help"
              onClick={e => {
                window.location.href = mailto;
                e.preventDefault();
              }}
            >
              Need Help? Contact Support
            </a>
          </div>
          <div className="text-center mb-2">
            <Auth0LoginButton disabled={isLoading} />
          </div>
          {sessionStorage.getItem('loginError') && (
            <div className={styles['login-error']}>{sessionStorage.getItem('loginError')}</div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginPage;
