// fix for plurals
export const filtersSettings = [
  {
    id: 1,
    items: [
      {
        key: 1,
        value: 'savedSearches',
        label: 'Saved Searches',
        subMenu: {
          type: 'savedSearch',
        },
      },
      {
        key: 2,
        value: 'collection',
        label: 'Collections',
        badgeLabel: 'Collections',
        subMenu: {
          type: 'collections',
        },
      },
      {
        key: 3,
        value: 'list',
        label: 'Lists',
        badgeLabel: 'Lists',
        subMenu: {
          type: 'kolList',
        },
      },
    ],
  },
  {
    id: 4,
    header: 'Profiles',
    items: [
      {
        key: 4,
        value: 'profile',
        label: 'Profile',
        badgeLabel: 'Profiles',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 5,
        value: 'country',
        label: 'Country',
        badgeLabel: 'Countries',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 6,
        value: 'state',
        label: 'State',
        badgeLabel: 'States',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 7,
        value: 'scientificRank',
        label: 'Scientific Rank',
        badgeLabel: 'Scientific Rank',
        subMenu: {
          type: 'range',
        },
      },
      {
        key: 8,
        value: 'hasTwitterFollowers',
        label: 'Has Twitter Followers?',
        badgeLabel: 'Has Twitter Followers',
        subMenu: {
          type: 'boolean',
        },
      },
      {
        key: 9,
        value: 'isScientificallyProfiled',
        label: 'Is Scientifically Profiled?',
        badgeLabel: 'Is Scientifically Profiled',
        subMenu: {
          type: 'boolean',
        },
      },
      {
        key: 10,
        value: 'isDigitalOpinionLeader',
        label: 'Is Digital Opinion Leader?',
        badgeLabel: 'Is Digital Opinion Leader',
        subMenu: {
          type: 'boolean',
        },
      },
      {
        key: 11,
        value: 'segment',
        label: 'Segment',
        badgeLabel: 'Segments',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 12,
        value: 'twitterBioSearch',
        label: 'Twitter Bio Search',
        badgeLabel: 'Twitter Bio Search',
        subMenu: {
          type: 'text',
        },
      },
    ],
  },
  {
    id: 3,
    header: 'Contribution',
    kolMenuItem: true,
    items: [
      {
        key: 13,
        value: 'treatment',
        label: 'Treatment',
        badgeLabel: 'Treatments',
        subMenu: {
          type: 'treatment',
        },
      },
      {
        key: 14,
        value: 'category',
        label: 'Earned/Owned',
        badgeLabel: 'Earned/Owned',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 15,
        value: 'source',
        label: 'Source',
        badgeLabel: 'Sources',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 16,
        value: 'sentiment',
        label: 'Sentiment',
        badgeLabel: 'Sentiment',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 17,
        value: 'diseaseState',
        label: 'Disease State',
        badgeLabel: 'Disease States',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 18,
        value: 'topic',
        label: 'Topic',
        badgeLabel: 'Topics',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 19,
        value: 'domain',
        label: 'Domain',
        badgeLabel: 'Domains',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 20,
        value: 'keyword',
        label: 'Keyword',
        badgeLabel: 'Keywords',
        subMenu: {
          type: 'list',
        },
      },
      {
        key: 21,
        value: 'addedToKwello',
        label: 'Added Since Date',
        badgeLabel: 'Added Since',
        subMenu: {
          type: 'date',
        },
      },
    ],
  },
];

export const conversationTabFilterLabels = {
  twitterHandle: 'Twitter Handle',
  author: 'Author',
  mentionedWith: 'Mentioned With',
  mentionedBy: 'Mentioned By',
};
