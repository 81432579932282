import { ReactComponent as SVGClock } from 'assets/icons/clock.svg';
import PropTypes from 'prop-types';

import styles from './ChartNotification.module.scss';

import { NO_DATA_MESSAGE } from './Charts.const';

const ChartNotification = ({ type, message }) => (
  <section className={`${styles.container} ${styles[type]}`}>
    <SVGClock className="mr-1" />
    &nbsp;
    {message}
  </section>
);

ChartNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
};

ChartNotification.defaultProps = {
  type: 'information',
  message: NO_DATA_MESSAGE,
};

export default ChartNotification;
