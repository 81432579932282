import numeral from 'numeral';
import PropTypes from 'prop-types';

export const DEFAULT_CHART_HEIGHT = '250px';
export const DEFAULT_CHART_CONTAINER_HEIGHT = '310px';
export const MOCK_DATA_BACKGROUND_COLOR = '#e7e7e7';
export const NO_DATA_MESSAGE = 'No data for current filters';
export const DEFAULT_LABEL_FORMAT = '0a';
export const CHART_EVENT_ACTIONS = {
  singleClick: 1,
  doubleClick: 2,
};

export const DATASET_PROP_TYPES = PropTypes.shape({
  label: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
});

export const CHART_PROP_TYPES = {
  asBody: PropTypes.bool,
  className: PropTypes.string,
  colorset: PropTypes.string,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(DATASET_PROP_TYPES),
  }).isRequired,
  exportAction: PropTypes.func,
  exportActionParams: PropTypes.object,
  filter: PropTypes.string,
  filterType: PropTypes.string,
  filterLabel: PropTypes.string,
  height: PropTypes.string,
  hideValues: PropTypes.bool,
  loading: PropTypes.bool,
  showLegend: PropTypes.bool,
  stacked: PropTypes.bool,
  title: PropTypes.string,
  titleOptions: PropTypes.array,
  vertical: PropTypes.bool,
  containerHeight: PropTypes.string,
  singleClickOverride: PropTypes.func,
  doubleClickOverride: PropTypes.func,
  exportApiConfig: PropTypes.object,
  noExport: PropTypes.bool,
};

export const CHART_DEFAULT_PROPS = {
  data: {
    labels: [],
    datasets: [],
  },
  asBody: false,
  className: undefined,
  colorset: 'default',
  exportAction: undefined,
  exportActionParams: {},
  filter: undefined,
  filterType: undefined,
  filterLabel: 'dataset',
  height: undefined,
  hideValues: false,
  loading: false,
  showLegend: false,
  stacked: false,
  title: undefined,
  titleOptions: undefined,
  vertical: false,
  containerHeight: undefined,
  singleClickOverride: undefined,
  doubleClickOverride: undefined,
  exportApiConfig: undefined,
  noExport: false,
};

const numberRegex = /^[0-9,.]*$/;

export const formatLabels = (label, fullScreen) => {
  if (label === null) return '';

  if (!numberRegex.test(label) && label?.length > 15) {
    return fullScreen ? label : `${label.substring(0, 12)}...`;
  }
  if (numberRegex.test(label)) {
    return numeral(label).format('0a').toUpperCase();
  }

  return label;
};

export const DEFAULT_CHART_OPTIONS = {
  maintainAspectRatio: false,
  animation: { duration: 0 },
  hover: { animationDuration: 0 },
  responsiveAnimationDuration: 0,
  responsive: true,
  layout: { padding: 10 },
  redraw: true,
  elements: {},
  scales: {
    x: {
      display: false,
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        crossAlign: 'far',
        color: '#000',
        padding: 10,
        callback(val) {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.getLabelForValue(val);
          return formatLabels(label);
        },
      },
    },
    y: {
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        crossAlign: 'far',
        color: '#000',
        padding: 10,
        callback(val) {
          // eslint-disable-next-line react/no-this-in-sfc
          const label = this.getLabelForValue(val);
          return formatLabels(label);
        },
      },
    },
  },
  plugins: {
    legend: { display: false, position: 'top' },
    title: { display: false },
    datalabels: {
      color: ({ dataIndex, dataset: { data } }) => {
        const max = Math.max(...data);
        if ((data[dataIndex] / max) * 100 > 80) {
          return '#ffffff';
        }
        return '#000000';
      },
      anchor: 'end',
      offset: ({ dataIndex, dataset: { data } }) => {
        const max = Math.max(...data);
        if ((data[dataIndex] / max) * 100 > 80) {
          const { length } = numeral(data[dataIndex]).format(DEFAULT_LABEL_FORMAT);
          const minMargin = -20;
          const calculatedMargin = length * -10 - 5;
          return Math.min(calculatedMargin, minMargin);
        }
        return 0;
      },
      align: 'end',
      formatter(value) {
        return numeral(value).format(DEFAULT_LABEL_FORMAT).toUpperCase();
      },
      font: { weight: '700' },
    },
  },
};

export const CHART_COLORS_PRIMARY = [
  '#73B761',
  '#4A588A',
  '#ECC846',
  '#CD4C46',
  '#71AFE2',
  '#8D6FD1',
  '#EE9E64',
  '#95DABB',
];
export const CHART_COLORS_SECONDARY = [
  '#F87700',
  '#F8BA00',
  '#F8E400',
  '#97E700',
  '#00C018',
  '#038698',
  '#0D53A2',
  '#152DAA',
  '#4B0FA9',
  '#9802A2',
  '#CA006B',
  '#F80600',
  '#9D0900',
  '#B26000',
  '#783200',
  '#606803',
  '#034701',
  '#002B43',
  '#0D0041',
  '#440048',
  '#660036',
  '#C4C4C4',
];
export const CHART_COLORS = [...CHART_COLORS_PRIMARY, ...CHART_COLORS_SECONDARY];

export const DATA_STEPS_CHARTS = [
  {
    filterName: 'source',
    filterKey: 'source',
    value: 'SourceName',
    label: 'Source',
    apiConfig: {
      method: 'POST',
      url: '/shared/Sources',
      data: {
        dataStep: 'SourceName',
        souce: [],
      },
    },
  },
  {
    filterName: 'sentiment',
    filterKey: 'sentiment',
    value: 'Sentiment',
    label: 'Sentiment',
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Sentiment',
        sentiment: [],
      },
    },
  },
  {
    filterName: 'country',
    filterKey: 'country',
    value: 'Country',
    label: 'Country',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Country',
        country: [],
      },
    },
  },
  {
    filterName: 'state',
    filterKey: 'state',
    value: 'State',
    label: 'State',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'State',
        state: [],
      },
    },
  },
  {
    filterName: 'segment',
    filterKey: 'segment',
    value: 'Segment',
    label: 'Segment',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Segment',
        segment: [],
      },
    },
  },
  {
    filterName: 'isScientificallyProfiled',
    filterKey: 'isScientificallyProfiled',
    value: 'IsSP',
    label: 'Is Scientifically Profiled?',
    type: 'boolean',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'IsSP',
        isScientificallyProfiled: [],
      },
    },
  },
  {
    filterName: 'isDigitalOpinionLeader',
    filterKey: 'isDigitalOpinionLeader',
    value: 'IsDOL',
    label: 'DOL?',
    type: 'boolean',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'IsDOL',
        isDigitalOpinionLeader: [],
      },
    },
  },
  {
    filterName: 'hasTwitterFollowers',
    filterKey: 'hasTwitterFollowers',
    value: 'HasTwitterFollowers',
    label: 'Has Twitter Followers?',
    type: 'boolean',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'HasTwitterFollowers',
        hasTwitterFollowers: [],
      },
    },
  },
  {
    filterName: 'profile',
    filterKey: 'profile',
    value: 'ProfileName',
    label: 'Profile',
    notApplicableToSingleKol: true,
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'ProfileName',
        profile: [],
      },
    },
  },
  {
    filterName: 'domain',
    filterKey: 'domain',
    value: 'Domain',
    label: 'Domain',
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Domain',
        domain: [],
      },
    },
  },
  {
    filterName: 'diseaseState',
    filterKey: 'diseaseState',
    value: 'Disease State',
    label: 'Disease States',
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Disease State',
        diseaseState: [],
      },
    },
  },
  {
    filterName: 'treatment',
    filterKey: 'treatment',
    value: 'Treatment',
    label: 'Treatments',
    exportKey: 'Treatments',
    apiConfig: {
      method: 'POST',
      url: '/shared/Treatments',
      data: {
        dataStep: 'Treatment',
        treatments: [],
      },
    },
  },
  {
    filterName: 'topic',
    filterKey: 'topic',
    value: 'Topic',
    label: 'Topics',
    exportKey: 'Topics',
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Topic',
        topic: [],
      },
    },
  },
  {
    filterName: 'category',
    filterKey: 'category',
    value: 'Category',
    label: 'Earned/Owned',
    exportKey: 'Category',
    apiConfig: {
      method: 'POST',
      url: '/shared/datasteps',
      data: {
        dataStep: 'Category',
        category: [],
      },
    },
  },
];
