/* eslint-disable func-names */
import { find } from 'lodash';

import { filtersSettings as filterMenu } from 'constants';

import { SavedFiltersListMenu } from '../../cardsMenu';
import {
  BooleanFilter,
  CollectionFilter,
  DateFilter,
  KolListsFilter,
  ListFilter,
  RangeFilter,
  TextFilter,
  TreatmentFilter,
} from '../filters';

export const resolveFilterComponent = ({ filter, kolProfile, source, update }) => {
  if (filter.subMenu) {
    switch (filter.subMenu.type) {
      case 'date':
        return (
          <DateFilter
            header={filter.label}
            filterKey={filter.value}
            filterOptions={filter?.options}
            isInKolProfilePopup={kolProfile}
            update={update}
            eventKey={filter.eventKey}
          />
        );
      case 'treatment':
        return (
          <TreatmentFilter
            header={filter.label}
            filterKey={filter.value}
            filterOptions={filter?.options}
            isInKolProfilePopup={kolProfile}
            update={update}
            eventKey={filter.eventKey}
          />
        );
      case 'list':
        return (
          <ListFilter
            header={filter.label}
            filterKey={filter.value}
            filterOptions={filter?.options}
            isInKolProfilePopup={kolProfile}
            update={update}
            eventKey={filter.eventKey}
          />
        );
      case 'boolean':
        return (
          <BooleanFilter
            header={filter.label}
            filterKey={filter.value}
            isInKolProfilePopup={kolProfile}
            update={update}
            source={source}
            eventKey={filter.eventKey}
          />
        );
      case 'range':
        return (
          <RangeFilter
            header={filter.label}
            filterKey={filter.value}
            isInKolProfilePopup={kolProfile}
            update={update}
            eventKey={filter.eventKey}
          />
        );
      case 'text':
        return (
          <TextFilter
            header={filter.label}
            filterKey={filter.value}
            isInKolProfilePopup={kolProfile}
            update={update}
            eventKey={filter.eventKey}
          />
        );
      case 'savedSearch':
        return <SavedFiltersListMenu eventKey={filter.eventKey} />;
      case 'collections':
        return <CollectionFilter eventKey={filter.eventKey} />;
      case 'kolList':
        return <KolListsFilter eventKey={filter.eventKey} />;
      default:
        // eslint-disable-next-line no-console
        return console.error('unknown filter type', filter.subMenu.type);
    }
  }

  return function () {
    // eslint-disable-next-line no-console
    return console.error('filter.subMenu not found', filter);
  };
};

const mapFilters = (filters, isInKolProfilePopup = false, showScientificRank = true) =>
  filters
    .filter(x => !isInKolProfilePopup || !x.notApplicableToSingleKol)
    .map(filterGroup => {
      const filterGroupItems = { ...filterGroup };
      filterGroupItems.items = filterGroup.items
        .filter(
          x =>
            showScientificRank || !['isScientificallyProfiled', 'scientificRank'].includes(x.value),
        )
        .map(filter => {
          const result = { ...filter };
          if (filter.subMenu) {
            result.renderSubmenu = resolveFilterComponent(filter, isInKolProfilePopup, 'submenu');
          }

          return result;
        });
      return filterGroupItems;
    });

const getFilterComponentByName = (filterName, isInKolProfilePopup = false) => {
  const filtersList = filterMenu.reduce((prev, curr) => {
    const newPrev = [...prev, ...curr.items];
    return newPrev;
  }, []);

  const filter = find(filtersList, x => x?.value === filterName);

  if (filter) {
    return resolveFilterComponent(filter, isInKolProfilePopup, 'badge');
  }

  return false;
};

export default mapFilters;

export { getFilterComponentByName };
