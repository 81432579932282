import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:
      process.env.REACT_APP_INSIGHT_INSTRUMENTATION_KEY || 'bd77e391-9618-468e-8bc5-eefbd2d1b7a3',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();
export { appInsights, reactPlugin };
