import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';
import { useModal } from 'context/modalContext';

import Button from 'common/button/Button';

import { AddListModal } from 'Pages/Lists/Modals';

export const AddListAction = ({ profiles }) => {
  const { openModal } = useModal();
  const { isDesktop } = useLayout();

  const addList = () => {
    openModal(<AddListModal profiles={profiles} />);
  };

  return (
    <Button buttonType="transparent" icon="listAdd" onClick={addList}>
      {profiles && isDesktop
        ? `Create a new List and add ${profiles?.length} Profile${profiles?.length > 1 ? 's' : ''}`
        : 'Create new List'}
    </Button>
  );
};

AddListAction.propTypes = {
  profiles: PropTypes.array,
};
AddListAction.defaultProps = {
  profiles: undefined,
};
