import { useModal } from 'context/modalContext';

import Button from 'common/button/Button';
import { CreateSavedSearchModal } from 'common/SavedSearch';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './AddKolModal.module.scss';

export const SavedSearchModal = () => {
  const { openModal } = useModal();

  return (
    <div className={styles.kolButtonCount}>
      <KwelloTooltip position="left" content="Create saved search">
        <Button
          buttonType="transparent"
          icon="saveDisc"
          onClick={() => openModal(<CreateSavedSearchModal />)}
        />
      </KwelloTooltip>
    </div>
  );
};
