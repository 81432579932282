import { isMobileOnly } from 'react-device-detect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import KwelloLogo from 'common/Logo/kwelloLogo';

import styles from './Login.module.scss';

const Footer = ({ fixed }) => {
  const mailto = 'mailto:support@accelerationpoint.com?subject=Kwello Support Request';
  const year = new Date().getFullYear();

  if (isMobileOnly) {
    return null;
  }

  return (
    <div
      className={`footer pt-2 pb-2 pl-4 d-flex align-items-center ${fixed ? 'footer-fixed' : ''}`}
    >
      <span>
        Copyright &copy;
        {year}
        &nbsp; Acceleration Point. All rights reserved
      </span>
      <Link to="/privacy-policy">Privacy Policy</Link>
      <Link to="/terms">Terms of Use</Link>
      <Link to="/cookies">Cookies</Link>
      <a
        data-test="link-contact-us"
        href="#"
        onClick={e => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        Contact Us
      </a>
      <KwelloLogo className={styles['kwello-footer-icon']} />
    </div>
  );
};

Footer.propTypes = { fixed: PropTypes.bool };
Footer.defaultProps = { fixed: false };

export default Footer;
