import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useLists } from 'context/listsContext';

import ROUTE from 'constants/route';
import { useGkpApi } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Icon from 'common/Icon/Icon';
import { toastNotify } from 'common/ToastNotification/ToastNotification';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './AlertButton.module.scss';

import { AlertButtonMenu } from './AlertButtonMenu';

export const AlertButton = ({
  notifFrequency,
  profileId,
  EventID,
  onSuccess,
  tooltip,
  collectionID,
  listId,
  listName,
  disabled,
  subMenuLabel,
  className,
  searchId,
  noHeader,
  collectionType,
}) => {
  const { defaultNotifFrequency, projectNotifFrequencies } = useGlobalKolPlanningContext();
  const { call: gkpCall } = useGkpApi();
  const { projectId } = useParams();
  const { configureAlert } = useCollections();
  const { configureAlerts } = useLists();
  const { currentPage } = useCurrentPage();
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);

  const [alertOff, setAlertOff] = useState(false);

  useEffect(() => {
    if (notifFrequency !== undefined) {
      const alert = ['None', 'none', 0, null, defaultNotifFrequency].includes(notifFrequency);
      setAlertOff(alert);
    }
  }, [notifFrequency, defaultNotifFrequency]);

  const updateFrequency = async frequency => {
    const response = await gkpCall({
      url: profileId
        ? '/GlobalKOLPlanningKOLProfiles/AddAlertToKolProfile'
        : '/GlobalKOLPlanningEvents/AddAlertToEvent',
      method: 'POST',
      data: {
        projectId,
        frequency,
        ...(profileId && { profiles: profileId.constructor === Array ? profileId : [profileId] }),
        ...(EventID && { events: EventID.constructor === Array ? EventID : [EventID] }),
      },
    });

    toastNotify('success', 'Alert frequency updated');
    if (onSuccess) onSuccess(frequency, response);
  };

  const onClick = () => {
    const { value: frequencyOff, label: labelOff } = projectNotifFrequencies.find(
      notifFreq => notifFreq.value === defaultNotifFrequency,
    );

    const { value: frequencyOn, label: labelOn } = projectNotifFrequencies.find(
      notifFreq => notifFreq.value !== defaultNotifFrequency,
    );

    // the state has not been updated yet so we need to inverse the options
    const frequency = alertOff ? frequencyOn : frequencyOff;
    const label = alertOff ? labelOn : labelOff;

    setAlertOff(!alertOff);
    if (listId) {
      configureAlerts({ listId, listName, notifFrequency: frequency, label });
    } else if (collectionID) {
      configureAlert({ collectionID, notifFrequency: frequency, label });
    } else {
      updateFrequency(frequency);
    }
  };

  return (
    <>
      {isGkp && (
        <div className={`${styles['notification-icon']} ${disabled ? styles.disabled : ''}`}>
          <KwelloTooltip position="bottom" content={tooltip}>
            <span onClick={onClick}>
              <Icon name={alertOff ? 'alertOff' : 'alertOn'} />
            </span>
          </KwelloTooltip>
        </div>
      )}
      {!isGkp && (
        <AlertButtonMenu
          notifFrequency={notifFrequency}
          listId={listId}
          listName={listName}
          disabled={disabled}
          subMenuLabel={subMenuLabel}
          profileId={profileId}
          onSuccess={onSuccess}
          className={className}
          searchId={searchId}
          noHeader={noHeader}
          collectionType={collectionType}
        />
      )}
    </>
  );
};

AlertButton.propTypes = {
  notifFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profileId: PropTypes.string,
  EventID: PropTypes.string,
  onSuccess: PropTypes.func,
  tooltip: PropTypes.string,
  collectionID: PropTypes.string,
  disabled: PropTypes.bool,
  listId: PropTypes.string,
  listName: PropTypes.string,
  subMenuLabel: PropTypes.string,
  className: PropTypes.string,
  searchId: PropTypes.string,
  collectionType: PropTypes.string,
  noHeader: PropTypes.bool,
};
AlertButton.defaultProps = {
  className: null,
  notifFrequency: undefined,
  profileId: undefined,
  EventID: undefined,
  onSuccess: undefined,
  tooltip: 'Click to set alert',
  collectionID: undefined,
  disabled: false,
  listId: undefined,
  listName: undefined,
  subMenuLabel: undefined,
  searchId: undefined,
  collectionType: undefined,
  noHeader: false,
};
