import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';
import Scroll from 'common/Scroll/Scroll';

import styles from './ExpandedModule.module.scss';

const iconNames = {
  'Social Monitoring': 'socialMonitoringModule',
  Insights: 'insightsModule',
  'SOSV Experts': 'sosvExpertsModule',
  'SOSV Science': 'sosvScienceModule',
  'Custom Reports': 'customReportsModule',
  'Global KOL Planning': 'globalKOLPlanningModule',
  'Insights Capture': 'insightsCaptureModule',
};

const moduleNames = {
  'Social Monitoring': 'Social',
  'Global KOL Planning': 'Planning',
  'Insights Capture': 'Insights',
};

export const ExpandedModule = ({ index, module, active, expanded, content, onNavigate }) => {
  return (
    <section
      className={`
        ${styles.container} 
        ${expanded === index ? styles.active : styles['not-active']} 
        ${styles.expanded}
      `}
    >
      <div className={styles['icon-name']} onClick={onNavigate}>
        <Icon name={iconNames[module.name]} size={24} />

        <span className={active ? styles['text-active'] : styles['text-inactive']}>
          {moduleNames[module.name] || module.name}
        </span>
      </div>

      {expanded === index && (
        <section className={styles.content}>
          <Scroll hideArrow>{content}</Scroll>
        </section>
      )}
    </section>
  );
};

ExpandedModule.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  onNavigate: PropTypes.func.isRequired,
  expanded: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    navLink: PropTypes.string,
  }).isRequired,
};
