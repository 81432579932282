import { cloneElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import useOutsideAlerter from 'utils/hooks/useOutsideAlerter';

import Button from 'common/button/Button';
import Scroll from 'common/Scroll/Scroll';

import useKeyPress from '../keyPressEvent/useKeyPress';

import styles from './DropdownMenu.module.scss';

import DropdownItem from './DropdownItem';

export const DropDownMenu = ({
  alignRight,
  alignLeft,
  menuItems,
  clickElement,
  className,
  maxHeight,
  shiftDown,
  setUpArrow,
  containerStyle,
}) => {
  const dropdownMenuItems = menuItems?.length ? menuItems : [{ label: 'Missing menuItems' }];
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const contentRef = useRef(null);
  const { scrollPosition, viewport } = useLayout();
  const [shiftRight, setShiftRight] = useState(false);
  const [shiftUp, setShiftUp] = useState(false);
  const [subMenu, setSubMenu] = useState();
  const menuId = useRef(`dropdown-menu-${Math.random()}`);

  useOutsideAlerter(dropdownRef, () => setOpen(false));

  useEffect(() => {
    const content = contentRef?.current?.getBoundingClientRect();
    setShiftRight(content?.right > viewport[1]);
    if (!shiftDown) {
      setShiftUp(content?.bottom > viewport[0]);
      if (setUpArrow) {
        setUpArrow(content?.bottom > viewport[0]);
      }
    }
  }, [open, viewport, shiftDown, setUpArrow]);

  useEffect(() => {
    setOpen(false);
  }, [scrollPosition]);

  // This closes the subnav menu when the main content closes so on re-open the sub nav is closed
  useEffect(() => {
    if (!open) {
      setSubMenu();
    }
  }, [open]);

  useKeyPress('Escape', () => {
    setOpen(false);
  });

  const getContainerStyle = () => {
    if (containerStyle) {
      return containerStyle;
    }
    if (alignLeft) {
      return { left: 'auto', right: '100%', top: '0%' };
    }
    return {
      left: alignRight || shiftRight ? 'auto' : 0,
      right: alignRight || shiftRight ? 0 : undefined,
      top: shiftUp ? 'auto' : '100%',
      bottom: shiftUp ? '100%' : undefined,
    };
  };

  return (
    <section ref={dropdownRef} className={`${styles.container} ${className}`}>
      {!clickElement && (
        <Button buttonType="transparent" icon="menu" onClick={() => setOpen(!open)} />
      )}
      {clickElement &&
        cloneElement(clickElement, {
          dropdown: 'true',
          onClick: () => {
            setOpen(!open);
          },
        })}
      {open && (
        <section
          ref={contentRef}
          className={`${styles['dropdown-content']} ${open ? styles.open : ''}`}
          style={getContainerStyle()}
          id="dropdown-container"
        >
          {!menuItems?.find(item => item.subMenuContent) ? (
            <Scroll maxHeight={maxHeight} hideArrow style={{ paddingRight: '0' }}>
              {dropdownMenuItems.map((menuItem, index) => (
                <DropdownItem
                  key={`${menuId.current}-${index}`}
                  shiftUp={shiftUp}
                  subMenu={subMenu}
                  setSubMenu={setSubMenu}
                  setOpen={setOpen}
                  item={menuItem}
                />
              ))}
            </Scroll>
          ) : (
            dropdownMenuItems.map((menuItem, index) => (
              <DropdownItem
                key={`${menuId.current}-${index}`}
                shiftUp={shiftUp}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
                setOpen={setOpen}
                item={menuItem}
              />
            ))
          )}
        </section>
      )}
    </section>
  );
};

DropDownMenu.propTypes = {
  alignRight: PropTypes.bool,
  menuItems: PropTypes.array,
  alignLeft: PropTypes.bool,
  className: PropTypes.string,
  maxHeight: PropTypes.string,
  clickElement: PropTypes.any,
  shiftDown: PropTypes.bool,
  setUpArrow: PropTypes.func,
  containerStyle: PropTypes.object,
};
DropDownMenu.defaultProps = {
  alignRight: false,
  menuItems: [],
  alignLeft: false,
  className: '',
  maxHeight: '50vh',
  clickElement: undefined,
  shiftDown: false,
  setUpArrow: undefined,
  containerStyle: undefined,
};
