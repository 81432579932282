import { useLists } from 'context/listsContext';
import { useModal } from 'context/modalContext';

export function useProfileLists({ profileId }) {
  const { lists, getProfileLists, removeProfileFromList, addProfilesToList } = useLists();
  const profilesInLists = getProfileLists(profileId);
  const { closeModal } = useModal();
  const selectedLists = profilesInLists?.map(list => list.collectionID);

  const allCollections = lists?.map(list => ({
    ...list,
    collectionID: list?.listId,
    collectionName: list?.listName,
  }));

  const onSave = selectedItems => {
    const removed = profilesInLists
      ?.filter(list => !selectedItems.includes(list.collectionID))
      ?.map(list => list.listId);
    const added = selectedItems?.filter(listId => !selectedLists.includes(listId));

    if (removed?.length) {
      removeProfileFromList(removed, [profileId]);
    }

    if (added?.length) {
      addProfilesToList(added, [profileId]);
    }

    closeModal();
  };

  return { allCollections, selectedLists, onSave };
}
