import PropTypes from 'prop-types';

import { StyledEmptySubmenu } from './styles';

const DropdownSubmenuEmpty = ({ text }) => (
  <StyledEmptySubmenu className="px-2 text-sm fst-italic text-muted">{text}</StyledEmptySubmenu>
);
DropdownSubmenuEmpty.propTypes = {
  text: PropTypes.string.isRequired,
};

export default DropdownSubmenuEmpty;
