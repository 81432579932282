import { useCallback, useEffect, useState } from 'react';

import { useFilters } from 'context/filtersContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { SearchFilter } from 'common/filtersBar';
import OverlayContainer from 'common/overlay/OverlayContainer';

import AdvancedSearchPopup from './AdvancedSearchPopup';

const SearchDropDown = () => {
  const { getFilter, setFilter } = useFilters();
  const searchItems = getFilter('search');
  const { currentPage } = useCurrentPage();
  const advancedSearchItem = getFilter('advancedSearch');
  const [title, setTitle] = useState('Search');
  const [hasSearchText, setHasSearchText] = useState(false);

  useEffect(() => {
    const searchArray = [...searchItems];
    const advancedString =
      advancedSearchItem?.length > 50
        ? `${advancedSearchItem.substring(0, 50)}...`
        : advancedSearchItem;

    if (advancedString) {
      searchArray.push(advancedString);
    }

    const filterValues = searchArray.filter(value => value.length > 0) || [];
    let filterText = '';

    if (filterValues.length >= 4) {
      filterText = `: ${filterValues.slice(0, 3).join(' or ')}, +${filterValues.length - 3} More`;
    } else if (filterValues.length > 0) {
      filterText = `: ${filterValues.join(' or ')}`;
    }

    setHasSearchText(filterValues.length > 0);

    setTitle(`Search${filterText}`);
  }, [advancedSearchItem, searchItems]);

  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);

  const resetFilters = useCallback(() => {
    setFilter({ search: [], advancedSearch: '' });
  }, [setFilter]);

  return (
    <>
      <DropDownMenu
        clickElement={
          <Button
            buttonType="filter"
            size="small"
            secondaryAction={hasSearchText ? resetFilters : undefined}
            icon="search"
          >
            {title}
          </Button>
        }
        menuItems={[
          {
            type: 'component',
            component: (
              <SearchFilter
                header="Search"
                onAdvancedSearchClick={
                  currentPage(ROUTE.globalKolPlanningUrl)
                    ? undefined
                    : () => setShowAdvancedSearch(true)
                }
                searchTitle=""
              />
            ),
          },
        ]}
      />

      <OverlayContainer show={showAdvancedSearch}>
        <AdvancedSearchPopup onClose={() => setShowAdvancedSearch(false)} />
      </OverlayContainer>
    </>
  );
};

export default SearchDropDown;
