import PropTypes from 'prop-types';

import { add, dateFormats, formatDate, subtract } from 'utils/dateHelper';

import styles from './DateButton.module.scss';

export const DateButton = ({ selected, type, label, start, end, value, setFormValues }) => {
  let from;
  let to;

  if (start && end) {
    from = start;
    to = end;
  }

  if (type?.includes('forward')) {
    from = formatDate(new Date(), dateFormats.api);
    to = formatDate(add(new Date(), { [`${value.metric}s`]: value.value }), dateFormats.api);
  }

  if (type?.includes('backward')) {
    from = formatDate(subtract(new Date(), { [`${value.metric}s`]: value.value }), dateFormats.api);
    to = formatDate(new Date(), dateFormats.api);
  }

  const onClick = () => {
    if (type === 'range') {
      setFormValues({
        start: formatDate(from, dateFormats.api),
        end: formatDate(to, dateFormats.api),
      });
    }

    if (type?.includes('period')) {
      setFormValues({
        selectedPeriod: { ...value, direction: type?.includes('forward') ? 'forward' : 'backward' },
        start: from,
        end: to,
      });
    }
  };

  return (
    <div
      className={`${styles['date-range-button']} ${selected ? styles.selected : ''}`}
      onClick={onClick}
    >
      <span className={styles['date-range-button-label']}>{label}</span>
      <span className={styles['date-range-button-date']}>
        {formatDate(from)} - {formatDate(to)}
      </span>
    </div>
  );
};

DateButton.propTypes = {
  selected: PropTypes.bool,
  type: PropTypes.string,
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  value: PropTypes.object,
  setFormValues: PropTypes.func,
};

DateButton.defaultProps = {
  selected: false,
  type: undefined,
  start: undefined,
  end: undefined,
  label: undefined,
  value: undefined,
  setFormValues: undefined,
};
