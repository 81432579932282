import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useLayout } from 'context/layoutContext';

import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ContributionItem } from '../ContributionItem';

import styles from './DetailsCollection.module.scss';

export const BookMarksDetailsCollection = ({
  collection,
  contributions,
  selectedContributions,
  toggleSelectedCollectionItem,
  editCollection,
}) => {
  const { isDesktop } = useLayout();
  const { collectionsLoading } = useCollections();

  if (collectionsLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {contributions.length === 0 && (
        <section className={styles['no-profiles']}>
          No contributions found. Please add a contribution / change your search criteria.
        </section>
      )}
      <Grid columns={isDesktop ? 2 : 1}>
        {contributions?.map((contribution, index) => (
          <GridItem key={index} colSpan={1}>
            <ContributionItem
              collectionID={collection?.collectionID}
              contribution={contribution}
              editCollection={editCollection}
              onClick={toggleSelectedCollectionItem}
              selected={
                selectedContributions.findIndex(
                  selectedCont =>
                    selectedCont.contributionId === contribution.contributionId &&
                    selectedCont.profileId === contribution.profileId,
                ) > -1
              }
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

BookMarksDetailsCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  contributions: PropTypes.array,
  selectedContributions: PropTypes.array,
  toggleSelectedCollectionItem: PropTypes.func,
  editCollection: PropTypes.bool,
};

BookMarksDetailsCollection.defaultProps = {
  contributions: [],
  selectedContributions: [],
  toggleSelectedCollectionItem: undefined,
  editCollection: false,
};
