import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const ExternalLinkContext = createContext({});

const ExternalLinkProvider = props => {
  const currentDomain = document.domain;
  const domain = process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI.substring(
    process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI.indexOf('://') + 3,
  );
  // Without port needs to be in here to cater for Local Dev
  const domainWithoutPort = domain.substring(
    0,
    domain.lastIndexOf(':') > 0 ? domain.indexOf(':') : domain.length,
  );
  const [showExternalLinkDialog, setShowExternalLinkDialog] = useState(false);
  const [externalLinkData, setExternalLinkData] = useState({});

  if (currentDomain !== domainWithoutPort) {
    window.location.href = process.env.REACT_APP_DEFAULT_AZURE_AUTH_REDIRECT_URI;
  }

  const openExternalLink = useCallback(({ href, target }) => {
    setExternalLinkData({ href, target });
    setShowExternalLinkDialog(true);
  }, []);

  const toggleExternalLinkDialog = useCallback(() => {
    setShowExternalLinkDialog(!showExternalLinkDialog);
  }, [showExternalLinkDialog]);

  const value = useMemo(
    () => ({
      showExternalLinkDialog,
      externalLinkData,
      openExternalLink,
      toggleExternalLinkDialog,
    }),
    [showExternalLinkDialog, externalLinkData, openExternalLink, toggleExternalLinkDialog],
  );

  return <ExternalLinkContext.Provider value={value} {...props} />;
};

const useExternalLink = () => useContext(ExternalLinkContext);

export { ExternalLinkProvider, useExternalLink };
