import { NotFoundTemplate } from './NotFoundTemplate';

export const ProjectNotFound = () => {
  return (
    <NotFoundTemplate
      title="You do not have access to this project."
      message="If you feel this is in error, please contact your administrator."
      homeButton
    />
  );
};
