import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import styles from './GridItem.module.scss';

const GridItem = ({
  colSpan,
  colSpanMobile,
  colSpanTablet,
  colSpanDesktop,
  colSpanLargeDesktop,
  rowSpan,
  rowSpanMobile,
  rowSpanTablet,
  rowSpanDesktop,
  rowSpanLargeDesktop,
  children,
  colStart,
  colEnd,
  colStartMobile,
  colEndMobile,
  colStartTablet,
  colEndTablet,
  colStartDesktop,
  colEndDesktop,
  colStartLargeDesktop,
  colEndLargeDesktop,
  rowStart,
  rowEnd,
  rowStartMobile,
  rowEndMobile,
  rowStartTablet,
  rowEndTablet,
  rowStartDesktop,
  rowEndDesktop,
  rowStartLargeDesktop,
  rowEndLargeDesktop,
  className,
  fixedWidth,
}) => {
  const [width, setWidth] = useState();
  const { layoutChanged } = useLayout();
  const cellRef = useRef();

  useEffect(() => {
    setWidth(cellRef?.current?.offsetWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutChanged, JSON.stringify(cellRef.current?.offsetWidth)]);

  return (
    <section
      ref={cellRef}
      // eslint-disable-next-line max-len
      className={` 
      ${colSpan ? styles[`col-span-${colSpan}`] : ''}  
      ${colSpanMobile ? styles[`col-span-mobile-${colSpanMobile}`] : ''} 
      ${colSpanTablet ? styles[`col-span-tablet-${colSpanTablet}`] : ''} 
      ${colSpanDesktop ? styles[`col-span-desktop-${colSpanDesktop}`] : ''} 
      ${colSpanLargeDesktop ? styles[`col-span-large-desktop-${colSpanLargeDesktop}`] : ''} 
      ${rowSpan ? styles[`row-span-${rowSpan}`] : ''}  
      ${rowSpanMobile ? styles[`row-span-mobile-${rowSpanMobile}`] : ''} 
      ${rowSpanTablet ? styles[`row-span-tablet-${rowSpanTablet}`] : ''} 
      ${rowSpanDesktop ? styles[`row-span-desktop-${rowSpanDesktop}`] : ''}  
      ${rowSpanLargeDesktop ? styles[`row-span-large-desktop-${rowSpanLargeDesktop}`] : ''}  
      ${colStart ? styles[`col-${colStart}-${colEnd}`] : ''} 
      ${colStartMobile ? styles[`col-mobile-${colStartMobile}-${colEndMobile}`] : ''} 
      ${colStartTablet ? styles[`col-tablet-${colStartTablet}-${colEndTablet}`] : ''} 
      ${colStartDesktop ? styles[`col-desktop-${colStartDesktop}-${colEndDesktop}`] : ''} 
      ${
        colStartLargeDesktop
          ? styles[`col-large-desktop-${colStartLargeDesktop}-${colEndLargeDesktop}`]
          : ''
      }  
      ${rowStart ? styles[`row-${rowStart}-${rowEnd}`] : ''}  
      ${rowStartMobile ? styles[`row-mobile-${rowStartMobile}-${rowEndMobile}`] : ''}  
      ${rowStartTablet ? styles[`row-tablet-${rowStartTablet}-${rowEndTablet}`] : ''}  
      ${rowStartDesktop ? styles[`row-desktop-${rowStartDesktop}-${rowEndDesktop}`] : ''} ${
        rowStartLargeDesktop
          ? styles[`row-large-desktop-${rowStartLargeDesktop}-${rowEndLargeDesktop}`]
          : ''
      }
      ${className || ''}
      `}
    >
      <section style={{ height: '100%', width: fixedWidth ? width : 'auto' }}>{children}</section>
    </section>
  );
};

GridItem.propTypes = {
  colSpan: PropTypes.number,
  colStart: PropTypes.number,
  colEnd: PropTypes.number,
  colStartMobile: PropTypes.number,
  colEndMobile: PropTypes.number,
  colStartTablet: PropTypes.number,
  colEndTablet: PropTypes.number,
  colStartDesktop: PropTypes.number,
  colEndDesktop: PropTypes.number,
  colSpanMobile: PropTypes.number,
  colSpanTablet: PropTypes.number,
  colSpanDesktop: PropTypes.number,
  colSpanLargeDesktop: PropTypes.number,
  rowSpan: PropTypes.number,
  rowSpanMobile: PropTypes.number,
  rowSpanTablet: PropTypes.number,
  rowSpanDesktop: PropTypes.number,
  rowSpanLargeDesktop: PropTypes.number,
  rowStart: PropTypes.number,
  rowEnd: PropTypes.number,
  rowStartMobile: PropTypes.number,
  rowEndMobile: PropTypes.number,
  rowStartTablet: PropTypes.number,
  rowEndTablet: PropTypes.number,
  rowStartDesktop: PropTypes.number,
  rowEndDesktop: PropTypes.number,
  colStartLargeDesktop: PropTypes.number,
  colEndLargeDesktop: PropTypes.number,
  rowStartLargeDesktop: PropTypes.number,
  rowEndLargeDesktop: PropTypes.number,
  className: PropTypes.string,
  fixedWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
};

GridItem.defaultProps = {
  colSpan: undefined,
  colStart: undefined,
  colEnd: undefined,
  colStartMobile: undefined,
  colEndMobile: undefined,
  colStartTablet: undefined,
  colEndTablet: undefined,
  colStartDesktop: undefined,
  colEndDesktop: undefined,
  colSpanMobile: undefined,
  colSpanTablet: undefined,
  colSpanDesktop: undefined,
  colSpanLargeDesktop: undefined,
  rowSpan: undefined,
  rowSpanMobile: undefined,
  rowSpanTablet: undefined,
  rowSpanDesktop: undefined,
  rowSpanLargeDesktop: undefined,
  rowStart: undefined,
  rowEnd: undefined,
  rowStartMobile: undefined,
  rowEndMobile: undefined,
  rowStartTablet: undefined,
  rowEndTablet: undefined,
  rowStartDesktop: undefined,
  rowEndDesktop: undefined,
  colStartLargeDesktop: undefined,
  colEndLargeDesktop: undefined,
  rowStartLargeDesktop: undefined,
  rowEndLargeDesktop: undefined,
  className: undefined,
  fixedWidth: false,
  children: undefined,
};

export default GridItem;
