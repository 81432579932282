import { isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';

import { MobileFiltersContainer } from 'common/filtersBar/filters/Mobile/MobileFiltersContainer';
import KwelloLogo from 'common/Logo/kwelloLogo';

import styles from './MobileNavBar.module.scss';

import { MobileHeaderMenu } from './MobileHeaderMenu';

export const MobileNavBar = ({ feedScrollDirection, hideFilters }) => {
  if (isTablet) {
    return null;
  }
  return (
    <div className={`${styles.header} ${feedScrollDirection === 'up' ? styles.fixedToTop : ''} `}>
      <nav className={styles.container}>
        <div className={styles.menuIcon}>
          <MobileHeaderMenu />
          <KwelloLogo className={styles['kwello-header-logo']} />
        </div>
        {!hideFilters && <MobileFiltersContainer />}
      </nav>
    </div>
  );
};

MobileNavBar.propTypes = {
  feedScrollDirection: PropTypes.string,
  hideFilters: PropTypes.bool,
};

MobileNavBar.defaultProps = { feedScrollDirection: 'down', hideFilters: false };
