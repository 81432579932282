import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import { MODULE_KEYS } from 'constants';
import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ModuleItem } from './ModuleItem';

export const GkpModulePicker = ({ setIsExpanded }) => {
  const history = useHistory();
  const { currentPage } = useCurrentPage();
  const { gkpProjects, fetchProjects, getProjectDisplayName } = useUser();
  const { pathname } = useLocation();

  const projectId = pathname.split('/')[2];

  useEffect(() => {
    fetchProjects(MODULE_KEYS.gkp);
  }, [fetchProjects]);

  const navigate = project => {
    setIsExpanded(-1);
    history.push(`${ROUTE.globalKolPlanningUrl}/${project}/${ROUTE.dashboard}`);
  };

  if (gkpProjects?.length > 0) {
    return gkpProjects.map((project, index) => (
      <ModuleItem
        tabIndex={index}
        key={project.projectID}
        name={getProjectDisplayName(project)}
        selected={
          currentPage(ROUTE.globalKolPlanningUrl) && project.projectID.toString() === projectId
        }
        onClick={() => navigate(project.projectID)}
      />
    ));
  }

  return <LoaderSpinner color="#FFF" />;
};

GkpModulePicker.propTypes = {
  setIsExpanded: PropTypes.func.isRequired,
};
