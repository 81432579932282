import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { AddListModal } from 'Pages/Lists/Modals';

import { EmptyListIcon } from '../EmptyListIcon';
import { ListItem } from '../ListItem/ListItem';

import styles from './ListCollection.module.scss';

export const ListCollection = ({ lists, onClick, selectedList, selectedProfiles, hideCount }) => {
  const { openModal } = useModal();

  return (
    <>
      {lists?.length === 0 && (
        <>
          <div className={styles['no-lists']}>No Lists found</div>
          <section className={styles.empty} onClick={() => openModal(<AddListModal />)}>
            <EmptyListIcon />{' '}
            <div className={styles['message-container']}>
              <span className={styles.title}>Create List</span>
              <span className={styles.message}>
                Add profiles to lists and set alerts to get regular updates
              </span>
            </div>
          </section>
        </>
      )}
      {lists?.map((list, index) => (
        <ListItem
          list={list}
          key={list.listId}
          onClick={onClick ? () => onClick({ index, list }) : null}
          selected={selectedList === index}
          selectedProfiles={selectedProfiles}
          hideCount={hideCount}
        />
      ))}
    </>
  );
};

ListCollection.propTypes = {
  lists: PropTypes.array,
  onClick: PropTypes.func,
  selectedList: PropTypes.number,
  selectedProfiles: PropTypes.array,
  hideCount: PropTypes.bool,
};

ListCollection.defaultProps = {
  lists: [],
  onClick: undefined,
  selectedList: 0,
  selectedProfiles: [],
  hideCount: false,
};
