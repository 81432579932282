import CompliancePage from './CompliancePage';

const CookieNotice = () => (
  <CompliancePage title="Cookie notice" downloadLink="/static/cookie-notice.pdf">
    <h6>Privacy overview </h6>
    <p>
      When you visit any web site, it may store or retrieve information on your browser, mostly in
      the form of cookies. This information might be about you, your preferences or your device and
      is mostly used to make the site work as you expect it to. The information does not usually
      directly identify you, but it can give you a more personalized web experience.
    </p>
    <p>
      This website uses cookies to improve your experience while you navigate through the website.
      Out of these, the cookies that are categorized as necessary are stored on your browser as they
      are essential for the working of basic functionalities of the website. We also use third-party
      cookies that help us analyze and understand how you use this website. These cookies will be
      stored in your browser only with your consent. You also have the option to opt-out of these
      cookies. But opting out of some of these cookies may affect your browsing experience.
    </p>
    <p>
      In some cases, data obtained from cookies is shared with third parties and may be considered a
      “sale of personal information” under the California Consumer Privacy Act (CCPA). You can
      exercise your right to opt-out of that sharing at any time by disabling cookies.
    </p>
    <h6>Necessary cookies </h6>
    <p>
      These cookies are necessary for the website to function and cannot be switched off in our
      systems. They are usually only set in response to actions made by you which amount to a
      request for services, such as setting your privacy preferences, logging in or filling in
      forms. You can set your browser to block or alert you about these cookies, but some parts of
      the site will not then work. These cookies do not store any personally identifiable
      information.
    </p>
    <h6>Analytic cookies</h6>
    <p>
      These cookies allow us to count visits and traffic sources, so we can measure and improve the
      performance of our site. They help us know which pages are the most and least popular and see
      how visitors move around the site. All information these cookies collect is aggregated and
      therefore anonymous. If you do not allow these cookies, we will not know when you have visited
      our site.
    </p>
    <h6>Functional cookies</h6>
    <p>
      These cookies enable the website to provide enhanced functionality and personalisation. They
      may be set by us or by third party providers whose services we have added to our pages. If you
      do not allow these cookies then some or all of these services may not function properly.
    </p>
    <h6>Targeting cookies</h6>
    <p>
      These cookies may be set through our site by our advertising partners. They may be used by
      those companies to build a profile of your interests and show you relevant adverts on other
      sites. They do not store directly personal information, but are based on uniquely identifying
      your browser and internet device. If you do not allow these cookies, you will experience less
      targeted advertising.
    </p>
  </CompliancePage>
);

export default CookieNotice;
