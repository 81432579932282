import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import inputStyles from './Inputs.module.scss';

export const InputText = ({
  className,
  type,
  name,
  label,
  placeholder,
  required,
  error,
  maxLength,
}) => {
  const { isInsightsCapture } = useCurrentPage();
  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field name={name}>
          {({ field: { onChange, value, ...fieldProps }, form: { setFieldValue } }) => (
            <>
              <input
                className={`${inputStyles.input} ${error ? inputStyles.error : ''} ${
                  isInsightsCapture ? inputStyles.insights : ''
                }`}
                type={type}
                name={name}
                value={value}
                onChange={event => setFieldValue(name, event.target.value)}
                placeholder={placeholder}
                {...fieldProps}
              />
              <div className={inputStyles['input-message-container']}>
                <div className={inputStyles['error-message-container']}>
                  <ErrorMessage name={name}>
                    {message => <span className={inputStyles.error}>{message}</span>}
                  </ErrorMessage>
                  {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
                </div>
                {maxLength && (
                  <div className={inputStyles['counter-message-container']}>
                    {value?.length} / {maxLength}
                  </div>
                )}
              </div>
            </>
          )}
        </Field>
      </label>
    </div>
  );
};

InputText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  maxLength: PropTypes.number,
};

InputText.defaultProps = {
  className: '',
  type: 'text',
  label: undefined,
  placeholder: undefined,
  required: false,
  error: undefined,
  maxLength: undefined,
};
