import PropTypes from 'prop-types';

import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { AlertMenuItem } from 'common/MenuItems/AlertMenuItem';

export const AlertButtonMenu = ({
  notifFrequency,
  profileId,
  listId,
  EventID,
  onSuccess,
  listName,
  setAlert,
  noHeader,
  collectionID,
  buttonLabel,
  subMenuLabel,
  disabled,
  className,
  searchId,
  collectionType,
}) => {
  const { defaultNotifFrequency } = useGlobalKolPlanningContext();
  const menuItem = {
    type: 'component',
    component: (
      <AlertMenuItem
        currentValue={notifFrequency}
        listId={listId}
        profileId={profileId}
        EventID={EventID}
        onSuccess={onSuccess}
        listName={listName}
        setAlert={setAlert}
        noHeader={noHeader}
        collectionID={collectionID}
        searchId={searchId}
        collectionType={collectionType}
      />
    ),
  };

  return (
    <DropDownMenu
      alignRight
      clickElement={
        <Button
          className={className}
          buttonType="transparent"
          icon={
            ['None', 'none', 0, null, defaultNotifFrequency].includes(notifFrequency)
              ? 'alertOff'
              : 'alertOn'
          }
          disabled={disabled}
          hideArrow
        >
          {buttonLabel}
        </Button>
      }
      menuItems={[
        subMenuLabel
          ? {
              label: subMenuLabel,
              subMenuContent: [menuItem],
            }
          : menuItem,
      ]}
    />
  );
};

AlertButtonMenu.propTypes = {
  notifFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  profileId: PropTypes.string,
  listId: PropTypes.string,
  EventID: PropTypes.string,
  onSuccess: PropTypes.func,
  setAlert: PropTypes.func,
  listName: PropTypes.string,
  noHeader: PropTypes.bool,
  collectionID: PropTypes.string,
  buttonLabel: PropTypes.string,
  subMenuLabel: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  searchId: PropTypes.string,
  collectionType: PropTypes.string,
};
AlertButtonMenu.defaultProps = {
  className: null,
  notifFrequency: undefined,
  profileId: undefined,
  listId: undefined,
  EventID: undefined,
  onSuccess: undefined,
  setAlert: undefined,
  listName: undefined,
  noHeader: false,
  collectionID: undefined,
  buttonLabel: undefined,
  subMenuLabel: undefined,
  disabled: false,
  searchId: undefined,
  collectionType: undefined,
};
