import Card from 'react-bootstrap/Card';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  padding-right: 3px;
  z-index: 2;
`;

export const StyledAvatar = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0 0.5rem 0 0;
  cursor: pointer;
`;

export const IsEditWrapper = styled.div`
  position: absolute;
  top: -1px;
  right: -1px;
  border: 1px solid #dedede;
  border-radius: 0 var(--border-radius-sm);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  ${({ selected }) =>
    selected
      ? `
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);

      & > svg {
        & > path {
          stroke: #ffffff;
        }
      }
    `
      : ''};

  width: 24px;
  height: 24px;
`;

export const StyledCard = styled(Card)`
  &.selected.with-select-indicator {
    border: 1px solid var(--primary-color);
  }

  &.with-select-indicator {
    cursor: pointer;
  }
`;

export const StyledFeedChartContainer = styled.div`
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 0;
  height: 96px;
  background: white;
  opacity: 1;
  -webkit-box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
  -moz-box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
  box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
`;

export const StyledFeedChartTitle = styled.div`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 0.5rem;
  opacity: 1;
  background: white;
  -webkit-box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
  -moz-box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
  box-shadow: 0px -3px 12px rgb(50 50 50 / 40%);
`;
