import { useEffect, useState } from 'react';

import { useFilters } from 'context/filtersContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

const useSearchService = () => {
  const { setFilter } = useFilters();
  const { filters } = useFilters();
  const filterKey = 'search';
  const { currentProject } = useSocialMonitoring();
  const filterVariable = JSON.stringify(currentProject);
  const [selectedItemValues, setSelectedItemValues] = useState(() =>
    (filters.search || []).map(item => ({ id: item, reference: item })),
  );

  useEffect(() => {
    if (!filters?.search?.length) setSelectedItemValues([]);
  }, [filters]);
  const [searchText, setSearchText] = useState('');

  const handleSearch = query => {
    setSearchText(query);
  };

  useEffect(() => {
    handleSearch('');
  }, [filterVariable]);

  const removeItem = value => {
    setSelectedItemValues(prev => prev.filter(x => x.id !== value));
  };

  const onEnterKeyDown = e => {
    if (filterKey === 'search' && e.key === 'Enter' && searchText.trim()) {
      e?.preventDefault();
      const searchTextValue =
        searchText.startsWith('"') && searchText.endsWith('"')
          ? searchText.replace(/^"(.*)"$/, '$1')
          : searchText;
      setSearchText('');
      const searchExists =
        selectedItemValues.findIndex(s => s.id.toLowerCase() === searchTextValue.toLowerCase()) >
        -1;
      if (searchExists) return;
      setSelectedItemValues(prev => [
        ...new Set([...prev, { id: searchTextValue, reference: searchTextValue }]),
      ]);
    }
  };

  const resetSearch = e => {
    e?.preventDefault();
    setSearchText('');
    setSelectedItemValues([]);
    setFilter({ [filterKey]: [] });
  };

  const onSearch = event => {
    event?.preventDefault();
    const items = selectedItemValues.map(item => item.reference);
    if (searchText) {
      if (searchText.startsWith('"') && searchText.endsWith('"')) {
        items.push(searchText.replace(/^"(.*)"$/, '$1').trim());
        setSearchText(searchText.replace(/^"(.*)"$/, '$1').trim());
      } else {
        items.push(searchText.trim());
      }
    }
    setFilter({ [filterKey]: items });
  };

  return {
    resetSearch,
    onEnterKeyDown,
    onSearch,
    handleSearch,
    removeItem,
    searchText,
    selectedItemValues,
    setSearchText,
  };
};

export default useSearchService;
