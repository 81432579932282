import { isDesktop, isMobileOnly } from 'react-device-detect';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';

import styles from './InputGroupButtons.module.scss';
import inputStyles from './Inputs.module.scss';

const InputButton = props => {
  const { type, label, value, field, form, childValues, onValueChangedCallback, standardInput } =
    props;
  const { isInsightsCapture } = useCurrentPage();
  const setValue = fieldValue => {
    if (fieldValue?.length === 0) {
      // set empty arrays as '' so that the formik validation works
      return form.setFieldValue(field.name, '');
    }
    return form.setFieldValue(field.name, fieldValue);
  };

  const onClick = event => {
    event.preventDefault();
    event.stopPropagation();

    if (childValues) {
      const filteredValues = Array.isArray(form.values[field.name])
        ? form.values[field.name]?.filter(val => val !== field.value)
        : [field.value];

      const addedValues = Array.isArray(form.values[field.name])
        ? [field.value, ...form.values[field.name]]
        : [field.value, form.values[field.name]];

      onValueChangedCallback({
        selectedParents: field.checked ? filteredValues : addedValues,
        parentId: field.name,
        existingValues: form.values,
        setFormValue: form.setFieldValue,
      });
    }

    if (type === 'radio') {
      if (form.values[field.name] !== value) {
        return setValue(value);
      }
      return setValue('');
    }

    if (form.values[field.name]?.includes(value)) {
      return setValue(form.values[field.name]?.filter(item => item !== value));
    }

    if (!form.values[field.name]) {
      return setValue([value]);
    }

    return setValue([...form.values[field.name], value]);
  };

  if (standardInput) {
    const desktopStyle = field.checked ? 'input-item-desktop-selected' : 'input-item-desktop';
    return (
      <div
        className={!isMobileOnly ? styles[desktopStyle] : styles['input-item']}
        onClick={onClick}
      >
        {!isMobileOnly && <div className={styles['desktop-label']}>{label}</div>}
        {!isMobileOnly && (
          <Icon name={field.checked ? 'tickSelector' : 'emptySelector'} size={32} />
        )}
        {type === 'radio' && isMobileOnly && (
          <Icon name={field.checked ? 'radioButtonSelected' : 'radioButton'} size={18} />
        )}

        {type === 'checkbox' && isMobileOnly && (
          <Icon
            name={field.checked ? 'tickedCheckBox' : 'emptyCheckBox'}
            className={field.checked ? styles['color-check'] : ''}
            size={18}
          />
        )}

        {isMobileOnly && <div>{label}</div>}
      </div>
    );
  }

  if (isInsightsCapture && isDesktop) {
    return (
      <Button
        size="small"
        buttonType={field.checked ? 'insights-checked' : 'filter'}
        onClick={onClick}
      >
        {label}
      </Button>
    );
  }

  return (
    <Button size="small" buttonType={field.checked ? 'filter-primary' : 'filter'} onClick={onClick}>
      {label}
    </Button>
  );
};

InputButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  value: PropTypes.any,
  field: PropTypes.object,
  form: PropTypes.object,
  childValues: PropTypes.array,
  onValueChangedCallback: PropTypes.func,
  standardInput: PropTypes.bool,
};

InputButton.defaultProps = {
  label: '',
  type: 'checkbox',
  value: undefined,
  field: {},
  form: {},
  childValues: undefined,
  onValueChangedCallback: undefined,
  standardInput: false,
};

export const InputGroupButtons = ({
  className,
  type,
  groupItems,
  required,
  error,
  label,
  name,
  onValueChangedCallback,
  standardInput,
}) => {
  return (
    <div
      role="group"
      aria-labelledby={`${type}-group`}
      className={`
    ${inputStyles['field-container']} 
    ${required ? inputStyles.required : ''} 
    ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <div className={standardInput ? '' : styles['input-group']}>
          {groupItems.map((item, index) => (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label
              htmlFor={name}
              className={standardInput ? '' : styles['item-container']}
              key={`${name}-${index}`}
            >
              <Field
                key={`${item.label}-${index}`}
                className={error ? styles.error : ''}
                id={name}
                name={name}
                label={item.label}
                type={type}
                value={item.value}
                standardInput={standardInput}
                childValues={item.childValues}
                onValueChangedCallback={onValueChangedCallback}
                component={InputButton}
              />
            </label>
          ))}
        </div>
        <ErrorMessage name={name}>
          {message => <span className={inputStyles.error}>{message}</span>}
        </ErrorMessage>
        {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
      </label>
    </div>
  );
};

InputGroupButtons.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['radio', 'checkbox']),
  groupItems: PropTypes.array,
  required: PropTypes.bool,
  error: PropTypes.string,
  name: PropTypes.string,
  onValueChangedCallback: PropTypes.func,
  standardInput: PropTypes.bool,
};

InputGroupButtons.defaultProps = {
  label: undefined,
  className: '',
  type: 'checkbox',
  groupItems: [],
  required: false,
  error: undefined,
  name: undefined,
  onValueChangedCallback: undefined,
  standardInput: false,
};
