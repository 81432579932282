import { useConfirm } from 'context/confirmationContext';
import { useLists } from 'context/listsContext';

export const DeleteListMenuItem = ({ list }) => {
  const { confirm } = useConfirm();
  const { deleteList } = useLists();

  const removeList = () => {
    confirm({
      title: 'Delete list',
      text: `Do you want to delete the list "${list?.listName}"?`,
      onConfirm: () => {
        deleteList(list?.listId);
      },
    });
  };

  return {
    label: 'Delete List',
    className: 'text-danger',
    onClick: removeList,
  };
};
