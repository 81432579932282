import { useState } from 'react';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import { formatDate } from 'utils/dateHelper';
import { useNavigate } from 'utils/hooks';

import Button from 'common/button/Button';
import { AlertButton } from 'common/MenuItems';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './EventItem.module.scss';

export const EventItem = ({ collectionID, event, selected, editCollection, onClick }) => {
  const {
    eventId,
    eventTitle,
    businessOwner,
    startDate,
    notifFrequency,
    typeOfEvent,
    eventCountry,
  } = event;
  const [alertFrequency, setAlertFrequency] = useState(notifFrequency);
  const { removeItemFromCollection } = useCollections();
  const { eventName } = useGlobalKolPlanningContext();

  const { navigateToEventDetail } = useNavigate();

  return (
    <section
      className={`
    ${styles.container} 
    ${editCollection ? styles.pointer : ''} 
    ${selected ? styles.selected : ''}
  `}
      onClick={editCollection ? () => onClick(eventId) : undefined}
    >
      <div className={styles['event-header']}>
        <span
          className={styles.title}
          onClick={() => {
            if (!editCollection) {
              navigateToEventDetail(eventId);
            }
          }}
        >
          {eventTitle}
        </span>

        <AlertButton
          notifFrequency={alertFrequency}
          EventID={eventId}
          onSuccess={frequency => setAlertFrequency(frequency)}
        />

        <KwelloTooltip position="top" content={`Remove ${eventName} from collection`}>
          <span>
            <Button
              buttonType="warning"
              hideArrow
              onClick={() =>
                removeItemFromCollection({
                  collectionID: [collectionID],
                  events: [eventId],
                })
              }
            >
              Remove
            </Button>
          </span>
        </KwelloTooltip>
      </div>

      <div className={styles['business-owner']}>Business Owner: {businessOwner}</div>
      <div className={styles['detail-row']}>
        <span className={styles['detail-label']}>Start Date</span>
        <span className={styles['detail-value']}>{formatDate(startDate)}</span>
      </div>
      <div className={styles['detail-row']}>
        <span className={styles['detail-label']}>{`Type of ${eventName}`}</span>
        <span className={styles['detail-value']}>{typeOfEvent}</span>
      </div>
      <div className={styles['detail-row']}>
        <span className={styles['detail-label']}>{`${eventName} Country`}</span>
        <span className={styles['detail-value']}>{eventCountry}</span>
      </div>
    </section>
  );
};

EventItem.propTypes = {
  collectionID: PropTypes.string,
  event: PropTypes.object,
  selected: PropTypes.bool,
  editCollection: PropTypes.bool,
  onClick: PropTypes.func,
};

EventItem.defaultProps = {
  collectionID: '',
  event: {},
  selected: false,
  editCollection: false,
  onClick: undefined,
};
