import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useOutsideAlerter from 'utils/hooks/useOutsideAlerter';
import useSearchService from 'utils/hooks/useSearchService';

import Button from 'common/button/Button';
import Scrollable from 'common/scrollable/Scrollable';
import { AccordionToggleWrapper } from 'common/wrappers';

import styles from './SearchFilter.module.scss';

export const SearchFilter = ({ onAdvancedSearchClick }) => {
  const searchFiltersRef = useRef(null);

  const {
    resetSearch,
    onEnterKeyDown,
    onSearch,
    handleSearch,
    removeItem,
    setSearchText,
    searchText,
    selectedItemValues,
  } = useSearchService();

  const clickedOutside = useOutsideAlerter(searchFiltersRef, () => setSearchText(''));

  useEffect(() => {
    if (clickedOutside) clickedOutside();
  }, [clickedOutside]);

  return (
    <div className={styles['search-container']} ref={searchFiltersRef}>
      <div className="pb-3 px-3">
        {selectedItemValues && selectedItemValues.length > 0 && (
          <>
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="font-weight-bold ">Selected items</div>
              </div>
              <Scrollable maxHeight={130} maxWidth={390} noShadow largeThumb>
                {selectedItemValues.map((x, index) => (
                  <div className="flex-fill" key={index}>
                    <Button
                      buttonType="secondary"
                      size="small"
                      key={x.reference}
                      block
                      className={`${x.reference.length >= 35 ? styles['button-text'] : ''}`}
                      secondaryAction={() => removeItem(x.id)}
                    >
                      {x.reference}
                    </Button>
                  </div>
                ))}
              </Scrollable>
            </div>
            <hr />
          </>
        )}

        <Form.Control
          type="text"
          onChange={e => handleSearch(e.target.value)}
          onKeyDown={onEnterKeyDown}
          disabled={selectedItemValues.length > 20}
          value={searchText}
          className="mb-3"
          maxLength={150}
          placeholder={
            selectedItemValues.length > 20
              ? 'Maximum Search Items Reached'
              : 'Type to search then press enter'
          }
        />

        <hr />
        <div className="d-flex justify-content-between align-items-center">
          <Button buttonType="secondary" onClick={resetSearch}>
            Reset
          </Button>
          {onAdvancedSearchClick && (
            <Button buttonType="secondary" onClick={onAdvancedSearchClick}>
              Boolean Search
            </Button>
          )}
          <AccordionToggleWrapper eventKey="0" onSubmit={onSearch}>
            Apply
          </AccordionToggleWrapper>
        </div>
      </div>
    </div>
  );
};

SearchFilter.propTypes = {
  onAdvancedSearchClick: PropTypes.func,
};

SearchFilter.defaultProps = { onAdvancedSearchClick: null };
