import styled from 'styled-components';

export const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5000;
  background-color: #00000099;

  body {
    overflow: hidden !important;
  }
`;

export const DialogContainer = styled.div`
  box-shadow: 0px 10px 30px 0px #3333334d;
  width: ${({ width }) => width || '33%'};
  background-color: #ffffff;
  border-radius: var(--border-radius-sm);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 0 0.5rem 0;
`;

export const Title = styled.div`
  font-family: primary-font;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  flex-grow: 0;
  margin: 0 0 0.75rem 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ withTitle }) => (withTitle ? 'border-top: 1px solid #dedede;' : '')}

  border-bottom: 1px solid #dedede;
  padding: 1.5rem 0;
  min-height: 50px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  margin: 0.75rem 0 0 0;
`;
