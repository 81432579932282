import { useHistory } from 'react-router';

import { useConfirm } from 'context/confirmationContext';

import Icon from 'common/Icon/Icon';

import styles from './Logout.module.scss';

export const Logout = () => {
  const history = useHistory();
  const { confirm } = useConfirm();

  const handleLogout = () => {
    history.push('/logout');
  };

  const confirmLogout = () => {
    confirm({
      title: 'Sign Out',
      text: 'Are you sure you want to Sign Out?',
      onConfirm: handleLogout,
    });
  };
  return (
    <div onClick={confirmLogout} className={styles.logout}>
      <Icon name="logout" />
      <span>Sign Out</span>
    </div>
  );
};
