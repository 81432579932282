import PropTypes from 'prop-types';

import Button from 'common/button/Button';

import styles from './MobilePageHeaderFooter.module.scss';

export const MobilePageHeader = ({ onCancel, onApply, title, actionLabel }) => {
  return (
    <section className={styles['mobile-header']}>
      <Button buttonType="secondary" onClick={onCancel}>
        Cancel
      </Button>
      <h3>{title}</h3>
      <Button onClick={onApply}>{actionLabel}</Button>
    </section>
  );
};

MobilePageHeader.propTypes = {
  onCancel: PropTypes.func,
  onApply: PropTypes.func,
  title: PropTypes.string,
  actionLabel: PropTypes.string,
};

MobilePageHeader.defaultProps = {
  onCancel: undefined,
  onApply: undefined,
  title: undefined,
  actionLabel: undefined,
};
