import PropTypes from 'prop-types';

import OverlayContainer from 'common/overlay/OverlayContainer';

const FullscreenComponent = ({ fullscreen, children }) =>
  fullscreen ? <OverlayContainer show>{children}</OverlayContainer> : children;

FullscreenComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  fullscreen: PropTypes.bool,
};

FullscreenComponent.defaultProps = {
  children: undefined,
  fullscreen: false,
};

export default FullscreenComponent;
