import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import {
  IconWrapper,
  SearchContainer,
  StyledFormControl,
  StyledFormControlWhite,
  StyledInputGroup,
  StyledInputGroupWhite,
} from './SearchBar.StyledComponents';

const SearchBar = ({
  className,
  onChange,
  value,
  placeholder,
  hideSearchIcon,
  whiteBackground,
  clearSearch,
}) => (
  <SearchContainer className={className}>
    {whiteBackground ? (
      <StyledInputGroupWhite>
        {!hideSearchIcon && (
          <IconWrapper>{!hideSearchIcon && <Icon name="search" size="16" />}</IconWrapper>
        )}
        <StyledFormControlWhite
          autoFocus
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
          aria-describedby="prep"
        />
        {clearSearch && (
          <span onClick={clearSearch} style={{ marginRight: '0.5rem' }}>
            <Icon name="cross" size={16} />
          </span>
        )}
      </StyledInputGroupWhite>
    ) : (
      <StyledInputGroup>
        {!hideSearchIcon && (
          <IconWrapper>{!hideSearchIcon && <Icon name="search" size="16" />}</IconWrapper>
        )}
        <StyledFormControl
          autoFocus
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
          aria-describedby="prep"
        />
      </StyledInputGroup>
    )}
  </SearchContainer>
);

SearchBar.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hideSearchIcon: PropTypes.bool,
  whiteBackground: PropTypes.bool,
  clearSearch: PropTypes.func,
};

SearchBar.defaultProps = {
  className: undefined,
  placeholder: 'Search',
  hideSearchIcon: false,
  whiteBackground: false,
  clearSearch: undefined,
};

export default SearchBar;
