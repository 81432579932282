import { useExternalLink } from 'context/externalLinkContext';

import Dialog from 'common/dialog/Dialog';

import styles from './ExternalLinkDialog.module.scss';

const ExternalLinkDiolog = () => {
  const { showExternalLinkDialog, externalLinkData, toggleExternalLinkDialog } = useExternalLink();
  const { href, target = '_blank' } = externalLinkData;

  const cancelDialog = () => {
    toggleExternalLinkDialog();
  };

  const openLink = () => {
    toggleExternalLinkDialog();
    window.open(href, target, 'noopener, noreferrer');
  };

  return (
    <Dialog
      title="Confirm navigation"
      saveButtonLabel="Continue to website"
      show={showExternalLinkDialog}
      onCancel={cancelDialog}
      onComplete={openLink}
    >
      <span className={styles.text}>You are about to navigate to:</span>
      <code className={styles.url}>{href}</code>
      <span className={styles.text}>
        You will be leaving Kwello.com and we are not responsible for the content on external
        websites.
      </span>
    </Dialog>
  );
};

export default ExternalLinkDiolog;
