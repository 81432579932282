import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import { thirtyMinutes } from 'constants/reactQueryConfig';
import ROUTE from 'constants/route';
import { useApi, useGkpApi } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { toastNotify } from 'common/ToastNotification/ToastNotification';

const CollectionsContext = createContext({});

const CollectionsProvider = props => {
  const { projectId } = props;
  const [createCollectionLoading, setCreateCollectionLoading] = useState(false);
  const [addItemToCollectionLoading, setAddItemToCollectionLoading] = useState(false);
  const [updateCollectionLoading, setUpdateCollectionLoading] = useState(false);
  const [deleteCollectionLoading, setDeleteCollectionLoading] = useState(false);
  const [removeItemFromCollectionLoading, setRemoveItemFromCollectionLoading] = useState(false);
  const { currentPage, isSocialMonitoring } = useCurrentPage();
  const { call: gkpCall } = useGkpApi();
  const { call: apiCall } = useApi();
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);
  const { pathname } = useLocation();
  const { filterReferenceElements, loadingSocialReferences, setFilterReferenceElements } =
    useFilters();

  const { projectSecret, eventName } = useGlobalKolPlanningContext();
  const queryClient = useQueryClient();

  const api = useCallback(
    params => (isGkp ? gkpCall(params) : apiCall(params)),
    [apiCall, gkpCall, isGkp],
  );

  const urls = useMemo(() => {
    if (isGkp) {
      return {
        fetchCollections: '/GlobalKOLPlanningLists/GetGKPCollections',
        createCollection: '/GlobalKOLPlanningLists/CreateGKPEventCollection',
        updateCollection: '/GlobalKOLPlanningLists/UpdateGKPEventCollection',
        deleteCollection: '/GlobalKOLPlanningLists/DeleteGKPEventCollection',
        removeItems: '/GlobalKOLPlanningLists/RemoveEventFromCollection',
        addItemToCollection: '/GlobalKOLPlanningLists/AddEventToCollection',
        configureAlerts: '/GlobalKOLPlanningLists/ConfigureUserCollectionAlerts',
      };
    }

    return {
      createCollection: '/Bookmark/CreateCollection',
      updateCollection: '/bookmark/updatecollection',
      deleteCollection: '/bookmark/deletecollection',
      removeItems: '/Shared/RemoveBookmarks',
      addItemToCollection: '/Contribution/BookmarkContribution',
    };

    // Needs to update on path change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pathname, isGkp]);

  const fetchCollections = useCallback(async () => {
    return api({
      url: urls.fetchCollections,
      method: 'POST',
      data: { projectId },
    });
  }, [api, urls.fetchCollections, projectId]);

  const reactQueryKey = useMemo(
    () => ['collections', projectId, projectSecret, isGkp],
    [isGkp, projectId, projectSecret],
  );

  const { data: gkpCollections, isLoading: collectionsLoading } = useQuery({
    queryKey: reactQueryKey,
    queryFn: () => fetchCollections(),
    staleTime: thirtyMinutes,
    enabled: !!projectSecret,
  });

  const getCollections = useCallback(() => {
    if (isGkp) {
      return gkpCollections;
    }
    return filterReferenceElements.collections;
  }, [isGkp, gkpCollections, filterReferenceElements]);

  const refreshData = useCallback(() => {
    return queryClient.invalidateQueries(reactQueryKey);
  }, [reactQueryKey, queryClient]);

  const getContributionCollections = useCallback(
    mentions => {
      const contributionsIds = mentions?.map(mention => mention.contributionId);
      const profileIds = mentions?.map(mention => mention.profileId);
      return (
        getCollections()
          ?.filter(collection =>
            collection?.contributions?.some(
              contribution =>
                contributionsIds?.includes(contribution.contributionId) &&
                profileIds?.includes(contribution.profileId),
            ),
          )
          .map(collection => ({
            collectionID: collection.collectionID,
            collectionName: collection.collectionName,
          })) || []
      );
    },
    [getCollections],
  );

  const getEventCollections = useCallback(
    eventId =>
      getCollections()
        ?.filter(collection =>
          collection?.events?.some(event =>
            Array.isArray(eventId) ? eventId.includes(event.eventId) : event.eventId === eventId,
          ),
        )
        .map(collection => ({
          collectionID: collection?.collectionID,
          collectionName: collection?.collectionName,
        })),
    [getCollections],
  );

  const configureAlert = useCallback(
    async apiData => {
      try {
        const response = await api({
          method: 'PUT',
          url: urls.configureAlerts,
          data: {
            ...apiData,
            projectId,
          },
        });

        if (response) {
          toastNotify('success', `${eventName} collection alert updated`);
          queryClient.invalidateQueries(reactQueryKey);
        }
      } catch (error) {
        toastNotify('error', error.message);
      }
    },
    [api, urls.configureAlerts, projectId, queryClient, reactQueryKey, eventName],
  );

  const addItemToCollection = useCallback(
    async apiData => {
      setAddItemToCollectionLoading(true);
      try {
        const response = await api({
          method: currentPage(ROUTE.socialMonitoringUrl) ? 'POST' : 'PUT',
          url: urls.addItemToCollection,
          version: currentPage(ROUTE.socialMonitoringUrl) ? 2 : undefined,
          data: currentPage(ROUTE.socialMonitoringUrl)
            ? apiData?.map(dataItem => ({ ...dataItem, projectId }))
            : {
                ...apiData,
                projectId,
              },
          failedMessage: 'Failed to add item to collection',
        });

        if (response) {
          setAddItemToCollectionLoading(false);
          const { events } = apiData;

          const item =
            events?.length > 1 ||
            (!events && apiData?.find(data => data?.mentions)?.mentions?.length > 1)
              ? 'Items'
              : 'Item';

          toastNotify('success', `${item} added to collection(s)`);
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              collections: prevState.collections.map(collection => ({
                ...collection,
                contributions: response.find(r => r.collectionID === collection.collectionID)
                  ? response.find(r => r.collectionID === collection.collectionID)?.contributions
                  : collection.contributions,
              })),
            }));
          } else {
            refreshData();
          }
        }
      } catch (error) {
        toastNotify('error', error.message);
        setAddItemToCollectionLoading(false);
      }
    },
    [
      api,
      urls.addItemToCollection,
      projectId,
      refreshData,
      currentPage,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const createCollection = useCallback(
    async (data, contribution) => {
      setCreateCollectionLoading(true);
      try {
        const response = await api({
          method: 'PUT',
          url: urls.createCollection,
          data: {
            ...data,
            projectId,
          },
          failedMessage: 'Failed to create collection',
        });

        if (response) {
          toastNotify('success', 'Collection created');
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              collections: sortBy([...prevState.collections, response], collection =>
                collection.collectionName.toLowerCase(),
              ),
            }));
          }

          if (contribution && Object.keys(contribution).length) {
            const apiData = isGkp
              ? { collectionID: [response.collectionID], events: contribution.events }
              : [
                  {
                    mentions: contribution.mentions,
                    collectionID: response.collectionID,
                  },
                ];
            await addItemToCollection(apiData);
            if (!isSocialMonitoring) {
              refreshData();
            }
          }
        }

        setCreateCollectionLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setCreateCollectionLoading(false);
      }
    },
    [
      api,
      urls.createCollection,
      projectId,
      isGkp,
      addItemToCollection,
      refreshData,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const updateCollection = useCallback(
    async data => {
      setUpdateCollectionLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.updateCollection,
          data: {
            ...data,
            projectId,
          },
          failedMessage: 'Failed to update collection item',
        });

        if (response) {
          toastNotify('success', 'Collection updated');
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              collections: [
                ...prevState.collections.filter(
                  collection => collection.collectionID !== response.collectionID,
                ),
                response,
              ],
            }));
          }
          refreshData();
        }

        setUpdateCollectionLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setUpdateCollectionLoading(false);
      }
    },
    [
      api,
      urls.updateCollection,
      projectId,
      refreshData,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const deleteCollection = useCallback(
    async collectionID => {
      setDeleteCollectionLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.deleteCollection,
          data: { collectionID, projectId },
          failedMessage: 'Failed to delete collection item',
        });

        if (response) {
          toastNotify('success', 'Collection item deleted');

          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              collections: sortBy(
                filterReferenceElements?.collections?.filter(
                  collection => collection.collectionID !== collectionID,
                ),
                collection => collection.collectionName.toLowerCase(),
              ),
            }));
          } else {
            refreshData();
          }
        }
        setDeleteCollectionLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setDeleteCollectionLoading(false);
      }
    },
    [
      api,
      urls.deleteCollection,
      projectId,
      refreshData,
      filterReferenceElements,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const removeItemFromCollection = useCallback(
    async data => {
      setRemoveItemFromCollectionLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.removeItems,
          version: currentPage(ROUTE.socialMonitoringUrl) ? 2 : undefined,
          data: currentPage(ROUTE.socialMonitoringUrl)
            ? data?.map(dataItem => ({ ...dataItem, projectId }))
            : {
                ...data,
                projectId,
              },
          failedMessage: 'Error removing item(s) from collection',
        });

        if (response) {
          setRemoveItemFromCollectionLoading(false);
          const { events } = data;
          const item =
            events?.length > 1 ||
            (Array.isArray(data) && data.find(val => val?.mentions)?.mentions?.length > 1)
              ? 'Items'
              : 'Item';
          toastNotify('success', `${item} removed from collection(s)`);

          if (isSocialMonitoring) {
            // eslint-disable-next-line no-param-reassign

            const newcollection = filterReferenceElements?.collections?.map(collection => ({
              ...collection,
              contributions: response.find(res => res.collectionID === collection.collectionID)
                ? response.find(res => res.collectionID === collection.collectionID).contributions
                : collection.contributions,
            }));

            setFilterReferenceElements(prevState => ({ ...prevState, collections: newcollection }));
          } else {
            refreshData();
          }
        }
      } catch (error) {
        toastNotify('error', error.message);
        setRemoveItemFromCollectionLoading(false);
      }
    },
    [
      api,
      urls.removeItems,
      currentPage,
      projectId,
      refreshData,
      filterReferenceElements,
      setFilterReferenceElements,
      isSocialMonitoring,
    ],
  );

  const isInCollection = useCallback(
    contributionId =>
      getCollections()?.some(collection =>
        collection.contributions.find(
          contribution => contribution.contributionId === contributionId,
        ),
      ),
    [getCollections],
  );

  const value = useMemo(
    () => ({
      collections: isGkp ? gkpCollections : filterReferenceElements.collections,
      collectionsLoading: isGkp ? collectionsLoading : loadingSocialReferences,
      createCollectionLoading,
      addItemToCollectionLoading,
      updateCollectionLoading,
      deleteCollectionLoading,
      removeItemFromCollectionLoading,
      getContributionCollections,
      getEventCollections,
      fetchCollections,
      createCollection,
      addItemToCollection,
      updateCollection,
      deleteCollection,
      removeItemFromCollection,
      isInCollection,
      configureAlert,
    }),
    [
      addItemToCollection,
      addItemToCollectionLoading,
      collectionsLoading,
      isGkp,
      loadingSocialReferences,
      createCollection,
      createCollectionLoading,
      deleteCollection,
      deleteCollectionLoading,
      fetchCollections,
      getContributionCollections,
      getEventCollections,
      isInCollection,
      removeItemFromCollection,
      removeItemFromCollectionLoading,
      updateCollection,
      updateCollectionLoading,
      configureAlert,
      filterReferenceElements.collections,
      gkpCollections,
    ],
  );

  return <CollectionsContext.Provider value={value} {...props} />;
};

const useCollections = () => useContext(CollectionsContext);
export { CollectionsProvider, useCollections };

CollectionsProvider.propTypes = {
  projectId: PropTypes.string.isRequired,
};
