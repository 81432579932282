import api from 'services/apiService';

import { useAuth } from 'context/authContext';

export const useApiService = () => {
  const { accessToken } = useAuth();

  const apiCall = async apiProps => api({ ...apiProps, accessToken });

  return apiCall;
};
