import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import background from 'assets/login/mobileBanner.png';

import KwelloLogo from 'common/Logo/kwelloLogo';
import { ProfileLogout } from 'common/Mobile';

import styles from './Login.module.scss';

import MobileBanner from './MobileBanner';

const Mobile = () => (
  <Container fluid>
    <Row>
      <div className="d-flex align-right">
        <ProfileLogout />
      </div>
      <div className="d-flex align-items-center flex-column pb-5 pt-4">
        <div className={styles['kwello-icon-section']}>
          <KwelloLogo className={styles['kwello-login-icon']} />
        </div>
        <div
          className="banner w-100 text-center d-flex flex-column justify-content-center"
          style={{ backgroundImage: `url(${background})`, minHeight: '100%' }}
        />
      </div>
      <div className="d-flex  flex-column pb-5 pt-5">
        <p>Kwello on mobile devices is not currently supported.</p>
        <p>
          We are working on mobile enabled features on our Roadmap, until then please continue to
          use a Laptop, Desktop or iPad to access Kwello.
        </p>
      </div>
      <MobileBanner />
    </Row>
  </Container>
);

export default Mobile;
