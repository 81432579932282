import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import { toastNotify } from 'common/ToastNotification/ToastNotification';

export const Auth0LoginButton = ({ disabled }) => {
  const { loginWithRedirect } = useAuth0();

  const onClick = () => {
    sessionStorage.removeItem('loginError');
    try {
      if (localStorage.getItem('previousKwelloPath')?.includes('auth0')) {
        localStorage.removeItem('previousKwelloPath');
      }
      loginWithRedirect();
    } catch {
      toastNotify('error', 'Login failed. Please refresh the browser and try again.');
    }
  };

  return (
    <Button size="large" onClick={onClick} block disabled={disabled} buttonType="contrast">
      Login with alternate provider
    </Button>
  );
};

Auth0LoginButton.propTypes = { disabled: PropTypes.bool };

Auth0LoginButton.defaultProps = { disabled: false };
