import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';
import { useLists } from 'context/listsContext';

import Scroll from 'common/Scroll/Scroll';

import { ListCollection } from 'Pages/Lists/Components';

import { Card } from '../Card';

import { ListCardMenuOptions } from './ListCardMenuOptions';

export const ListCard = ({ height, selectedProfiles, asBody }) => {
  const { listsLoading, lists: contextList, createListLoading } = useLists();
  const { setFilter, getFilter, resetFilter } = useFilters();
  const menuOptions = ListCardMenuOptions({ selectedProfiles });

  const selectedList = contextList?.findIndex(list => list.listId === getFilter('list')?.[0]?.id);

  return (
    <Card
      title="Lists"
      asBody={asBody}
      fullScreen
      loading={listsLoading || createListLoading}
      height={height}
      menuOptions={menuOptions}
    >
      <Scroll hideArrow>
        <ListCollection
          lists={contextList}
          onClick={({ list }) => {
            if (getFilter('list') && getFilter('list')?.find(item => item.id === list.listId)) {
              if (getFilter('list')?.length === 1) {
                resetFilter('list');
              } else {
                const removeSelectedFilter = getFilter('list').filter(
                  item => item.id !== list.listId,
                );
                setFilter({ list: removeSelectedFilter });
              }
            } else {
              const addNewFilter = getFilter('list')
                ? [...getFilter('list'), { id: list.listId, reference: list.listName }]
                : [{ id: list.listId, reference: list.listName }];
              setFilter({ list: addNewFilter });
            }
          }}
          selectedProfiles={selectedProfiles}
          selectedList={selectedList}
        />
      </Scroll>
    </Card>
  );
};

ListCard.propTypes = {
  height: PropTypes.string,
  selectedProfiles: PropTypes.array,
  asBody: PropTypes.bool,
};

ListCard.defaultProps = {
  height: undefined,
  selectedProfiles: [],
  asBody: false,
};
