import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';

import { MODULE_KEYS } from 'constants';
import { DEFAULT_KWELLO_COLOR, DEFAULT_KWELLO_SECONDARY_COLOR } from 'constants/index';
import ROUTE from 'constants/route';
import { useApiService } from 'utils/hooks/useApiService';

import { toastNotify } from 'common/ToastNotification/ToastNotification';

import { useAuth } from './authContext';

const User = createContext({});

const DEFAULT_UI_PREFS = {
  UiThemeCss: `:root {
    --primary-color: #f36633;
    --hover-color: rgba(243, 102, 51, 0.05);
    --primary-hover-color: #f15117;
    --text-main-color: #463833;
    --text-highlight-color: #72635d;
    --text-grey-color: rgba(114, 99, 93, 0.6);
    --page-background-color: rgba(247, 246, 245, 0.51);
    --card-background-color: #fff;
    --filter-background-color: rgba(247, 246, 245, 0.51);
    --border-color: rgba(70, 56, 51, 0.15);
    --hover-border-color: #f36633;
    --shadow-color: rgba(70, 56, 51, 0.15);
    --table-even-rows-color: rgba(250, 249, 248, 0.51);
    --card-header-font-size: 18px;
    --darker-background-color: #ece9e7;
    --border-radius-xs: 2px;
    --border-radius-sm: 5px;
    --border-radius-lg: 16px;
    --color-warning: #cf0a0a;
    --color-success: #0acf83;
    --success-background-color: rgba(10, 207, 131, 0.05);
    --text-contrast-color: #fff;
    --background-panel: #141E28;
    --scrollbar-track: #fafafa;
    --scrollbar-thumb: rgba(51, 51, 51, 0.05);
    --scrollbar-thumb-hover: rgba(51, 51, 51, 0.05);
    --primary-button-color: #F76900;
  }`,
};

const UserProvider = props => {
  const [userDetails, setUserDetails] = useState();
  const [demoModePreference, setDemoModePreference] = useState(true);
  const [teams, setTeams] = useState([]);
  const [modules, setModules] = useState([]);
  const [uiPrefs, setUiPrefs] = useState(DEFAULT_UI_PREFS);
  const { isAuthenticated, accessToken, logout } = useAuth();
  const [loadingUserDetails, setLoadingUserDetails] = useState(true);
  const [tableColumnPreferences, setTableColumnPreferences] = useState({});
  const [sortByFeedPreference, setSortByFeedPreference] = useState('most-recent');
  const [enableProjects, setEnableProjects] = useState({});
  const gkpModuleId = modules.find(module => module.key === MODULE_KEYS.gkp)?.id;
  const insightsCaptureModuleId = modules.find(
    module => module.key === MODULE_KEYS.insightsCapture,
  )?.id;

  const api = useApiService();
  const firstLogin = useMemo(() => userDetails?.firstLogin || false, [userDetails]);
  const primaryColor = useMemo(() => uiPrefs?.PrimaryColor || DEFAULT_KWELLO_COLOR, [uiPrefs]);
  const secondaryColor = useMemo(
    () => uiPrefs?.SecondaryColor || DEFAULT_KWELLO_SECONDARY_COLOR,
    [uiPrefs],
  );

  const currentModuleProject = useMemo(() => {
    const currentModule = modules?.find(module => module.isCurrent);

    return {
      moduleId: currentModule?.id,
      projectId: currentModule?.currentProject,
    };
  }, [modules]);

  const setCurrentModuleProject = ({ moduleId, projectId }) => {
    setModules(prev =>
      prev.map(module => ({
        ...module,
        isCurrent: module.id === moduleId,
        currentProject: module.id === moduleId ? projectId : module.currentProject,
      })),
    );
  };

  const parseEmbeddedProjects = projects => {
    if (!projects?.length) return [];

    return projects.map(project => ({
      ...project,
      projectDetails: sortBy(project.projectDetails, 'sortOrder'),
    }));
  };

  const generateEmbeddedApiDetails = key => {
    return {
      url: '/EmbedInfo/GetUserEmbeddedProjects',
      method: 'POST',
      data: {
        moduleId: modules?.find(module => module.key === key)?.id,
      },
    };
  };

  const fetchProjects = useCallback(
    key => {
      if (!enableProjects[key]) {
        setEnableProjects(prev => ({ ...prev, [key]: true }));
      }
    },
    [enableProjects],
  );

  const socialMonitoringProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.socialMonitoring] || false,
    queryKey: [MODULE_KEYS.socialMonitoring],
    queryFn: () =>
      api({
        url: '/userProfile/projects',
      }),
    staleTime: Infinity,
    select: response => response.data,
  });

  const gkpProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.gkp] || false,
    queryKey: [MODULE_KEYS.gkp],
    queryFn: () =>
      api({
        url: '/shared/GetUserGkpRoles',
        method: 'POST',
        data: {
          moduleId: gkpModuleId,
        },
      }),
    staleTime: Infinity,
    select: response => response.data.gkpProjects,
  });

  const insightsCaptureProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.insightsCapture] || false,
    queryKey: [MODULE_KEYS.insightsCapture],
    queryFn: () =>
      api({
        url: '/Common/GetUserModulesProjects',
        method: 'POST',
        data: {
          moduleId: insightsCaptureModuleId,
          userId: userDetails?.userId,
          roleId: null,
        },
      }),
    staleTime: Infinity,
    select: response => response.data,
  });

  const insightsProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.insights] || false,
    queryKey: [MODULE_KEYS.insights],
    queryFn: () => api(generateEmbeddedApiDetails(MODULE_KEYS.insights)),
    staleTime: Infinity,
    select: response => parseEmbeddedProjects(response?.data?.projects),
  });

  const sosvExpertsProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.sosvExperts] || false,
    queryKey: [MODULE_KEYS.sosvExperts],
    queryFn: () => api(generateEmbeddedApiDetails(MODULE_KEYS.sosvExperts)),
    staleTime: Infinity,
    select: response => parseEmbeddedProjects(response?.data?.projects),
  });

  const sosvScienceProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.sosvScience] || false,
    queryKey: [MODULE_KEYS.sosvScience],
    queryFn: () => api(generateEmbeddedApiDetails(MODULE_KEYS.sosvScience)),
    staleTime: Infinity,
    select: response => parseEmbeddedProjects(response?.data?.projects),
  });

  const customReportsProjects = useQuery({
    enabled: enableProjects?.[MODULE_KEYS.customReports] || false,
    queryKey: [MODULE_KEYS.customReports],
    queryFn: () => api(generateEmbeddedApiDetails(MODULE_KEYS.customReports)),
    staleTime: Infinity,
    select: response => parseEmbeddedProjects(response?.data?.projects),
  });

  const fetchTeams = async () => {
    try {
      const response = await api({
        url: '/userProfile/teams',
      });

      setTeams(response.data);
    } catch {
      toastNotify('error', 'Failed to fetch teams');
    }
  };

  const fetchCurrentUser = async () => {
    setLoadingUserDetails(true);

    try {
      const response = await api({
        url: '/auth/getCurrentUser',
        authCall: true,
        returnError: true,
      });

      if (response?.data?.email) {
        const { modules: responseModules, uiPrefs: responseUiPrefs, ...userData } = response.data;

        setUserDetails(userData);
        setModules(
          sortBy(responseModules, [
            module => {
              if (module.navLink === ROUTE.captureInsights) {
                return 0;
              }
              if (module.navLink === ROUTE.insights) {
                return 1;
              }
              if (module.navLink === ROUTE.globalKolPlanningUrl) {
                return 2;
              }
              if (module.navLink === ROUTE.socialMonitoringUrl) {
                return 3;
              }
              return module.priority;
            },
          ]),
        );
        setUiPrefs(
          responseUiPrefs.reduce((obj, item) => ({ ...obj, [item.name]: item.value }), {}),
        );
        fetchTeams();
      }
    } catch (error) {
      const status = error?.response?.status;
      let loginError;

      if (status === 403) {
        loginError =
          'Sorry, we could not find a Kwello account with that email address. Please contact support.';
      } else if (status === 404) {
        loginError =
          "Sorry, we could not find a Kwello user with that email address. Please check your email address and contact support if it's correct.";
      } else {
        loginError = 'Sorry, we are unable to log you in. Please contact support.';
      }

      sessionStorage.setItem('loginError', loginError);
      logout();
    } finally {
      setLoadingUserDetails(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && accessToken) {
      fetchCurrentUser();
    }
    // fetchCurrentUser is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, isAuthenticated]);

  const moduleProjects = useCallback(
    module => {
      switch (module) {
        case MODULE_KEYS.socialMonitoring:
          return socialMonitoringProjects?.data;
        case MODULE_KEYS.gkp:
          return gkpProjects?.data;
        case MODULE_KEYS.insights:
          return insightsProjects?.data;
        case MODULE_KEYS.sosvExperts:
          return sosvExpertsProjects?.data;
        case MODULE_KEYS.sosvScience:
          return sosvScienceProjects?.data;
        case MODULE_KEYS.customReports:
          return customReportsProjects?.data;
        case MODULE_KEYS.insightsCapture:
          return insightsCaptureProjects?.data;
        default:
          return null;
      }
    },
    [
      gkpProjects,
      insightsProjects,
      socialMonitoringProjects,
      sosvExpertsProjects,
      sosvScienceProjects,
      customReportsProjects,
      insightsCaptureProjects,
    ],
  );

  const updateProfileImage = useCallback(newImage => {
    setUserDetails(prevState => ({
      ...prevState,
      profileImage: newImage,
    }));
  }, []);

  const getProjectDisplayName = useCallback(
    project => {
      if (!demoModePreference && project.projectClientName) {
        return `${
          project.projectDisplayName ? project.projectDisplayName : project.projectName
        } - ${project.projectClientName}`;
      }
      return project.projectDisplayName || project.projectName;
    },
    [demoModePreference],
  );

  const value = useMemo(
    () => ({
      userDetails,
      teams,
      firstLogin,
      loadingUserDetails,
      modules,
      uiPrefs,
      socialProjects: socialMonitoringProjects?.data,
      loadingSocialProjects: socialMonitoringProjects?.isLoading,
      gkpProjects: gkpProjects?.data,
      loadingGkpProjects: gkpProjects?.isLoading,
      embeddedProjects: {
        insights: insightsProjects?.data,
        sosvExperts: sosvExpertsProjects?.data,
        sosvScience: sosvScienceProjects?.data,
        customReports: customReportsProjects?.data,
        insightsCapture: insightsCaptureProjects?.data,
      },
      loadingEmbeddedProjects: {
        insights: insightsProjects?.isLoading,
        sosvExperts: sosvExpertsProjects?.isLoading,
        sosvScience: sosvScienceProjects?.isLoading,
        customReports: customReportsProjects?.isLoading,
        insightsCapture: insightsCaptureProjects?.isLoading,
      },
      fetchProjects,
      moduleProjects,
      currentModuleProject,
      setCurrentModuleProject,
      updateProfileImage,
      primaryColor,
      secondaryColor,
      tableColumnPreferences,
      setTableColumnPreferences,
      sortByFeedPreference,
      setSortByFeedPreference,
      demoModePreference,
      setDemoModePreference,
      getProjectDisplayName,
      insightsCaptureProjects: insightsCaptureProjects?.data,
      loadingInsightsCaptureProjects: insightsCaptureProjects?.isLoading,
    }),
    [
      userDetails,
      firstLogin,
      loadingUserDetails,
      modules,
      uiPrefs,
      socialMonitoringProjects?.data,
      socialMonitoringProjects?.isLoading,
      gkpProjects?.data,
      gkpProjects?.isLoading,
      insightsProjects?.data,
      insightsProjects?.isLoading,
      sosvExpertsProjects?.data,
      sosvExpertsProjects?.isLoading,
      sosvScienceProjects?.data,
      sosvScienceProjects?.isLoading,
      customReportsProjects?.data,
      customReportsProjects?.isLoading,
      fetchProjects,
      moduleProjects,
      currentModuleProject,
      teams,
      updateProfileImage,
      primaryColor,
      secondaryColor,
      tableColumnPreferences,
      setTableColumnPreferences,
      sortByFeedPreference,
      setSortByFeedPreference,
      demoModePreference,
      setDemoModePreference,
      getProjectDisplayName,
      insightsCaptureProjects?.data,
      insightsCaptureProjects?.isLoading,
    ],
  );

  return <User.Provider value={value} {...props} />;
};

const useUser = () => useContext(User);

export { UserProvider, useUser };
