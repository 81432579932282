import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useConfirm } from 'context/confirmationContext';
import { useLists } from 'context/listsContext';

import Button from 'common/button/Button';

import { ListForm } from '../../Forms';

import styles from './EditList.module.scss';

export const EditList = ({ list, setEditList, selectedProfiles, setSelectedProfiles, isModal }) => {
  const editFormRef = useRef();
  const { confirm } = useConfirm();
  const {
    updateList,
    deleteList,
    removeProfileFromList,
    updateListLoading,
    removeProfileFromListLoading,
    deleteListLoading,
  } = useLists();

  const removeList = () => {
    confirm({
      title: 'Delete list',
      text: `Do you want to delete the list "${list.listName}"?`,
      onConfirm: () => {
        deleteList(list.listId, () => {
          if (!isModal) {
            setEditList(false);
          }
        });
      },
    });
  };

  const removeAllProfiles = () => {
    confirm({
      title: 'Remove all profiles',
      text: `Do you want to remove all profiles associated with the list "${list.listName}"?`,
      onConfirm: () => {
        removeProfileFromList(
          [list.listId],
          list.koLs.map(kol => kol.profileId),
          () => {
            if (!isModal) {
              setEditList(false);
            }
          },
        );
      },
    });
  };

  const removeSelectedProfiles = () => {
    confirm({
      title: `Remove ${selectedProfiles?.length} profile(s)`,
      text: `Do you want to remove ${selectedProfiles?.length} profile(s) associated with the list "${list.listName}"?`,
      onConfirm: () => {
        removeProfileFromList([list.listId], selectedProfiles, () => {
          if (!isModal) {
            setSelectedProfiles([]);
          }
        });
      },
    });
  };

  const editSubmit = values => {
    updateList(values, () => {
      setEditList(false);
    });
  };

  return (
    <div className={styles.edit}>
      <ListForm ref={editFormRef} list={list} onSubmit={editSubmit} />
      <div className={styles['edit-actions']}>
        <Button buttonType="secondary" onClick={() => setEditList(false)}>
          Cancel
        </Button>
        <div className={styles['delete-actions']}>
          <Button buttonType="warning" onClick={removeList} loading={deleteListLoading}>
            Delete List
          </Button>

          <Button
            buttonType="warning"
            onClick={removeAllProfiles}
            disabled={list.koLs?.length === 0}
            loading={removeProfileFromListLoading}
          >
            Remove All Profiles
          </Button>

          <Button
            buttonType="warning"
            onClick={removeSelectedProfiles}
            disabled={selectedProfiles?.length === 0}
            loading={removeProfileFromListLoading}
          >
            Remove ({selectedProfiles?.length}) Profiles
          </Button>
        </div>
        <Button
          onClick={() => {
            editFormRef?.current?.submitForm();
          }}
          loading={updateListLoading}
        >
          Save List
        </Button>
      </div>
    </div>
  );
};

EditList.propTypes = {
  list: PropTypes.object,
  setEditList: PropTypes.func,
  selectedProfiles: PropTypes.array,
  setSelectedProfiles: PropTypes.func,
  isModal: PropTypes.bool,
};

EditList.defaultProps = {
  list: {},
  setEditList: undefined,
  selectedProfiles: [],
  setSelectedProfiles: undefined,
  isModal: false,
};
