import { useContext, useEffect } from 'react';

const { AccordionContext } = require('react-bootstrap');

const useBootstrapAccordionHook = ({ eventKey, element }) => {
  const { activeEventKey } = useContext(AccordionContext);

  useEffect(() => {
    if (eventKey === activeEventKey && element?.current) {
      setTimeout(() => {
        element.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 350);
    }
  }, [activeEventKey, eventKey, element]);

  return { activeEventKey };
};

export default useBootstrapAccordionHook;
