import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';

import { dateFormats } from 'utils/dateHelper';

import styles from './DateRangeSelector.module.scss';

export const DateRangeSelector = ({
  startDate,
  endDate,
  onStartChange,
  onEndChange,
  maxEndDate,
  minStartDate,
  className,
}) => {
  return (
    <div className={`${className} ${styles['date-picker']}`}>
      <DatePicker
        selectsStart
        selected={startDate}
        onChange={date => {
          if (onStartChange) {
            onStartChange(date);
          }
        }}
        startDate={startDate}
        minDate={minStartDate}
        maxDate={endDate}
        dateFormat={dateFormats.default}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
      <DatePicker
        selectsEnd
        selected={endDate}
        onChange={date => {
          if (onEndChange) {
            onEndChange(date);
          }
        }}
        endDate={endDate}
        startDate={startDate}
        minDate={startDate}
        maxDate={maxEndDate}
        dateFormat={dateFormats.default}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
  );
};

DateRangeSelector.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onStartChange: PropTypes.func,
  onEndChange: PropTypes.func,
  maxEndDate: PropTypes.instanceOf(Date),
  minStartDate: PropTypes.instanceOf(Date),
  className: PropTypes.string,
};

DateRangeSelector.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  onStartChange: undefined,
  onEndChange: undefined,
  maxEndDate: undefined,
  minStartDate: undefined,
  className: undefined,
};
