import { createContext, useCallback, useContext, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const SlideDrawerContext = createContext({});

const SlideDrawerProvider = props => {
  const { children, ...restProps } = props;
  const [slideDrawerOpen, setSlideDrawerOpen] = useState(false);
  const [slideDrawerModalOpen, setSlideDrawerOpenModal] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState(null);
  const lastTitle = useRef();
  const lastModalContent = useRef();

  const openSlideDrawer = useCallback(({ drawerTitle, drawerContent }) => {
    lastTitle.current = drawerTitle;
    lastModalContent.current = drawerContent;
    setTitle(drawerTitle);
    setContent(drawerContent);
    setSlideDrawerOpen(true);
  }, []);

  const closeSlideDrawer = useCallback(() => {
    setSlideDrawerOpen(false);
    setContent(null);
  }, []);

  const reOpenDrawer = useCallback(() => {
    openSlideDrawer({ drawerTitle: lastTitle.current, drawerContent: lastModalContent.current });
  }, [openSlideDrawer]);

  const value = useMemo(
    () => ({
      slideDrawerOpen,
      slideDrawerModalOpen,
      content,
      title,
      setSlideDrawerOpenModal,
      openSlideDrawer,
      closeSlideDrawer,
      reOpenDrawer,
    }),
    [
      slideDrawerOpen,
      slideDrawerModalOpen,
      title,
      content,
      closeSlideDrawer,
      reOpenDrawer,
      openSlideDrawer,
    ],
  );

  return (
    <SlideDrawerContext.Provider value={value} {...restProps}>
      {children}
    </SlideDrawerContext.Provider>
  );
};

SlideDrawerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
};

SlideDrawerProvider.defaultProps = {
  children: undefined,
};

const useSlideDrawer = () => useContext(SlideDrawerContext);

export { SlideDrawerProvider, useSlideDrawer };
