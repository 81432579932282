import { useQueryClient } from '@tanstack/react-query';

import { gkpDetailPages } from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { useKwelloQuery } from './kwelloQuery';
import { useGkpApi } from './useGkpApi';

export const useGkpSummaryQuery = ({ type, id }) => {
  const { call: callGkpApi } = useGkpApi();
  const queryClient = useQueryClient();
  const { currentPage } = useCurrentPage();

  const fetchSummary = async () => {
    let response = [];

    try {
      response = await callGkpApi({
        url: '/GlobalKOLPlanningShared/GetSummary',
        data: {
          type,
          id,
        },
      });
      return response;
    } catch {}
    return response;
  };

  const {
    data: summaryData,
    isFetching,
    kwelloQueryKey,
  } = useKwelloQuery({
    queryKey: ['fetch-summary-data', type, id],
    queryFn: () => fetchSummary(),
    enabled: id ? currentPage(gkpDetailPages) : !currentPage(gkpDetailPages),
  });

  const refreshSummary = () => {
    queryClient.invalidateQueries({ queryKey: ['fetch-summary-data'] });
  };

  return { summaryData, refreshSummary, isFetching, kwelloQueryKey };
};
