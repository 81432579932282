import { useHistory } from 'react-router-dom';
import { ReactComponent as LoginIcon } from 'assets/login/login.svg';

import KwelloLogo from 'common/Logo/kwelloLogo';

import styles from './Login.module.scss';

import IconText from './IconText';

const Header = () => {
  const history = useHistory();
  const redirectToLogin = () => {
    history.push('/login');
  };

  return (
    <div className="header w-100 pt-2 pb-2 pl-4 pr-4 d-flex align-items-center justify-content-between">
      <KwelloLogo className={styles['kwello-header-icon']} />
      <IconText text="Login" onClick={redirectToLogin}>
        <LoginIcon />
      </IconText>
    </div>
  );
};

export default Header;
