/**
 * @function isSosvModule
 * @param {string} pathname - The pathname to check
 * @returns {boolean} - Whether the pathname is a sosv module
 */
function isSosvModule(pathname) {
  const sosvRoutes = ['sosv-experts', 'sosv-science'];

  return sosvRoutes.includes(pathname.split('/')[1]);
}

export default isSosvModule;
