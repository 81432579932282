import { useEffect, useRef, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useFilters } from 'context/filtersContext';
import { useModal } from 'context/modalContext';
import { useSlideDrawer } from 'context/slideDrawerContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import { filtersSettings as socialMonitoringFilterConfig } from 'constants';
import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import { resolveFilterComponent } from 'common/filtersBar/utilities/mapFilters';
import { Header } from 'common/formControls/styles';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import Modal from 'common/Modals/Modal';
import SearchBar from 'common/searchBar/SearchBar';

import styles from './FiltersMenu.module.scss';

export const FiltersMenu = ({ openFilter, onApply, hideSearchBar }) => {
  const { showScientificRank } = useSocialMonitoring();
  const { currentPage } = useCurrentPage();
  const isKolProfile = currentPage(ROUTE.kolProfile);
  const [searchText, setSearchText] = useState('');
  const { activeFilters, resetFilter, applyFilters, updatedFilters, clearChanges, appliedFilters } =
    useFilterMenu();
  const initialFilters = useRef(appliedFilters);
  const currentFilters = useRef(updatedFilters);
  const { openModal, closeModal } = useModal();
  const { reOpenDrawer } = useSlideDrawer();
  const { gkpFilterConfig, loadingReferences } = useFilters();
  const [openSection, setOpenSection] = useState();
  const { search } = useLocation();

  useEffect(() => {
    if (openFilter !== undefined) {
      setOpenSection(openFilter);
    }
  }, [openFilter]);

  useEffect(() => {
    currentFilters.current = updatedFilters;
  }, [updatedFilters]);

  const filterConfig = currentPage(ROUTE.globalKolPlanningUrl)
    ? gkpFilterConfig
    : socialMonitoringFilterConfig;

  useEffect(
    () => () => {
      if (
        JSON.stringify(initialFilters.current) !== JSON.stringify(currentFilters.current) &&
        !onApply &&
        !isMobileOnly
      ) {
        openModal(
          <Modal
            title="Please confirm"
            saveText="Yes"
            onSave={() => {
              clearChanges();
              closeModal();
            }}
            cancelText="No"
            onCancel={() => {
              reOpenDrawer();
              closeModal();
            }}
            hideClose
          >
            You have unsaved filters, are you sure you want to close? any unconfirmed filters will
            not be applied
          </Modal>,
        );
      }
    },
    [clearChanges, closeModal, openModal, reOpenDrawer, onApply],
  );

  const submit = () => {
    if (search) {
      // remove any search query from the url
      const { href } = window.location;
      window.location.href = href.substring(0, href.indexOf('?'));
    }

    initialFilters.current = updatedFilters;
    if (onApply) {
      onApply(updatedFilters);
    } else {
      applyFilters();
    }
  };

  return (
    <div className={isMobileOnly ? styles['mobile-container'] : styles.container}>
      <div className={styles.accordion}>
        <Accordion
          defaultActiveKey={openFilter || 0}
          onSelect={sectionId => {
            setOpenSection(sectionId);
          }}
        >
          {!hideSearchBar && (
            <SearchBar
              onChange={value => setSearchText(value)}
              value={searchText}
              isInKolProfilePopup={isKolProfile}
            />
          )}
          {currentPage(ROUTE.globalKolPlanningUrl) && loadingReferences && <LoaderSpinner />}
          {filterConfig
            .filter(filterGroup => !isKolProfile || filterGroup.kolMenuItem)
            .map(filterGroup => (
              <div key={filterGroup.id}>
                {filterGroup.header && (
                  <Header className={styles['group-header']}>{filterGroup.header}</Header>
                )}

                {filterGroup.items
                  .filter(
                    filter =>
                      (showScientificRank ||
                        !['isScientificallyProfiled', 'scientificRank'].includes(filter.value)) &&
                      filter.label.toLowerCase().includes(searchText.toLowerCase()),
                  )
                  .map(filter => (
                    <Accordion.Item
                      key={filter?.key}
                      eventKey={filter?.key}
                      className={styles['accordion-item']}
                    >
                      <Accordion.Header>
                        <div className={styles['title-container']}>
                          {filter.label}
                          <span
                            className={activeFilters.includes(filter?.value) ? styles.active : ''}
                          />
                        </div>
                      </Accordion.Header>

                      <Accordion.Body className={styles['accordion-body']}>
                        {openSection === filter.key &&
                          resolveFilterComponent({
                            filter: { ...filter, eventKey: filter.key },
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
              </div>
            ))}
        </Accordion>
      </div>
      {!isMobileOnly && (
        <div className={styles.footer}>
          <Button buttonType="secondary" onClick={() => resetFilter()}>
            Reset all
          </Button>

          <Button icon="check" onClick={() => submit()}>
            Apply Filters
          </Button>
        </div>
      )}
    </div>
  );
};

FiltersMenu.propTypes = {
  openFilter: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onApply: PropTypes.func,
  hideSearchBar: PropTypes.bool,
};

FiltersMenu.defaultProps = {
  openFilter: undefined,
  onApply: undefined,
  hideSearchBar: false,
};
