import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useLayout } from 'context/layoutContext';

import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { EventItem } from '../EventItem';

import styles from './EventDetails.module.scss';

export const EventDetailsCollection = ({
  collection,
  contributions,
  selectedCollectionItems,
  toggleSelectedCollectionItem,
  editCollection,
}) => {
  const { isDesktop } = useLayout();
  const { collectionsLoading } = useCollections();

  if (collectionsLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      {contributions.length === 0 && (
        <section className={styles['no-events']}>
          No events found. Please add an event / change your search criteria.
        </section>
      )}
      <Grid columns={isDesktop ? 2 : 1}>
        {contributions?.map((contribution, index) => (
          <GridItem key={index} colSpan={1}>
            <EventItem
              collectionID={collection?.collectionID}
              event={contribution}
              editCollection={editCollection}
              onClick={toggleSelectedCollectionItem}
              selected={selectedCollectionItems.includes(contribution.eventId)}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

EventDetailsCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  contributions: PropTypes.array,
  selectedCollectionItems: PropTypes.array,
  toggleSelectedCollectionItem: PropTypes.func,
  editCollection: PropTypes.bool,
};

EventDetailsCollection.defaultProps = {
  contributions: [],
  selectedCollectionItems: [],
  toggleSelectedCollectionItem: undefined,
  editCollection: false,
};
