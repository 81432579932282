import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import styles from './LetterAvatar.module.scss';

export const LetterAvatar = ({ className }) => {
  const { userDetails } = useUser();
  if (!userDetails) {
    return null;
  }
  const firstName = userDetails.name.substring(0, userDetails.name.indexOf(' '));
  const lastName = userDetails.name.substring(userDetails.name.indexOf(' ') + 1);
  const firstNameInitial = firstName?.split('')?.[0];

  const lastNameInitial = lastName?.split('')?.[0];

  return (
    <span className={`${className} ${styles['letter-avatar']} tiny-loud-desktop-blue`}>
      {firstNameInitial}
      {lastNameInitial}
    </span>
  );
};
LetterAvatar.propTypes = {
  className: PropTypes.string,
};

LetterAvatar.defaultProps = {
  className: '',
};
