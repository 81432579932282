import { forwardRef, useEffect } from 'react';
import { Form as FormikForm, Formik, useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const FormObserver = ({ onValueChange }) => {
  const { values, dirty } = useFormikContext();
  const formValues = JSON.stringify(values);

  useEffect(() => {
    if (onValueChange && dirty) {
      onValueChange(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues, dirty]);

  return null;
};

FormObserver.propTypes = {
  onValueChange: PropTypes.func.isRequired,
};

export const Form = forwardRef(
  (
    { initialValues, validationSchema, onSubmit, children, onValueChange, renderFormWithProps },

    ref,
  ) => {
    return (
      <Formik
        innerRef={ref}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {props => {
          return (
            <FormikForm autoComplete="off">
              {renderFormWithProps ? renderFormWithProps(props) : children}

              {onValueChange && <FormObserver onValueChange={onValueChange} />}
            </FormikForm>
          );
        }}
      </Formik>
    );
  },
);

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  children: PropTypes.any,
  onValueChange: PropTypes.func,
  validationSchema: PropTypes.object,
  renderFormWithProps: PropTypes.func,
};

Form.defaultProps = {
  initialValues: {},
  onSubmit: () => {},
  children: null,
  onValueChange: () => {},
  validationSchema: undefined,
  renderFormWithProps: undefined,
};
