import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import useOutsideAlerter from 'utils/hooks/useOutsideAlerter';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';

import styles from './Modal.module.scss';

const Modal = ({
  className,
  hideClose,
  ignoreOutsideClick,
  title,
  children,
  onCancel,
  cancelText,
  cancelLoading,
  onSave,
  saveText,
  saveLoading,
  onClose,
  note,
  width,
  height,
  saveDisabled,
  noteClassName,
  hideUnderline,
}) => {
  const { closeModal } = useModal();
  const modalRef = useRef(null);

  const handleCloseModal = () => {
    if (onClose) onClose();
    closeModal();
  };

  useOutsideAlerter(modalRef, () => {
    if (!hideClose && !ignoreOutsideClick) handleCloseModal();
  });

  return (
    <section
      ref={modalRef}
      style={{ height }}
      className={`${styles['modal-container']} ${className} ${
        width ? styles[`width-${width}`] : ''
      }`}
    >
      <div
        className={`${
          hideUnderline
            ? `${styles['no-underline-title-container']} h6-loud-desktop`
            : styles['title-container']
        }`}
      >
        {title && <div className={styles['modal-title']}>{title}</div>}
        {!hideClose && (
          <span onClick={handleCloseModal}>
            <Icon name="cross" size={28} className={styles['close-icon']} />
          </span>
        )}
      </div>
      {children}
      {(onCancel || onSave) && (
        <section
          className={`${hideUnderline ? styles['no-underline-footer'] : styles['modal-footer']}`}
        >
          <div>
            {onCancel && (
              <Button buttonType="secondary" onClick={onCancel} loading={cancelLoading}>
                {cancelText}
              </Button>
            )}
          </div>
          {note && <span className={noteClassName}>{note}</span>}
          <div>
            {onSave && (
              <Button onClick={onSave} loading={saveLoading} disabled={saveDisabled}>
                {saveText}
              </Button>
            )}
          </div>
        </section>
      )}
    </section>
  );
};

Modal.propTypes = {
  className: PropTypes.string,
  hideClose: PropTypes.bool,
  ignoreOutsideClick: PropTypes.bool,
  title: PropTypes.any,
  children: PropTypes.node.isRequired,
  onCancel: PropTypes.func,
  cancelText: PropTypes.string,
  onSave: PropTypes.func,
  saveText: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'mobile']),
  height: PropTypes.string,
  cancelLoading: PropTypes.bool,
  saveLoading: PropTypes.bool,
  note: PropTypes.any,
  saveDisabled: PropTypes.bool,
  noteClassName: PropTypes.string,
  hideUnderline: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  hideClose: false,
  ignoreOutsideClick: false,
  title: undefined,
  onCancel: undefined,
  cancelText: 'Cancel',
  onSave: undefined,
  saveText: 'Save',
  onClose: undefined,
  width: undefined,
  height: undefined,
  cancelLoading: false,
  saveLoading: false,
  note: undefined,
  saveDisabled: false,
  noteClassName: null,
  hideUnderline: false,
};

export default Modal;
