import { isTablet } from 'react-device-detect';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import { MobilePageFooter } from 'common/Mobile';

import styles from './MobileFooterWithButton.module.scss';

export const MobileFooterWithButton = ({ buttonType, onClick, buttonText, disabled, type }) => {
  return (
    <MobilePageFooter
      footerItems={[
        <Button
          hideArrow
          type={type}
          buttonType={buttonType}
          className={isTablet ? styles['tablet-footer-button'] : styles['footer-button']}
          centerButton
          onClick={onClick}
          disabled={disabled}
        >
          {buttonText}
        </Button>,
      ]}
    />
  );
};

MobileFooterWithButton.propTypes = {
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
};

MobileFooterWithButton.defaultProps = {
  buttonType: 'contrast',
  onClick: undefined,
  buttonText: undefined,
  disabled: false,
  type: undefined,
};
