import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';
import { Form, InputDate } from 'common/Form';

import styles from './DateFilter.module.scss';

export const DateFilter = ({ filterKey, eventKey }) => {
  const { setFilter, selectedValues, resetFilter, minMaxDate } = useFilterMenu();
  const selectedItems = selectedValues(filterKey);
  const dateRange = minMaxDate(filterKey);

  const filterRef = useRef();

  useBootstrapAccordionHook({ eventKey, element: filterRef });

  const onChange = values => {
    setFilter({
      filterKey,
      filterValue: [values?.date],
      singleValue: true,
    });
  };

  const reset = () => {
    resetFilter(filterKey);
  };

  return (
    <div ref={filterRef}>
      <div className={styles.container}>
        <Form initialValues={{ date: selectedItems?.[0] || undefined }} onValueChange={onChange}>
          <InputDate name="date" allowUndefined min={dateRange?.min} max={dateRange?.max} />
        </Form>
        {selectedItems?.length && (
          <Button icon="eraser" size="small" buttonType="secondary" className="" onClick={reset} />
        )}
      </div>
    </div>
  );
};

DateFilter.propTypes = {
  filterKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
