import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useLayout } from 'context/layoutContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import { Badge } from 'common/Badge';
import { globalHideFilters } from 'common/Constants/hideFiltersAndSummary';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { AlertButton } from 'common/MenuItems';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';

import { AddKolModal } from '../../Modals';
import { EmptyListIcon } from '../EmptyListIcon';
import {
  AddProfilesToListMenuItem,
  DeleteListMenuItem,
  EditListMenuItem,
  RemoveProfilesFromListMenuItem,
} from '../MenuItems';

import styles from './ListItem.module.scss';

export const ListItem = ({
  list,
  onClick,
  selected,
  showNameOnly,
  selectedProfiles,
  hideCount,
}) => {
  const { isDesktop } = useLayout();
  const { currentPage } = useCurrentPage();
  const { internalFilters } = useFilterMenu();
  const selectedFilters = useMemo(
    () => internalFilters?.list?.find(listfilter => listfilter.id === list.listId),
    [internalFilters, list],
  );
  const isSelected = currentPage(globalHideFilters) ? selected : selectedFilters;
  const menuItems = [
    RemoveProfilesFromListMenuItem({ list }),
    EditListMenuItem({ list }),
    DeleteListMenuItem({ list }),
  ];
  if (selectedProfiles?.length) {
    menuItems.unshift(AddProfilesToListMenuItem({ list, selectedProfiles }));
  }

  return (
    <div className={`${styles.container} ${isSelected ? styles.selected : ''}`}>
      {isDesktop && (
        <div>
          {list.picture && <ProfilePicture url={list.picture} size="60px" />}
          {!list.picture && <EmptyListIcon />}
        </div>
      )}
      <div className={styles.details} onClick={onClick}>
        <span className={styles['list-name']}>{list.listName}</span>
        <span className={styles.description}>{list.description}</span>
      </div>
      {!showNameOnly && (
        <div>
          <AlertButton
            notifFrequency={list?.notifFrequency}
            listId={list?.listId}
            listName={list?.listName}
          />
        </div>
      )}
      {!hideCount && !showNameOnly && (
        <Badge className={styles.badge}>
          <AddKolModal list={list} kolCount={list.koLs.length} showTitle={false} />
        </Badge>
      )}
      {!showNameOnly && <DropDownMenu alignRight menuItems={menuItems} />}
    </div>
  );
};

ListItem.propTypes = {
  list: PropTypes.shape({
    listId: PropTypes.string.isRequired,
    listName: PropTypes.string,
    description: PropTypes.string,
    picture: PropTypes.string,
    notifFrequency: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    koLs: PropTypes.array,
  }).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  showNameOnly: PropTypes.bool,
  selectedProfiles: PropTypes.array,
  hideCount: PropTypes.bool,
};

ListItem.defaultProps = {
  onClick: undefined,
  selected: false,
  selectedProfiles: [],
  showNameOnly: false,
  hideCount: false,
};
