import { useCallback } from 'react';

import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import { useApi } from './useApi';

export const useGkpApi = () => {
  const { call, loading } = useApi();
  const { filters } = useFilters();

  const { projectSecret, projectParams, roleTeamName } = useGlobalKolPlanningContext();

  const groupedFilters = useCallback(
    resetFilter => {
      return Object.keys(filters || {}).reduce((obj, filter) => {
        if (filter === resetFilter) return obj;

        const [key, name] = filter.split('_');
        const groupKey = `${key}Filters`;

        if (!name) {
          return {
            ...obj,
            [key]: filters[key],
          };
        }

        if (name) {
          return {
            ...obj,
            [groupKey]: [
              ...(obj?.[groupKey] || []),
              { columnName: name, reference: filters[filter] },
            ],
          };
        }

        return obj;
      }, {});
    },
    [filters],
  );

  const gkpCall = useCallback(
    async ({
      url,
      version,
      data,
      secret,
      responseType,
      applyProjectParams = true,
      resetFilter,
    }) => {
      if (projectSecret || secret) {
        // eslint-disable-next-line no-useless-catch
        try {
          const response = await call({
            method: 'POST',
            url,
            version,
            responseType,
            data: {
              ...data,
              filters: groupedFilters(resetFilter),
              key: projectSecret || secret,
              paramaters: applyProjectParams ? projectParams : undefined,
              roleTeamName,
            },
          });
          return response?.data || response;
        } catch (error) {
          throw error;
        }
      } else {
        throw new Error(`No project secret found ${url}`);
      }
    },
    [call, groupedFilters, projectParams, projectSecret, roleTeamName],
  );

  return { call: gkpCall, loading, groupedFilters };
};
