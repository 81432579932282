import { useQuery } from '@tanstack/react-query';

import { useInsightsAi } from 'context/insightsAiContext';

import { capitaliseFirstLetter } from 'utils/formatStrings';

import { useApi } from './useApi';

export const useCommonSummary = () => {
  const { call } = useApi();
  const {
    insightAiFilters,
    currentEmbedProject,
    projectKey,
    projectRoleId,
    defaultInsightAiFilters,
  } = useInsightsAi();

  const { data, isLoading } = useQuery({
    queryKey: ['fetch-common-summary', insightAiFilters, currentEmbedProject],
    queryFn: () =>
      call({
        url: '/Common/GetSummaryValues',
        method: 'POST',
        data: {
          key: projectKey,
          RoleID: [projectRoleId],
          insightFilters: {
            ...defaultInsightAiFilters,
            ...insightAiFilters,
          },
        },
      }),
    staleTime: Infinity,
    enabled: projectRoleId !== undefined,
  });

  const getLabel = key => {
    const label = key?.split(/(?=[A-Z])/).join(' ');
    return capitaliseFirstLetter(label);
  };

  const summary =
    (data?.length &&
      Object.keys(data?.[0])
        ?.filter(key => data?.[0][key] !== null)
        ?.map(key => ({
          value: data?.[0][key],
          label: getLabel(key),
        }))) ||
    [];

  return { summary, loading: isLoading };
};
