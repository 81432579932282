import PropTypes from 'prop-types';

import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { AlertButton } from 'common/MenuItems';

import styles from './SavedSearchItem.module.scss';

export const SavedSearchItem = ({ search, onClick, selected, menuItems }) => {
  return (
    <div className={`${styles.container} ${selected ? styles.selected : ''}`}>
      <div className={styles.details} onClick={onClick}>
        <span className={styles['search-name']}>{search.savedSearchName}</span>
      </div>

      <AlertButton
        notifFrequency={search?.notifFrequency}
        searchId={search?.savedSearchId}
        collectionType="savedSearch"
      />
      {menuItems && <DropDownMenu alignRight menuItems={menuItems} />}
    </div>
  );
};

SavedSearchItem.propTypes = {
  search: PropTypes.shape({
    savedSearchId: PropTypes.string.isRequired,
    savedSearchName: PropTypes.string,
    notifFrequency: PropTypes.string,
    filter: PropTypes.object,
  }).isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  menuItems: PropTypes.array,
};

SavedSearchItem.defaultProps = {
  onClick: undefined,
  selected: false,
  menuItems: undefined,
};
