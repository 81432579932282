import { useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import { dateFormats, formatDate, parseDate } from 'utils/dateHelper';

import Icon from 'common/Icon/Icon';

import styles from './InputDate.module.scss';
import inputStyles from './Inputs.module.scss';

export const InputDate = ({
  className,
  name,
  label,
  required,
  error,
  min,
  max,
  allowUndefined,
}) => {
  const fieldRef = useRef();
  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <div className={styles.input}>
          <Field name={name}>
            {({
              field: { onChange, value = allowUndefined ? undefined : new Date(), ...fieldProps },
              form: { setFieldValue },
            }) => (
              <>
                <ReactDatePicker
                  className={`${inputStyles.input} ${styles['date-picker']} ${
                    error ? inputStyles.error : ''
                  }`}
                  selected={value ? parseDate(value) : value}
                  dateFormat={dateFormats.default}
                  onChange={val => {
                    setFieldValue(name, formatDate(val, dateFormats.api));
                  }}
                  {...fieldProps}
                  customInput={<input type="text" ref={fieldRef} />}
                  minDate={min ? parseDate(min) : undefined}
                  maxDate={max ? parseDate(max) : undefined}
                />
                <Icon name="calendar" />
              </>
            )}
          </Field>
        </div>
        <ErrorMessage name={name}>
          {message => <span className={inputStyles.error}>{message}</span>}
        </ErrorMessage>
        {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
      </label>
    </div>
  );
};

InputDate.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  allowUndefined: PropTypes.bool,
};

InputDate.defaultProps = {
  className: '',
  name: '',
  label: undefined,
  required: false,
  min: undefined,
  max: undefined,
  error: undefined,
  allowUndefined: false,
};
