import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { useItemInCollections } from 'utils/hooks';

import { BookmarkIcon } from 'common/BookmarkIcon';
import { CollectionManagementModal } from 'common/Modals/CollectionManagementModal';

import { AddCollectionAction } from 'Pages/Bookmarks/Components/Actions';

export const ContributionCollections = ({ mentions }) => {
  const { openModal } = useModal();
  const { collections, selectedCollections, onSave } = useItemInCollections({
    mentions,
  });

  const onClick = () => {
    openModal(
      <CollectionManagementModal
        title="Manage Collections"
        allCollections={collections}
        selectedCollections={selectedCollections}
        createCollection={[<AddCollectionAction mentions={mentions} />]}
        onSave={onSave}
      />,
    );
  };

  return <BookmarkIcon mentions={mentions} onClick={onClick} tooltip="Manage Collections" />;
};

ContributionCollections.propTypes = {
  mentions: PropTypes.array,
};
ContributionCollections.defaultProps = {
  mentions: undefined,
};
