import ROUTE from 'constants/route';

export const gkpHideFilters = [
  ROUTE.lists,
  ROUTE.kolProfile,
  ROUTE.eventDetails,
  ROUTE.attendeeDetails,
  ROUTE.interactionDetail,
  ROUTE.bookmarks,
  ROUTE.exportGlobalKolPlanningProject,
  ROUTE.savedSearch,
];

export const gkpHideSummary = [
  ROUTE.lists,
  ROUTE.kolProfile,
  ROUTE.eventDetails,
  ROUTE.interactionDetail,
  ROUTE.attendeeDetails,
  ROUTE.bookmarks,
  ROUTE.exportGlobalKolPlanningProject,
];

export const globalHideFilters = [
  ROUTE.lists,
  ROUTE.kolProfile,
  ROUTE.eventDetails,
  ROUTE.interactionDetail,
  ROUTE.attendeeDetails,
  ROUTE.bookmarks,
  ROUTE.exportGlobalKolPlanningProject,
  ROUTE.userSettings,
  ROUTE.exportProject,
  ROUTE.contribution,
  ROUTE.savedSearch,
  ROUTE.mobileFilters,
  ROUTE.mobileSearch,
];

export const smHideFilters = [
  ROUTE.lists,
  ROUTE.bookmarks,
  ROUTE.userSettings,
  ROUTE.exportProject,
  ROUTE.kolProfile,
  ROUTE.contribution,
  ROUTE.savedSearch,
  ROUTE.mobileFilters,
  ROUTE.mobileSearch,
];

export const smHideSummary = [
  ROUTE.lists,
  ROUTE.bookmarks,
  ROUTE.userSettings,
  ROUTE.exportProject,
  ROUTE.kolProfile,
  ROUTE.contribution,
  ROUTE.savedSearch,
  ROUTE.mobileFilters,
  ROUTE.mobileSearch,
];
