import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useLists } from 'context/listsContext';
import { useModal } from 'context/modalContext';

import Modal from 'common/Modals/Modal';

import { ListForm } from 'Pages/Lists/Forms';

export const AddListModal = ({ profiles }) => {
  const { closeModal } = useModal();
  const formRef = useRef();
  const { createList, createListLoading } = useLists();

  const saveList = values => {
    createList(values, profiles);
  };

  const onSave = () => {
    formRef.current?.submitForm();
  };

  return (
    <Modal
      width="large"
      title="Create New List"
      onCancel={closeModal}
      onSave={onSave}
      saveText="Save List"
      saveLoading={createListLoading}
      hideClose
    >
      <ListForm ref={formRef} onSubmit={saveList} />
    </Modal>
  );
};

AddListModal.propTypes = {
  profiles: PropTypes.array,
};
AddListModal.defaultProps = {
  profiles: undefined,
};
