import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import { ModuleItem } from './ModuleItem';

const ReportsModulePicker = ({ module, setIsExpanded }) => {
  const { embeddedProjects, fetchProjects, getProjectDisplayName } = useUser();
  const { currentPageIncludesAll } = useCurrentPage();
  const history = useHistory();

  const currentProjects = embeddedProjects[module.key];

  useEffect(() => {
    fetchProjects(module.key);
  }, [fetchProjects, module.key]);

  const navigate = project => {
    const page = project.projectDetails
      .find(item => !item.parentMPPID)
      .pageDisplayName.toLowerCase();

    setIsExpanded(-1);
    history.push(`${module?.navLink ?? ''}/${project?.projectID}/${page}`);
  };

  if (currentProjects?.length > 0) {
    return currentProjects?.map((project, index) => (
      <ModuleItem
        key={project.projectID}
        tabIndex={index}
        keyValue={project.projectDisplayname}
        onClick={() => navigate(project)}
        name={getProjectDisplayName(project)}
        selected={currentPageIncludesAll([module.navLink, project.projectID])}
      />
    ));
  }

  return <LoaderSpinner color="#FFF" />;
};

ReportsModulePicker.propTypes = {
  module: PropTypes.shape({
    icon: PropTypes.string,
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    navLink: PropTypes.string,
    priority: PropTypes.number,
  }).isRequired,
  setIsExpanded: PropTypes.func.isRequired,
};

export default ReportsModulePicker;
