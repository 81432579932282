import { useReducer, useRef } from 'react';

export const SearchStatus = {
  INITIAL: 'initial',
  ERROR: 'error',
  SUCCESS: 'success',
  SEARCHING: 'searching',
};

const validateReducer = (state, action) => {
  switch (action.type) {
    case SearchStatus.ERROR:
      return {
        results: null,
        error: action.error,
        status: SearchStatus.ERROR,
      };
    case SearchStatus.SUCCESS:
      return {
        results: action.results,
        error: null,
        status: SearchStatus.SUCCESS,
      };
    case SearchStatus.SEARCHING:
      return {
        results: null,
        error: null,
        status: SearchStatus.SEARCHING,
      };
    default:
      return {
        results: null,
        error: null,
        status: SearchStatus.SUCCESS,
      };
  }
};

export function useSearch() {
  const [{ results, error, status }, dispatch] = useReducer(validateReducer, {
    results: null,
    error: null,
    status: SearchStatus.INITIAL,
  });

  const invokeSearch = useRef(search => {
    dispatch({ type: SearchStatus.SEARCHING });
    search(
      resp => dispatch({ type: SearchStatus.SUCCESS, results: resp }),
      searchError => dispatch({ type: SearchStatus.ERROR, error: searchError }),
    );
  }).current;

  const validating = status === SearchStatus.SEARCHING;

  return { invokeSearch, results, error, status, validating };
}
