import PropTypes from 'prop-types';

import styles from './MobilePageHeaderFooter.module.scss';

export const MobilePageFooter = ({ footerItems, className }) => {
  return footerItems?.map((item, ind) => (
    <span className={`${className} ${styles['mobile-footer']}`} key={ind}>
      {item}
    </span>
  ));
};

MobilePageFooter.propTypes = {
  footerItems: PropTypes.array,
};

MobilePageFooter.defaultProps = {
  footerItems: undefined,
};
