import { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import FullScreen from 'common/fullscreen/Fullscreen';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';

import './Card.scss';
import styles from './Card.module.scss';

export const Card = forwardRef(
  (
    {
      loading,
      asBody,
      children,
      fullScreen,
      className,
      height,
      contentHeight,
      menuOptions,
      title,
      titleOptions,
      titleIcon,
      footer,
      onFullScreen,
      sticky,
      stickyTop,
      showScrollbar,
      titleElements,
      isFeedComponent,
      cardTitleClass,
      shiftDown,
      cardContentClass,
      cardBodyClass,
    },
    ref,
  ) => {
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [upArrow, setUpArrow] = useState(false);
    const { tarquinValue } = useLayout();
    const [top, setTop] = useState(200);

    useEffect(() => {
      setTop(stickyTop ? `${stickyTop}px` : `${tarquinValue}px`);
    }, [stickyTop, tarquinValue]);

    const toggleFullScreen = () => {
      setIsFullScreen(!isFullScreen);
      if (onFullScreen) {
        onFullScreen(!isFullScreen);
      }
    };

    return (
      <FullScreen fullscreen={isFullScreen}>
        <section
          ref={ref}
          className={`
          ${styles.card} 
          ${sticky ? styles.sticky : ''} 
          ${isFullScreen ? styles['full-screen'] : ''} 
          ${className || ''} 
          ${asBody && !isFullScreen && styles['card-as-body']} 
          kwello-card
          ${!asBody ? 'box-shadow' : ''}
          ${isFeedComponent ? styles['feed-card'] : ''}
        `}
          style={{ height: isFullScreen ? '100%' : height || 'auto', top }}
        >
          {title && (
            <section
              className={`
                ${styles['card-header']}
                ${className || ''} 
                ${isFeedComponent ? styles['feed-card'] : ''}
            `}
            >
              <section
                className={
                  cardTitleClass || `${styles['card-header-title']} kwello-card-header-title`
                }
              >
                {titleIcon && (
                  <section>
                    <Button
                      buttonType="transparent"
                      icon={titleIcon}
                      size="medium"
                      radius="large"
                      block
                    />
                  </section>
                )}
                {!titleOptions && title}
                {titleOptions && (
                  <DropDownMenu
                    menuItems={titleOptions}
                    setUpArrow={setUpArrow}
                    clickElement={
                      <Button
                        className={styles['card-header-title']}
                        buttonType="transparent"
                        upArrow={upArrow}
                      >
                        {title}
                      </Button>
                    }
                    clickElementClass="click-element"
                  />
                )}
              </section>
              {titleElements?.map((titleElement, index) => (
                <section
                  key={`title-element-${index}`}
                  className={styles['card-header-max-button']}
                  data-html2canvas-ignore="true"
                >
                  {titleElement}
                </section>
              ))}
              {fullScreen && (
                <section
                  className={styles['card-header-max-button']}
                  data-html2canvas-ignore="true"
                >
                  <Button buttonType="transparent" icon="fullscreen" onClick={toggleFullScreen} />
                </section>
              )}
              {menuOptions && (
                <DropDownMenu alignRight menuItems={menuOptions} shiftDown={shiftDown} />
              )}
            </section>
          )}

          <section className={cardBodyClass || styles['card-body']}>
            <section
              className={`
                ${cardContentClass || styles['card-content-container']} 
                ${showScrollbar && !isFeedComponent ? styles['show-scrollbar'] : ''}
                ${showScrollbar && isFeedComponent ? styles['show-scrollbar-feed'] : ''}
              `}
              style={{ height: contentHeight, position: height || sticky ? undefined : 'relative' }}
            >
              {loading && <LoaderSpinner />}
              {!loading && children}
            </section>
          </section>
          {footer && <section className={styles['card-footer']}>{footer}</section>}
        </section>
      </FullScreen>
    );
  },
);

Card.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  asBody: PropTypes.bool,
  menuOptions: PropTypes.array,
  titleOptions: PropTypes.array,
  fullScreen: PropTypes.bool,
  height: PropTypes.string,
  sticky: PropTypes.bool,
  stickyTop: PropTypes.number,
  showScrollbar: PropTypes.bool,
  cardTitleClass: PropTypes.string,
  cardContentClass: PropTypes.string,
  cardBodyClass: PropTypes.string,
  titleElements: PropTypes.array,
  shiftDown: PropTypes.bool,
  className: PropTypes.string,
  contentHeight: PropTypes.string,
  footer: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  onFullScreen: PropTypes.func,
  isFeedComponent: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
};

Card.defaultProps = {
  loading: false,
  title: undefined,
  titleIcon: undefined,
  asBody: false,
  menuOptions: undefined,
  titleOptions: undefined,
  fullScreen: false,
  height: undefined,
  sticky: false,
  stickyTop: undefined,
  showScrollbar: false,
  cardTitleClass: undefined,
  cardContentClass: undefined,
  titleElements: [],
  shiftDown: false,
  className: undefined,
  contentHeight: undefined,
  footer: undefined,
  onFullScreen: undefined,
  isFeedComponent: false,
  children: undefined,
  cardBodyClass: undefined,
};
