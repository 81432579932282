import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobileOnly } from 'react-device-detect';
import { debounce } from 'lodash';

import { useUser } from './userContext';

const screenSize = {
  mobile: 576,
  tablet: 768,
  largeTablet: 1080,
  desktop: 1440,
  largeDesktop: 1800,
};

const LayoutContext = createContext({});

const LayoutProvider = props => {
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isScrolled, setIsScrolled] = useState(window.innerWidth < screenSize.desktop);
  const [atBottom, setAtBottom] = useState(false);
  const [screenWidth, setScreenWidth] = useState('');
  const [tarquinValue, setTarquinValue] = useState(0);
  const [hasSideBar, setHasSideBar] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [layoutChanged, setLayoutChanged] = useState('');
  const [layoutResized, setLayoutResized] = useState('');
  const [viewport, setViewport] = useState(0);
  const screenWidthRef = useRef();
  const { modules, moduleProjects } = useUser();

  const listenToScroll = useCallback(() => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;

    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    setScrollPosition(winScroll);
    setIsScrolled(winScroll > 65);
    setAtBottom(scrolled >= 1);
  }, []);

  const listenToResize = useCallback(() => {
    setViewport([window.innerHeight, window.innerWidth]);
    if (window.innerWidth < screenSize.tablet) {
      setScreenWidth('mobile');
      setIsDesktop(false);
      screenWidthRef.current = 'mobile';
    } else if (window.innerWidth < screenSize.largeTablet) {
      setScreenWidth('tablet');
      setIsDesktop(false);
      screenWidthRef.current = 'tablet';
    } else if (window.innerWidth < screenSize.desktop) {
      setScreenWidth('largeTablet');
      setIsDesktop(false);
      screenWidthRef.current = 'largeTablet';
    } else if (window.innerWidth < screenSize.largeDesktop) {
      setScreenWidth('desktop');
      setIsDesktop(true);
      screenWidthRef.current = 'desktop';
    } else {
      setScreenWidth('largeDesktop');
      setIsDesktop(true);
      screenWidthRef.current = 'largeDesktop';
    }

    listenToScroll();
  }, [listenToScroll]);

  useEffect(() => {
    window.addEventListener('scroll', debounce(listenToScroll, 250));
    window.addEventListener('resize', debounce(listenToResize, 1000));

    // Set initial screen size
    listenToResize();

    return () => {
      window.removeEventListener('scroll', listenToScroll);
      window.removeEventListener('resize', listenToResize);
    };
  }, [listenToResize, listenToScroll]);

  const updateFixedHeaderHeight = useCallback(value => {
    setFixedHeaderHeight(value);
  }, []);

  const updateTarquinValue = useCallback(value => {
    setTarquinValue(value);
  }, []);

  useEffect(() => {
    if (isMobileOnly) {
      return setHasSideBar(false);
    }

    return setHasSideBar(true);
  }, [moduleProjects, modules, modules.length]);

  useEffect(() => {
    setLayoutChanged(Date.now());
  }, [tarquinValue, isScrolled, fixedHeaderHeight, viewport]);

  useEffect(() => {
    setLayoutResized(Date.now());
  }, [viewport]);

  const value = useMemo(
    () => ({
      scrollPosition,
      isScrolled,
      atBottom,
      fixedHeaderHeight,
      screenWidth,
      tarquinValue,
      hasSideBar,
      isDesktop,
      layoutChanged,
      updateFixedHeaderHeight,
      updateTarquinValue,
      viewport,
      layoutResized,
    }),
    [
      scrollPosition,
      isScrolled,
      atBottom,
      screenWidth,
      tarquinValue,
      hasSideBar,
      isDesktop,
      layoutChanged,
      updateFixedHeaderHeight,
      updateTarquinValue,
      fixedHeaderHeight,
      viewport,
      layoutResized,
    ],
  );

  return <LayoutContext.Provider value={value} {...props} />;
};

const useLayout = () => useContext(LayoutContext);

export { LayoutProvider, useLayout };
