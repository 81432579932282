import { useParams } from 'react-router-dom';

import { useFilters } from 'context/filtersContext';
import { useInsightsAi } from 'context/insightsAiContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import { useApi } from './useApi';

export const useSummarize = () => {
  const { call } = useApi();
  const { projectId } = useParams();
  const { filters } = useFilters();

  const {
    setServerLoading,
    updateChatHistory,
    insightAiFilters,
    currentEmbedProject,
    setServerError,
    setConversationId,
    conversationId,
    projectKey,
    projectRoleId,
    defaultInsightAiFilters,
  } = useInsightsAi();
  const { isSocialMonitoring } = useCurrentPage();

  const getSummary = async () => {
    let response = [];
    setServerLoading(true);
    const apiValue = isSocialMonitoring
      ? {
          method: 'POST',
          url: '/Common/Chat',
          data: {
            key: '',
            projectId,
            clientName: 'social',
            socialFilters: {
              ...filters,
            },
            conversationId,
            conversationType: 'summarize',
          },
        }
      : {
          method: 'POST',
          url: '/Common/Chat',
          data: {
            key: projectKey,
            RoleID: [projectRoleId],
            projectId,
            clientName: currentEmbedProject?.projectClientName,
            insightFilters: {
              startDate: defaultInsightAiFilters.startDate,
              endDate: defaultInsightAiFilters.endDate,
              ...insightAiFilters,
            },
            conversationId,
            conversationType: 'summarize',
          },
        };

    try {
      response = await call(apiValue);

      updateChatHistory({
        messageId: Date.now(),
        response: [
          {
            title: 'Summary',
            value: response?.chatResponse,
          },
          {
            title: 'Insights',
            value: response?.insights,
          },
        ].filter(val => val.value),
        citationFeed: response?.citationFeed,
        error: null,
        chatType: 'summary',
        conversationType: response?.conversationType,
        conversationId: response?.conversationId,
      });
      if (!conversationId) {
        setConversationId(response.conversationId);
      }
      setServerLoading(false);
      setServerError(false);
    } catch {
      setServerLoading(false);
      setServerError(true);
    }

    return response;
  };

  return { getSummary };
};
