import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';

import inputStyles from './Inputs.module.scss';

export const InputTextArea = ({
  className,
  name,
  label,
  placeholder,
  required,
  error,
  rows,
  maxLength,
  disabled,
}) => {
  return (
    <div
      className={`
        ${inputStyles['field-container']} 
        ${required ? inputStyles.required : ''} 
        ${className}`}
    >
      <label htmlFor={name}>
        <span className={inputStyles.label}>{label}</span>
        <Field name={name}>
          {({ field: { onChange, value, ...fieldProps }, form: { setFieldValue } }) => (
            <>
              <textarea
                className={`${inputStyles.input} ${error ? inputStyles.error : ''}`}
                name={name}
                value={value}
                onChange={event => setFieldValue(name, event.target.value)}
                placeholder={placeholder}
                rows={rows}
                disabled={disabled}
                {...fieldProps}
              />
              <div>
                <div className={inputStyles['error-message-container']}>
                  <ErrorMessage name={name}>
                    {message => {
                      if (!error) {
                        return <span className={inputStyles.error}>{message}</span>;
                      }
                      return null;
                    }}
                  </ErrorMessage>
                  {error && <span className={inputStyles.error}>{error.replace(name, label)}</span>}
                </div>
                {maxLength && (
                  <div className={inputStyles['counter-message-container']}>
                    {value?.length || 0} / {maxLength}
                  </div>
                )}
              </div>
            </>
          )}
        </Field>
      </label>
    </div>
  );
};
InputTextArea.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
};
InputTextArea.defaultProps = {
  className: '',
  label: undefined,
  placeholder: undefined,
  required: false,
  rows: 5,
  maxLength: 99999,
  name: PropTypes.string,
  error: undefined,
  disabled: false,
};
