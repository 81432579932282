import background from 'assets/login/bannerBackgroundLarge.png';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';

const Banner = ({ text, subText }) => {
  const onLinkClick = () => {
    window.location.href = 'https://accelerationpoint.com/kol-social-monitoring/';
  };

  return (
    <div
      className="banner w-100 text-center d-flex flex-column justify-content-center"
      style={{ backgroundImage: `url(${background})`, minHeight: '100%' }}
    >
      <h1 className="mb-4">{text || 'Not a Customer?'}</h1>
      {subText && (
        <p className="mb-4" style={{ maxWidth: '70%', color: 'white', margin: '0 auto' }}>
          {subText}
        </p>
      )}
      <div>
        <Button data-test="button-learn-more" onClick={onLinkClick}>
          Learn more
        </Button>
      </div>
    </div>
  );
};

Banner.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
};

Banner.defaultProps = {
  text: undefined,
  subText: undefined,
};

export default Banner;
