import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import Button from 'common/button/Button';

export const EditCollectionAction = ({ setEditCollection, editCollection, disabled, label }) => {
  const { isDesktop } = useLayout();

  return (
    <Button
      buttonType="transparent"
      icon="pencil"
      onClick={() => setEditCollection(true)}
      disabled={editCollection || disabled}
    >
      {isDesktop ? label : ''}
    </Button>
  );
};

EditCollectionAction.propTypes = {
  setEditCollection: PropTypes.func.isRequired,
  editCollection: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

EditCollectionAction.defaultProps = {
  editCollection: false,
  disabled: false,
  label: 'Edit Collection',
};
