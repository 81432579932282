import { useHistory, useParams } from 'react-router-dom';

import { useConfirm } from 'context/confirmationContext';
import { useFilters } from 'context/filtersContext';

import ROUTE from 'constants/route';

export const DownloadSavedSearchMenuItem = ({ search }) => {
  const { applySavedSearch } = useFilters();
  const { projectId } = useParams();
  const { confirm } = useConfirm();
  const history = useHistory();

  const confirmAndNavigateToExportProject = () => {
    confirm({
      title: 'Please confirm',
      text: 'This will overwrite any currently applied Filters for the PowerPoint Export, are you sure you want to proceed?',
      onConfirm: () => {
        applySavedSearch(search);
        history.push(`${ROUTE.socialMonitoringUrl}/${projectId}/${ROUTE.exportProject}`);
      },
    });
  };

  return {
    label: 'Export Saved Search',
    onClick: confirmAndNavigateToExportProject,
  };
};
