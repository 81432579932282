import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { useKwelloQuery } from './kwelloQuery';
import { useApi } from './useApi';
import { useGkpApi } from './useGkpApi';

export const useKolSearch = ({ listId }) => {
  const { call: callGkpApi } = useGkpApi();
  const { projectSecret } = useGlobalKolPlanningContext();
  const { currentPage } = useCurrentPage();
  const { call: callSocialApi } = useApi();
  const { projectId } = useParams();

  const fetchGkpKolList = async () => {
    const response = await callGkpApi({
      url: '/GlobalKolPlanningSearch/SearchForProfile',
      secret: projectSecret,
      data: {
        listId,
      },
    });
    return response;
  };

  const { data: gkpProfiles, isLoading: gkpProfilesLoading } = useKwelloQuery({
    queryKey: ['fetch-gkp-kol-list', listId, projectSecret],
    queryFn: () => fetchGkpKolList(),
    staleTime: Infinity,
    enabled: currentPage(ROUTE.globalKolPlanningUrl),
  });

  const fetchSocialProfiles = () => {
    return callSocialApi({
      url: '/shared/SearchForProfile',
      method: 'POST',
      data: {
        searchFor: '',
        listId,
        projectId,
      },
    });
  };

  const { data: socialProfiles, isLoading: socialProfilesLoading } = useQuery({
    queryKey: ['fetch-social-kol-list', projectId, listId],
    queryFn: () => fetchSocialProfiles(),
    enabled: currentPage(ROUTE.socialMonitoringUrl),
    staleTime: Infinity,
  });

  return { gkpProfiles, gkpProfilesLoading, socialProfiles, socialProfilesLoading };
};
