import PropTypes from 'prop-types';

import { BookMarksDetailsCollection } from 'Pages/Bookmarks/Components/DetailsCollection';
import { EventDetailsCollection } from 'Pages/EventCollections/Components/DetailsCollection';
import { DetailsCollection } from 'Pages/Lists/Components/DetailsCollection';
import { SavedSearchDetailsCollection } from 'Pages/SavedSearches';

export const DetailsCollectionObject = ({
  collection,
  contributions,
  selectedCollectionItems,
  toggleSelectedCollectionItem,
  editCollection,
  collectionType,
  hideFeedPreview,
}) => {
  const editCollectionContent = () => {
    switch (collectionType) {
      case 'bookmark':
        return (
          <BookMarksDetailsCollection
            collection={collection}
            contributions={contributions}
            selectedContributions={selectedCollectionItems}
            toggleSelectedCollectionItem={toggleSelectedCollectionItem}
            editCollection={editCollection}
          />
        );
      case 'event':
        return (
          <EventDetailsCollection
            collection={collection}
            contributions={contributions}
            selectedCollectionItems={selectedCollectionItems}
            toggleSelectedCollectionItem={toggleSelectedCollectionItem}
            editCollection={editCollection}
          />
        );
      case 'list':
        return (
          <DetailsCollection
            list={collection}
            profiles={contributions}
            selectedProfiles={selectedCollectionItems}
            toggleSelectedProfile={toggleSelectedCollectionItem}
            editList={editCollection}
            hideFeedPreview={hideFeedPreview}
          />
        );
      case 'savedSearch':
        return (
          <SavedSearchDetailsCollection
            search={collection}
            selectedSearches={selectedCollectionItems}
            toggleSelectedSearch={toggleSelectedCollectionItem}
            editSearch={editCollection}
          />
        );
      default:
        return 'Define the details component for your collection';
    }
  };

  return <>{editCollectionContent()}</>;
};

DetailsCollectionObject.propTypes = {
  collection: PropTypes.object,
  contributions: PropTypes.array,
  selectedCollectionItems: PropTypes.array,
  toggleSelectedCollectionItem: PropTypes.func,
  collectionType: PropTypes.string,
  editCollection: PropTypes.bool,
  hideFeedPreview: PropTypes.bool,
};

DetailsCollectionObject.defaultProps = {
  collection: {},
  contributions: [],
  selectedCollectionItems: [],
  toggleSelectedCollectionItem: undefined,
  collectionType: 'bookmark',
  editCollection: false,
  hideFeedPreview: false,
};
