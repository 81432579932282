import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// import { GoBackButton } from 'common/GoBack/GoBackButton';
import Icon from 'common/Icon/Icon';

import styles from './MobilePageHeaderFooter.module.scss';

export const MobilePageHeaderGoBack = ({ title, goBack, className }) => {
  const history = useHistory();

  const onGoBack = () => {
    if (goBack) {
      goBack();
    } else {
      history.goBack();
    }
  };
  return (
    <section className={`${className} ${styles['mobile-header-goback']}`} onClick={onGoBack}>
      <Icon name="goBack" size={30} />
      <div className={styles.title}>{title}</div>
    </section>
  );
};

MobilePageHeaderGoBack.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func,
  className: PropTypes.string,
};

MobilePageHeaderGoBack.defaultProps = {
  title: undefined,
  goBack: undefined,
  className: undefined,
};
