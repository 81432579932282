import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import { ExpandTitle } from 'common/ExpandTitle/ExpandTitle';
import { HelpCenter } from 'common/HelpCenter';
import { Logout } from 'common/Logout/Logout';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';
import ToggleSwitch from 'common/ToggleSwitch/ToggleSwitch';
import { UserSettings } from 'common/UserSettings/UserSettings';

import styles from './ExpandProfile.module.scss';

export const ExpandProfile = ({ isOpen, className, onClick, setTitleExpanded }) => {
  const { demoModePreference, setDemoModePreference, userDetails } = useUser();
  return (
    <div className={className}>
      {!isOpen && (
        <div onClick={onClick}>
          <ProfilePicture
            url={userDetails?.profileImage}
            size="32px"
            alt={userDetails?.name}
            showLetters={!userDetails?.profileImage}
            letterClassName={styles['letter-avatar']}
          />
        </div>
      )}
      {isOpen && (
        <div className={styles['expanded-container']}>
          <ExpandTitle
            className={styles.title}
            title={userDetails?.name}
            expandedClass={styles.title}
            icon={
              <ProfilePicture
                url={userDetails?.profileImage}
                size="28px"
                alt={userDetails?.name}
                showLetters={!userDetails?.profileImage}
                letterClassName={styles['letter-avatar']}
              />
            }
            isExpanded
            onClick={clickValue => setTitleExpanded(clickValue)}
          >
            <HelpCenter />
            <UserSettings onNavigate={onClick} />
            <Logout />
            {userDetails?.kwelloClientId && ( // kwello clients only have the toggle displayed
              <ToggleSwitch
                className={styles['demo-mode-toggle']}
                label="Demo mode"
                value={demoModePreference}
                onClick={() => setDemoModePreference(!demoModePreference)}
                block
              />
            )}
          </ExpandTitle>
        </div>
      )}
    </div>
  );
};

ExpandProfile.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  setTitleExpanded: PropTypes.func,
};

ExpandProfile.defaultProps = {
  className: '',
  isOpen: false,
  onClick: undefined,
  setTitleExpanded: () => {},
};
