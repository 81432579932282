import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';

import { ALERT_FREQUENCIES } from 'constants/index';
import Yup from 'utils/formValidation';

import { Form, InputGroupButtons, InputText } from 'common/Form';

export const SavedSearchForm = forwardRef(({ savedSearch, onSubmit }, ref) => {
  const { savedSearches } = useFilters();
  const savedSearchNames = savedSearches
    ?.map(search => search.savedSearchName.toLowerCase())
    .filter(searchName => searchName !== savedSearch?.savedSearchName?.toLowerCase());

  const initialValues = {
    savedSearchId: savedSearch?.savedSearchId,
    savedSearchName: savedSearch?.savedSearchName || '',
    notifFrequency: savedSearch?.notifFrequency || 'None',
  };

  const validationSchema = Yup.object({
    savedSearchName: Yup.string()
      .validateName()
      .validateUniqueName(savedSearchNames, 'Saved search name already exists')
      .required('Saved search name is required'),
    notifFrequency: Yup.string(),
  });

  return (
    <Form
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      ref={ref}
      initialValues={initialValues}
    >
      <InputText name="savedSearchName" label="Saved Search Name" required />
      <InputGroupButtons
        label="Configure Alerts"
        name="notifFrequency"
        type="radio"
        groupItems={ALERT_FREQUENCIES.map(item => ({
          value: item,
          label: item,
        }))}
      />
    </Form>
  );
});

SavedSearchForm.propTypes = {
  savedSearch: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
SavedSearchForm.defaultProps = {
  savedSearch: {},
};
