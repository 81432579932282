import { createContext, useContext, useMemo, useState } from 'react';

const LoginFormContext = createContext({});

const LoginFormProvider = props => {
  const [messageSent, setMessageSent] = useState(false);

  const updateMessageSent = isSent => {
    setMessageSent(isSent);
  };

  const value = useMemo(
    () => ({
      messageSent,
      updateMessageSent,
    }),
    [messageSent],
  );

  return <LoginFormContext.Provider value={value} {...props} />;
};

const useMessageSent = () => useContext(LoginFormContext);

export { LoginFormProvider, useMessageSent };
