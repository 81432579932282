import { useState } from 'react';
import PropTypes from 'prop-types';

import { useLists } from 'context/listsContext';

import { EditCollectionAction } from 'common/Collections';
import { AlertButton } from 'common/MenuItems';
import Modal from 'common/Modals/Modal';
import Scroll from 'common/Scroll/Scroll';
import SearchBar from 'common/searchBar/SearchBar';

import { AddKolAction, DetailsCollection, EditList } from 'Pages/Lists/Components';

import styles from './EditListModal.module.scss';

export const EditListModal = ({ list }) => {
  const [editList, setEditList] = useState();
  const [selectedProfiles, setSelectedProfiles] = useState([]);
  const [profileSearchValue, setProfileSearchValue] = useState('');

  const { lists } = useLists();

  const toggleSelectedProfile = profileId => {
    if (selectedProfiles.includes(profileId)) {
      setSelectedProfiles(selectedProfiles.filter(profile => profile !== profileId));
    } else {
      setSelectedProfiles([...selectedProfiles, profileId]);
    }
  };

  const currentList = lists?.find(item => item.listId === list.listId) || {};

  const filteredProfiles =
    currentList?.koLs?.filter(kol =>
      kol.name.toLowerCase().includes(profileSearchValue.toLowerCase()),
    ) || [];

  return (
    <Modal width="large" height="65vh" title="Edit List" ignoreOutsideClick>
      <section className={styles.header}>
        <h5>{currentList?.listName}</h5>
        <AddKolAction list={currentList} />
        <AlertButton
          notifFrequency={currentList?.notifFrequency}
          listId={currentList?.listId}
          listName={currentList?.listName}
          tooltip="Configure alerts for selected list"
          subMenuLabel="Configure alerts for selected list"
        />
        <EditCollectionAction
          editCollection={editList}
          setEditCollection={setEditList}
          label="Edit List"
        />
      </section>
      {editList && (
        <section>
          <EditList
            list={currentList}
            setEditList={setEditList}
            selectedProfiles={selectedProfiles}
            isModal
          />
        </section>
      )}

      <section className={styles.search}>
        <SearchBar
          value={profileSearchValue}
          onChange={value => {
            setProfileSearchValue(value);
            setSelectedProfiles([]);
          }}
        />
      </section>

      <Scroll height={currentList?.koLs?.length > 0 ? '45vh' : undefined} hideArrow>
        <DetailsCollection
          list={currentList}
          profiles={filteredProfiles}
          editList={editList}
          toggleSelectedProfile={toggleSelectedProfile}
          selectedProfiles={selectedProfiles}
        />
      </Scroll>
    </Modal>
  );
};

EditListModal.propTypes = {
  list: PropTypes.object,
};

EditListModal.defaultProps = {
  list: undefined,
};
