import PropTypes from 'prop-types';

import styles from './KwelloTooltip.module.scss';

const KwelloTooltip = ({ children, content, position, disabled, containerClass }) => {
  return (
    <span className={`${containerClass} ${styles.container} ${disabled ? styles.disabled : ''}`}>
      {children}
      <span className={`${styles.content} ${styles[position]}`}>{content}</span>
    </span>
  );
};

KwelloTooltip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  disabled: PropTypes.bool,
  containerClass: PropTypes.string,
};

KwelloTooltip.defaultProps = {
  position: 'top',
  disabled: false,
  containerClass: null,
};

export default KwelloTooltip;
