import { useLayout } from 'context/layoutContext';

import { formatNumberToKandM, formatToCommaSeparated } from 'utils/formatNumbers';

export const useNumberHelper = () => {
  const { isDesktop } = useLayout();

  const formatNumber = number => {
    if (!isDesktop) {
      return formatNumberToKandM(number, 2);
    }

    return formatToCommaSeparated(number);
  };

  return { formatNumber };
};
