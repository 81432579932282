import { savedSearchLabels } from 'constants';
import { formatDate } from 'utils/dateHelper';

export const resolveSavedSearchFilterValue = filter => {
  if (filter.label === 'Date Range') {
    return `${formatDate(filter.value.start)} - ${formatDate(filter.value.end)}`;
  }
  if (filter.label === 'Project Period') {
    return `${filter.value.value} ${filter.value.metric}(s)`;
  }
  if (filter.label === 'Advanced Search') {
    return `${filter.value}`;
  }

  if (Array.isArray(filter.value)) {
    if (filter.value.length < 4) {
      return filter.value.map(value => value.reference || value).join(', ');
    }
    return `${filter.value
      .map(value => value.reference || value)
      .slice(0, 3)
      .join(', ')}, +${filter.value.length - 3} More`;
  }
  const propNames = Object.getOwnPropertyNames(filter.value);
  return propNames
    .reduce((prev, curr) => [...prev, `${curr}: ${filter.value[curr]}`], [])
    .join(', ');
};

export const getActiveSavedSearchFilters = savedSearchFilter => {
  if (savedSearchFilter) {
    const activeFilters = Object.keys(savedSearchFilter)
      .filter(key => {
        const filter = savedSearchFilter[key];

        if (!filter) {
          return false;
        }

        if (key === 'numberOfFollowers' || key === 'scientificRank') {
          return filter.greaterThanOrEqualTo || filter.lessThanOrEqualTo;
        }

        if (Array.isArray(filter)) {
          return filter.length > 0;
        }

        return true;
      })
      .map(key => {
        const filterModel = savedSearchFilter[key];

        return {
          label: savedSearchLabels[key],
          value: filterModel,
          originalKey: key,
        };
      });
    // Eliminate date range if period is present
    return activeFilters?.filter(
      activeFilter =>
        activeFilter.label !== 'Date Range' ||
        !activeFilters.some(y => y.label === 'Project Period'),
    );
  }
  return undefined;
};
