import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useLists } from 'context/listsContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import DropdownSubmenuEmpty from 'common/formControls/DropdownSubmenuEmpty';
import Icon from 'common/Icon/Icon';

import styles from './KolListsFilter.module.scss';

export const KolListsFilter = ({ eventKey }) => {
  const { lists } = useLists();
  const { internalFilters, setFilter, removeFilter } = useFilterMenu();

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  return (
    <div ref={filterRef}>
      {lists?.map(list => (
        <div
          key={list.listId}
          className={styles['filter-list-item']}
          onClick={() => {
            if (!internalFilters?.list?.find(listfilter => listfilter.id === list.listId)) {
              setFilter({
                filterKey: 'list',
                filterValue: {
                  id: list.listId,
                  reference: list.listName,
                },
              });
            } else {
              removeFilter({ filterKey: 'list', filterValue: { id: list.listId } });
            }
          }}
        >
          {internalFilters?.list?.find(listfilter => listfilter.id === list.listId) ? (
            <span className={`${styles['selected-list-name']} ${styles['list-name']}`}>
              {list.listName}
              <Icon name="cross" size={16} />
            </span>
          ) : (
            <span className={styles['list-name']}>{list.listName}</span>
          )}
        </div>
      ))}
      {!lists?.length && <DropdownSubmenuEmpty text="No lists for this report" />}
    </div>
  );
};

KolListsFilter.propTypes = {
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
