import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

const useFilterEffect = (callback, additionalDependancies = []) => {
  const { filters } = useFilters();
  const { projectId } = useParams();
  const { currentProject } = useSocialMonitoring();
  const { projectSecret } = useGlobalKolPlanningContext();

  useEffect(() => {
    callback({ filters, currentProject });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, currentProject, projectId, projectSecret, ...additionalDependancies]);
};

export default useFilterEffect;
