import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';
import { useFilters } from 'context/filtersContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import Scroll from 'common/Scroll/Scroll';
import { useSearchBar } from 'common/searchBar/useSearchBar';
import { TreatmentsButtonWrapper } from 'common/wrappers';

export const TreatmentFilter = ({ filterKey, eventKey }) => {
  const { currentProject } = useSocialMonitoring();
  const { selectedValues, setFilter, removeFilter, optionsHeight, resetFilter } = useFilterMenu();
  const { loadingReferences, filterReferenceElements } = useFilters();
  const filterOptions = filterReferenceElements?.[filterKey] || [];
  const selectedItems = selectedValues(filterKey);
  const {
    searchValue: searchText,
    searchBar: SearchBarComponent,
    setSearchText,
  } = useSearchBar({
    placeholder: 'Type to search',
    hideSearchIcon: true,
    whiteBackground: true,
    clearSearch: true,
  });

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  const focusTreatments = filterOptions?.filter(
    option =>
      option.isFocusProduct &&
      !selectedItems?.find(item => item.reference === option.reference) &&
      option?.reference?.toLowerCase().includes(searchText.toLowerCase()),
  );

  const otherTreatments = filterOptions?.filter(
    option =>
      !option.isFocusProduct &&
      !selectedItems?.find(item => item.reference === option.reference) &&
      option?.reference?.toLowerCase().includes(searchText.toLowerCase()),
  );

  useEffect(() => {
    setSearchText('');
  }, [currentProject, filterReferenceElements, setSearchText]);

  const addItem = item => {
    setFilter({
      filterKey,
      filterValue: { id: item.id, reference: item.reference, bgColor: item.bgColor },
    });
  };

  const removeItem = item => {
    removeFilter({
      filterKey,
      filterValue: { id: item.id, reference: item.reference, bgColor: item.bgColor },
    });
  };

  const addItems = items => {
    items.forEach(item => addItem(item));
  };

  return (
    <div ref={filterRef}>
      <div className="pb-3 px-3">
        {selectedItems?.length && (
          <>
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="font-weight-bold ">Selected items</div>
              <Button
                icon="eraser"
                size="small"
                buttonType="secondary"
                onClick={() => resetFilter(filterKey)}
              />
            </div>
            <Scroll maxHeight={optionsHeight} className="d-flex flex-wrap gap-8" hideArrow>
              {selectedItems.map((treatment, index) => (
                <div key={`${treatment.reference}-${index}`} className="flex-fill">
                  <TreatmentsButtonWrapper color={treatment.bgColor}>
                    <Button
                      buttonType="secondary"
                      size="small"
                      key={`${treatment.reference}-${index}`}
                      secondaryAction={() => removeItem(treatment)}
                      block
                    >
                      {treatment.reference}
                    </Button>
                  </TreatmentsButtonWrapper>
                </div>
              ))}
            </Scroll>
            <hr />
          </>
        )}
        {SearchBarComponent}
        <div>
          <div className="d-flex justify-content-between mb-2">
            <div className="font-weight-bold">Focus treatments</div>
            <Button size="small" buttonType="link" onClick={() => addItems(focusTreatments)}>
              Select all
            </Button>
          </div>

          <Scroll maxHeight={optionsHeight} className="d-flex flex-wrap gap-8 mb-3" hideArrow>
            {loadingReferences && <LoaderSpinner />}
            {!loadingReferences &&
              focusTreatments.map(treatment => (
                <div key={treatment.reference} className="flex-fill">
                  <TreatmentsButtonWrapper color={treatment.bgColor}>
                    <Button
                      buttonType="secondary"
                      size="small"
                      key={treatment.reference}
                      onClick={() => addItem(treatment)}
                      block
                    >
                      {treatment.reference}
                    </Button>
                  </TreatmentsButtonWrapper>
                </div>
              ))}
          </Scroll>
        </div>
        <div>
          <div className="d-flex justify-content-between mb-2">
            <div className="font-weight-bold">Top other treatments</div>
            <Button size="small" buttonType="link" onClick={() => addItems(otherTreatments)}>
              Select all
            </Button>
          </div>

          <Scroll maxHeight={optionsHeight} className="d-flex flex-wrap gap-8" hideArrow>
            {loadingReferences && <LoaderSpinner />}
            {!loadingReferences &&
              otherTreatments.map(treatment => (
                <div key={treatment.reference} className="flex-fill">
                  <TreatmentsButtonWrapper color={treatment.bgColor}>
                    <Button
                      buttonType="secondary"
                      size="small"
                      key={treatment.reference}
                      onClick={() => addItem(treatment)}
                      block
                    >
                      {treatment.reference}
                    </Button>
                  </TreatmentsButtonWrapper>
                </div>
              ))}
          </Scroll>
        </div>
      </div>
    </div>
  );
};

TreatmentFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
