import PropTypes from 'prop-types';

import './hamburgerMenuIcon.scss';

const HamburgerMenuIcon = ({ onClick, isOpen, className, variant }) => (
  <div
    className={`hamburger-menu-icon ${isOpen ? 'open' : ''} ${
      className || ''
    } hamburger-menu-icon-${variant}`}
    onClick={onClick}
  >
    <span />
    <span />
    <span />
    <span />
  </div>
);

HamburgerMenuIcon.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  variant: PropTypes.string,
};

HamburgerMenuIcon.defaultProps = {
  onClick: undefined,
  className: '',
  isOpen: false,
  variant: 'light',
};

export default HamburgerMenuIcon;
