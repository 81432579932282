import PropTypes from 'prop-types';

import { getActiveSavedSearchFilters, resolveSavedSearchFilterValue } from 'common/SavedSearch';

import {
  SavedFilterTooltipBadge,
  SavedFilterTooltipContentContainer,
} from './savedSearchTooltipStyles';

export const SavedSearchTooltipContent = ({ savedSearch }) => {
  const activeSearchFilters = getActiveSavedSearchFilters(savedSearch?.filter);

  return (
    <SavedFilterTooltipContentContainer className="">
      {activeSearchFilters?.map((activeFilter, index) => (
        <SavedFilterTooltipBadge className="" key={index}>
          <span>{activeFilter.label}: </span>
          <span>
            <strong>{resolveSavedSearchFilterValue(activeFilter)}</strong>
          </span>
        </SavedFilterTooltipBadge>
      ))}
    </SavedFilterTooltipContentContainer>
  );
};

SavedSearchTooltipContent.propTypes = { savedSearch: PropTypes.object.isRequired };
