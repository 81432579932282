import background from 'assets/login/bannerBackgroundLarge.png';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';

const MobileBanner = ({ text, subText }) => {
  const onLinkClick = () => {
    window.location.href = 'https://accelerationpoint.com/kol-social-monitoring/';
  };

  return (
    <div
      className="mobileBanner w-100 text-center d-flex flex-column justify-content-center"
      style={{ backgroundImage: `url(${background})`, minHeight: '100%' }}
    >
      <h5 className="mb-4 mt-3">{text || 'Not a Customer?'}</h5>
      {subText && (
        <p className="mb-4" style={{ maxWidth: '70%', color: 'white', margin: '0 auto' }}>
          {subText}
        </p>
      )}
      <div>
        <Button className="mb-3" data-test="button-learn-more" onClick={onLinkClick}>
          Learn more
        </Button>
      </div>
    </div>
  );
};

MobileBanner.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string,
};

MobileBanner.defaultProps = {
  text: undefined,
  subText: undefined,
};

export default MobileBanner;
