import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { BlurredDiv, ChildrenDiv } from './styles';

const OverlayContainer = forwardRef(({ children, show, className }, ref) =>
  show ? (
    <BlurredDiv data-test="component-overlay-container-blurred-div" className="wrapped-block">
      <ChildrenDiv
        data-test="component-overlay-container-children-div"
        className={className}
        ref={ref}
      >
        {children}
      </ChildrenDiv>
    </BlurredDiv>
  ) : null,
);

OverlayContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  show: PropTypes.bool,
};

OverlayContainer.defaultProps = {
  children: undefined,
  className: '',
  show: false,
};

export default OverlayContainer;
