import styled from 'styled-components';

export const BlurredDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  backdrop-filter: blur(25px);
  background-color: rgba(247, 247, 247, 0.6);
  z-index: 1500;

  &:hover {
    margin-top: 0 !important;
  }
`;

export const ChildrenDiv = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  padding: 1.5rem;
  max-height: 100vh;
  overflow-y: auto;
`;
