import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';

import styles from './Badge.module.scss';

export const Badge = ({ children, className, type, icon }) => {
  return (
    <section className={`${styles.container} ${styles[type]} ${className}`}>
      {icon && <Icon className={styles.icon} name={icon} size={10} />}
      {children}
    </section>
  );
};

Badge.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['default', 'error']),
  icon: PropTypes.string,
};

Badge.defaultProps = {
  className: '',
  type: 'default',
  icon: undefined,
};
