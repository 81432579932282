import { useFilters } from 'context/filtersContext';

import { useNavigate } from 'utils/hooks';

import Button from 'common/button/Button';

import styles from './MobileFiltersContainer.module.scss';

import { MobileFilterIcon } from './MobileFilterIcon';

export const MobileFiltersContainer = () => {
  const { updatedFiltersArray } = useFilters();
  const { navigateToMobileFiltersPage, navigateToMobileSearchPage } = useNavigate();

  return (
    <div className={styles['mobile-filters']}>
      <MobileFilterIcon
        appliedFilters={updatedFiltersArray.filter(appliedFilter => appliedFilter.id > 0)?.length}
        onClick={navigateToMobileFiltersPage}
      />
      <Button
        buttonType="transparent"
        icon="search"
        className={`${styles['search-icon']}
          ${
            updatedFiltersArray.find(filter => filter.key === 'search')
              ? styles['applied-filters']
              : null
          }`}
        onClick={navigateToMobileSearchPage}
      />
    </div>
  );
};
