/* eslint-disable no-param-reassign */
// TO DO - We should not be reassigning target, need to implement a better solution
import html2canvas from 'html2canvas';
import fileDownload from 'js-file-download';
import api from 'services/apiService';

import { toastNotify } from 'common/ToastNotification/ToastNotification';

import { dateFormats, formatDate } from './dateHelper';

export const EXPORT_FEED_LIMIT = 10000;

/**
 * @function downloadData
 * @param {string} data - The data to download
 * @param {string} name - The name of the file to download
 * @return {void}
 */
export const downloadData = (data, name) => {
  fileDownload(
    data,
    `${name?.replaceAll(' ', '_')}_${formatDate(new Date(), dateFormats.api)}.csv`,
  );
};

/**
 * @function exportDataFn
 * @param {string} name - The name of the data to be exported.
 * @param {function} exportFn - The function used to fetch the data to be exported.
 * @returns {void}
 */
export function exportDataFn(name, exportFn) {
  return exportFn({
    onSuccess: data => downloadData(data, name),
    exportRequest: true,
    headers: { Accept: 'text/csv' },
  });
}

/**
 * @function exportDataWithFilter
 * @param {HTMLElement} scrollableEl - The scrollable element to stretch.
 */
const stretchToContent = scrollableEl => {
  if (scrollableEl) {
    scrollableEl.style.maxHeight = 'none';
    scrollableEl.style.maxWidth = 'none';
    scrollableEl.style.overflow = 'visible';
    scrollableEl.style.height = 'auto';
  }
};

/**
 * @function convertToCanvas
 * @param {HTMLElement} elementToExport - the HTML element to convert to a canvas
 * @returns {Promise<HTMLCanvasElement>} - the canvas element created from the given HTML element
 */
const convertToCanvas = elementToExport =>
  html2canvas(elementToExport, {
    onclone: (_document, element) => {
      // set properties for scrollable divs so that it streches to fit the content.
      // below code is based on current implementation of Card.jsx component
      const scrollableParentEl = element.getElementsByClassName('containers')[0];
      stretchToContent(scrollableParentEl);

      const scrollableEl = element.getElementsByClassName('parent')[0];
      stretchToContent(scrollableEl);

      // update element height if less than the scrollable content
      if (scrollableParentEl && element.offsetHeight < scrollableParentEl.scrollHeight) {
        element.style.height = `${scrollableParentEl.scrollHeight}px`;
      }

      const canvasEl = element.getElementsByTagName('canvas')[0];
      if (canvasEl) {
        if (element.offsetHeight < canvasEl.offsetHeight) {
          element.style.height = `${canvasEl.offsetHeight + 60}px`;
        }
        if (element.offsetWidth < canvasEl.offsetWidth) {
          element.style.width = `${canvasEl.offsetWidth}px`;
        }
      }
    },
  });

/**
 * @async
 * @function getBlob
 * @param {HTMLElement} element - The HTML element to convert to a blob.
 * @returns {Promise<Blob>} The resulting Blob object.
 */
const getBlob = async element =>
  new Promise((resolve, reject) => {
    convertToCanvas(element)
      .then(canvas => {
        canvas.toBlob(blob => {
          resolve(blob);
        });
      })
      .catch(() => {
        toastNotify('error', 'Error creating image');
        reject();
      });
  });

/**
* @function getElementToExport
* @param {Object} ref - A reference to an element in the DOM.
* @return {(HTMLElement|undefined)} Returns the last child of the referenced element, if it exists;
  otherwise, returns the first table element or canvas element found within the referenced element.
  If no such element is found, returns undefined.
*/
const getElementToExport = ref => {
  const elementToExport = ref.current;
  if (!elementToExport) {
    return undefined;
  }
  const [table] = ref.current.getElementsByTagName('table');
  if (table) {
    return table;
  }
  const [canvas] = ref.current.getElementsByTagName('canvas');
  if (canvas) {
    return canvas;
  }

  return elementToExport.lastChild;
};

/**
 * @async
 * @function copyImageToClipboard
 * @param {string} name - The name of the element
 * @param {Object} ref - The ref object of the element to be copied
 * @returns {Promise} Promise - Resolves if the image is successfully copied to clipboard
 */
export async function copyImageToClipboard(name, ref) {
  const elementToExport = getElementToExport(ref);
  if (!elementToExport) {
    return;
  }
  const promiseData = [new window.ClipboardItem({ 'image/png': getBlob(elementToExport) })];

  navigator.clipboard
    .write(promiseData)
    .then(() => {
      toastNotify('success', `${name} copied to clipboard`);
    })
    .catch(async () => {
      const blob = await getBlob(elementToExport);

      navigator.clipboard
        .write([new window.ClipboardItem({ 'image/png': blob })])
        .then(() => {
          toastNotify('success', `${name} copied to clipboard`);
        })
        .catch(() => {
          toastNotify('error', 'Error copying to clipboard');
        });
    });
}

/**
 * @async
 * @function exportAsImage
 * @param {string} name - The name of the element
 * @param {Object} ref - The ref object of the element to be copied
 * @returns {Promise} Promise - Resolves if the image is successfully saved
 */
export async function exportAsImage(name, ref) {
  const elementToExport = getElementToExport(ref);
  if (!elementToExport) {
    return;
  }

  try {
    const blob = await getBlob(elementToExport);
    fileDownload(blob, `${name}_${formatDate(new Date(), dateFormats.timeStamp)}.png`);
    toastNotify('success', 'Image saved');
  } catch {
    toastNotify('error', 'Error saving image');
  }
}

/**
 * @function doPPT
 * @param {string} exportElement - the id of the element to export
 * @returns {Promise<string>} - the base64 string of the canvas
 */
export function doPPT(exportElement) {
  return html2canvas(document.querySelector(`#${exportElement}`), { scale: 2, useCORS: true }).then(
    canvas => {
      const base64Canvas = canvas.toDataURL('image/jpeg').split(';base64,')[1];
      return base64Canvas;
    },
  );
}

/**
 * @async
 * @function exportToPPT
 * @param {Object} param - The object containing the data to be exported, the url to export to, and the name of the data
 * @param {Object} param.data - The data to be exported
 * @param {string} param.url - The url to export to
 * @param {string} param.dataName - The name of the data
 * @returns {Promise} Promise - Resolves if the data is successfully exported
 */
export const exportDataWithApi = async ({
  data,
  url,
  dataName,
  onSuccess,
  onFailure,
  accessToken,
}) => {
  try {
    const response = await api({
      accessToken,
      method: 'POST',
      url,
      exportRequest: true,
      headers: { Accept: 'text/csv' },
      data,
    });
    const name = dataName || 'Data_Export';
    downloadData(response, name);
    if (onSuccess) onSuccess();
  } catch (error) {
    toastNotify('error', 'Failed to download ', dataName);
    if (onFailure) onFailure(error);
  }
};
