import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';

import ROUTE from 'constants/route';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Icon from 'common/Icon/Icon';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './BookmarkIcon.module.scss';

export const BookmarkIcon = ({
  mentions,
  eventId,
  showCount,
  onClick,
  disabled,
  bookmarkOff,
  tooltip,
}) => {
  const { currentPage } = useCurrentPage();
  const isGkp = currentPage(ROUTE.globalKolPlanningUrl);
  const { getEventCollections, getContributionCollections } = useCollections();
  const collectionCount = isGkp
    ? getEventCollections(eventId)?.length
    : getContributionCollections(mentions)?.length;
  const iconName = collectionCount > 0 ? 'bookmarkOn' : 'bookmarkOff';

  return (
    <div
      className={`${onClick ? styles['click-icon'] : styles['button-icon']}  ${
        disabled ? styles.disabled : ''
      }`}
    >
      {tooltip ? (
        <KwelloTooltip content={tooltip} position="bottom">
          {' '}
          <span onClick={onClick}>
            <Icon size={16} name={bookmarkOff ? 'bookmarkOff' : iconName} />
            {showCount && collectionCount > 0 && collectionCount}
          </span>
        </KwelloTooltip>
      ) : (
        <span onClick={onClick}>
          <Icon size={16} name={bookmarkOff ? 'bookmarkOff' : iconName} />
          {showCount && collectionCount > 0 && collectionCount}
        </span>
      )}
    </div>
  );
};

BookmarkIcon.propTypes = {
  mentions: PropTypes.array,
  eventId: PropTypes.string,
  showCount: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  bookmarkOff: PropTypes.bool,
  tooltip: PropTypes.string,
};

BookmarkIcon.defaultProps = {
  mentions: undefined,
  eventId: undefined,
  showCount: false,
  onClick: undefined,
  disabled: false,
  bookmarkOff: false,
  tooltip: undefined,
};
