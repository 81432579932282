import { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './FixedToolTip.module.scss';

export const FixedTooltip = ({ content, position, disabled, containerClass, children }) => {
  const [positions, setPositions] = useState(undefined);
  const onTooltipHover = event => {
    const { top, right, left } = event.target.getBoundingClientRect();
    const height = content.length - 24;
    if (position === 'left' || position === 'right') {
      setPositions({ top: top - 12, right: right - 320, left: left + 20 });
    } else {
      setPositions({ top: position === 'bottom' ? top + 24 : top - height, right, left });
    }
  };

  const style = () => {
    switch (position) {
      case 'left':
        return { top: positions?.top, left: positions?.right };
      case 'right':
        return { top: positions?.top, left: positions?.left };
      default:
        return { top: positions?.top, left: positions?.left, right: positions?.right };
    }
  };

  return (
    <span
      className={`${containerClass} ${styles.container} ${disabled ? styles.disabled : ''}`}
      onMouseEnter={event => onTooltipHover(event)}
    >
      {children}
      <span className={`${styles.content} ${styles[position]} `} style={style()}>
        {content}
      </span>
    </span>
  );
};

FixedTooltip.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  disabled: PropTypes.bool,
  containerClass: PropTypes.string,
  children: PropTypes.any,
};

FixedTooltip.defaultProps = {
  position: 'top',
  disabled: false,
  containerClass: null,
  children: undefined,
};
