import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useCollections } from 'context/collectionsContext';
import { useFilters } from 'context/filtersContext';
import { useLists } from 'context/listsContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

const filterBooleanKeys = ['hasTwitterFollowers', 'isDigitalOpinionLeader'];

export const useSearchQuery = () => {
  const { savedSearches, applySavedSearch, setFilter, filterReferenceElements, defaultFilters } =
    useFilters();
  const { isSocialMonitoring } = useCurrentPage();
  const { search: searchParams } = useLocation();
  const { lists } = useLists();
  const { collections } = useCollections();

  const searchParameters = useMemo(() => new URLSearchParams(searchParams), [searchParams]);

  const savedSearchId = useMemo(() => searchParameters?.get('savedSearchId'), [searchParameters]);

  useEffect(() => {
    if (searchParameters && savedSearches && savedSearchId) {
      const savedSearch = savedSearches?.find(search => search.savedSearchId === savedSearchId);
      if (savedSearch) {
        applySavedSearch(savedSearch);
      }
    }
  }, [savedSearches, applySavedSearch, searchParameters, savedSearchId]);

  useEffect(() => {
    const filter = {};

    if (!savedSearchId) {
      const listId = searchParameters?.get('listId');
      const collectionId = searchParameters?.get('collectionId');

      if (listId && lists) {
        const listIds = listId.split(',');
        const list = listIds.reduce((results, id) => {
          const item = lists?.find(listItem => listItem.listId === id);
          if (item) {
            results.push({ id: item.listId, reference: item.listName });
          }
          return results;
        }, []);

        if (list?.length) {
          filter.list = list;
        }
        searchParameters.delete('listId');
      } else if (collectionId && collections) {
        const collectionIds = collectionId.split(',');
        const collection = collectionIds.reduce((results, id) => {
          const item = collections?.find(collectionItem => collectionItem.collectionID === id);
          if (item) {
            results.push({ id: item.collectionID, reference: item.collectionName });
          }
          return results;
        }, []);

        if (collection?.length) {
          filter.collection = collection;
        }
        searchParameters.delete('collectionId');
      }
    }
    if (!savedSearchId && filterReferenceElements && Object.keys(filterReferenceElements)?.length) {
      Object.keys(filterReferenceElements)?.forEach(key => {
        const reference = searchParameters?.get(key);
        if (reference) {
          const references = reference?.split(',');
          const values = references?.reduce((results, id) => {
            const item = filterReferenceElements[key]?.find(
              ref => ref.id === id || ref.id === parseInt(id, 10),
            );
            if (item) {
              results.push({ id: item.id, reference: item.reference });
            }
            return results;
          }, []);

          if (values?.length) {
            filter[key] = values;
          }
          searchParameters.delete(key);
        }
      });
    }
    if (!savedSearchId && isSocialMonitoring) {
      filterBooleanKeys.forEach(key => {
        const reference = searchParameters?.get(key);
        if (reference) {
          filter[key] = [reference === 'true'];
          searchParameters.delete(key);
        }
      });
    }
    if (Object.keys(filter).length) {
      localStorage.removeItem('SAVED_FILTERS');
      const filters = { ...defaultFilters, ...filter };
      setFilter(filters);
    }
  }, [
    searchParameters,
    savedSearchId,
    filterReferenceElements,
    setFilter,
    isSocialMonitoring,
    collections,
    lists,
    defaultFilters,
  ]);
};
