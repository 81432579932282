import React from 'react';
import { isMobileOnly } from 'react-device-detect';
import { toast, ToastContainer } from 'react-toastify';
import parse from 'html-react-parser';

import 'react-toastify/dist/ReactToastify.css';
import styles from './ToastNotification.module.scss';

const buildTemplate = (type, text) => (
  <div className={`${styles.toast} ${styles[type]}`}>{parse(text)}</div>
);

const toastNotify = (type = 'success', text) => toast(buildTemplate(type, text));

const ToastNotification = () => (
  <ToastContainer
    position="top-center"
    autoClose={isMobileOnly ? 2000 : 4000}
    hideProgressBar
    pauseOnFocusLoss={false}
    closeButton={!isMobileOnly}
    limit={4}
  />
);

export { ToastNotification, toastNotify };
