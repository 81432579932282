import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { useItemInCollections } from 'utils/hooks';

import { BookmarkIcon } from 'common/BookmarkIcon';
import { CollectionManagementModal } from 'common/Modals/CollectionManagementModal';

import { AddCollectionAction } from 'Pages/Bookmarks/Components/Actions';

export const EventCollections = ({ EventID, disabled }) => {
  const { openModal } = useModal();
  const { collections, selectedCollections, onSave } = useItemInCollections({ EventID });

  const onClick = () => {
    openModal(
      <CollectionManagementModal
        title="Manage Collections"
        allCollections={collections}
        selectedCollections={selectedCollections}
        createCollection={[<AddCollectionAction events={[EventID]} />]}
        onSave={onSave}
        type="event"
      />,
    );
  };

  return <BookmarkIcon eventId={EventID} onClick={onClick} disabled={disabled} />;
};

EventCollections.propTypes = {
  EventID: PropTypes.string,
  disabled: PropTypes.bool,
};
EventCollections.defaultProps = {
  EventID: undefined,
  disabled: false,
};
