import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import Scroll from 'common/Scroll/Scroll';

import styles from './DropdownItem.module.scss';

import { SubMenuItem } from './SubMenuItem';

const SubMenu = ({ isShowing, content, clickPosition, shiftUp }) => {
  const [style, setStyle] = useState({});
  const contentRef = useRef(null);
  const { viewport } = useLayout();

  useEffect(() => {
    const newStyle = {};
    newStyle.right = '100%';

    const bounds = contentRef?.current?.getBoundingClientRect();
    if (!shiftUp) {
      newStyle.top = `${clickPosition}px`;
      newStyle.bottom = 'auto';
    } else if (bounds.bottom > viewport[0]) {
      newStyle.top = 'auto';
      newStyle.bottom = '50%';
    } else {
      newStyle.top = 'auto';
      newStyle.bottom = `${clickPosition}px`;
    }
    setStyle(newStyle);
  }, [shiftUp, clickPosition, viewport]);

  return (
    <div
      ref={contentRef}
      className={isShowing ? styles['sub-menu-showing'] : styles['sub-menu']}
      style={style}
    >
      <Scroll maxHeight="50vh" hideArrow>
        {content?.map((menuItem, index) => (
          <SubMenuItem key={`${menuItem.label}-${index}`} menuItem={menuItem} />
        ))}
      </Scroll>
    </div>
  );
};

SubMenu.propTypes = {
  isShowing: PropTypes.bool,
  content: PropTypes.any,
  clickPosition: PropTypes.number,
  shiftUp: PropTypes.bool,
};
SubMenu.defaultProps = {
  isShowing: false,
  content: null,
  clickPosition: 0,
  shiftUp: false,
};

export default SubMenu;
