import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useConfirm } from 'context/confirmationContext';

import { capitaliseFirstLetter } from 'utils/formatStrings';

import Button from 'common/button/Button';

import { CollectionForm } from '../../Forms';

import styles from './EditCollection.module.scss';

export const EditCollection = ({
  collection,
  setEditCollection,
  selectedCollections,
  setSelectedCollections,
  itemName,
}) => {
  const editFormRef = useRef();
  const { confirm } = useConfirm();
  const {
    deleteCollection,
    deleteCollectionLoading,
    removeItemFromCollection,
    removeItemFromCollectionLoading,
    updateCollection,
    updateCollectionLoading,
  } = useCollections();

  const removeCollection = () => {
    confirm({
      title: 'Delete Collection',
      text: `Do you want to delete the collection "${collection.collectionName}"?`,
      onConfirm: () => {
        setEditCollection(false);
        deleteCollection(collection.collectionID);
      },
    });
  };

  const removeAllItems = () => {
    confirm({
      title: `Remove all ${capitaliseFirstLetter(itemName)}s`,
      text: `Do you want to remove all ${itemName}s associated with the collection "${collection.collectionName}"?`,
      onConfirm: () => {
        setEditCollection(false);
        removeItemFromCollection(
          itemName !== 'contribution'
            ? {
                collectionID: [collection.collectionID],
                events: collection?.events?.map(event => event.eventId),
              }
            : [
                {
                  collectionID: collection.collectionID,
                  mentions: collection.contributions.map(contribution => ({
                    contributionId: contribution.contributionId,
                    profileId: contribution.profileId,
                  })),
                },
              ],
        );
      },
    });
  };

  const removeSelectedItems = () => {
    confirm({
      title: `Remove ${selectedCollections?.length} ${capitaliseFirstLetter(itemName)}(s)`,
      text: `Do you want to remove ${selectedCollections?.length} ${itemName}(s) associated with the collection "${collection.collectionName}"?`,
      onConfirm: () => {
        removeItemFromCollection(
          itemName !== 'contribution'
            ? [{ collectionID: collection.collectionID, mentions: selectedCollections }]
            : {
                collectionID: [collection.collectionID],
                events: selectedCollections,
              },
        );

        setSelectedCollections([]);
      },
    });
  };

  const editSubmit = values => {
    const data = {
      ...values,
      notifFrequency: itemName !== 'contribution' ? undefined : collection.notifFrequency,
    };
    updateCollection(data, () => {
      setEditCollection(false);
    });
  };

  return (
    <div className={styles.edit}>
      <CollectionForm ref={editFormRef} collection={collection} onSubmit={editSubmit} />
      <div className={styles['edit-actions']}>
        <Button buttonType="secondary" onClick={() => setEditCollection(false)}>
          Cancel
        </Button>
        <div className={styles['delete-actions']}>
          <Button buttonType="warning" onClick={removeCollection} loading={deleteCollectionLoading}>
            Delete Collection
          </Button>

          <Button
            buttonType="warning"
            onClick={removeAllItems}
            disabled={collection.contributions?.length === 0}
            loading={removeItemFromCollectionLoading}
          >
            Remove All {capitaliseFirstLetter(itemName)}s
          </Button>

          <Button
            buttonType="warning"
            onClick={removeSelectedItems}
            disabled={selectedCollections?.length === 0}
            loading={removeItemFromCollectionLoading}
          >
            Remove ({selectedCollections?.length}) {capitaliseFirstLetter(itemName)}
            {selectedCollections?.length > 1 ? 's' : ''}
          </Button>
        </div>
        <Button
          onClick={() => {
            editFormRef?.current?.submitForm();
          }}
          loading={updateCollectionLoading}
        >
          Save Collection
        </Button>
      </div>
    </div>
  );
};

EditCollection.propTypes = {
  collection: PropTypes.object.isRequired,
  setEditCollection: PropTypes.func,
  selectedCollections: PropTypes.array,
  setSelectedCollections: PropTypes.func,
  itemName: PropTypes.string,
};

EditCollection.defaultProps = {
  setEditCollection: undefined,
  selectedCollections: [],
  setSelectedCollections: undefined,
  itemName: 'contribution',
};
