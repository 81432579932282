import PropTypes from 'prop-types';

import Icon from 'common/Icon/Icon';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './ClearSelectionIcon.module.scss';

export const ClearSelectionIcon = ({ onClick, tooltip, disabled }) => {
  return (
    <div
      className={`${onClick ? styles['click-icon'] : styles['button-icon']}  ${
        disabled ? styles.disabled : ''
      }`}
    >
      {tooltip ? (
        <KwelloTooltip content={tooltip} position="bottom">
          {' '}
          <span onClick={onClick}>
            <Icon size={16} name="eraser" />
          </span>
        </KwelloTooltip>
      ) : (
        <span onClick={onClick}>
          <Icon size={16} name="eraser" />
        </span>
      )}
    </div>
  );
};

ClearSelectionIcon.propTypes = {
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};
ClearSelectionIcon.defaultProps = {
  onClick: undefined,
  tooltip: undefined,
  disabled: false,
};
