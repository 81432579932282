import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Badge } from 'common/Badge';
import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import Icon from 'common/Icon/Icon';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import SearchProfilesMenuContent from 'modules/socialMonitoring/common/cardMenus/SearchProfilesMenuContent';

import styles from './NavBarItems.module.scss';

const NavigationMenuItems = ({ filter, type, element, active, kolSearch, navItems }) => {
  const location = useLocation();

  return (
    <>
      {kolSearch && (
        <div className={styles.kolSearch}>
          <DropDownMenu
            alignRight
            clickElement={<Button icon="userActions" buttonType="transparent" hideArrow />}
            menuItems={[
              {
                type: 'component',
                component: <SearchProfilesMenuContent />,
              },
            ]}
          />
        </div>
      )}

      {navItems
        .filter(item => filter === 'all' || item.type === filter)
        .filter(item => !active || location.pathname.includes(item.url))
        .filter(item => !item.hidden)
        .map(menuItem => (
          <KwelloTooltip
            key={`nav-link-${menuItem.url}`}
            content={menuItem.label}
            position="bottom"
            disabled={element !== 'icon'}
          >
            <NavLink
              key={`nav-link-${menuItem.url}`}
              className={`${styles['menu-item-link']} ${type} ${element || ''} ${
                menuItem.disabled ? styles.disabled : ''
              }`}
              to={menuItem.url}
              target={menuItem.target}
            >
              {element !== 'label' && <Icon name={menuItem.icon} />}

              {element !== 'icon' && menuItem.label}
              {menuItem.icon === 'elsieAI' && <Badge className={styles['beta-badge']}>Beta</Badge>}
              {active && element === 'label'}
            </NavLink>
          </KwelloTooltip>
        ))}
    </>
  );
};

NavigationMenuItems.propTypes = {
  filter: PropTypes.oneOf(['none', 'all', 'primary', 'secondary']),
  type: PropTypes.oneOf(['nav-bar', 'dropdown']),
  element: PropTypes.oneOf(['icon', 'label']),
  active: PropTypes.bool,
  kolSearch: PropTypes.bool,
  navItems: PropTypes.arrayOf(Object),
};

NavigationMenuItems.defaultProps = {
  filter: 'primary',
  type: 'nav-bar',
  element: undefined,
  active: false,
  kolSearch: false,
  navItems: [],
};

export default NavigationMenuItems;
