import { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';

import { useUser } from 'context/userContext';

export const useCustomerIo = () => {
  const { userDetails, currentModuleProject, modules } = useUser();

  const trackCio = useCallback(() => {
    // eslint-disable-next-line no-underscore-dangle
    const cio = window._cio;
    if (userDetails?.email) {
      if (cio) {
        cio.identify({
          id: userDetails.email,
          first_name: userDetails.name.substring(0, userDetails.name.indexOf(' ')),
          last_name: userDetails.name.substring(userDetails.name.indexOf(' ') + 1),
        });
      }
    }

    cio.page(window.location.href, { isMobile: isMobileOnly });
    cio.track('pageViewEvent', {
      isMobile: isMobileOnly,
      moduleId: currentModuleProject?.moduleId,
      projectId: currentModuleProject?.projectId,
      currentModule: modules?.find(module => module.isCurrent)?.name,
    });
  }, [userDetails, currentModuleProject, modules]);

  return { trackCio };
};
