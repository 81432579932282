import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';

import { percentChange } from 'utils/formatNumbers';
import { useNumberHelper } from 'utils/hooks/useNumberHelper';

import Icon from 'common/Icon/Icon';
import { FixedTooltip } from 'common/tooltip';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './SummaryBarItem.module.scss';

const SummaryBarItem = ({ data, groupedItem, onClick }) => {
  const difference = percentChange(data.value, data.prevValue);
  const { formatNumber } = useNumberHelper();

  return (
    <section
      className={`
        ${styles.container}
        ${styles['summary-item']} 
        ${groupedItem ? styles['grouped-item'] : ''}
        ${onClick ? styles.clickable : ''}`}
      onClick={() => (onClick ? onClick() : null)}
    >
      <div className={styles['count-container']}>
        <span className={`${styles.count} ${data.color ? styles[data.color] : ''}`}>
          {data.value && formatNumber(data.value)}
          {data.icon && <Icon className={styles.icon} name={data.icon} />}
        </span>
        {!data.hideTrend && data.prevValue > 0 && difference !== 0 && (
          <KwelloTooltip
            containerClass={styles.tooltip}
            content={`Previously ${formatNumber(data.prevValue)}`}
          >
            <Badge bg={difference > 0 ? 'success' : 'danger'}>{Math.abs(difference)}%</Badge>
          </KwelloTooltip>
        )}
      </div>
      <div className={`${styles['label-container']} ${data.type === 'bar' ? styles.bar : ''}`}>
        {data.type === 'bar' && (
          <div
            className={styles.bar}
            style={{ width: `${(100 * data.value) / data.total}%`, backgroundColor: data.barColor }}
          />
        )}
        <span className={styles.label}>
          {data.label}
          {data.tooltipContent ? (
            <FixedTooltip
              content={data.tooltipContent}
              position="left"
              containerClass={styles.icon}
            >
              <Icon name="info" size={12} />
            </FixedTooltip>
          ) : (
            ''
          )}
        </span>
      </div>
    </section>
  );
};

SummaryBarItem.propTypes = {
  data: PropTypes.object,
  groupedItem: PropTypes.bool,
  onClick: PropTypes.func,
};

SummaryBarItem.defaultProps = {
  data: undefined,
  groupedItem: false,
  onClick: undefined,
};

export default SummaryBarItem;
