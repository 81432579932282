import { isValid } from 'date-fns';
import * as Yup from 'yup';

/**
 * @function YupValidateDate
 * @param {string} msg - The error message to display
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateDate(msg = 'Invalid date') {
  return this.test({
    name: 'validateDate',
    message: msg,
    test: value => {
      return isValid(value) || !value;
    },
  });
}

/**
 * @function YupValidateName
 * @param {string} msg - The error message to display
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateName(msg = 'Invalid name') {
  return this.test({
    name: 'validateName',
    message: msg,
    test: value => {
      if (value?.trim() === '') {
        return false;
      }
      return true;
    },
  });
}

/**
 * @function YupValidateUniqueName
 * @param {string} existingNames - An array of names
 * @param {string} msg - The error message to display
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateUniqueName(existingNames, msg = 'This name already exists') {
  return this.test({
    name: 'validateUniqueName',
    message: msg,
    test: value => {
      if (existingNames?.includes(value?.toLowerCase())) {
        return false;
      }
      return true;
    },
  });
}

/**
 * @function YupValidateTextLength
 * @param {string} length - The maximum allowed length
 * @param {string} msg - The error message to display
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateTextLength(length, msg = 'Text length exceeds maximum allowed') {
  return this.test({
    name: 'validateUniqueName',
    message: msg,
    test: value => {
      if (value?.length > length) {
        return false;
      }
      return true;
    },
  });
}

/**
 * @function YupValidateFileName
 * @param {string} msg - The error message to display
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateFileName(msg = 'Invalid file name') {
  const fileNameRegex = /[\\/:"*?<>|]+/;
  return this.test({
    name: 'validateFileName',
    message: msg,
    test: value => {
      if (fileNameRegex.test(value)) {
        return false;
      }
      if (value?.endsWith('.')) {
        return false;
      }
      return true;
    },
  });
}

/**
 * @function YupValidateFileSize
 * @param {number} maxDocumentSize - The maximum file size in MB
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateFileSize(maxDocumentSize) {
  return this.test({
    name: 'validateFileSize',
    test: (value, testContext) => {
      let sizeError;
      if (value?.length) {
        sizeError = Object.values(value).find(file => file.size / 1000000 > maxDocumentSize);
      } else if (value?.size) {
        sizeError = value.size / 1000000 > maxDocumentSize;
      }
      if (sizeError) {
        const msg = `${
          sizeError.name || 'File'
        } is too large. Please upload a file that is not larger than ${maxDocumentSize}MB`;
        return testContext.createError({ message: msg });
      }
      return true;
    },
  });
}

/**
 * @function YupValidateFileType
 * @param {string[]} fileTypes - The allowed file types
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateFileType(fileTypes) {
  return this.test({
    name: 'validateFileType',
    test: (value, testContext) => {
      if (value?.length) {
        const typeError = Object.values(value).find(file => {
          const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
          return !fileTypes.includes(fileExtension.toLowerCase());
        });
        if (typeError) {
          const msg = `${typeError.name} has an incorrect type. Please use one of ${fileTypes.join(
            ',',
          )}`;
          return testContext.createError({ message: msg });
        }
      } else if (value?.type) {
        if (!value?.type.startsWith(fileTypes)) {
          const msg = `${value.name} has an incorrect type. Please use a file type of '${fileTypes}'`;
          return testContext.createError({ message: msg });
        }
      }
      return true;
    },
  });
}

/**
 * @function YupValidateHours
 * @param {number} hours- The allowed hours
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateHours(msg = 'Invalid Hours') {
  const hourValidationRegex = /^\d{1,3}(\.\d{1,2})?$/;
  return this.test({
    name: 'validateHours',
    message: msg,
    test: value => {
      if (!hourValidationRegex.test(value) && value !== undefined) {
        return false;
      }
      return true;
    },
  });
}

/**
 * @function YupValidateFMVRate
 * @param {number} FMVRate- The allowed rate
 * @returns {Yup.TestOptions} - The test options for Yup
 */
export function YupValidateFMVRate(msg = 'Invalid FMV Rate') {
  const rateValidationRegex = /^\d{1,4}(\.\d{1,2})?$/;
  return this.test({
    name: 'validateFMVRate',
    message: msg,
    test: value => {
      if (!rateValidationRegex.test(value) && value !== undefined) {
        return false;
      }
      return true;
    },
  });
}

Yup.addMethod(Yup.date, 'validateDate', YupValidateDate);
Yup.addMethod(Yup.string, 'validateFileName', YupValidateFileName);
Yup.addMethod(Yup.string, 'validateTextLength', YupValidateTextLength);
Yup.addMethod(Yup.mixed, 'validateFileSize', YupValidateFileSize);
Yup.addMethod(Yup.mixed, 'validateFileType', YupValidateFileType);
Yup.addMethod(Yup.string, 'validateName', YupValidateName);
Yup.addMethod(Yup.number, 'validateHours', YupValidateHours);
Yup.addMethod(Yup.number, 'validateFMVRate', YupValidateFMVRate);
Yup.addMethod(Yup.string, 'validateUniqueName', YupValidateUniqueName);

export default Yup;
