/* eslint-disable import/prefer-default-export */
import { useCallback, useState } from 'react';
import axios from 'axios';

import { useApiService } from './useApiService';

export const useApi = ({ defaultLoading } = {}) => {
  const [loading, setLoading] = useState(defaultLoading || false);
  const api = useApiService();

  const call = useCallback(async ({ method, url, version, data, authCall, responseType }) => {
    setLoading(true);

    try {
      const response = await api({
        method,
        url,
        version,
        data,
        authCall,
        responseType,
      });

      setLoading(false);
      return response?.data || response;
    } catch (error) {
      if (axios.isCancel(error)) {
        // Don't set loading to false for cancelled requests
        throw error;
      }

      setLoading(false);
      throw error;
    }
    // api is a function that does not change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    call,
  };
};
