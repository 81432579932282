import { useRef } from 'react';
import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useModal } from 'context/modalContext';

import Modal from 'common/Modals/Modal';

import { CollectionForm } from 'Pages/Bookmarks/Forms/CollectionForm';

export const AddCollectionModal = ({ events, mentions }) => {
  const { closeModal } = useModal();
  const formRef = useRef();
  const { createCollection, createCollectionLoading } = useCollections();
  const { defaultNotifFrequency } = useGlobalKolPlanningContext();

  const saveCollection = values => {
    const data = {
      ...values,
      events,
      notifFrequency: defaultNotifFrequency,
    };
    let otherData;
    if (mentions?.length) {
      otherData = mentions ? { mentions } : {};
    } else {
      otherData = events ? { events } : {};
    }

    createCollection(data, otherData);
    closeModal();
  };

  const onSave = () => {
    formRef.current?.submitForm();
  };

  return (
    <Modal
      width="large"
      title="Create New Collection"
      onCancel={closeModal}
      onSave={onSave}
      saveText="Save Collection"
      saveLoading={createCollectionLoading}
      hideClose
    >
      <CollectionForm ref={formRef} onSubmit={saveCollection} />
    </Modal>
  );
};

AddCollectionModal.propTypes = {
  events: PropTypes.array,
  mentions: PropTypes.array,
};
AddCollectionModal.defaultProps = {
  events: undefined,
  mentions: undefined,
};
