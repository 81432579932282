import { useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import ROUTE from 'constants/route';

export const useNavigate = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const storageName = 'previousPath';
  const { projectId } = useParams();
  const module = pathname.split('/')[1];

  const clearPreviousPath = useCallback(() => {
    localStorage.removeItem(storageName);
  }, []);

  const navigate = useCallback(
    (url, clearPath) => {
      if (clearPath) {
        clearPreviousPath();
      } else {
        localStorage.setItem(storageName, pathname);
      }

      history.push({
        pathname,
        state: {
          from: pathname,
        },
      });
    },
    [pathname, history, clearPreviousPath],
  );

  const navigateWithState = useCallback(
    (url, state) => {
      history.push({
        pathname: url,
        state: {
          from: pathname,
          ...state,
        },
      });
    },
    [history, pathname],
  );

  const navigateToEventPage = useCallback(() => {
    navigateWithState(`/${module}/${projectId}/${ROUTE.events}`);
  }, [projectId, module, navigateWithState]);

  const navigateToProfilePage = useCallback(() => {
    navigateWithState(`/${module}/${projectId}/${ROUTE.kol}`);
  }, [projectId, module, navigateWithState]);

  const navigateToProfileDetail = useCallback(
    profileId => {
      navigateWithState(`/${module}/${projectId}/${ROUTE.kolProfile}/${profileId}`);
    },
    [projectId, module, navigateWithState],
  );

  const navigateToContributionDetail = useCallback(
    contributionId => {
      navigateWithState(`/${module}/${projectId}/${ROUTE.contribution}/${contributionId}`);
    },
    [projectId, module, navigateWithState],
  );

  const navigateToEventDetail = useCallback(
    eventId => {
      navigateWithState(`/${module}/${projectId}/${ROUTE.eventDetails}/${eventId}`);
    },
    [projectId, module, navigateWithState],
  );

  const navigateToInteractionDetail = useCallback(
    (interactionId, interactionData) => {
      navigateWithState(
        `/${module}/${projectId}/${ROUTE.interactionDetail}/${interactionId}`,
        interactionData,
      );
    },
    [projectId, module, navigateWithState],
  );

  const navigateToEventAttendeeDetail = useCallback(
    (eventId, attendeeId, attendeeData) => {
      navigateWithState(`/${module}/${projectId}/${ROUTE.attendeeDetails}/${attendeeId}`, {
        ...attendeeData,
        EventID: eventId,
      });
    },
    [projectId, module, navigateWithState],
  );

  const navigateToMobileFiltersPage = useCallback(() => {
    navigateWithState(`/${module}/${projectId}/${ROUTE.mobileFilters}`);
  }, [projectId, module, navigateWithState]);

  const navigateToMobileSearchPage = useCallback(() => {
    navigateWithState(`/${module}/${projectId}/${ROUTE.mobileSearch}`);
  }, [projectId, module, navigateWithState]);

  const navigateToInsightQuestions = useCallback(
    topic => {
      navigateWithState(`/${module}/${projectId}/${ROUTE.insightsQuestions}`, { topic });
    },
    [module, navigateWithState, projectId],
  );

  const previousPath = localStorage.getItem(storageName);

  const value = {
    navigate,
    navigateWithState,
    navigateToProfileDetail,
    navigateToContributionDetail,
    navigateToEventDetail,
    navigateToInteractionDetail,
    navigateToEventAttendeeDetail,
    navigateToEventPage,
    navigateToProfilePage,
    navigateToMobileFiltersPage,
    navigateToMobileSearchPage,
    navigateToInsightQuestions,
    previousPath,
    clearPreviousPath,
    module,
  };

  return value;
};
