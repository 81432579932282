import {
  add as dateFnsAdd,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  format,
  formatDistance,
  getQuarter,
  isToday,
  isValid,
  isYesterday,
  sub,
} from 'date-fns';

export const dateFormats = {
  default: 'dd LLL yyyy',
  timeStamp: 'yyyyMMddHHmm',
  short: 'dd/MM/yyyy',
  long: 'EEE dd LLL yyyy',
  api: 'yyyy-MM-dd', // Format with this EVERY time we send dates to the BE
};

/**
 * @function calculateQuarter
 * @param {number} quarter - The quarter of the year (1-4)
 * @param {number} [year=current year] - The year to calculate the quarter for
 * @return {Object} - The start and end dates of the quarter
 */
export const calculateQuarter = (quarter, year = new Date().getFullYear()) => {
  const start = new Date(year, quarter * 3 - 3, 1);
  const end = new Date(year, quarter * 3, 0);
  return { start, end };
};

/**
 * @function calculateNextQuarters
 * @param {number} [numberOfQuarters=4] - The number of quarters to calculate
 * @return {Object[]} - An array of objects representing the calculated quarters
 */
export const calculateNextQuarters = (numberOfQuarters = 4) => {
  const today = new Date();
  let thisQuarter = getQuarter(today);

  let i = 0;
  let year = today.getFullYear();

  const quarters = [];

  while (i < numberOfQuarters) {
    quarters.push({
      label: `Quarter ${thisQuarter} (${year})`,
      ...calculateQuarter(thisQuarter, year),
    });

    if (thisQuarter === 4) {
      i += 1;
      thisQuarter = 1;
      year += 1;
    } else {
      i += 1;
      thisQuarter += 1;
    }
  }

  return quarters;
};

/**
 * @function calculateYear
 * @param {number} [year=current year] - The year to calculate
 * @return {Object} - The start and end dates of the year
 */
export const calculateYear = (year = new Date().getFullYear()) => {
  const start = new Date(year, 0, 1);
  const end = new Date(year, 11, 31);
  return { start, end };
};

/**
 * @function calculatePeriodDate
 * @param {string} startEnd - Whether to calculate the start or end date of the period
 * @param {Object} period - The period to calculate
 * @param {string} period.metric - The unit of time for the period (e.g. 'day', 'month')
 * @param {number} period.value - The number of units of time for the period
 * @param {string} period.direction - The direction of the period ('forward' or 'backward')
 * @return {Date} - The calculated start or end date of the period
 */
export const calculatePeriodDate = (startEnd, period) => {
  if (startEnd === 'start') {
    return period.direction === 'forward'
      ? new Date()
      : sub(new Date(), { [`${period.metric}s`]: period.value });
  }

  return period.direction === 'forward'
    ? dateFnsAdd(new Date(), { [`${period.metric}s`]: period.value })
    : new Date();
};

/**
 * @function parseDate
 * @param {Date|string} [date=current date] - The date to parse
 * @param {string} [modifier] - A modifier to apply to the date
 * @return {Date} - The parsed date
 */
export const parseDate = (date = new Date(), modifier) => {
  const formatDate = typeof date === 'string' ? date.replace(/-/g, '/') : date; // #4600 weirdness of javascript dates
  const modifiedDate = typeof date === 'string' ? new Date(`${formatDate.split('T')[0]}`) : date;

  if (!isValid(modifiedDate)) {
    return modifiedDate;
  }

  switch (modifier) {
    case 'none':
      break;
    case 'startOfDay':
      modifiedDate.setHours(0, 0, 0, 0);
      break;
    case 'endOfDay':
      modifiedDate.setHours(23, 59, 59, 999);
      break;
    default:
      modifiedDate.setHours(12, 0, 0, 0);
      break;
  }

  return modifiedDate;
};

/**
 * @function difference
 * @param {Date|string} left - The first date
 * @param {Date|string} right - The second date
 * @param {string} [period='minutes'] - The unit of time to measure the difference in (e.g. 'seconds', 'minutes')
 * @return {number} - The difference between the two dates
 */
export const difference = (left, right, period = 'minutes') => {
  switch (period.toLowerCase()) {
    case 'seconds':
      return differenceInSeconds(parseDate(left, 'none'), parseDate(right, 'none'));
    case 'minutes':
      return differenceInMinutes(parseDate(left, 'none'), parseDate(right, 'none'));
    case 'hours':
      return differenceInHours(parseDate(left, 'none'), parseDate(right, 'none'));
    case 'days':
      return differenceInDays(parseDate(left, 'none'), parseDate(right, 'none'));
    default:
      return differenceInMinutes(parseDate(left, 'none'), parseDate(right, 'none'));
  }
};

/**
 * @function add
 * @param {Date|string} [date=current date] - The date to add to
 * @param {Object} period - The time period to add
 * @param {string} period.metric - The unit of time for the period (e.g. 'day', 'month')
 * @param {number} period.value - The number of units of time to add
 * @return {Date} - The date with the added time period
 */
export const add = (date = new Date(), period) => {
  return dateFnsAdd(parseDate(date), period);
};

/**
 * @function subtract
 * @param {Date|string} [date=current date] - The date to subtract from
 * @param {Object} period - The time period to subtract
 * @param {string} period.metric - The unit of time for the period (e.g. 'day', 'month')
 * @param {number} period.value - The number of units of time to subtract
 * @return {Date} - The date with the subtracted time period
 */
export const subtract = (date = new Date(), period) => {
  return sub(parseDate(date), period);
};

/**
 * @function formatDate
 * @param {Date|string} date - The date to format
 * @param {string} [thisDateFormat=dateFormats.default] - The date format to use
 * @return {string} - The formatted date
 */
export const formatDate = (date, thisDateFormat = dateFormats.default) => {
  if (!date) {
    return null;
  }
  return format(parseDate(date), thisDateFormat);
};

/**
 * @function formatPastDateToString
 * @param {Date|string} date - The date to format
 * @return {string} - The formatted date as "Today" or "Yesterday" if applicable, otherwise the date formatted as a string
 */
export const formatPastDateToString = date => {
  if (isToday(parseDate(date))) {
    return 'Today';
  }

  if (isYesterday(parseDate(date))) {
    return 'Yesterday';
  }

  return formatDate(parseDate(date));
};

/**
 * @function formatTimeSince
 * @param {Date|string} date - The date to format
 * @return {string} - The formatted time difference between the given date and the current date
 */
export const formatTimeSince = date => {
  return formatDistance(parseDate(date), parseDate());
};
