import { ReactComponent as SwitchOff } from 'assets/icons/switchOff.svg';
import { ReactComponent as SwitchOn } from 'assets/icons/switchOn.svg';
import PropTypes from 'prop-types';

import styles from './ToggleSwitch.module.scss';

const ToggleSwitch = ({ className, label, value, onClick, block }) => (
  <div className={`${styles.container} ${className} ${block ? styles.block : ''}`}>
    {label && <span className={styles.label}>{label}</span>}
    <button className={styles.button} type="button" onClick={onClick}>
      {value ? <SwitchOn className={styles.icon} /> : <SwitchOff className={styles.icon} />}
    </button>
  </div>
);

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  block: PropTypes.bool,
};

ToggleSwitch.defaultProps = {
  className: '',
  block: false,
  label: undefined,
};

export default ToggleSwitch;
