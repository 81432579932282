import { useHistory } from 'react-router';
import PropTypes from 'prop-types';

import ROUTE from 'constants/route';

import Icon from 'common/Icon/Icon';

import styles from './UserSettings.module.scss';

export const UserSettings = ({ onNavigate }) => {
  const history = useHistory();

  const navigateToUserSettings = () => {
    onNavigate();
    history.push(ROUTE.userSettings);
  };

  return (
    <div onClick={navigateToUserSettings} className={styles.settings}>
      <Icon name="cog" />
      <span>User Settings</span>
    </div>
  );
};

UserSettings.propTypes = {
  onNavigate: PropTypes.func,
};

UserSettings.defaultProps = {
  onNavigate: () => {},
};
