import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';

import { thirtyMinutes } from 'constants/reactQueryConfig';
import ROUTE from 'constants/route';
import { useApi, useGkpApi } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import { toastNotify } from 'common/ToastNotification/ToastNotification';

import { useGlobalKolPlanningContext } from './globalKolPlanningContext';
import { useModal } from './modalContext';

const ListsContext = createContext({});

const ListsProvider = props => {
  const { projectId } = props;
  const [addProfilesToListLoading, setaddProfilesToListLoading] = useState(false);
  const [createListLoading, setCreateListLoading] = useState(false);
  const [updateListLoading, setUpdateListLoading] = useState(false);
  const [deleteListLoading, setDeleteListLoading] = useState(false);
  const [changeListLoading, setChangeListLoading] = useState(false);
  const [removeProfileFromListLoading, setRemoveProfileFromListLoading] = useState(false);
  const { projectSecret } = useGlobalKolPlanningContext();
  const { currentPage, isSocialMonitoring } = useCurrentPage();
  const { filterReferenceElements, loadingSocialReferences, setFilterReferenceElements } =
    useFilters();
  const { call: gkpCall } = useGkpApi();
  const { call: apiCall } = useApi();
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const urls = useMemo(() => {
    if (currentPage(ROUTE.globalKolPlanningUrl)) {
      return {
        fetchLists: '/GlobalKOLPlanningLists/GetGKPLists',
        createList: '/GlobalKOLPlanningLists/CreateGKPList',
        updateList: '/GlobalKOLPlanningLists/UpdateGKPList',
        deleteList: '/GlobalKOLPlanningLists/DeleteGKPList',
        removeProfiles: '/GlobalKOLPlanningLists/RemoveKolProfilesFromList',
        addProfiles: '/GlobalKOLPlanningLists/AddKolProfileToList',
        configureAlerts: '/GlobalKOLPlanningLists/ConfigureAlerts',
      };
    }

    return {
      createList: '/Shared/CreateList',
      updateList: '/list/updatelist',
      deleteList: '/list/deletelist',
      removeProfiles: '/shared/removeProfilesFromList',
      addProfiles: '/shared/addProfilesToList',
      configureAlerts: 'list/ConfigureAlerts',
    };
  }, [currentPage]);

  const api = useCallback(
    params => (currentPage(ROUTE.globalKolPlanningUrl) ? gkpCall(params) : apiCall(params)),
    [apiCall, gkpCall, currentPage],
  );

  const fetchLists = useCallback(async () => {
    const listsResponse = await api({
      method: 'POST',
      url: urls.fetchLists,
      call: 'fetchLists',
      data: { projectId },
    });

    return listsResponse.map(item => ({
      ...item,
      koLs: item.koLs?.map(kol => ({ ...kol })),
    }));
  }, [api, urls.fetchLists, projectId]);

  const reactQueryKey = useMemo(
    () => ['lists', projectId, projectSecret],
    [projectId, projectSecret],
  );

  const { data: gkpLists, isLoading } = useQuery({
    queryKey: reactQueryKey,
    queryFn: () => fetchLists(),
    staleTime: thirtyMinutes,
    enabled: !!projectSecret,
  });

  const getLists = useCallback(() => {
    if (isSocialMonitoring) {
      return filterReferenceElements?.lists;
    }
    return gkpLists;
  }, [gkpLists, filterReferenceElements, isSocialMonitoring]);

  const refreshData = useCallback(() => {
    return queryClient.invalidateQueries(reactQueryKey);
  }, [reactQueryKey, queryClient]);

  const listsCount =
    useMemo(() => getLists()?.reduce((a, b) => a + b.koLs.length, 0), [getLists]) || 0;

  const getProfileLists = useCallback(
    profileId =>
      getLists()
        ?.filter(list => list?.koLs?.some(kol => kol.profileId === profileId))
        .map(list => ({
          ...list,
          collectionID: list?.listId,
          collectionName: list?.listName,
        })),
    [getLists],
  );

  const addProfilesToList = useCallback(
    async (listId, profiles) => {
      setaddProfilesToListLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.addProfiles,
          data: {
            listId,
            profiles: typeof profiles === 'string' ? [profiles] : profiles,
            projectId,
          },
          failedMessage: 'Error adding profile(s) to list',
        });

        toastNotify('success', 'Profile(s) added to the list');
        if (isSocialMonitoring) {
          setFilterReferenceElements(prevState => ({
            ...prevState,
            lists: prevState.lists.map(list => ({
              ...list,
              koLs: list.listId === response.listId ? response.koLs : list.koLs,
            })),
          }));
        } else {
          refreshData();
        }
        const searchListQuery = queryClient
          .getQueryCache()
          .queries.filter(query =>
            currentPage(ROUTE.globalKolPlanningUrl)
              ? query.queryKey.includes('fetch-gkp-kol-list') &&
                query.queryKey.includes(listId.toString())
              : query.queryKey.includes('fetch-social-kol-list') &&
                query.queryKey.includes(listId.toString()),
          );
        if (searchListQuery?.length === 1) {
          queryClient.invalidateQueries(searchListQuery[0].queryKey);
        }

        setaddProfilesToListLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setaddProfilesToListLoading(false);
      }
    },
    [
      api,
      urls.addProfiles,
      projectId,
      queryClient,
      currentPage,
      refreshData,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const createList = useCallback(
    async (data, profileId) => {
      setCreateListLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.createList,
          data: {
            ...data,
            projectId,
          },
          failedmessage: 'Error creating list',
        });
        if (response) {
          toastNotify('success', 'List created');
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: sortBy([...prevState.lists, response], list => list.listName.toLowerCase()),
            }));
          }

          if (profileId?.length) {
            if (response?.listId) {
              await addProfilesToList([response.listId], profileId);
            }
          }
          if (!isSocialMonitoring) {
            refreshData();
          }
        }

        closeModal();
        setCreateListLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setCreateListLoading(false);
      }
    },
    [
      api,
      urls.createList,
      projectId,
      closeModal,
      addProfilesToList,
      refreshData,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const updateList = useCallback(
    async (data, callback) => {
      setUpdateListLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.updateList,
          data: {
            ...data,
            projectId,
          },
          failedMessage: 'Error updating list',
        });
        if (response) {
          toastNotify('success', 'List updated');
          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: [...prevState.lists.filter(list => list.listId !== response.listId), response],
            }));
          } else {
            refreshData();
          }

          if (callback) callback();
        }
        setUpdateListLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setUpdateListLoading(false);
      }
    },
    [api, projectId, refreshData, urls.updateList, isSocialMonitoring, setFilterReferenceElements],
  );

  const deleteList = useCallback(
    async listId => {
      setDeleteListLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.deleteList,
          data: { listId, projectId },
          failedMessage: 'Error deleting list',
        });
        if (response) {
          toastNotify('success', 'List deleted');

          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: filterReferenceElements?.lists?.filter(list => list.listId !== listId),
            }));
          } else {
            refreshData();
          }
        }
        setDeleteListLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setDeleteListLoading(false);
      }
    },
    [
      api,
      projectId,
      refreshData,
      urls.deleteList,
      filterReferenceElements,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const changeList = useCallback(async () => {
    setChangeListLoading(true);
    await api({
      method: 'POST',
      url: '/list/changelist',
      data: { projectId },
      failedMessage: 'Error changing list',
    });

    setChangeListLoading(false);
  }, [api, projectId]);

  const removeProfileFromList = useCallback(
    async (listid, profiles, callback) => {
      setRemoveProfileFromListLoading(true);
      try {
        const response = await api({
          method: 'POST',
          url: urls.removeProfiles,
          data: {
            listid,
            profiles,
            projectId,
          },
          failedMessage: 'Error removing profile(s) from list',
        });

        if (response) {
          toastNotify('success', 'Profile(s) removed from the list');
          if (callback) callback();

          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: prevState.lists.map(list => ({
                ...list,
                koLs:
                  list.listId === listid[0]
                    ? list.koLs.filter(kol => !profiles.includes(kol.profileId))
                    : list.koLs,
              })),
            }));
          } else {
            refreshData();
          }
          const searchListQuery = queryClient
            .getQueryCache()
            .queries.filter(query =>
              currentPage(ROUTE.globalKolPlanningUrl)
                ? query.queryKey.includes('fetch-gkp-kol-list') &&
                  query.queryKey.includes(listid.toString())
                : query.queryKey.includes('fetch-social-kol-list') &&
                  query.queryKey.includes(listid.toString()),
            );
          if (searchListQuery?.length === 1) {
            queryClient.invalidateQueries(searchListQuery[0].queryKey);
          }
        }

        setRemoveProfileFromListLoading(false);
      } catch (error) {
        toastNotify('error', error.message);
        setRemoveProfileFromListLoading(false);
      }
    },
    [
      api,
      projectId,
      queryClient,
      urls.removeProfiles,
      currentPage,
      refreshData,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const refreshLists = useCallback(
    profile => {
      if (getProfileLists(profile)) {
        refreshData();
      }
    },
    [getProfileLists, refreshData],
  );

  const configureAlerts = useCallback(
    async ({ listId, listName, notifFrequency, label }) => {
      try {
        const response = await api({
          url: urls.configureAlerts,
          method: 'POST',
          data: {
            listId,
            notifFrequency,
            projectId,
          },
        });

        if (response) {
          const alertMessage = listName
            ? `Alert for list "${listName}" set to ${label || notifFrequency}`
            : `Alerts updated to ${label}`;
          toastNotify('success', alertMessage);

          if (isSocialMonitoring) {
            setFilterReferenceElements(prevState => ({
              ...prevState,
              lists: prevState.lists.map(list => ({
                ...list,
                notifFrequency: list.listId === listId ? notifFrequency : list.notifFrequency,
              })),
            }));
            queryClient.invalidateQueries({ queryKey: ['feed'] });
          } else {
            refreshData();
          }
        }
      } catch {}
    },
    [
      api,
      projectId,
      queryClient,
      refreshData,
      urls.configureAlerts,
      isSocialMonitoring,
      setFilterReferenceElements,
    ],
  );

  const value = useMemo(
    () => ({
      lists: isSocialMonitoring ? filterReferenceElements?.lists : gkpLists,
      listsCount,
      listsLoading: isSocialMonitoring ? loadingSocialReferences : isLoading,
      addProfilesToListLoading,
      createListLoading,
      updateListLoading,
      deleteListLoading,
      changeListLoading,
      removeProfileFromListLoading,
      loading: isSocialMonitoring ? loadingSocialReferences : isLoading,
      fetchLists,
      addProfilesToList,
      createList,
      updateList,
      deleteList,
      changeList,
      removeProfileFromList,
      refreshLists,
      configureAlerts,
      getProfileLists,
    }),
    [
      listsCount,
      isLoading,
      isSocialMonitoring,
      loadingSocialReferences,
      addProfilesToListLoading,
      createListLoading,
      updateListLoading,
      deleteListLoading,
      changeListLoading,
      removeProfileFromListLoading,
      fetchLists,
      addProfilesToList,
      createList,
      updateList,
      deleteList,
      changeList,
      removeProfileFromList,
      refreshLists,
      configureAlerts,
      getProfileLists,
      filterReferenceElements?.lists,
      gkpLists,
    ],
  );

  return <ListsContext.Provider value={value} {...props} />;
};

const useLists = () => useContext(ListsContext);

export { ListsProvider, useLists };

ListsProvider.propTypes = {
  projectId: PropTypes.string.isRequired,
};
