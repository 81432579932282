import PropTypes from 'prop-types';

import styles from './Header.module.scss';

export const Header = ({ title, actions }) => {
  return (
    <section className={styles.container}>
      <h4 className={styles.title}>{title}</h4>

      <div className={styles.actions}>
        {actions?.map((action, index) => (
          <div key={index} className={styles.action}>
            {action}
          </div>
        ))}
      </div>
    </section>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.array,
};

Header.defaultProps = {
  title: '',
  actions: undefined,
};
