import { useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';

export const TextFilter = ({ filterKey, eventKey }) => {
  const { setFilter, selectedValues, resetFilter } = useFilterMenu();
  const selectedItems = selectedValues(filterKey);

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  return (
    <div ref={filterRef}>
      <div className="p-3 d-flex gap-8 align-items-center">
        <Form.Control
          type="text"
          onChange={e => setFilter({ filterKey, filterValue: [e.target.value], singleValue: true })}
          val={selectedItems?.[0] || ''}
        />
        {selectedItems?.length && (
          <Button
            icon="eraser"
            size="small"
            buttonType="secondary"
            onClick={() => resetFilter(filterKey)}
          />
        )}
      </div>
    </div>
  );
};

TextFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
