/* eslint-disable no-shadow */
import { isMobileOnly } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { useFilters } from 'context/filtersContext';
import { useGlobalKolPlanningContext } from 'context/globalKolPlanningContext';
import { useSocialMonitoring } from 'context/socialMonitoringContext';
import { useUser } from 'context/userContext';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';

import styles from './ReportsDropdown.module.scss';

const ReportsDropdown = () => {
  const { projects } = useSocialMonitoring();
  const { gkpProjects, projectDisplayName } = useGlobalKolPlanningContext();
  const history = useHistory();
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const { getProjectDisplayName } = useUser();
  const { currentSavedSearch, removeSavedSearch } = useFilters();

  const project = projects?.find(project => project.projectID === projectId);
  const projectName = projectDisplayName || getProjectDisplayName(project);
  const moduleProjects = projects || gkpProjects;
  const currentProjectName =
    isMobileOnly && projectName.length > 20 ? `${projectName.substring(0, 20)}...` : projectName;

  return (
    <>
      {moduleProjects?.length === 1 && (
        <h4 className="font-weight-700 mb-0 mr-4">{currentProjectName}</h4>
      )}

      {moduleProjects?.length > 1 && (
        <DropDownMenu
          clickElement={
            <Button buttonType="transparent" className={styles.title}>
              {currentProjectName}
            </Button>
          }
          menuItems={moduleProjects.map(project => ({
            label: getProjectDisplayName(project),
            onClick: () => {
              if (currentSavedSearch) {
                removeSavedSearch();
              }
              history.push(pathname.replace(`/${projectId}`, `/${project.projectID}`));
            },
          }))}
        />
      )}
    </>
  );
};

export default ReportsDropdown;
