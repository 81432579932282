import { useLists } from 'context/listsContext';

export const AddProfilesToListMenuItem = ({ list, selectedProfiles }) => {
  const { addProfilesToList } = useLists();

  return {
    label: 'Add Selected Profiles',
    onClick: () => addProfilesToList([list.listId], selectedProfiles),
    hidden: !selectedProfiles || !selectedProfiles?.length,
  };
};
