import PropTypes from 'prop-types';

import { useFilters } from 'context/filtersContext';

export const FilterByProfileMenuItem = ({ profileId, profileName, selectedProfiles }) => {
  const { setFilter } = useFilters();

  const updateFilters = () => {
    if (selectedProfiles?.length) {
      setFilter({
        profile: selectedProfiles,
      });
    } else {
      setFilter({
        profile: [
          {
            id: profileId,
            reference: profileName,
          },
        ],
      });
    }
  };

  return {
    label: selectedProfiles?.length > 1 ? 'Filter by profiles' : 'Filter by profile',
    onClick: () => updateFilters(),
  };
};

FilterByProfileMenuItem.propTypes = {
  profileId: PropTypes.string.isRequired,
  profileName: PropTypes.string.isRequired,
  selectedProfiles: PropTypes.array,
};

FilterByProfileMenuItem.defaultValues = {
  selectedRows: undefined,
  selectedProfiles: [],
};
