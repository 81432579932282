import PropTypes from 'prop-types';

import { useCollections } from 'context/collectionsContext';

import { formatDate } from 'utils/dateHelper';
import { useNavigate } from 'utils/hooks';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';

import ContributionSentimentIcon from 'modules/socialMonitoring/common/contributionSentimentIcon/ContributionSentimentIcon';
import ContributionSourceIcon from 'modules/socialMonitoring/common/contributionSourceIcon/ContributionSourceIcon';

import styles from './ContributionItem.module.scss';

export const ContributionItem = ({
  collectionID,
  contribution,
  selected,
  editCollection,
  onClick,
}) => {
  const {
    profileId,
    profileName,
    profileImageUrl,
    snippet,
    title,
    type,
    reach,
    sentiment,
    source,
    authorName,
    date,
    contributionId,
  } = contribution;
  const { navigateToProfileDetail, navigateToContributionDetail } = useNavigate();
  const { removeItemFromCollection } = useCollections();

  const removeSingleBookmark = () => {
    removeItemFromCollection([
      {
        collectionID,
        mentions: [{ contributionId, profileId }],
      },
    ]);
  };

  return (
    <section
      className={`
        ${styles.container} 
        ${editCollection ? styles.pointer : ''} 
        ${selected ? styles.selected : ''}
      `}
      onClick={editCollection ? () => onClick(contributionId, profileId) : undefined}
    >
      <div className={styles['heading-container']}>
        <div className={styles['profile-picture-container']}>
          <Button buttonType="transparent" onClick={() => navigateToProfileDetail(profileId)}>
            <ProfilePicture
              url={profileImageUrl}
              alt={`${profileName} profile picture`}
              size="60px"
            />
          </Button>
        </div>
        <div className={styles.name}>
          <Button buttonType="transparent" onClick={() => navigateToProfileDetail(profileId)} wrap>
            {profileName}
          </Button>
          <div className={`${styles.icons} ${styles.svgMargin}`}>
            <Icon name="megaphone" />
            {type}
          </div>
        </div>
        <div className={styles['delete-contribution']}>
          <Button buttonType="warning" onClick={removeSingleBookmark}>
            Remove
          </Button>
        </div>
      </div>

      <div className={styles['body-container']}>
        <div
          className={`${styles['body-block']} ${styles.contribution}`}
          onClick={() => navigateToContributionDetail(contributionId)}
        >
          {title && <div className={styles['body-block']}>{title}</div>}
          {snippet && <div>{snippet}</div>}
        </div>
        {authorName && (
          <div className={styles['body-block']}>
            <b>{authorName}</b>
            <span className={styles.date}>{formatDate(date)}</span>
          </div>
        )}
        <div className={`${styles['body-block']} ${styles.iconSection}`}>
          {reach > 0 && (
            <span className={`${styles.icons} ${styles.svgMargin}`}>
              <Icon name="graphStatsAscend" />
              {`Reach: ${reach}`}
            </span>
          )}
          {sentiment && (
            <span className={styles.icons}>
              <ContributionSentimentIcon sentiment={sentiment} />
            </span>
          )}
          {source && (
            <span className={`${styles.icons} ${styles.svgMargin}`}>
              <ContributionSourceIcon source={source} />
              {source}
            </span>
          )}
        </div>
      </div>
    </section>
  );
};

ContributionItem.propTypes = {
  collectionID: PropTypes.string,
  contribution: PropTypes.object,
  selected: PropTypes.bool,
  editCollection: PropTypes.bool,
  onClick: PropTypes.func,
};

ContributionItem.defaultProps = {
  collectionID: '',
  contribution: {},
  selected: false,
  editCollection: false,
  onClick: undefined,
};
