import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';

import ROUTE from 'constants/route';
import { formatDate } from 'utils/dateHelper';
import { camelize } from 'utils/formatStrings';
import { useKwelloQuery } from 'utils/hooks/kwelloQuery';
import { convertArrayToTableData } from 'utils/tableUtils';
import { isValidHttpUrl } from 'utils/urlUtils';

import ExternalLink from 'common/ExternalLink/ExternalLink';
import Icon from 'common/Icon/Icon';

import { useApi } from './useApi';
import useCurrentPage from './useCurrentPage';
import { useGkpApi } from './useGkpApi';

const DefaultCell = ({ children, className }) => {
  const value = Array.isArray(children) ? children.join(', ') : children;
  return (
    <span className={className || ''} style={value?.length < 50 ? { whiteSpace: 'nowrap' } : {}}>
      {children}
    </span>
  );
};

DefaultCell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

DefaultCell.defaultProps = {
  children: null,
  className: '',
};

const defaultCellFormatting = cell => {
  const { value, column } = cell;

  switch (column.dataType) {
    case 'Radio':
    case 'DropdownSingle':
      return (
        <DefaultCell>
          <span className={`status-${camelize(value?.label)}`}>{value?.label}</span>
        </DefaultCell>
      );
    case 'Date':
      return <DefaultCell>{formatDate(value)}</DefaultCell>;
    case 'Number':
      return <DefaultCell>{value}</DefaultCell>;
    case 'Boolean':
      return <DefaultCell>{value ? 'Yes' : 'No'}</DefaultCell>;
    case 'ConflictBoolean':
      return (
        <DefaultCell className={value ? 'text-danger' : ''}>{value ? 'Yes' : 'No'}</DefaultCell>
      );
    case 'Checkbox':
    case 'DropdownMulti':
      return (
        <DefaultCell>
          {Array.isArray(value) ? value.map(val => val?.label).join(', ') : value}
        </DefaultCell>
      );
    case 'dbo.InteractionStatus':
      return (
        <DefaultCell>
          <span className={`status-${camelize(value)}`}>{value}</span>
        </DefaultCell>
      );
    case 'Url':
      if (isValidHttpUrl(value)) {
        return (
          <ExternalLink className="d-block text-center" href={value}>
            <Icon name="linkIcon" />
          </ExternalLink>
        );
      }

      return <DefaultCell>{value}</DefaultCell>;
    default:
      return <DefaultCell>{value}</DefaultCell>;
  }
};

export const useCustomTable = ({
  columnOverrides,
  onFirstColumnClick,
  apiData,
  enabled,
  queryKey,
} = {}) => {
  const { currentPage } = useCurrentPage();
  const { call } = useApi();
  const { call: gkpCall, loading: gkpLoading } = useGkpApi();
  const queryClient = useQueryClient();

  const formatTableData = useCallback(data => {
    return (
      data.reduce((dataArray, current) => {
        return [...dataArray, convertArrayToTableData(current)];
      }, []) || []
    );
  }, []);

  const formatData = useCallback(apiResponse => {
    const sortedColumns = sortBy(
      apiResponse?.columns.filter(column => column.sortOrder !== null) || [],
      [item => item.sortOrder, item => item.label],
    );

    const columnHeaders =
      sortedColumns.reduce((cols, current, index) => {
        if (current.sortOrder || current.sortOrder === 0) {
          cols.push({
            ...current,
            Header: current.label,
            accessor: `${current.key}`,
            type: 'switch',
            Cell: cell => defaultCellFormatting(cell),
            onClick: index === 0 && onFirstColumnClick ? onFirstColumnClick : undefined,
            ...columnOverrides?.[current.key],
          });
        }

        return cols;
      }, []) || [];

    const data = formatTableData(apiResponse?.tableData || []);

    return { columnHeaders, data };
    // Can't add overrides or first click as they're never memoized
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTableData = async () => {
    let formattedData = [];
    try {
      let response;

      if (currentPage(ROUTE.globalKolPlanningUrl)) {
        response = await gkpCall(apiData);
      } else {
        response = await call(apiData);
      }

      formattedData = formatData(response);

      return { columns: formattedData.columnHeaders, data: formattedData.data };
    } catch {}
    return formattedData;
  };

  const {
    data: queryData,
    isLoading,
    kwelloQueryKey,
  } = useKwelloQuery({
    queryKey,
    queryFn: () => fetchTableData(),
    enabled,
  });

  const replaceTableData = useCallback(
    (data, format = true) => {
      queryClient.setQueryData(kwelloQueryKey, old => ({
        columns: old?.columns,
        data: format ? formatTableData(data) : data,
      }));
    },
    [queryClient, kwelloQueryKey, formatTableData],
  );

  return {
    loading: isLoading || gkpLoading,
    columns: queryData?.columns,
    data: queryData?.data,
    kwelloQueryKey,
    replaceTableData,
  };
};
