import PropTypes from 'prop-types';

import { useConfirm } from 'context/confirmationContext';
import { useFilterMenu } from 'context/filterMenuContext';
import { useFilters } from 'context/filtersContext';
import { useSlideDrawer } from 'context/slideDrawerContext';

import Grid from 'common/Grid/Grid';
import GridItem from 'common/Grid/GridItem';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import { getActiveSavedSearchFilters } from 'common/SavedSearch';

import styles from './SavedSearchDetails.module.scss';

import { SavedSearchFilter } from './SavedSearchFilter';

export const SavedSearchDetailsCollection = ({
  search,
  selectedSearches,
  toggleSelectedSearch,
  editSearch,
}) => {
  const { closeSlideDrawer } = useSlideDrawer();
  const { confirm } = useConfirm();

  const {
    loadingSavedSearches,
    saveSearch,
    currentSavedSearch,
    removeSavedSearch,
    SocialMonitoringDefaultFilters,
    resetFilter,
  } = useFilters();
  const { clearChanges } = useFilterMenu();

  if (loadingSavedSearches) {
    return <LoaderSpinner />;
  }

  const savedSearchFilters = getActiveSavedSearchFilters(search?.filter);

  const removeOneFilter = filter => {
    if (currentSavedSearch?.savedSearchId === search?.savedSearchId) {
      removeSavedSearch();
    }
    const data = {
      ...search,
      filter: {
        ...search.filter,
        [filter.originalKey]: SocialMonitoringDefaultFilters[filter.originalKey],
      },
      notifFrequency: undefined,
    };
    saveSearch(data);
  };

  const confirmRemoveFilter = filter => {
    confirm({
      title: 'Remove Search Filter',
      text: `Are you sure you want to remove the '${filter.label}' filter?`,
      onConfirm: () => removeOneFilter(filter),
    });
  };

  const updateSavedSearch = filters => {
    saveSearch({
      ...search,
      filter: { ...search.filter, ...filters },
      notifFrequency: undefined,
    });
    resetFilter();
    clearChanges();
    closeSlideDrawer();
  };

  const onApply = filters => {
    confirm({
      title: 'Update Filters',
      text: 'Are you sure you want to update the filters?',
      onConfirm: () => updateSavedSearch(filters),
    });
  };

  return (
    <>
      {savedSearchFilters?.length === 0 && (
        <section className={styles['no-filters']}>No filters found for this search</section>
      )}
      <Grid columns={1}>
        {savedSearchFilters?.map((filter, index) => (
          <GridItem key={index} colSpan={1}>
            <SavedSearchFilter
              onRemoveFilter={confirmRemoveFilter}
              filter={filter}
              editSearch={editSearch}
              onClick={toggleSelectedSearch}
              selected={selectedSearches.includes(filter.originalKey)}
              onApply={onApply}
            />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};

SavedSearchDetailsCollection.propTypes = {
  search: PropTypes.object.isRequired,
  selectedSearches: PropTypes.array,
  toggleSelectedSearch: PropTypes.func,
  editSearch: PropTypes.bool,
};

SavedSearchDetailsCollection.defaultProps = {
  selectedSearches: [],
  toggleSelectedSearch: undefined,
  editSearch: false,
};
