import PropTypes from 'prop-types';

import HamburgerMenuIcon from 'common/hamburgerMenuIcon/HamburgerMenuIcon';

import styles from './SlidingMenu.module.scss';

export const SlidingMenu = ({ children, onChange, showMenu, height, width }) => {
  return (
    <div>
      <HamburgerMenuIcon
        onClick={onChange}
        variant="dark"
        className={styles.menuIcon}
        isOpen={showMenu}
      />
      <div
        id={styles.flyoutMenu}
        className={showMenu ? styles.show : styles.hide}
        style={{ height, width }}
      >
        {children}
      </div>
    </div>
  );
};

SlidingMenu.propTypes = {
  children: PropTypes.node,
  onChange: PropTypes.func,
  showMenu: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
};

SlidingMenu.defaultProps = {
  children: undefined,
  onChange: undefined,
  showMenu: false,
  height: '100vh',
  width: '90vw',
};
