import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { useProfileLists } from 'utils/hooks';

import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import { FilterByProfileMenuItem } from 'common/MenuItems/FilterByProfileMenuItem';
import { CollectionManagementModal } from 'common/Modals/CollectionManagementModal';

import { AddListAction } from 'Pages/Lists/Components/Actions';

import ShowContributionDetailsMenuItem from './MenuItems/ShowContributionDetailsMenuItem';

const FeedPostMenuItems = ({ data }) => {
  const contributionId = data?.contributionId;
  const profileId = data?.profileId;
  const { allCollections, selectedLists, onSave: onListSave } = useProfileLists({ profileId });
  const { openModal } = useModal();

  const defaultMenuOptions = [
    {
      label: 'Mentioned Profile',
      type: 'title',
    },
    FilterByProfileMenuItem({
      profileId,
      profileName: data?.profileName,
    }),
    {
      label: 'Manage Lists',
      onClick: () =>
        openModal(
          <CollectionManagementModal
            title="Manage Lists"
            allCollections={allCollections}
            selectedCollections={selectedLists}
            createCollection={[<AddListAction profiles={[profileId]} />]}
            onSave={onListSave}
            type="list"
          />,
        ),
    },
    {
      label: 'Contribution',
      type: 'title',
    },
    ShowContributionDetailsMenuItem({ contributionId }),
  ];

  return <DropDownMenu alignRight menuItems={defaultMenuOptions} />;
};

FeedPostMenuItems.propTypes = {
  data: PropTypes.shape({
    profileId: PropTypes.string,
    profileName: PropTypes.string.isRequired,
    notifFrequency: PropTypes.string.isRequired,
    contributionId: PropTypes.string.isRequired,
  }).isRequired,
};

export default FeedPostMenuItems;
