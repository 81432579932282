import { useRef } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

import { useFilterMenu } from 'context/filterMenuContext';

import useBootstrapAccordionHook from 'utils/hooks/useBootstrapAccordionHook';

import Button from 'common/button/Button';

import styles from './BooleanFilter.module.scss';

export const BooleanFilter = ({
  trueLabel = 'Yes',
  falseLabel = 'No',
  filterKey,
  source,
  eventKey,
}) => {
  const { setFilter, selectedValues, resetFilter } = useFilterMenu();
  const selectedItems = selectedValues(filterKey);

  const filterRef = useRef();
  useBootstrapAccordionHook({ eventKey, element: filterRef });

  const handleChange = e => {
    setFilter({
      filterKey,
      filterValue: [e.target.value === 'true'],
      singleValue: true,
    });
  };

  const reset = () => {
    resetFilter(filterKey);
  };

  return (
    <div ref={filterRef}>
      <div className={styles.container}>
        <div className={`boolean-radio-group ${styles['radio-group']}`}>
          <Form.Check
            type="radio"
            className={selectedItems?.[0] === true ? 'active' : ''}
            label={trueLabel}
            name={filterKey}
            checked={selectedItems?.[0] === true}
            onChange={handleChange}
            value="true"
            inline
            id={`${source}-${filterKey}-true`}
          />
          <Form.Check
            type="radio"
            className={selectedItems?.[0] === false ? 'active' : ''}
            label={falseLabel}
            name={filterKey}
            checked={selectedItems?.[0] === false}
            onChange={handleChange}
            value="false"
            inline
            id={`${source}-${filterKey}-false`}
          />
          {selectedItems?.length && (
            <Button
              icon="eraser"
              size="small"
              buttonType="secondary"
              className={styles.button}
              onClick={reset}
            />
          )}
        </div>
      </div>
    </div>
  );
};

BooleanFilter.propTypes = {
  trueLabel: PropTypes.string,
  falseLabel: PropTypes.string,
  source: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  filterKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

BooleanFilter.defaultProps = {
  trueLabel: 'Yes',
  falseLabel: 'No',
  source: undefined,
};
