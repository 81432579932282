import emptyProfilePlaceholder from 'assets/images/emptyProfilePlaceholder.png';
import PropTypes from 'prop-types';

import styles from './ProfilePicture.module.scss';

import { LetterAvatar } from './LetterAvatar';

const ProfilePicture = ({ url, alt, size, className, onClick, showLetters, letterClassName }) => {
  const handleOnClick = () => {
    if (onClick) onClick();
  };
  if (showLetters) {
    return <LetterAvatar className={letterClassName} />;
  }

  return (
    <div
      className={`${styles.wrapper} ${onClick ? styles.pointer : ''} ${className}`}
      style={{ height: size, width: size }}
      onClick={handleOnClick}
    >
      <div
        className={styles.background}
        style={{ height: size, width: size, backgroundImage: `url("${url}")` }}
      />

      <img className={styles.image} src={url || emptyProfilePlaceholder} alt={alt} />
    </div>
  );
};

ProfilePicture.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  showLetters: PropTypes.bool,
  letterClassName: PropTypes.string,
};

ProfilePicture.defaultProps = {
  url: undefined,
  alt: 'Profile Picture',
  size: '128px',
  className: '',
  onClick: undefined,
  showLetters: false,
  letterClassName: '',
};

export default ProfilePicture;
