import { useState } from 'react';

import { useDebounce } from 'common/Debounce/useDebounce';
import SearchBar from 'common/searchBar/SearchBar';

// This component will always return a debounced search value from the search bar
// Pass in a debounceDelay to add a pause to allow the user to finish typing the search value, useDebounce uses a default value of 300ms

export const useSearchBar = ({
  debounceDelay = 300,
  placeholder,
  hideSearchIcon = false,
  whiteBackground,
  clearSearch,
  className,
} = {}) => {
  const [searchText, setSearchText] = useState('');
  let searchValue = useDebounce(searchText, debounceDelay);
  searchValue = searchValue.trim();
  const onClearSearch = () => {
    setSearchText('');
  };

  const searchBar = (
    <SearchBar
      onChange={value => setSearchText(value)}
      value={searchText}
      placeholder={placeholder}
      hideSearchIcon={hideSearchIcon}
      whiteBackground={whiteBackground}
      clearSearch={clearSearch ? onClearSearch : undefined}
      className={className}
    />
  );

  return { searchValue, searchBar, setSearchText };
};
