import ExternalLink from 'common/ExternalLink/ExternalLink';

import CompliancePage from './CompliancePage';

const PrivacyPolicy = () => (
  <CompliancePage title="Privacy policy" downloadLink="/static/privacy-policy.pdf">
    <h6>
      Privacy Notice for Healthcare Practitioners (HCPs) / Key Opinion Leaders (KOLs)
      (&lsquo;Profiles&rsquo;)
    </h6>

    <p>
      Acceleration Point collects, collates and analyses information from
      &lsquo;Profiles&rsquo;&nbsp; for use by our clients, being Pharmaceutical companies and
      provides the information in an online platform named &lsquo;Kwello&rsquo;
      (&lsquo;Kwello.com&rsquo;) to allow interested organisations to track the information relating
      to &lsquo;Profiles&rsquo;.
    </p>

    <p>
      The purpose of this notice is to explain how Kwello collects and shares &lsquo;Profile&rsquo;
      data with its users, how we process their personal information and the privacy rights of the
      &lsquo;Profile&rsquo;.
    </p>

    <h6>Who we are</h6>

    <p>Kwello is a wholly owned subsidiary of Acceleration Point LLC</p>

    <ul>
      <li>
        (USA) Acceleration Point LLC - 119 Norfolk Ave., Suite 110, Roanoke, VA, 24011
        <ul>
          <li>Telephone: +15402964075</li>
        </ul>
      </li>
      <li>
        (United Kingdom) Acceleration Point LTD - Summit House, 170 Finchley Road, London, United
        Kingdom, NW3 6BP
        <ul>
          <li>Telephone: +442086098730</li>
        </ul>
      </li>
    </ul>
    <p>
      Data Protection Officer (DPO) contact email &ndash;
      <a href="mailto:dataprotection@accelerationpoint.com">dataprotection@accelerationpoint.com</a>
    </p>

    <h6>What information do we collect</h6>
    <p>
      We collect a variety of information from &lsquo;Profiles&rsquo; and store this information on
      our database, this information is broken down and can include some or all of the following
    </p>
    <ul>
      <li>
        Name, gender, place(s) of work, associations, work email, work telephone number, website(s)
        URLs, Geolocational data, professional Title(s)
      </li>
      <li>
        Social media posts and profile information such as a Bio (where provided), and handles from
        platforms such as Twitter, Linked In, YouTube, Facebook (Business Pages), Instagram
      </li>
      <li>
        Meetings, Congresses, Publications, Presentations, Clinical Trials, Treatment Guidelines
      </li>
    </ul>

    <h6>How do we collect information</h6>
    <p>
      The data we collect is from publicly available sources such as PubMed, Social media feeds,
      ORCID and other similar content providers, all the information that we collect and share is
      information that the &lsquo;Profiles&rsquo; has published into the public domain, we simply
      collate, analyse and store this information to allow pharmaceutical companies to learn more
      about what is being said &ndash; typically relevant to specific Therapeutic Areas, about their
      Brand and/or products (such as drugs and treatments).
    </p>

    <h6>How do we use information</h6>
    <p>
      The data that we hold on you is used to allow Pharmaceutical companies to monitor and learn
      more about their brand and products. Our legal basis for doing this is that we have a
      legitimate interest in providing our services to our clients, our customers have a similar
      legitimate interest to understand how their products and brand are perceived in the
      marketplace.
    </p>

    <h6>Sharing of information</h6>
    <p>
      We will share the data with our customers who request information relating to subjects that
      you are linked to.
    </p>

    <h6>How long do we keep it for</h6>
    <p>
      We will keep your data for as long as we require it to fulfil our service, we will of course
      delete your data if you request us to do so, or if you delete it from the platform on which
      you published it.
    </p>

    <h6>Where do we store your data</h6>
    <p>
      We may transfer your data and store in a country other than your own. Whenever we transfer
      your data outside of the UK we ensure that adequate safeguards are in place to protect your
      data .
    </p>

    <h6>Rights of Data subjects</h6>
    <p>
      <strong>What are your rights regarding your personal data?</strong>
    </p>
    <p>
      <strong>You have the right:</strong>
    </p>
    <ul>
      <li>
        To access to your personal data and information about our processing of it. You also have
        the right to request a copy of your personal data (but we will need to remove information
        about other people).
      </li>
      <li>To rectify incorrect personal data that we are processing.</li>
      <li>
        To request that we erase your personal data if:
        <ul>
          <li>we no longer need it;</li>
          <li>if we are processing your personal data by consent and you withdraw that consent;</li>
          <li>if we no longer have a legitimate ground to process your personal data; or</li>
          <li>we are processing your personal data unlawfully</li>
        </ul>
      </li>
      <li>To object to our processing if it is by legitimate interest.</li>
      <li>To restrict our processing if it was by legitimate interest.</li>
      <li>
        To request that your personal data be transferred from us to another company if we were
        processing your data under a contract or with your consent and the processing is carried out
        automated means.
      </li>
    </ul>
    <p>If you want to exercise any of these rights, please contact us.</p>
    <p>
      If you have a concern about the way we are collecting or using your personal data, please
      raise your concern with us in the first instance.&nbsp; You may also contact the Information
      Commissioner&rsquo;s Office at{' '}
      <ExternalLink href="https://ico.org.uk/concerns">https://ico.org.uk/concerns</ExternalLink>
    </p>
  </CompliancePage>
);

export default PrivacyPolicy;
