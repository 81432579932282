import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useAuth } from 'context/authContext';
import { useUser } from 'context/userContext';

import { EXPORT_FEED_LIMIT, exportDataWithApi } from 'utils/exportUtils';
import { useCollectionsFeed } from 'utils/hooks/apiHooks/useCollectionsFeed';

import Button from 'common/button/Button';
import { DropDownMenu } from 'common/DropdownMenu/DropdownMenu';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './CollectionsFeedActions.module.scss';

export const CollectionsFeedActions = ({ collection }) => {
  const [exporting, setExporting] = useState(false);
  const { accessToken } = useAuth();
  const { sortByFeedPreference, setSortByFeedPreference } = useUser();
  const { projectId } = useParams();
  const { data, collectionFilters } = useCollectionsFeed({ collection });
  const hasData = data?.length > 0; // get this from useFeed data.length > 0

  const sortOptions = [
    {
      label: 'Most Recent',
      value: 'most-recent',
    },
    {
      label: 'Oldest',
      value: 'least-recent',
    },
    {
      label: 'Highest Reach',
      value: 'most-reach',
    },
    {
      label: 'Lowest Reach',
      value: 'least-reach',
    },
  ];

  const exportFeeds = async () => {
    setExporting(true);
    exportDataWithApi({
      accessToken,
      dataName: 'Feed',
      url: '/shared/feedAll',
      data: {
        filter: collectionFilters,
        pageSize: EXPORT_FEED_LIMIT,
        sortBy: sortByFeedPreference,
        projectId,
      },
      onSuccess: () => setExporting(false),
      onFailure: () => setExporting(false),
    });
  };
  return (
    <div className={styles['feed-actions']}>
      <div className={styles['feed-title-button']}>
        {exporting ? (
          <LoaderSpinner className={styles['export-spinner']} />
        ) : (
          <KwelloTooltip content={hasData ? 'Export feed' : 'No feed to export'} position="bottom">
            <span className={styles['export-icon']}>
              <Button
                buttonType="transparent"
                icon="exportToCsvIcon"
                onClick={exportFeeds}
                disabled={!hasData}
              />
            </span>
          </KwelloTooltip>
        )}
      </div>
      <div className={styles['feed-title-button']}>
        <DropDownMenu
          alignRight
          clickElement={
            <div>
              <KwelloTooltip content="Sort" position="bottom">
                <span>
                  <Button
                    buttonType="transparent"
                    size="small"
                    icon="sortIcon"
                    disabled={!hasData}
                  />
                </span>
              </KwelloTooltip>
            </div>
          }
          menuItems={[
            {
              type: 'title',
              label: 'Sort by',
            },
            ...sortOptions.map(option => ({
              label: option.label,
              onClick: () => setSortByFeedPreference(option.value),
            })),
          ]}
        />
      </div>
    </div>
  );
};

CollectionsFeedActions.propTypes = {
  collection: PropTypes.object,
};

CollectionsFeedActions.defaultProps = {
  collection: undefined,
};
