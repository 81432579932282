import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useUser } from 'context/userContext';

import useCurrentPage from 'utils/hooks/useCurrentPage';

import { SocialMonitoringModulePicker } from 'common/moduleSelectionMenu';
import { InsightsCapturePicker } from 'common/moduleSelectionMenu/InsightsCapturePicker';

import styles from './MobileModuleSelectionMenu.module.scss';

import { MobileModule } from './MobileModule';

export const MobileModuleSelectionMenu = ({ onSelect }) => {
  const { currentPage } = useCurrentPage();
  const [isExpanded, setIsExpanded] = useState(-1);

  const renderModuleButtons = module => {
    if (module.key === 'socialMonitoring') {
      return <SocialMonitoringModulePicker setIsExpanded={setIsExpanded} onSelect={onSelect} />;
    }

    if (module.key === 'insightsCapture') {
      return <InsightsCapturePicker setIsExpanded={setIsExpanded} onSelect={onSelect} />;
    }
    return (
      <NavLink
        to={module.navLink ? `${module.navLink}` : 'null'}
        className="d-flex align-items-center mb-4"
      >
        &nbsp;
      </NavLink>
    );
  };

  const { modules } = useUser();
  const filteredModules = modules.filter(
    module => module.key === 'socialMonitoring' || module.key === 'insightsCapture',
  );

  return (
    <div>
      <section className={styles['modules-container']}>
        {filteredModules.map((module, index) => (
          <MobileModule
            index={index}
            expandMenu={setIsExpanded}
            key={module.key}
            module={module}
            active={currentPage(module.navLink)}
            expanded={isExpanded}
            content={renderModuleButtons(module)}
            onSelect={onSelect}
          />
        ))}
      </section>
    </div>
  );
};

MobileModuleSelectionMenu.propTypes = {
  onSelect: PropTypes.func,
};

MobileModuleSelectionMenu.defaultProps = {
  onSelect: undefined,
};
