import { isMobileOnly } from 'react-device-detect';
import { useQueryClient } from '@tanstack/react-query';
import emptyProfilePlaceholder from 'assets/images/emptyProfilePlaceholder.png';
import PropTypes from 'prop-types';

import { useModal } from 'context/modalContext';

import { useItemInCollections, useNavigate } from 'utils/hooks';

import { BookmarkIcon } from 'common/BookmarkIcon';
import Icon from 'common/Icon/Icon';
import { AlertButton } from 'common/MenuItems';
import { CollectionManagementModal } from 'common/Modals/CollectionManagementModal';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';

import FeedPostMenuItems from 'modules/socialMonitoring/common/Menus/FeedPostMenuItems';

import { AddCollectionAction } from 'Pages/Bookmarks/Components/Actions';

import styles from './FeedComponent.module.scss';

const CardHeader = ({
  post,
  hideActions,
  menuItems,
  hideFeedPost,
  onAlertUpdate,
  onProfileNavigate,
}) => {
  const { navigateToProfileDetail } = useNavigate();
  const queryClient = useQueryClient();
  const contributionId = post?.contributionId;
  const profileId = post?.profileId;
  const { collections, selectedCollections, onSave } = useItemInCollections({
    mentions: [{ contributionId, profileId }],
  });
  const { openModal } = useModal();

  const onManageCollections = () => {
    openModal(
      <CollectionManagementModal
        title="Manage Collections"
        allCollections={collections}
        selectedCollections={selectedCollections}
        createCollection={[<AddCollectionAction mentions={[{ contributionId, profileId }]} />]}
        onSave={onSave}
        type="contribution"
      />,
    );
  };

  const onMenuSuccess = frequency => {
    if (onAlertUpdate) {
      onAlertUpdate(frequency);
    } else {
      queryClient.invalidateQueries({ queryKey: ['feed'] });
    }
  };

  const openProfile = () => {
    if (!isMobileOnly) {
      onProfileNavigate
        ? onProfileNavigate(post.profileId)
        : navigateToProfileDetail(post.profileId);
    }
  };

  return (
    <>
      <div className="mr-1">
        <ProfilePicture
          size="60px"
          url={post.profileUrl || post.profileImageUrl || emptyProfilePlaceholder}
          onClick={openProfile}
        />
      </div>
      <div className="d-flex flex-column justify-content-between w-100">
        <div className="text-regular font-weight-500 d-flex justify-content-between">
          <div tabIndex={0} onClick={openProfile} role="button">
            {post.profileName}
          </div>
          <div className="justify-content-between post-header mb-2 d-flex">
            {hideActions ? null : (
              <div className="right d-flex align-items-center actions">
                <AlertButton
                  notifFrequency={post?.notifFrequency}
                  profileId={profileId}
                  onSuccess={onMenuSuccess}
                  className={styles['alert-icon']}
                  noHeader={isMobileOnly}
                />
                {!isMobileOnly && (
                  <BookmarkIcon
                    mentions={[{ contributionId: post.contributionId, profileId }]}
                    onClick={onManageCollections}
                  />
                )}
                {!isMobileOnly && !hideFeedPost && <FeedPostMenuItems data={post} />}
                {post.ordinalId && `[${post.ordinalId}]`}
              </div>
            )}
            {menuItems && (
              <div className="right d-flex align-items-center actions">
                {menuItems?.map((item, ind) => (
                  <span key={ind}>{item}</span>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <span className="badge badge-light text-capitalize font-weight-500 text-sm mr-2 p-1 d-flex align-items-center">
            <Icon name="megaphone" className="mr-1" />
            {post.type.toLowerCase()}
          </span>
        </div>
      </div>
    </>
  );
};

export default CardHeader;

CardHeader.propTypes = {
  post: PropTypes.object,
  hideActions: PropTypes.bool,
  menuItems: PropTypes.array,
  hideFeedPost: PropTypes.bool,
  onAlertUpdate: PropTypes.func,
  onProfileNavigate: PropTypes.func,
};

CardHeader.defaultProps = {
  post: undefined,
  hideActions: false,
  menuItems: undefined,
  hideFeedPost: false,
  onAlertUpdate: undefined,
  onProfileNavigate: undefined,
};
