import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'common/button/Button';
import Icon from 'common/Icon/Icon';
import Scroll from 'common/Scroll/Scroll';
import KwelloTooltip from 'common/tooltip/KwelloTooltip';

import styles from './MobileModule.module.scss';

const iconNames = {
  'Social Monitoring': 'socialMonitoringModule',
  Insights: 'insightsModule',
  'SOSV Experts': 'sosvExpertsModule',
  'SOSV Science': 'sosvScienceModule',
  'Custom Reports': 'customReportsModule',
  'Global KOL Planning': 'globalKOLPlanningModule',
  'Insights Capture': 'insightsCaptureModule',
};

export const MobileModule = ({ index, module, active, expanded, content, expandMenu }) => {
  const history = useHistory();

  const navigate = () => {
    if (expanded === -1 || expanded !== index) {
      expandMenu(index);
    } else {
      expandMenu(-1);

      history.push({
        pathname: `${module.navLink}`,
      });
    }
  };

  return (
    <section
      className={`
        ${styles['mobile-container']} 
        ${active || expanded === index ? styles.active : ''} 
        ${expanded === index ? styles.expanded : ''}
      `}
    >
      <KwelloTooltip content={module.name} position="right" disabled={expanded > -1}>
        <div className={styles['mobile-name-dropdown']} onClick={navigate}>
          <Button
            icon={iconNames[module.name]}
            size="medium"
            radius="large"
            className={`
          ${styles['mobile-button']} 
          ${active ? styles['mobile-active'] : ''} 
          ${expanded === index ? styles['mobile-expanded'] : ''}
        `}
            block
            hideArrow
          >
            {module.name}
          </Button>

          <Icon
            name="arrowUp"
            size={13}
            className={`${styles['arrow-icon']} ${expanded === index ? styles.rotate : null}`}
          />
        </div>
      </KwelloTooltip>
      {expanded === index && (
        <section className={styles['mobile-content']}>
          <Scroll className={styles.scroll} hideArrow>
            {content}
          </Scroll>
        </section>
      )}
    </section>
  );
};

MobileModule.propTypes = {
  active: PropTypes.bool.isRequired,
  content: PropTypes.node.isRequired,
  expandMenu: PropTypes.func.isRequired,
  expanded: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    navLink: PropTypes.string,
  }).isRequired,
};
