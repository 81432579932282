import { useEffect, useState } from 'react';
import emptyProfilePlaceholder from 'assets/images/emptyProfilePlaceholder.png';
import PropTypes from 'prop-types';

import ROUTE from 'constants/route';
import { useKolSearch, useNavigate } from 'utils/hooks';
import useCurrentPage from 'utils/hooks/useCurrentPage';

import Button from 'common/button/Button';
import LoaderSpinner from 'common/LoaderSpinner/LoaderSpinner';
import ProfilePicture from 'common/ProfilePicture/ProfilePicture';
import Scrollable from 'common/scrollable/Scrollable';
import { useSearchBar } from 'common/searchBar/useSearchBar';

import styles from './SearchProfiles.module.scss';

const HighlightedResult = ({ text, searchString }) => {
  const searchIndex = text.toLowerCase().indexOf(searchString.toLowerCase());
  const textBefore = text.substring(0, searchIndex);
  const textAfter = text.substring(searchIndex + searchString.length, text.length);

  // Getting this substringed to keep original casing
  const searchText = text.substring(searchIndex, searchIndex + searchString.length);

  return (
    <>
      <span>{textBefore}</span>
      <span className="font-weight-bold">{searchText}</span>
      <span>{textAfter}</span>
    </>
  );
};

HighlightedResult.propTypes = {
  text: PropTypes.string.isRequired,
  searchString: PropTypes.string,
};

HighlightedResult.defaultProps = {
  searchString: '',
};

const SearchProfilesMenuContent = ({ onSelect, listId, onCancel }) => {
  const { navigateToProfileDetail } = useNavigate();
  const { searchValue, searchBar: SearchBarComponent } = useSearchBar();
  const { currentPage } = useCurrentPage();
  const { gkpProfiles, gkpProfilesLoading, socialProfiles, socialProfilesLoading } = useKolSearch({
    listId,
  });

  const menuOptions = currentPage(ROUTE.socialMonitoringUrl) ? socialProfiles : gkpProfiles;

  const [filterOptions, setFilterOptions] = useState(menuOptions);

  useEffect(() => {
    if (searchValue) {
      const name = currentPage(ROUTE.socialMonitoringUrl) ? 'profileName' : 'name';

      setFilterOptions(
        menuOptions.filter(item => {
          if (searchValue.includes('-')) {
            return item[name]?.toLowerCase().includes(searchValue.toLowerCase());
          }
          return item[name]?.toLowerCase().replace('-', ' ').includes(searchValue.toLowerCase());
        }),
      );
    } else {
      setFilterOptions(menuOptions);
    }
  }, [searchValue, menuOptions, currentPage]);

  return (
    <div className={styles.container}>
      <h5>Search Profiles</h5>
      <div>
        {SearchBarComponent}
        {gkpProfilesLoading && currentPage(ROUTE.globalKolPlanningUrl) && <LoaderSpinner />}
        {socialProfilesLoading && currentPage(ROUTE.socialMonitoringUrl) && <LoaderSpinner />}
        <Scrollable maxHeight={285}>
          {filterOptions?.map((x, i) => (
            <div
              key={i}
              className="d-flex p-1 my-1 align-items-center hoverable"
              role="button"
              tabIndex={i}
              onClick={() => {
                if (onSelect) {
                  onSelect([listId], x.profileId);
                } else {
                  navigateToProfileDetail(x.profileId);
                }
              }}
              aria-label="Select profile"
            >
              <ProfilePicture
                className="mr-2"
                size="2rem"
                url={x.profileImageUrl || emptyProfilePlaceholder}
              />
              <div className="flex-grow-1">
                <HighlightedResult text={x.profileName || x.name} searchString={searchValue} />
              </div>
            </div>
          ))}
        </Scrollable>
      </div>
      {onCancel && (
        <div className={styles.footer}>
          <Button buttonType="secondary" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  );
};

export default SearchProfilesMenuContent;

SearchProfilesMenuContent.propTypes = {
  onSelect: PropTypes.func,
  listId: PropTypes.string,
  onCancel: PropTypes.func,
};

SearchProfilesMenuContent.defaultProps = {
  onSelect: undefined,
  listId: undefined,
  onCancel: undefined,
};
