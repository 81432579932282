import { isMobileOnly } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useLayout } from 'context/layoutContext';

import { Button } from 'common';
import { MobileNavBar } from 'common/Mobile';
import { NavBar } from 'common/NavBar';
import RouteContainer from 'common/RouteContainer/RouteContainer';

import styles from './NotFoundTemplate.module.scss';

export const NotFoundTemplate = ({ title, message, children, homeButton, homeButtonText }) => {
  const { hasSideBar } = useLayout();
  const history = useHistory();

  const navigateHome = () => {
    history.push('/');
  };

  if (isMobileOnly) {
    return (
      <>
        <MobileNavBar hideFilters />
        <section className={styles['mobile-container']}>
          <h3>{title}</h3>

          {message && <p>{message}</p>}

          {children}

          {(homeButton || homeButtonText) && (
            <Button icon="house" className="text-center" onClick={navigateHome}>
              {homeButtonText}
            </Button>
          )}
        </section>
      </>
    );
  }

  return (
    <RouteContainer hasSideBar={hasSideBar}>
      <NavBar />
      <section className={styles.container}>
        <h3>{title}</h3>

        {message && <p>{message}</p>}

        {children}

        {(homeButton || homeButtonText) && (
          <Button icon="house" className="text-center" onClick={navigateHome}>
            {homeButtonText}
          </Button>
        )}
      </section>
    </RouteContainer>
  );
};

NotFoundTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  children: PropTypes.node,
  homeButton: PropTypes.bool,
  homeButtonText: PropTypes.string,
};

NotFoundTemplate.defaultProps = {
  children: null,
  homeButton: false,
  homeButtonText: 'Back to home',
};
